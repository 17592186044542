import {useEffect} from 'react';
import Routers from "./routers";
import "./assets/scss/fonts/index.scss";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-MW6QC8W'
}

TagManager.initialize(tagManagerArgs)

function App() {
  // console.log(document.location.pathname,'rerererererere')
  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: `https://reesa.com${document.location.pathname}`,
        title: document.location.pathname
      }
    });
  }, [document])
  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
