import styled from "styled-components";
import * as variables from '../../Style/__variables';

export const GoogleMaps = styled.div`
  height: ${(props) => (props.minHeight ? props.minHeight : "76vh")};
  .search_area_button{
    position: absolute;
    top: 45%;
    left: 35%;
    padding: 13px 35px 13px 35px;
    border: none;
    color: ${variables.team_list_name};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: ${variables.white};
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
  }
  .map-pin__outline {
    stroke-width: 2px;
    stroke: #49bcc4;
  }
`;
