import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const CheckoutSectionComponent = styled.div`
   
    .propert_checkout_footer{
        background-color: ${variables.white};
        box-shadow: 0px 7px 7px -4px rgba(0, 0, 0, 0.21);
        height: 80px;
        .propert_checkout_footer_lefttext{
            color : ${variables.darkPink}
        }   
    }
    .fixed_size{
        display:grid;
        grid-template-columns: 1fr 1fr;
        @media(max-width:576px){
            grid-template-columns: 1fr;
        }

    }
    .propert_checkout_footer_lefttext{
    cursor: pointer;
    }
`;
