import { PerspectiveMercatorViewport } from 'viewport-mercator-project';
import request from 'superagent';
import  {  WebMercatorViewport } from 'react-map-gl';


const screenBreakpoints = [
    {
      width: 99999,
      cardsPerRow: 2,
      resultsWidth: 755
    },
    {
      width: 1360,
      cardsPerRow: 2,
      resultsWidth: 755
    },
    {
      width: 1030,
      cardsPerRow: 1,
      resultsWidth: 395
    }
  ];
  function getViewportWidth() {
    if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth;
    }
    if (document.body && document.body.clientWidth) {
      return document.body.clientWidth;
    }
    return window.innerWidth;
  }
  
  function getViewportHeight() {
    if (document.documentElement && document.documentElement.clientHeight) {
      return document.documentElement.clientHeight;
    }
    if (document.body && document.body.clientHeight) {
      return document.body.clientHeight;
    }
    return window.innerHeight;
  }
  export function getContainerSize() {
    const screenWidth = getViewportWidth();
    const screenHeight = getViewportHeight();
    const resultsWidth = screenBreakpoints.reduce((result, bp) => {
      if (screenWidth < bp.width) {
        // return bp.resultsWidth + 150;
         const resultsWidth = 830
        return resultsWidth;
      }
      return result;
    }, 0);
    const mapWidth = (screenWidth - 830) ;
    
    // const mapWidth = (screenWidth - resultsWidth) ;
    const mapHeight = screenHeight - (400);
    
    return {
      resultsWidth,
      mapWidth,
      mapHeight,
      screenWidth
    };
  }