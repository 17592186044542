import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const Typo = styled.div`
  font-size: ${(props) => variables[props.size]}px;
  font-family: ${(props) => variables[props.variant]};
  @media (max-width: 576px) {
    font-size: ${(props) => variables[props.mobileSize]}px;
  } ;
`;
