import { PerspectiveMercatorViewport } from 'viewport-mercator-project';
import request from 'superagent';
import  {  WebMercatorViewport } from 'react-map-gl';


let googleKey;
const apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

if (JSON.stringify(false)) {
  googleKey = window.__GOOGLE_TOKEN__;
}

export function lookupForward(value) {
  return new Promise((resolve, reject) => {
    return request
      .get(apiUrl)
      .query({
        address: `${value}, UK`,
        key: 'AIzaSyBafczuk1KLX0BvZ6Grbk_K30LjyiGo-xw',
        sensor: false,
        region: 'uk'
      })
      .end((err, res) => {

        if (err) {
          return reject(err);
        }
        switch (res.body.status) {
          case 'OK':
          case 'ZERO_RESULTS':
            return resolve(res.body.results);
          default: {
            return reject('Failed to fetch geo', res.body);
          }
        }
      });
  })
  .then((data) =>  parse(data))
  .catch(err => {
    console.error(err);
    throw err;
  });
}

function parseGeometry({ types, geometry }) {
  let bounds;
  let output = {};
  // if it's a route then use the bounds
  // because viewport doesn't look good
  // if (!types.includes('route')) {
    bounds = geometry.viewport;
    // bounds = geometry.bounds;
    const { northeast, southwest } = bounds;
    const bbox = [northeast.lng, northeast.lat, southwest.lng, southwest.lat];
    output = {
      ...output,
      bbox
    };
  // }
  const { location } = geometry;
  const center = [location.lng, location.lat];

  return {
    ...output,
    center
  };
}

function parse(data) {
  
  return {
    features: data.map(d => ({
      id: d.place_id,
      place_name: d.formatted_address,
      relevance: 1,
      text: d.formatted_address,
      ...parseGeometry(d)
    }))
  };
}

// export function getViewportFromBounds({ bounds, zoom = 10, width = 300, height = 300 }) {
//     const viewport = new PerspectiveMercatorViewport({
//     longitude: 0,
//     latitude: 0,
//     zoom,
//     width,
//     height
//   });
//   return viewport.fitBounds(bounds);
// }

export const getBoundsForPoints = (northeastlat,northeastlng,southwestlat,southwestlng, height, width) => {
  // const cornersLongLat = [
  //   [-0.1222881 , 51.5404951],
  //   [-0.1289415 , 51.5291887]
  // ]
  const cornersLongLat = [
    [Number(northeastlng) , Number(northeastlat)],
    [Number(southwestlng), Number(southwestlat)]
  ]

  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({ width: width ? width : 320 , height: height ? height : 660 })
    .fitBounds(cornersLongLat, { padding: 10 }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom   } = viewport

  return { longitude, latitude, zoom }
}


export const getBounds =(northeastlng, northeastlat, southwestlng, southwestlat) =>{
  const bounds = `${southwestlng},${northeastlat}|${northeastlng},${northeastlat}|${northeastlng},${southwestlat}|${southwestlng},${southwestlat}`;

  return bounds
}