import React from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import "./index.scss";

function index(props) {
  return (
    <div>
      <NavBar history={props.history} />
      <div className="container pb-5">
        <h1 className="privacy mb-5">Privacy Policy</h1>
        <div className="reesaText">
          <p>
            REESA is a trading name of Row Group Limited ("we") have created
            this privacy policy statement in order to demonstrate our firm
            commitment to safeguarding the privacy of our website visitors and
            customers. In choosing to use this website you are agreeing to the
            terms of this privacy policy, so please familiarise yourself with
            its contents.
          </p>
          <p>
            For the purposes of the Data Protection Act 1998 (the Act), the data
            controller is Row Group Limited.
          </p>
          <h2>What information do we collect?</h2>
          <p>
            We may collect, store and use the following kinds of personal
            information:
          </p>
          <ul>
            <li>
              Information about your computer and about your visits to and use
              of this website, such as your IP address, geographical location,
              browser type, referral source, length of visit and number of page
              views
            </li>
            <li>
              Information relating to any transactions carried out between you
              and us, on or in relation to this website. This includes
              information relating to the products and services we offer
            </li>
            <li>
              Information that you provide to us for the purpose of registering
              with us including your email address, name, address, business name
              and address and any other details that you provide
            </li>
            <li>
              Information that you provide to us for the purpose of subscribing
              to our website services, email notifications and/or newsletters
            </li>
          </ul>
          <h2>Cookies</h2>
          <p>
            We use cookies on this website. A cookie is a text file sent by a
            web server to a web browser, and stored by the browser. The text
            file is then sent back to the server each time the browser requests
            a page from the server. This enables the web server to identify and
            track the web browser.
          </p>
          <p>
            We may send a cookie which may be stored by your browser on your
            computer's hard drive. We may use that information to recognise your
            computer when you visit our website, and to personalise our website
            for you.
          </p>
          <p>
            We may also use the information we obtain from the cookie in the
            administration of this website, to improve the website's usability
            and for marketing purposes.
          </p>
          <p>
            Most browsers allow you to refuse to accept cookies. This will,
            however, have a negative impact upon the usability of many websites,
            including ours.
          </p>
          <p>
            We use an analytics package to monitor use of this website. This
            programme generates statistical and other information about our
            website use by means of cookies, which are stored on users'
            computers. The information generated relating to our website is used
            to create reports about the use of the website.
          </p>
          <h2>Using your personal data</h2>
          <p>
            Personal data submitted on this website will be used for the
            purposes specified in this privacy policy or in relevant parts of
            the website.
          </p>
          <p>We may use your personal information to:</p>
          <ul>
            <li>to register you as a new or existing client / customer </li>
            <li>
              whilst processing your enquiry when looking for a property we will
              liaise with the vendors and providers of property in the search
              for a solution to match your requirement{" "}
            </li>
            <li>Whilst booking viewings of a property(s)</li>
            <li>
              Maintaining contact with you whilst you are in occupation of a
              property following your initial search
            </li>
            <li>
              conduct market research and tracking of advertising, enquiry and
              sales data
            </li>
            <li>administer the website</li>
            <li>
              improve your browsing experience by personalising the website
            </li>
            <li>enable your use of the services available on the website;</li>
            <li>send you general (non-marketing) commercial communications</li>
            <li>
              send you email notifications which you have specifically requested
            </li>
            <li>
              send you newsletters and other marketing communications relating
              to our business or the businesses of carefully selected third
              parties which we think may be of interest to you by post or, where
              you have specifically agreed to this, by email or similar
              technology
            </li>
            <li>
              provide third parties with statistical information about our users
              – but this information will not be used to identify any individual
              user
            </li>
            <li>
              deal with enquiries and complaints made by or about you related to
              the website.
            </li>
            <li>
              We use the order information that we collect generally to fulfil
              any orders placed through the site (including processing your
              payment information, arranging for shipping, and providing you
              with invoices and/or order confirmations)
            </li>
          </ul>
          <p>
            Additionally, when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information,
            email address, and phone number. We refer to this information as
            “Order Information.”
          </p>
          <p>
            You can inform us at any time if you no longer require marketing
            communications to be sent by emailing us at{" "}
            <span className="text-primary">privacy@reesa.com </span>
          </p>

          <h2>Providing information to Third parties</h2>
          <p>
            Row Group Limited will not sell, trade or rent your information for
            the purposes of direct marketing.
          </p>
          <p>
            We will never disclose information about you to third parties,
            except to fulfil your specific instructions for a product/property
            or in the event that third parties deliver the relevant service.
          </p>
          <p>
            Other circumstances where we are operationally or contractually
            obliged to pass on your information may be where third parties
            administer part or all of the service, underwriting, for pricing
            purposes, testing, and to maintain management information for
            business analysis.
          </p>
          <h2>Other disclosures</h2>
          <p>
            In addition to the disclosures reasonably necessary for the purposes
            identified elsewhere in this privacy policy, we may disclose
            information about you:
          </p>
          <ul>
            <li>to the extent that we are required to do so by law, and</li>
            <li>
              to the purchaser, or prospective purchaser, of any business or
              asset which we are, or are contemplating, selling.
            </li>
          </ul>
          <p>
            Except as provided in this privacy policy, we will not provide your
            information to third parties.
          </p>

          <h2>Data Retention</h2>
          <p>
            Personal data is retained to fulfil the purposes we collected it for
            and then thereafter only for as long as necessary so that we satisfy
            any reporting for accounting or legal requirements of keeping
            certain information including transaction or purchase data, identity
            and contract details.{" "}
          </p>
          <p>
            There could be times when we will analyse personal data retained
            anonymously for statistical or research reasons. In this situation
            there will be no means to identify you within a data set and in this
            case we reserve the right to use this information in perpetuity.{" "}
          </p>
          <p>
            For accounting and tax purposes there can be a legal requirement to
            retain such information for a period of up to seven years from the
            end of a transaction. Also applying to data retention is a
            consideration into the potential risk of unauthorised disclosure of
            personal data outside of the reasons for it being retained and
            whether it is possible to achieve the purpose for retaining the
            information via other means whilst still being compliant to any
            legal requirements.
          </p>

          <h2>Data Security</h2>
          <p>
            Once we have received your information, we use reasonable technical
            and organisational procedures to try and prevent unauthorised access
            to, or loss, misuse, disclosure or modification of the personal
            information that you provide to us.
          </p>
          <p>
            We will store all the personal information you provide on our secure
            servers. All electronic transactions you make to or receive from us
            will be encrypted using SSL technology.
          </p>
          <p>
            We confine access to your personal data to those employees,
            contractors and other third parties who have a business need to
            know. They will only process your personal data on our instructions
            and they are subject to a duty of confidentiality. This includes
            exchanging information with other companies and organisations for
            the purposes of fraud protection and credit risk reduction.
          </p>
          <p>
            Where you have chosen a password which grants you to access certain
            areas of our website, you are responsible for keeping this password
            confidential. We will not ask you for your password, and recommend
            that you do not share your password in order to avoid illegitimate
            intrusion into your privacy or your business affairs.{" "}
          </p>
          <p>
            All the information you supply will be held in the strictest
            confidence and only used for the purposes described in this policy.
            Due to the nature of the internet, the transmission of information
            online is not completely secure. Although we will do our best to
            protect your personal data in accordance with industry practice, we
            cannot guarantee the security of your data transmitted to our
            website.
          </p>

          <h2>Your rights to your personal information</h2>
          <p>
            You have the right to contact us in order to find out what
            information we hold about you, or to:{" "}
          </p>
          <ul>
            <li>Correct your data</li>
            <li>Withdraw your consent for how we use your data</li>
            <li>Erase your personal data </li>
          </ul>
          <p>
            If you wish to exercise these rights please contact us in accordance
            with the contact details set out above.
          </p>

          <h2>Changes to this Privacy Policy</h2>
          <p>
            {" "}
            We may update or amend this Privacy Policy from time to time to
            comply with law or to meet our changing business requirements
            without notice to you. Any updates or amendments will be posted on
            the website and will be effective as soon as posted. By continuing
            to use the website, you agree to be bound by the terms of these
            updates and amendments.
          </p>
          <h2>Our liability to you</h2>
          <p>
            We endeavour to publish accurate, up-to-date information on this
            site, but Row Group Limited does not accept any liability for any
            inaccuracies or omissions. Nor does Row Group Limited accept
            liability for any direct, indirect, special, consequential or other
            losses or damages of any kind arising from the use of this website
            or information contained within it.
          </p>
          <h2>How to complain </h2>
          <p>
            If you have any concerns about our use of your personal information,
            you can make a complaint to us at REESA (Row Group Limited), Longbow
            house, 1st floor, 20 Chiswell Street, London EC1Y 4TW for data
            protection queries.
          </p>
          <p>
            You can also complain to the ICO if you are unhappy with how we have
            used your data.
          </p>
          <p>The ICO’s address:</p>
          <p className="pb-5">
            Information Commissioner’s Office, Wycliffe House, Water Lane,
            Wilmslow, Cheshire SK9 5AF
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default index;
