import React from "react";
import Typography from "src/Component/Typography";
import { TextCardWrapper } from "./styledComponent";
const TextCard = ({ title, description, themeColor }) => {
  return (
    <TextCardWrapper themeColor={themeColor}>
      <div className="title_font">{title}</div>
      {typeof description === "string" ? (
        <Typography size="xxxSmall" variant="regular">
          <div
            className="title_font_content"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </Typography>
      ) : (
        <div className="row">
          {description?.mainDes && (
            <div className="col-sm-12">
              <Typography
                size="xxxSmall"
                variant="regular"
                className="title_font_content mb-3"
              >
                {description.mainDes}
              </Typography>
            </div>
          )}
          {description.subDesc && (
            <div className="col-sm-12">
              <Typography size="xxxSmall" variant="regular" className="mb-3">
                {description.subDesc}
              </Typography>
            </div>
          )}
          {description?.items1 && (
            <div className={description.items2 ? "col-sm-6" : "col-sm-12"}>
              {description.items1.map((val, i) => {
                return (
                  <Typography
                    className=""
                    size="xxxSmall"
                    variant="regular"
                    className={`title_font_content listItem ${
                      description?.bulletType == "+" ? "plus" : "minus"
                    }`}
                  >
                    {val}
                  </Typography>
                );
              })}
            </div>
          )}
          {description?.items2 && (
            <div className="col-sm-6">
              {description.items2.map((val, i) => {
                return (
                  <Typography
                    size="xxxSmall"
                    variant="regular"
                    className={`title_font_content listItem ${
                      description?.bulletType == "+" ? "plus" : "minus"
                    }`}
                  >
                    {val}
                  </Typography>
                );
              })}
            </div>
          )}

          {description?.subList && (
            <div className="col-sm-12">
              {description.subList.map((val, i) => {
                return (
                  <Typography
                    className=""
                    size="xxxSmall"
                    variant="regular"
                    className="title_font_content mb-3 px-3"
                  >
                    {val}
                  </Typography>
                );
              })}
            </div>
          )}
          {description.bottomDes && (
            <div className="col-sm-12">
              <Typography
                size="xxxSmall"
                variant="regular"
                className="mb-3 title_font_content"
              >
                {description.bottomDes}
              </Typography>
            </div>
          )}
        </div>
      )}
    </TextCardWrapper>
  );
};

export default TextCard;
