import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const ResultSectionComponent = styled.div`
  
  .propert_result_section {
    height: ${(props) => props.mapHeight}px;
  }

  @media screen and (max-width: 576px){
    height: 440px;
  }
}

`;
