import background1 from '../assets/CaseStudies/Case_studies/background1.jpeg';
import background2 from '../assets/CaseStudies/Case_studies/background2.jpeg';
import background3 from '../assets/CaseStudies/Case_studies/background3.jpeg';
import background4 from '../assets/CaseStudies/Case_studies/background4.jpeg';
import background5 from '../assets/CaseStudies/Case_studies/background5.jpeg';
import logo1 from '../assets/CaseStudies/Case_studies/logo1.png';
import logo2 from '../assets/CaseStudies/Case_studies/logo2.png';
import logo3 from '../assets/CaseStudies/Case_studies/logo3.png';
import logo4 from '../assets/CaseStudies/Case_studies/logo4.png';
import logo5 from '../assets/CaseStudies/Case_studies/logo5.png';



export const caseStudiesList = [
    {
        img: logo1,
        bckg: background1,
        address: "Acting for Prosek Partners who acquired the 4th and 5th floors of 17 St Swithin’s Lane, London, EC4",
        location: "PROSEK PARTNERS – CITY, LONDON",
        name:"First"
    },
    {
        img: logo2,
        bckg: background2,
        address: " A multi phase project involving the acquisition of a leasehold headquarters, subsequent disposal and move to serviced office accommodation over 3 years",
        location: "LOVECRAFTS – MIDTOWN, LONDON",
        name:"Second"
    },
    {
        img: logo3,
        bckg: background3,
        address: "Acting for EB7 limited on the acquisition of the 4th floor of Holborn Tower, WC1",
        location: "EB7 LIMITED – MIDTOWN, LONDON",
        name:"Third"
    },
    {
        img: logo4,
        bckg: background4,
        address: "A move from Leasehold Space to Modern flexible Space",
        location: "SOLA – CITY OF LONDON, LONDON SE1 & EC3",
        name:"Fourth"
        
    },
    {
        img: logo5,
        bckg: background5,
        address: "Flexible office relocation 190 people",
        location: "HIVE – 1 BLOOMSBURY WAY, COVENT GARDEN, LONDON",
        name:"Fifth"
    },
]