export const businessRatesData1 = {
  content: [
    {
      title: "Received your New Business Rates bill?",
      description: {
        mainDes:
          "If you’ve just moved into a new business premises a couple of things normally happen:",
        subList: [
          "1. You'll receive a bill to pay from the Local Authority in connection to  business rates.",
          "2. You’ll probably be the recipient of cold calls and unsolicited offers from unknown companies offering a service to help reduce the bill.",
        ],
        bottomDes:
          "At this stage REESA will provide quick and trusted advice as to whether your assessment looks correct and if there is a valid opportunity to appeal.",
      },
    },
    {
      title: "Understanding your Rateable Value",
      description: `<p>In order to appreciate whether the Government has correctly assessed the level of business rates to apply to a premises the facts need to be established by an expert.</p>
        <p>Incomplete entries, changes that haven’t been factored in or simple mistakes in the valuation are things that REESA will investigate.<p>`,
    },
  ],
  heading:
    "REESA's dedicated team of expert Surveyors advise on mitigating your Business Rates liability",
};

export const businessRatesData2 = {
  content: [
    {
      title: "Appealing Business Rates",
      description:
        "If there is a feeling that that your Business Rates are too high then a case can be put together to appeal the assessment with the Local Council. There are technical steps to be considered and REESA's professional advisors will be managing the process on your behalf.",
    },
    {
      title: "Empty Business Rates",
      description:
        "When a property becomes vacant there are statutory grounds to claim empty property relief. REESA can also manage beneficial occupation tactics to reduce liabilities.",
    },
    {
      title: "Budget planning and estimates",
      description: {
        items1: [
          "Create budgets to assist with business planning",
          "Reduce costs for unused space",
          "Effective forecasting to capture business rate changes",
        ],
      },
    },
    {
      title: "EXEMPTIONS & RELIEFS",
      description: {
        items1: [
          "Charitable relief",
          "Small business relief",
          "Retail discount ",
          "Hardship relief ",
          "Enterprise zones ",
          "Exempted buildings",
        ],
      },
    },
  ],
  heading: "We fight for savings",
};

export const businessRatesData3 = {
  content: [
    {
      title: "Historic Rates Audits",
      description:
        "Our Business Rates Surveyors undertake an audit of past and current rates demands to investigate historical mistakes, and/or anomalies which could arise to a refund claim. ",
    },
    // {
    //   title: "No Win – No Fee",
    //   description:
    //     "As a REESA client we will work on Business Rates appeals and advice on fee based on a percentage of savings achieved. Please contact us to learn more.",
    // },
    {
      title: "MULTI TENANTED BUILDINGS",
      description: {
        mainDes:
          "When lettable units change constantly within an estate or building, a proactive and dedicated approach is required to deal with the constant admin and opportunities.",
        items1: [
          "Serviced offices ",
          "Coworking spaces",
          "Storage facilites",
          "Shopping centres",
          "Enterprise parks",
        ],
      },
    },
    {
      title: "ACROSS THE UK & IRELAND",
      description: {
        mainDes:
          "The different regions that we operate in present specific Business Rates challenges with their own rating systems.",
        subDesc: "We serve :",
        items1: ["England", "Wales", "Scotland", "Northern Ireland", "Ireland"],
      },
    },
  ],
  heading: "Ongoing support and coverage ",
};
