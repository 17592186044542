import * as constants from "./constant";
import { createAction } from "redux-actions";

export const getPropertyDataRequest = createAction(constants.GET_PROPERTY_DATA_REQUEST);
export const getPropertyDataSuccess = createAction(constants.GET_PROPERTY_DATA_SUCCESS);
export const getPropertyDataError = createAction(constants.GET_PROPERTY_DATA_ERROR);
export const getPropertyDataClear = createAction(constants.GET_PROPERTY_DATA_CLEAR);

export const getPropertyDetailRequest = createAction(constants.GET_PROPERTY_DETAILS_REQUEST);
export const getPropertyDetailaSuccess = createAction(constants.GET_PROPERTY_DETAILS_SUCCESS);
export const getPropertyDetailError = createAction(constants.GET_PROPERTY_DETAILS_ERROR);

export const shortlistAdd = createAction(constants.SHORTLIST_ADD);
export const shortlistedRemove = createAction(constants.SHORTLIST_REMOVE);


export const registerRequest = createAction(constants.REGISTER_REQUEST);
export const registerSuccess = createAction(constants.REGISTER_SUCCESS);
export const registerError = createAction(constants.REGISTER_ERROR);

export const enqueryFormRequest = createAction(constants.ENQUIRE_FORM_REQUEST);
export const enqueryFormSuccess = createAction(constants.ENQUIRE_FORM_SUCCESS);
export const enqueryFormError = createAction(constants.ENQUIRE_FORM_ERROR);

export const resetenqueryFormSuccess= createAction(constants.RESET_ENQUIRE_FORM_REQUEST)
export const resetregistrationFormSuccess = createAction(constants.RESET_REGISTRATION_REQUEST)

export const notifyFormRequest = createAction(constants.NOTIFY_FORM_REQUEST);
export const notifyFormSuccess = createAction(constants.NOTIFY_FORM_SUCCESS);
export const notifyFormError = createAction(constants.NOTIFY_FORM_ERROR);