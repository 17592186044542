import CompassNavbar from "../../Component/Compass/Navbar";
import CompassFooterImage from "../../assets/compass/com-footer.jpg";
import CompassHeroImage1 from "../../assets/compass/c-hero-1.jpg";
import CompassHeroImage2 from "../../assets/compass/c-hero-2.jpg";
import CompassHeroImage3 from "../../assets/compass/c-hero-3.jpg";
import CompassHeroImage4 from "../../assets/compass/newimage.png";
import InclusiveServiceIcon from "../../assets/compass/inclusive-services.png";
import AddServicesIcon from "../../assets/compass/add-on-services.png";
import { ReactComponent as CompassBg } from "../../assets/compass/compass-bg-img.svg";

import CompassImage1 from "../../assets/compass/com-img-1.jpg";
import Typography from "src/Component/Typography";
import {
  CompassDesc,
  CompassBenifitSection,
  PercentageSection,
  CarouselSection,
  ServicesSection,
  LookingForSection,
  PromiseSection,
  CompassFooter,
} from "./styledComponent";
import CompassImage2 from "src/assets/compass/com-img-2.png";
import CompassImage3 from "src/assets/compass/com-img-3.png";
import CompassImage4 from "src/assets/compass/comSafari.jpg";
import CompassImage5 from "src/assets/compass/com-img-5.png";
import BannerImage from "src/assets/compass/c-banner.jpg";
import CompassCarousel from "src/Component/Compass/Carousel";
import Button from "src/Component/common/Button";

const benifitsSection = [
  {
    image: CompassImage2,
    desc: "Inspiration & differentiation",
  },
  {
    image: CompassImage3,
    desc: "Valuable & engaging content",
  },
  {
    image: CompassImage4,
    desc: "Evidence & validation",
  },
  {
    image: CompassImage5,
    desc: "Time saving & clarity",
  },
];

const percentageSection = [
  {
    val: 30,
    desc: "Average occupancy saving p/a",
  },
  {
    val: 20,
    desc: "Discount on all Real Estate services",
  },
  {
    val: 10,
    desc: "Discount at our online Furniture store",
  },
  {
    val: 20,
    desc: "Discount on all Recruitment fees",
  },
];
const slide1 = (
  <div className="w-100">
    <div className="tag">
      <Typography
        variant="extraBold"
        size="ExtraLarge"
        mobileSize="smLarge"
        className="reesacompass"
      >
        REESA Compass
      </Typography>
    </div>
    <img src={CompassHeroImage1} className="w-100 compassHeroImage" />
  </div>
);
const slide2 = (
  <div className="w-100">
    <div className="caption">
      <Typography variant="extraBold" size="ExtraLarge" mobileSize="smLarge">
        How you work,{" "}
      </Typography>
    </div>
    <img src={CompassHeroImage2} className="w-100 compassHeroImage" />
  </div>
);
const slide3 = (
  <div className="w-100">
    <div className="caption">
      <Typography variant="extraBold" size="ExtraLarge" mobileSize="smLarge">
        Where you work,{" "}
      </Typography>
    </div>
    <img src={CompassHeroImage3} className="w-100 compassHeroImage" />
  </div>
);
const slide4 = (
  <div className="w-100">
    <div className="caption">
      <Typography variant="extraBold" size="ExtraLarge" mobileSize="smLarge">
        Propelling people.{" "}
      </Typography>
    </div>
    <img src={CompassHeroImage4} className="w-100 compassHeroImage" />
  </div>
);

const Compass = () => {
  return (
    <>
      <CompassNavbar />
      <CarouselSection className="arrowIssue">
        <CompassCarousel className="arrowIssue" data={[slide1, slide2, slide3, slide4]} />
      </CarouselSection>
      <CompassDesc className="row align-items-center">
        <div className="col-sm-5 col-12 col-left mb-5 mb-sm-0">
          <Typography
            variant="extraBold"
            size="xxxxLarge"
            mobileSize="mdLarge"
            className="title"
          >
            What is REESA Compass?
          </Typography>
          <Typography
            variant="regular"
            size="small"
            mobileSize="xxxSmall"
            className="desc"
          >
            <p>
              Save money across all areas of your Real Estate and human
              resources. Maximising their impact, return on investment and
              driving shareholder value.
            </p>
            <p>
              REESA Compass members get more & pay less on everything property &
              people.
            </p>
            <p>
              Memberships are on a rolling monthly contract which can be
              cancelled at any time.
            </p>
          </Typography>
        </div>
        <div className="col-sm-7 col-right">
          <img src={CompassImage1} className="w-100" />
        </div>
      </CompassDesc>
      <CompassBenifitSection>
        <Typography
          size="xxxxLarge"
          variant="extraBold"
          className="title ml-5"
          mobileSize="mdLarge"
        >
          Member benefits
        </Typography>
        <div className="row m-0">
          {benifitsSection.map((item, i) => {
            return (
              <div className="col-sm-6 col-md-3 col-lg-3 d-flex flex-column align-items-center">
                <img src={item.image} className="w-100" />
                <Typography
                  variant="regular"
                  size="xxMedium"
                  mobileSize="xxxSmall"
                  className="text-center benifitDesc"
                >
                  {item.desc}
                </Typography>
              </div>
            );
          })}
        </div>
      </CompassBenifitSection>

      <PercentageSection className="row m-0">
        {percentageSection.map((item, i) => {
          return (
            <div className="col-sm-6 col-lg-3 col-12">
              <Typography
                variant="extraBold"
                size="xxxxxxxLarge"
                mobileSize="xxxxLarge"
                className="percentageText"
              >
                {item.val}%
              </Typography>
              <Typography
                variant="regular"
                size="xxxxMedium"
                mobileSize="xxxSmall"
                className="percentageDesc"
              >
                {item.desc}
              </Typography>
            </div>
          );
        })}
      </PercentageSection>
      <img src={BannerImage} className="w-100" />
      <ServicesSection>
        <div className="row">
          <div className="col-sm-6 col-12">
            <Typography
              size="xxxxLarge"
              variant="extraBold"
              className="title"
              mobileSize="mdLarge"
            >
              Inclusive services
            </Typography>
            <Typography
              size="xxxxMedium"
              mobileSize="xxxSmall"
              variant="regular"
              className="desc"
            >
              <p>
                Every REESA Compass member will receive our FREE and inclusive
                services.{" "}
              </p>
              <p>
                These ensure you are on track to reach your organisations future
                goals and aspirations.
              </p>
            </Typography>
            <img src={AddServicesIcon} className="w-100" />
          </div>
          <div className="col-sm-6 col-12">
            <img src={InclusiveServiceIcon} className="w-100" />
            <Typography
              size="xxxxLarge"
              mobileSize="mdLarge"
              variant="extraBold"
              className="title"
            >
              Add-on services{" "}
            </Typography>
            <Typography
              size="xxxxMedium"
              mobileSize="xxxSmall"
              variant="regular"
              className="desc"
            >
              <p>
                Nobody likes paying for services they don’t use. With REESA
                Compass you can pick and choose what services you require, as
                and when you need them.
              </p>{" "}
              <p>
                Simply self-serve, add what you want to your basket and check
                out.
              </p>
            </Typography>
          </div>
        </div>
      </ServicesSection>
      <LookingForSection className="text-center">
        <Typography
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="mdLarge"
          className="title"
        >
          Didn’t find what you were looking for?
        </Typography>
        <Typography
          variant="regular"
          size="xxxxMedium"
          mobileSize="xxxSmall"
          className="desc"
        >
          If you are interested in creating a bespoke service for your
          organisation then get in touch with us so that you can build a
          tailored package.
        </Typography>
      </LookingForSection>
      <PromiseSection>
        <div className="row align-items-center">
          <div className="col-sm-6 col-12">
            <Typography
              size="xxxxLarge"
              mobileSize="mdLarge"
              variant="extraBold"
              className="title"
            >
              Our forever promise
            </Typography>
            <Typography
              variant="regular"
              size="xxxMedium"
              mobileSize="xxxSmall"
              className="desc"
            >
              <p>
                Our firm has been built by putting our members interests above
                our own.
              </p>{" "}
              <p>
                We pride ourselves on being the most honest and trustworthy
                company providing our products and services in the UK and
                Ireland.
              </p>
              <p>
                As a proud member of the Royal Institution of Chartered
                Surveyors we uphold the highest standards of conduct and
                professional services.
              </p>
              <p>
                For as long as our members have property and people, we promise
                to work alongside them to ensure that better results are
                achieved.
              </p>
            </Typography>
          </div>
          <div className="col-sm-6 col-12">
            <CompassBg className="w-100 compassBg" />
          </div>
        </div>
      </PromiseSection>
      <div style={{ backgroundImage: `url(${CompassFooterImage})` }}>
        <LookingForSection className="text-center">
          <div className='gettingStartedSection'
          >
            <Typography
              variant="extraBold"
              size="xxxxLarge"
              mobileSize="mdLarge"
              className="title pt-3"
            >
              Getting started{" "}
            </Typography>
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxxSmall"
              className="desc pb-5"
            >
              Join REESA Compass for FREE and start enjoying the benefits today.
            </Typography>
            <Button
              color={"waterBlue"}
              className="text-uppercase  px-5"
              onClick={() => window.open('https://store.reesa.com/user/register')}
            >
              <span style={{ textDecoration: "underline" }}>CLICK HERE</span> TO
              SIGN UP
            </Button>
          </div>
        </LookingForSection>
      </div>
    </>
  );
};

export default Compass;
