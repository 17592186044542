import React, { useState, useEffect } from "react";
import PropertyResultList from "../../PropertyResultList";
import { markerList } from "../../HomePage/MapSection/Map";
import PropertyMapSection from '../../PropertyMap';
import {ResultSectionComponent} from './styledComponent';

function ResultSection({mapHeight,resultsWidth,mapWidth, pageCount,urlLocation,isLoading,markerList,handleCheckoutFormSubmit,handleCheckoutFormInput, handleShowCardDetails, google,propertyData, ...props }) {
  const [activeMarker ,setActivemarker] = useState("")
  const [inactiveInactiveCard, setInactiveInactiveCard] =useState(false)
const handleActiveMarker = (e) =>{
  if(e == activeMarker ){
    setInactiveInactiveCard(false)
  }else{
    setActivemarker(e)
    setInactiveInactiveCard(true)
  }
}
  useEffect(()=>{
    setInactiveInactiveCard(false)
  },[])
  const handleActiveMarkerClicButton = () =>{
    setInactiveInactiveCard(false)
  }
  const handleActiveMarkerCard = () => {
    setInactiveInactiveCard(false)
  }

  return (
    <ResultSectionComponent mapHeight={mapHeight}>
    <div className='no-gutters d-flex propert_result_section' >
      <div style={{width : resultsWidth}}>
        <PropertyResultList
         handleShowCardDetails={(propertyId) =>handleShowCardDetails(propertyId)}
         propertyData={propertyData}
         handleCheckoutFormSubmit={handleCheckoutFormSubmit}
         handleCheckoutFormInput={handleCheckoutFormInput}
         isLoading={isLoading}
         mapHeight={mapHeight}
         activeMarker={activeMarker}
         inactiveInactiveCard={inactiveInactiveCard}
         handleActiveMarkerCard={handleActiveMarkerCard}
        />
      </div>
      <div style={{width : mapWidth }}>
        <PropertyMapSection
         northeastlat={urlLocation.northeastlat}
         northeastlng={urlLocation.northeastlng} 
         southwestlat={urlLocation.southwestlat} 
         southwestlng={urlLocation.southwestlng}  
         width ={645} 
         height={650}
         markerList={markerList}  
         propertyData={propertyData} 
         pageCount={pageCount}
         minHeight={mapHeight}
         handleActiveMarker={handleActiveMarker}
         handleActiveMarkerClicButton={handleActiveMarkerClicButton}
         activeMarker={activeMarker}
         />
      </div>
    </div>
    {/* <div style={{width : mapWidth }}>
        <PropertyMapSection
         northeastlat={urlLocation.northeastlat}
         northeastlng={urlLocation.northeastlng} 
         southwestlat={urlLocation.southwestlat} 
         southwestlng={urlLocation.southwestlng}  
         width ={645} 
         height={650}
         markerList={markerList}  
         propertyData={propertyData} 
         pageCount={pageCount}
         minHeight={mapHeight}
         handleActiveMarker={handleActiveMarker}
         handleActiveMarkerClicButton={handleActiveMarkerClicButton}
         activeMarker={activeMarker}
         />
      </div> */}
    </ResultSectionComponent>
  );
}
export default ResultSection;
