import React, { useState } from "react";
import {
  GoogleApiWrapper,
  Map,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";
import { mapstyle } from "./mapStyle";
import "./index.scss";
import markerImg from "../../../assets/marker.svg";
import Typography from "src/Component/Typography";
import Homemap from "../../../assets/Homemap/desktop_map.png"
import { markerList } from "./Map";

const MapSection = ({ history, google, ...props }) => {
  const [markerListItem, SetMarkerListItem] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [markerValue, setMarkerValue] = useState({});

  let handleMouseOver = (props, marker, e) => {
    setShowInfoWindow(true);
  };
  const onMouseoverMarker = (val) => {
    SetMarkerListItem(markerList);
  };
  const onMouseoutMarker = () => {
    SetMarkerListItem([]);
  };
  const containerStyle = {
    backgroundImage: "linear-gradient(to bottom, #3E3E54, #151D35)"
  }

  return (
    <div className="map_section">
      <div className="row no-gutters">
        <div className="col-lg-5 col-sm-12 text-white">
          <div className="map-text">
            <Typography
              className="heading-title"
              variant="extraBold"
              size="xxxxLarge"
              mobileSize="smLarge"
            >
              All Over the UK and Ireland
            </Typography>

            <div className="my-4">
              <Typography
                className="desc"
                variant="regular"
                size="xsLarge"
                mobileSize="xxSmall"
              >
                The territory of the UK & Ireland is 69% water, the rest is
                covered by REESA.
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-sm-12">
          <div className="googleMaps">
            <Map
              styles={mapstyle}
              containerStyle={containerStyle}
              google={google}
              data-cy="google-map"
              visible={true}
              initialCenter={{
                lat: 54.809129,
                lng: -7.997996,
              }}
              zoom={5.7}
              scrollwheel={false}
              navigationControl={false}
              mapTypeControl={false}
              disableDefaultUI={true}
              onMouseover={() => onMouseoverMarker()}
              onMouseout={() => onMouseoutMarker()}
            >
              {markerListItem &&
                markerListItem.map((val) => (
                  <Marker
                    onClick={(a, e) => handleMouseOver(val, a, e)}
                    title={val.name}
                    name={val.name}
                    position={{ lat: val.position.lat, lng: val.position.lng }}
                    style={{
                      backgroundColor: "red ",
                      fontSize: "30px",
                      height: "20px",
                      width: "30px",
                    }}
                    icon={markerImg}
                  >
                  
                  </Marker>
                ))}
            </Map>
            </div>
    
        </div>
      </div>
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyCKcf-kB3dLR0XC8t3pHPJcgxOQUFJ78qk",
  region: "IN",
})(MapSection);
