import React from "react";
import PropertyResultList from "../../PropertyResultList";
import CheckoutForm from "../CheckoutForm";
import Typography from "../../Typography";
import { CheckoutSectionComponent } from "./styledComponent";

function CheckoutSection({
  handleShowCardDetails,
  handleCheckoutFormSubmit,
  handleCheckoutFormInput,
  handleCheckoutFormcheckbox,
  ShortListReducer,
  propertyData,
  handleBack,
  enqueryFormReducer,
  email,
}) {
  return (
    <CheckoutSectionComponent>
      <div className="no-gutters fixed_size">
        <div className="mt-4">
          <PropertyResultList
            handleShowCardDetails={(propertyId) =>
              handleShowCardDetails(propertyId)
            }
            propertyData={propertyData}
          />
          <div className="propert_checkout_footer">
            <div className="d-flex justify-content-between pt-4 mx-4">
              <div className="d-flex" onClick={handleBack}>
                <img />
                <Typography
                  className="propert_checkout_footer_lefttext heading-title pr-3 "
                  variant="bold"
                  size="xxSmall"
                  mobileSize="xxSmall"
                >
                  BACK TO SEARCH RESULT
                </Typography>
              </div>
              <Typography
                className="propert_checkout_footer_righttext heading-title pr-3 "
                variant="regular"
                size="xxxSmall"
                mobileSize="xxxSmall"
              >
                {ShortListReducer?.items?.length} {ShortListReducer?.items.length > 1 ? 'properties shortlisted' : 'property shortlisted'}
              </Typography>
            </div>
            <div className="paggination_border"></div>
          </div>
        </div>
        <div
          className=""
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <CheckoutForm
            buttonText="SEND"
            shortlistCopy={true}
            checkoutHeading={true}
            handleCheckoutFormInput={(e) => handleCheckoutFormInput(e)}
            handleCheckoutFormcheckbox={handleCheckoutFormcheckbox}
            handleCheckoutFormSubmit={(e) => handleCheckoutFormSubmit(e)}
            enqueryFormReducer={enqueryFormReducer}
            email={email}
          />
        </div>
      </div>
    </CheckoutSectionComponent>
  );
}
export default CheckoutSection;
