export const MoreData1 = {
  content: [
    {
      description: `Work out whether a managed office solution is right for your business with the application of REESA’s financial, head count and space utilisation forecasting tools. Then easily compare the results against the choice of managed, leased or serviced spaces.`,
    },
  ],
  heading: "Review your business and property strategy",
};

export const MoreData2 = {
  content: [
    {
      description: "",
    },
  ],
  heading: "Build out a budget and project plan",
};

export const MoreData3 = {
  content: [
    {
      description:
        "Select either a “blank canvas” which you tailor to your exact needs or find an existing space and then customise it for your company.",
    },
  ],
  heading: "Property search",
};
export const MoreData4 = {
  content: [
    {
      description:
        "When choosing a ”blank canvas” REESA can run a tender process of managed office providers. i.e you choose the partner to finance and manage your space. Otherwise you can view a range of available properties from different providers that have been fitted out already. Ready for you to customise.",
    },
  ],
  heading: "Selection of your managed space partner",
};
export const MoreData5 = {
  content: [
    {
      description: "",
    },
  ],
  heading: "Design, layout & customise",
};
export const MoreData6 = {
  content: [
    {
      description:
        "A Managed Office deal often involves three parties and is a more complex negotiation than a standard lease. REESA's experienced Real Estate team are experts in getting deals done.",
    },
  ],
  heading: "Contract negotiations",
};
export const MoreData7 = {
  content: [
    {
      description: "",
    },
  ],
  heading: "Fitout build & furniture installation",
};
export const MoreData8 = {
  content: [
    {
      description: "",
    },
  ],
  heading:
    "Enjoy fixed costs for your customised space throughout your tenancy",
};
