import Typography from "../Typography";
import { ContentWrapper } from "./styledComponent";
const SubHeader = (props) => {
  const { subHeading, heading, showBar, themeColor,maxWidth,margin } = props;
  return (
    <ContentWrapper
      themeColor={themeColor}
      maxWidth={maxWidth}
      margin={margin}
      className="d-flex 
    justify-content-center flex-column"
    >
      {heading && <Typography
        className="text-center heading-title"
        variant="extraBold"
        size="xxxxLarge"
        mobileSize="xLarge"
      >
        {heading}{" "}
      </Typography>}
      {typeof subHeading == "string" ? (
        <Typography
          className={`text-center heading-content ${showBar && "bar"}`}
          size="xxMedium"
          variant="regular"
          mobileSize="xSmall"
        >
          {subHeading}
        </Typography>
      ) : (
        <Typography
          className={`text-center heading-content ${showBar && "bar"}`}
          size="xxMedium"
          variant="regular"
          mobileSize="xSmall"
        >
          {subHeading()}
        </Typography>
      )}
    </ContentWrapper>
  );
};

export default SubHeader;
