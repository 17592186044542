import React, { useRef } from "react";
import "./benfit.scss";
import Typography from "../../Typography";
import Benifit from "../../../assets/homebenfit_resizeimage.png";
// import Benifit from "../../../assets/team_benfit.png";

import LazyLoad from 'react-lazyload';
// import 'react-lazy-load-image-component/src/effects/blur.css';

function Benfit() {
  return (
    <div className="benefit row no-gutters">
      <div className="benefit_text col-lg-5  col-xl-4 col-md-12  col-12  benefit_item px-3 pt-3">
        <Typography
          className="heading  heading-title "
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="smLarge"
        >
          Benefit from the entire breadth and expertise of our team
        </Typography>
        <div className="team_list ">
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Account directors
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Chartered Surveyors
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Project managers
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Architects
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Furniture consultants
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Interior designers
            </Typography>
          </div>
          <div className="d-flex team pb-2">
            <Typography
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              +
            </Typography>{" "}
            <Typography
              className="team_name  pl-3  heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xxSmall"
            >
              Strategists
            </Typography>
          </div>
        </div>
      </div>
      <div className="benefit_img benefit_item  col-lg-7 col-xl-8 col-12  col-md-12 ">
        {/* <LazyLoad 
        resize={true}
         > */}
          <img src={Benifit} className="w-100" />
        {/* </LazyLoad> */}
      </div>
    </div>
  );
}
export default Benfit;
