import React from 'react';
import "./index.scss";
import Typography from "../../Typography";
import our_story_bg from '../../../assets/AboutUs/our_story_bg.png';

function Ourpeople() {
  return (
    <div className="our_people my-5">
      <Typography
        className="our_people_heading text-start heading-title"
        variant="extraBold"
        size="xxxxLarge"
        mobileSize='large'
      >Our people
      </Typography>
      <div className="ourpeople_bg my-4" style={{ backgroundImage: `url(${our_story_bg})` }}>
        <div className="ourpeople_story" >
          <Typography
            className="py-2 text-start heading-title"
            variant="regular"
            size="xSmall"
            mobileSize='xxSmall'
          >Working at REESA means you’ll be dealing with a diverse mix of clients and a cross section of challenges to solve. As trends change, and the markets we operate in constantly evolve. Resourceful, tenacious and creative problem solvers thrive at REESA.
          </Typography>
          <Typography
            className="py-2 text-start heading-title"
            variant="regular"
            size="xSmall"
            mobileSize='xxSmall'
          >
            We employ caring, friendly and ambitious people whilst providing an environment that promotes work life balance and a sense of purpose in the work we do.
      </Typography>
          <Typography
            className="py-2 text-start heading-title"
            variant="regular"
            size="xSmall"
            mobileSize='xxSmall'
          >
            We are an equal opportunities employer and work in accordance with the requirements of the UK Modern Slavery Act (2015).
           </Typography>
          <Typography
            className="py-2 text-start heading-title"
            variant="regular"
            size="xSmall"
            mobileSize='xxSmall'
          >
            We would love to hear from candidates who are interested in joining our team.
      </Typography>
        </div>
      </div>
    </div>
  )
}
export default Ourpeople;