import React, { useEffect, useState } from 'react';
import './styledComponent.js';
import property_img from '../../assets/PropertySearch/property_img.png';
import Typography from "../Typography";
import heart from '../../assets/PropertySearch/heart.svg';
import like from '../../assets/PropertySearch/like.svg';
import cancel from '../../assets/PropertySearch/cancel1.svg';
import { PropertyCardComponent } from "./styledComponent";
import { shortlistAdd, shortlistedRemove } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { shorlisted } from '../../Utils/localStorageUtil';
import { Link, Element, Events, scrollSpy } from 'react-scroll';
import { getDimensions, PlaceholderImage } from 'src/Component/ImageDimensions';
function PropertyCard({handleActiveMarkerCard, propertyallData, propertyId, handleShowCardDetails, address1, address2, address3, address4, squareFootageMax, squareFootageMin, squareFootage, images, priceHotDesk, priceLeasedManaged, pricePrivateOffice }) {
    const [shortList, setShortList] = useState(false)
    const [dimension, setDimension] = useState([])
    const bottonList = ["sqft"]
    const priceList = [{ type: "HOT DESK", price: priceHotDesk ? parseInt(priceHotDesk) : "", }, { type: "MANAGED", price: priceLeasedManaged ? parseInt(priceLeasedManaged) : "", }, { type: "PRIVATE", price: pricePrivateOffice ? parseInt(pricePrivateOffice) : "", }]
    const dispatch = useDispatch()
    const ShortListReducer = useSelector((state) => state.ShortListReducer)


    const handleAddShortlistProperty = () => {
        setShortList(!shortList)
        dispatch(shortlistAdd(propertyallData))
        handleActiveMarkerCard()
    }

    const handleRemoveShortlistProperty = () => {
        dispatch(shortlistedRemove(propertyallData))
    }

    useEffect(() => {
        if (ShortListReducer?.items?.length > 0) {
            shorlisted(ShortListReducer);
            let shortListedProperty = ShortListReducer.items.find(space => space?.id == propertyId);
            if (shortListedProperty && shortListedProperty?.id) {
                setShortList(true)
            } else {
                setShortList(false)
            }
        }
    }, [ShortListReducer])
    useEffect(() => {
        const dims = getDimensions('result-sm', 'space');
        if (dims?.length > 0) {
            setDimension(dims)
        }
    }, [])
    return (
        <PropertyCardComponent>
            <Element >
                <div className="property_card" >
                    <div className="property_details" onClick={() => handleShowCardDetails(propertyId)}>
                        {dimension.length > 0 && propertyallData?.images[0] ?
                            <div className="property_bg" style={{ backgroundImage: `url(${`${process.env.REACT_APP_IMG_BASE_URL}/images/${propertyallData?.images[0]?.spaceId}/${propertyallData?.images[0]?.id}_${dimension && dimension[0]}_${dimension && dimension[1]}`})` }}>
                                <div className="card_img ">
                                    {bottonList.map(val => (
                                        <div >
                                            {squareFootage ? <button className="healthcare_button ml-2">
                                                <Typography
                                                    className="healthcare_button_text heading-title "
                                                    variant="regular"
                                                    size="xxxxxxSmall"
                                                    mobileSize='xxxxxxSmall'
                                                >{val == "sqft" ? squareFootage : ""} sqft
                                           </Typography>
                                            </button> : null}
                                        </div>
                                    ))}
                                </div>
                            </div> : <div className="no_image"> <PlaceholderImage /> </div>}
                        <div className="card_details pl-2">
                            <div className="property_location">
                                <Typography
                                    className="property_address heading-title "
                                    variant="bold"
                                    size="small"
                                    mobileSize='small'
                                >{address2 ? address2 : address1}
                                </Typography>
                                <Typography
                                    className="property_sublocation heading-title "
                                    variant="regular"
                                    size="xxxSmall"
                                    mobileSize='xxxSmall'
                                >{address4} {propertyallData?.postcode?.split(' ')[0]}
                                </Typography>
                            </div>
                            <Typography
                                className="property_sublocation heading-title "
                                variant="regular"
                                size="xxxSmall"
                                mobileSize='xxxSmall'
                            >{propertyallData.leasehold ? "Rent Per Square Foot per annum" : "Price Per Person Per Month"}
                            </Typography>
                            {propertyallData.leasehold ?
                                <div className="row">
                                    <div className="col-6">
                                        <div className="d-flex   flex-column  justify-content-around price_list">
                                            <Typography
                                                className="price_heading heading-title text-center"
                                                variant="bold"
                                                size="xxxSmall"
                                                mobileSize='xxxSmall'
                                            >GUIDE PRICE
                                            </Typography>
                                            <Typography
                                                className="price heading-title "
                                                variant="regular"
                                                size="xxMedium"
                                                mobileSize='xxMedium'
                                            >{propertyallData?.guidePrice ? parseInt(propertyallData?.guidePrice) : "POA"}
                                            </Typography>
                                        </div>
                                     </div> 
                                </div>
                                 :
                                <div className="d-flex justify-content-around price_list">
                                    {priceList.map(val => (
                                        <div>
                                            <Typography
                                                className=" price_heading heading-title "
                                                variant="bold"
                                                size="xxxSmall"
                                                mobileSize='xxxSmall'
                                            >{val.type}
                                            </Typography>
                                            {val.price ?
                                                <Typography
                                                    className="price heading-title "
                                                    variant="regular"
                                                    size="xxMedium"
                                                    mobileSize='xxMedium'
                                                >£{val.price}
                                                </Typography> : <span className="d-flex justify-content-center null_price">-</span>}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    {shortList ?
                        <div className="shortlist">
                            <div className="shortlist_button d-flex justify-content-between">
                                <div></div>
                                <div>
                                    <img src={like} alt="like" /><span className="shortlist_button_text pl-2">SHORTLISTED</span>
                                </div>
                                <img src={cancel} alt="cancel" className="mr-4 " onClick={handleRemoveShortlistProperty} />
                            </div>
                        </div> :
                        <div className="shortlist_cancle">
                            <div className="shortlist_button d-flex justify-content-center" onClick={handleAddShortlistProperty}>
                                <div>
                                    <img src={heart} alt="heart" /><span className="shortlist_button_text pl-2">SHORTLIST</span>
                                </div>
                            </div>
                        </div>}
                </div>
            </Element>
        </PropertyCardComponent>
    )
}
export default PropertyCard;