import React from "react";
import TextCard from "src/Component/InteriorDesign/text-card";
import Button from "../common/Button";
import TimelineCircle from "../timelineCircle";
import StepHeading from "../stepHeading";
import AdvisorsForm from "../AdvisorsForm/advisorsForm";
import { ThemeProvider } from "styled-components";
import {
  BorderContainerTwo,
  BorderContainerThree,
  BorderContainerFour,
  Section1,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Image1,
  Image2,
  Image3,
  Image7,
  BuilderBox,
} from "./styledComponent";
import Typography from "../Typography";
const Timeline2 = (props) => {
  const {
    formBg,
    totalSteps,
    person2ImageStep,
    borderContainerTwoClassName,
    borderContainerFourClassName,
    section5ClassName,
    timelineData1,
    timelineData2,
    timelineData3,
    timelineData4,
    timelineData5,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    timelineDivider1Height,
    borderContainerWidth,
    divider1Height,
  } = props;
  const theme = {
    color: props.themeColor,
    borderContainerWidth: borderContainerWidth,
  };
  const handleClick = () => {
    window.open("https://store.reesa.com/project/step1");
  };
  const renderStep1 = () => {
    if (totalSteps >= 1) {
      return (
        <>
          <Section1>
            <div className="timeline_circle_wrapper d-flex align-items-center">
              <TimelineCircle
                step={1}
                width={"4px"}
                height={
                  timelineDivider1Height ? timelineDivider1Height : "104px"
                }
              />
              <StepHeading className="px-4">
                {timelineData1.heading}
              </StepHeading>
            </div>
            <div className="row timeline_wrapper">
              <Image1
                className="col-sm-6 col-left col-12 d-flex justify-content-center h-100"
                divider1Height={divider1Height}
              >
                <img src={image1} className="w-100" />
              </Image1>
              <div className="col-sm-6 col-right col-12">
                {timelineData1.content?.map(({ title, description }) => (
                  <TextCard
                    title={title}
                    description={description}
                    themeColor={props.themeColor}
                  />
                ))}
              </div>
            </div>
            <div className="section2 row">
              <div className="col-sm-6 col-left col-12 d-flex justify-content-center align-items-center ">
               
                <BuilderBox>
                  <Typography
                    variant="regular"
                    size="xxxxMedium"
                    className="project-builder-title"
                  >
                    TRY OUR ONLINE PROJECT BUILDER
                  </Typography>
                  <Typography
                    variant="regular"
                    size="xxxSmall"
                    className="project-builder-content"
                  >
                    Choose furniture and products that reflect your style and
                    use, upload a floor plan and share your project with others.
                  </Typography>
                  <Button
                    className="w-100 text-uppercase"
                    onClick={handleClick}
                  >
                    Project Builder
                  </Button>
                </BuilderBox>
              </div>
              <div className="col-sm-6 col-right col-12 d-flex justify-content-center">
                <Image3>
                  <img src={image3} className="w-100" />
                </Image3>
              </div>
            </div>
          </Section1>
          <Section3>
            <div
              className="image_4"
              style={{ backgroundImage: `url(${image4})` }}
            />
            
          </Section3>
        </>
      );
    }
  };

  const renderStep2 = () => {
    if (totalSteps >= 2) {
      return (
        <Section4>
          <div className="row ">
            <div className="col-sm-6 col-left  col-12 ">
              <StepHeading className="step2Heading">
                {timelineData2.heading}{" "}
              </StepHeading>
              {timelineData2.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
            </div>
            <div className="col-sm-6 col-12 col-right d-flex flex-column align-items-center justify-content-center">
              <Image2>
                <img src={image2} className="w-100" />
                <TimelineCircle className="image2-timeline-circle" step={2} />
              </Image2>
              <img src={image5} className="w-100 image_5" />
            </div>
          </div>
         
        </Section4>
      );
    } else {
      return null;
    }
  };

  const renderStep3 = () => {
    if (totalSteps >= 3) {
      return (
        <Section5 className={`${section5ClassName ? section5ClassName : ""}`}>
          <BorderContainerTwo
            className={`${
              borderContainerTwoClassName ? borderContainerTwoClassName : ""
            }`}
          >
            <div className="step-three">
              <TimelineCircle
                step={3}
                width={"0"}
                height={"0px"}
                stepSide={"left"}
              />
              <StepHeading className="px-4">
                {timelineData3.heading}{" "}
              </StepHeading>
            </div>
          </BorderContainerTwo>
          <div className="row">
            <div className="col-sm-6 col-left col-12">
              <div
                className="image_6"
              >
                <img src={image6} className='w-100'/>
              </div>
            </div>
            <div className="col-sm-6 col-right col-12 ">
              {timelineData3.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
              {person2ImageStep === 3 && (
                <Image7>
                  <img src={image9} className="w-100 " />
                </Image7>
              )}
            </div>
          </div>
        </Section5>
      );
    }
  };

  const renderStep4 = () => {
    if (totalSteps >= 4) {
      return (
        <Section6 className="section6 wrapper_container">
          <div className="row">
            <div className="col-sm-6 col-left col-12 ">
              {timelineData4?.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
            </div>
            <div className="col-sm-6 col-right col-12 d-flex justify-content-center">
              <div
                className="image_5"
                style={{ backgroundImage: `url(${image7})` }}
              />
            </div>
          </div>
          <BorderContainerThree>
            <div className="step-four">
              <StepHeading className="px-4">
                {timelineData4.heading}
              </StepHeading>

              <TimelineCircle
                step={4}
                width={"0px"}
                height={"0px"}
                stepSide={"right"}
              />
            </div>
          </BorderContainerThree>
        </Section6>
      );
    }
  };

  const renderStep5 = () => {
    if (totalSteps >= 5) {
      return (
        <Section7>
          <div className="row">
            <div className="col-sm-6 col-left  col-12 d-flex justify-content-center">
              <div
                className="image_5"
                style={{ backgroundImage: `url(${image8})` }}
              />
            </div>
            <div className="col-sm-6 col-right col-12">
              {timelineData5.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
              {person2ImageStep === 5 && (
                <Image7>
                  <img src={image9} className="w-100" />
                </Image7>
              )}
            </div>
          </div>

          <BorderContainerFour
            className={`${
              borderContainerFourClassName ? borderContainerFourClassName : ""
            }`}
          >
            <div className="step-5">
              <TimelineCircle
                step={5}
                width={"0"}
                height={"0px"}
                stepSide={"left"}
              />
              <StepHeading className="px-4">
                {timelineData5.heading}
              </StepHeading>
            </div>
          </BorderContainerFour>
        </Section7>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="main_wrapper">
        {renderStep1()}
        {renderStep2()}
        {renderStep3()}
        {renderStep4()}
        {renderStep5()}
        <AdvisorsForm bgImage={formBg} themeColor={props.themeColor}/>
      </div>
    </ThemeProvider>
  );
};

export default Timeline2;
