import React, { useEffect, useState, useCallback } from "react";
import { GoogleMaps } from "./styledComponent";
import Button from "../../Component/Button";
import { useDispatch } from "react-redux";
import ReactMapGL, { Marker } from "react-map-gl";
import { ReactComponent as MarkerImg }from "../../assets/marker.svg";
import markerImg from "../../assets/marker.svg";
import { withRouter } from "react-router-dom";
import { getBoundsForPoints, getBounds } from "../../constants/geocoder";
import bbox from "@turf/bbox";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import { mapDetails } from 'src/constants/MapboxDetails'
import { getPropertyDataRequest } from "src/redux/action";

import { PerspectiveMercatorViewport } from 'viewport-mercator-project';
import _ from 'lodash';
import {
  Link,
  animateScroll as scroll,
} from "react-scroll";

function PropertyMapSection({
  pageCount,
  northeastlat,
  northeastlng,
  southwestlat,
  southwestlng,
  height,
  width,
  markerList,
  google,
  propertyData,
  handleActiveMarker,
  handleActiveMarkerClicButton,
  activeMarker,
  ...props
}) {

  var bounds = getBoundsForPoints(
    northeastlat,
    northeastlng,
    southwestlat,
    southwestlng,
    height,
    width
  );

  const [pageMount, setPageMount] = useState()
  const [areaButton, setAreaButton] = useState(false);
  const [boundsCoordinate, setBoudsCoordinate] = useState("");
  const [searchAreaLatLngButton, setSearchAreaLatLngButton] = useState({
    lat: "",
    lng: "",
  });
  const [viewport, setViewport] = useState({
    width: "100%",
    height:"100%",
    pitch: 0,
    bearing: 0,
    ...bounds,
  });
  const[markerActive, setMarkerActive] = useState(0)
  const dispatch = useDispatch();
  useEffect(() =>{
    setPageMount(true)
  })
  const onViewportChange = (port, vp) => {
    if(bounds){
      if (
        bounds.zoom != port.zoom ||
        bounds.latitude != port.latitude ||
        bounds.longitude != port.longitude
      ) {
        setAreaButton(true);
      }
    }
    setSearchAreaLatLngButton({
      ...searchAreaLatLngButton,
      lat: vp.latitude,
      lng: vp.longitude,
    });
    setViewport(port);
    if (vp.getBounds().corners().length > 0) {
      const cornerspoint = vp.getBounds().corners();
      const getBoundscoordinate = getBounds(
        cornerspoint[0][0],
        cornerspoint[0][1],
        cornerspoint[1][0],
        cornerspoint[1][1]
      );
      setBoudsCoordinate(getBoundscoordinate);
    }
  };

 

  const handleClick = async () => {
    const routeValue = props.match.params;
    await dispatch(
      getPropertyDataRequest({
        page: pageCount,
        per_page: 100,
        location: boundsCoordinate,
        type: routeValue.type,
        propertyType: routeValue.propertyType,
        desk_min: routeValue.desk_min,
        desk_max: routeValue.desk_max,
      })
    );
    await setAreaButton(false);
    handleActiveMarkerClicButton()
  };

  const handleOnSetActive = (e) => {
    if(e){
      handleActiveMarker(e)
    }
  };

  return (
    <>
      <GoogleMaps className="position-relative" minHeight={props?.minHeight}>
        <ReactMapGL
          {...viewport}
          mapStyle="mapbox://styles/mapbox/streets-v10"
          mapboxApiAccessToken={mapDetails.access_token}
          onViewportChange={(vp) => onViewportChange(vp, new Viewport(vp))}
          width="100%"
          height={props.minHeight}
        >
          {propertyData &&
            propertyData.map((val) => (              
              <Marker
                latitude={val?.location?.coordinates[1]}
                longitude={val?.location?.coordinates[0]}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <Link
                   activeClass="active"
                  to={val.id}
                  duration={250}
                  smooth={true}
                  containerId="containerElement"
                  ignoreCancelEvents={false}
                  spy={true}
                  isDynamic={true}
                  onClick={() => handleOnSetActive(val.id)}
                > 
                {(activeMarker == val.id) ? <MarkerImg  />:                 
                <img src={markerImg} alt="markerImg" /> }
                  </Link>
              </Marker>
            ))}
        </ReactMapGL>
        { areaButton ? (
          <Button
            buttonClass="search_area_button"
            buttonText="SEARCH THIS AREA"
            handleClick={handleClick}
          ></Button>
        ) : null}
      </GoogleMaps>
    </>
  );
}
export default withRouter(PropertyMapSection);

export function LngLat(arr) {
  let rawLngLat;
  if (typeof arr === 'string') {
    rawLngLat = arr.split(',').map(c => parseFloat(c));
  } else {
    rawLngLat = arr.toJSON ? arr.toJSON() : arr;
  }
  this.lnglat = rawLngLat;
  this.longitude = rawLngLat[0];
  this.latitude = rawLngLat[1];
}
LngLat.fromLatLng = function (latLng) {
  return new LngLat([latLng[1], latLng[0]]);
};
LngLat.prototype.toJSON = function () {
  return this.lnglat;
};

export function LngLatBounds(bounds) {
  let rawBounds;
  if (typeof bounds === 'string') {
    rawBounds = bounds.split('|').map(b => b.split(',').map(c => parseFloat(c)));
  } else {
    rawBounds = bounds.toJSON ? bounds.toJSON() : bounds;
  }
  this.bounds = rawBounds.map(b => new LngLat(b));
}
LngLatBounds.fromLatLngBounds = function (bounds) {
  return new LngLatBounds(bounds.map(b => [b[1], b[0]]));
};
LngLatBounds.prototype.toLatLngBounds = function () {
  return this.bounds.map(b => [b.latitude, b.longitude]);
};
LngLatBounds.prototype.corners = function () {
  if (this.bounds.length === 2) {
    return this.toJSON();
  }
  return [this.bounds[0].toJSON(), this.bounds[2].toJSON()];
};
LngLatBounds.prototype.toJSON = function () {
  return this.bounds.map(b => b.toJSON());
};



export function Viewport({
  latitude = 0,
  longitude = 0,
  zoom = 10,
  width = 500,
  height = 500,
  pitch = 0,
  altitude = 0
} = {}) {
  this.viewport = new PerspectiveMercatorViewport({
    longitude,
    latitude,
    zoom,
    width,
    height,
    pitch,
    altitude
  });
}

Viewport.fromCenter = function (center, defaultProps = {}) {
  const centerPoint = new LngLat(center);
  return new Viewport({
    ...defaultProps,
    longitude: centerPoint.longitude,
    latitude: centerPoint.latitude
  });
};

Viewport.fromBounds = function (bounds, defaultProps = {}, opts = {}) {
  const corners = new LngLatBounds(bounds).corners();
  return new Viewport(defaultProps).fitBounds(corners, opts);
};

Viewport.prototype.fitBounds = function (bounds, opts) {
  const corners = new LngLatBounds(bounds).corners();
  this.viewport = this.viewport.fitBounds(corners, opts);
  return this;
};

Viewport.prototype.getBounds = function () {
  const { width, height } = this.viewport;
  const nw = this.viewport.unproject([0, 0]);
  const ne = this.viewport.unproject([width, 0]);
  const se = this.viewport.unproject([width, height]);
  const sw = this.viewport.unproject([0, height]);
  return new LngLatBounds([nw, ne, se, sw]);
};

Viewport.prototype.getCenter = function () {
  const { latitude, longitude } = this.viewport;
  return new LngLat([longitude, latitude]);
};

Viewport.prototype.values = function () {
  const {
    longitude,
    latitude,
    zoom,
    width,
    height
  } = this.viewport;
  return {
    longitude,
    latitude,
    zoom,
    width,
    height
  };
};

Viewport.prototype.isEqual = function (vp) {
  if (!vp) return false;
  return _.isEqualWith(this.values(), vp.values());
};
