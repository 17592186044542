import React from 'react';

export  const markerList  = [
    {
        "name" :  "Edinburgh",
        "position" : {
            "lat" : 55.95500204144114,
            "lng" : -3.188139872020615
        },
        "Country" : "Scotland"
    },
    {
        "Country" : "Scotland",
        "name" :  "Glasgow",
        "position" : {
            "lat" : 55.86766,
            "lng" : 55.86766
        }
    },
     {
        "Country" : "Scotland",
        "name" :  "Dundee",
        "position" : {
            "lat" : 56.46489239152569,
            "lng" : -2.970970157688381
        }
    },
    {
        "Country" : "Scotland",
        "name" :  "Aberdeen",
        "position" : {
            "lat" : 57.15151683995085,
            "lng" : -2.0934344276306907
        }
    },
    {
        "Country" : "England",
        "name" :  "Cardiff",
        "position" : {
            "lat" : 51.48422782834987,
            "lng" : -3.1789923514440726
        }
    },
    {
        "Country" : "England",
        "name" :  "Swansea",
        "position" : {
            "lat" : 51.696624781477176,
            "lng" : -3.934595371445013 
        }
    },
    {   "Country" : "Ireland",
        "name" :  "Bangor",
        "position" : {
            "lat" : 53.22736262995212,
            "lng" : -4.129466753703479 
        }
    },
    {
        "Country" : "England",
        "name" :  "Aberystwyth",
        "position" : {
            "lat" : 52.41577515772128,
            "lng" : -4.083133636690871 
        }
    },
    {
        "Country" : "Ireland",
        "name" :  "Cork",
        "position" : {
            "lat" : 551.894951376828196,
            "lng" : -8.477062030391505 
        }
    },
    {
        "Country" : "Ireland",
        "name" :  "Dublin",
        "position" : {
            "lat" : 53.35867540796404,
            "lng" : -6.328748036796313 
        }
    },
    {
        "Country" : "Ireland",
        "name" :  "Galway",
        "position" : {
            "lat" : 53.27279204884948,
            "lng" : -9.059062283121827 
        }
    },
    {
        "Country" : "Ireland",
        "name" :  "Limerick",
        "position" : {
            "lat" : 52.66377103619802,
            "lng" : -8.646605281046003 
        }
    },
    {
        "Country" : "Ireland",
        "name" :  "Belfast",
        "position" : {
            "lat" : 54.599216755316526,
            "lng" : -5.928685138600085 
        }
    },
     {
        "Country" : "England",
        "name" :  "Newcastle",
        "position" : {
            "lat" : 54.21690714630327,
            "lng" : -5.892533255511859 
        }
    },
    {
        "Country" : "England",
        "name" :  "Leeds",
        "position" : {
            "lat" : 53.80585735679974,
            "lng" : -1.552214142980408 
        }
    },
    {
        "Country" : "England",
        "name" :  "Manchester",
        "position" : {
            "lat" : 53.482323165607724,
            "lng" : -2.245409908452052  
        }
    },
    {
        "Country" : "England",
        "name" :  "Liverpool",
        "position" : {
            "lat" :  53.399439324651446,
            "lng" : -2.985241606546057   
        }
    },
    {
        "Country" : "England",
        "name" :  "Hull",
        "position" : {
            "lat" :  53.768155134756505,
            "lng" : -0.3299226215423373  
        }
    },
    {
        "Country" : "England",
        "name" :  "Sheffield",
        "position" : {
            "lat" :  53.38405242484262,
            "lng" :  -1.4720311384487739 
        }
    },
    {
        "Country" : "England",
        "name" :  "Nottingham",
        "position" : {
            "lat" :  52.955215657498094,
            "lng" :  -1.1594156606005734 
        }
    },
    {
        "Country" : "England",
        "name" :  "Norwich",
        "position" : {
            "lat" :  52.63301105691736,
            "lng" :  -1.1594156606005734 
        }
    },
    {
        "Country" : "England",
        "name" :  "Birmingham",
        "position" : {
            "lat" :  52.494445639168035,
            "lng" :  -1.88762182487166  
        }
    },
    {
        "Country" : "England",
        "name" :  "Coventry",
        "position" : {
            "lat" :  52.4082071269003,
            "lng" : -1.5180164982521096  
        }
    },  
    {
        "Country" : "England",
        "name" :  "Oxford",
        "position" : {
            "lat" :  51.75399336184745,
            "lng" :  -1.2571967399435704  
        }
    },
    {
        "Country" : "England",
        "name" :  "Leicester",
        "position" : {
            "lat" :  52.63249140133808,
            "lng" :   -1.1273294787446353  
        }
    },
    {
        "Country" : "England",
        "name" :  "Cambridge",
        "position" : {
            "lat" :  52.207638120558556,
            "lng" :  0.12137472120598311  
        }
    },
    {
        "Country" : "England",
        "name" :  "London",
        "position" : {
            "lat" :  51.51184448598992,
            "lng" :  -0.12979552110158307   
        }
    },
    {
        "Country" : "England",
        "name" :  "Brighton",
        "position" : {
            "lat" :  50.808474838172465,
            "lng" :  -1.082416584285707  
        }
    },
    {
        "Country" : "England",
        "name" :  "Portsmouth",
        "position" : {
            "lat" :   50.808474838172465,
            "lng" :  -1.082416584285707  
        }
    },
    {
        "Country" : "England",
        "name" :  "Southampton",
        "position" : {
            "lat" :   550.93695605512248,
            "lng" :  -1.4351522361755156
        }
    },
    {
        "Country" : "England",
        "name" :  "Exeter",
        "position" : {
            "lat" :   50.720509879101485,
            "lng" :  -3.533805192605202 
        }
    },
    {
        "Country" : "England",
        "name" :  "Exeter",
        "position" : {
            "lat" :   50.720509879101485,
            "lng" : -3.533805192605202  
        }
    },
    {
        "Country" : "England",
        "name" :  "Bristol",
        "position" : {
            "lat" :  51.4559144190714,
            "lng" : -2.588696149144864 
        }
    },
    {
        "Country" : "England",
        "name" :  "Plymouth",
        "position" : {
            "lat" :  550.38585125383163,
            "lng" : -4.1454533544249275 
        }
    },
    {
        "Country" : "England",
        "name" :  "Bath",
        "position" : {
            "lat" :  51.38129289286616,
            "lng" : -2.3592072572013287 
        }
    }
]