export const interiorDesignData1 = {
  content: [
    {
      title: "The Vision session",
      description:
        "This is all about bringing together influences and exploring ideas to form a guide and vision look book. A reference guide and an evolving process. It's fun, creative and will stimulate your inner creative.",
    },
    {
      title: "Creative guidance and direction",
      description:
        "Our designers are experts in interpreting the work done during the visionary session and translating the ideas into a clear and strong direction.",
    },
    {
      title: "Establishing your signature look & feel",
      description:
        "Much that interior design will have its trends and current topics which act as a benchmark for modern thinking. It’s important to focus on giving each client their own identity and something to be uniquely proud of.",
    },
    {
      title: "Palette picking",
      description:
        "The examination of colour. The feel of the fabric, and the choice of materials. All fundamental to the quality, both of design and construction. They must harmonise. At times contrast but most importantly establish an emotional connection.",
    },
  ],
  heading:'Get inspired'
};

export const interiorDesignData2 = {
  content: [
    {
      title: "Bringing the floor plans to life",
      description:
        "Never underestimate the importance of getting the fundamental layout correct. The flow of space determines so much. But when ready! overlaying the vision, the colours and texture is when things start to get really exciting.",
    },
    {
      title: "Focus on what really matters",
      description:
        "Sometimes the design process identifies what’s possible and what’s not. As designers we are always adaptable, but nervous when clients have to compromise their goals too much. We’ll help you protect the principles established in the first steps stage.",
    },
    {
      title: "Engagement",
      description:
        "Clients will have different people involved during a project, and at different milestones. We support our contacts in selling the vision and communicating internally with the hierarchy and other stakeholders.",
    },
    {
      title: "Visualisation",
      description:
        "Our Build colleagues and Project Managers provide pricing documentation and associated material. The Design Team produce stunning moodboards, mock-ups, 3-D renders, samples and anything which to helps demonstrate the essence of the end result.",
    },
  ],
  heading:'Make it yours'
};

export const interiorDesignData3 = {
  content: [
    {
      title: "Wall coverings",
      description:
        "Many times, our designers have created bespoke patterns or graphics. Printed as wallpaper, manifestations or signage to promote a brands individuality and culture.",
    },
    {
      title: "Fabric",
      description:
        "We Love fabric. Simple as that really. It’s such a wonderful world to immerse yourself into and worth investing the time and energy into selecting something special.",
    },
    {
      title: "Finishes",
      description:
        "How a carpet feels underfoot; how the kitchen reflects light; how easy is the boardroom table to wipe clean. An acoustic lampshade. All examples of finishes and so important to consider at every stage.",
    },
    {
      title: "Materials",
      description:
        "More than ever it’s important to understand what materials are being used, where they come from, are they appropriate and of the right quality. What footprint on the environment do they leave behind?",
    },
  ],
  heading:'Attention to the details '
};

export const interiorDesignData4 = {
  content: [
    {
      title: "Our Furniture Team",
      description:
        "The furniture team are interwoven into the creative process. Once the vision, palette and workplace strategy are understood workplace products will be put forward for consideration.",
    },
    {
      title: "How important is Furniture to Interior Design?",
      description:
        "The choice of furniture makes or breaks a project. But it’s not to say furniture is more important than interior design. In fact, the two don’t really exist without one another. For instance, who wants the right chair but in the wrong colour. Nobody!",
    },
    {
      title: "Showroom Tours",
      description:
        "Showroom tours or onsite demonstrations are particularly important for some furniture pieces. The team are on hand to organise and accompany.",
    },
    {
      title: "Our Project Builder Tool",
      description:
        "We have built an online store which guides clients through product selection based on style and function. Create an account to purchase online and build out a project package with the help of a REESA consultant.",
    },
  ],
  heading:'Furniture & Product Selection'
};

export const interiorDesignData5 = {
  content: [
    {
      title: "Signs, touch screens – your brand",
      description:
        "Bring your brand message to life with clear signage. A chance to use A/V technology, interactive touchscreens and video walls.",
    },
    {
      title: "Plants",
      description:
        "Everyone knows that plants are a healthy addition to any interior. They go a long way to finishing off a space. We install living walls, planters, pots, and hanging sculptures. Either purchase upfront, or agree a rental plan. Maintenance and care packages available.",
    },
    {
      title: "Furnishings & Accessories",
      description:
        "For a house to feel like a home you need to put the curtains up, get the cushions on the sofa and plates in the cupboards. It’s no different for commercial interiors it’s the finishing touches which make the difference.",
    },
    {
      title: "ART",
      description:
        "Work with our curators to develop an Art strategy for your space and business. We have experts who advise on existing collections and how to invest in art to complement the space and build assets.",
    },
  ],
  heading:'Accents & finishing touches'
};



