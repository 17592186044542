import React from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import "./Eb7.scss";
import eb7_post from "../../assets/CasestudiesItemone/eb7_post.jpeg";

function Eb7() {
  return (
    <div>
      <NavBar />
      <div className="Main_container container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <h1 className="eb7_header mt-5 mb-4">
              EB7 Limited – Midtown, London
            </h1>
            <p>
              Acting for EB7 limited on the acquisition of the 4th floor of
              Holborn Tower, WC1
            </p>
            <h3 className="mt-5 mb-3">BACKGROUND</h3>
            <p>
              EB7 Ltd, London’s recognised specialists in Rights of Light
              planning wanted new offices that mirrored their success. The
              growth trajectory has been more rapid than forecast and their
              offices weren’t fit for purpose. Reesa were appointed to search
              for and acquire offices that portrayed them as a firm. One of
              their criteria was an office that spoke to clients before they
              did.
            </p>
            <h5 className="mt-5 mb-3">PROCESS</h5>
            <p>
              EB7’s criteria was set by 4 founding Directors and all had a
              slightly different set of underlying wants. We took all the briefs
              and broke them down into vital and nice to haves giving us the
              opportunity to find options that suited all of them. We viewed
              circa 20 properties over a 2 month period and shortlisted to 2.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <img src={eb7_post} className="mt-5 mb-5 w-100" />
        <div className="row mb-4">
          <div className="col-3"></div>
          <div className="col-6">
            <p className="reesa_have">
              "Reesa have worked with us over an extended period of time in our
              office search, showing us a wide range of options in the market
              and providing great insight into everything considered. We have
              ended up with the perfect office for our growing company and would
              certainly recommend them to anyone on the hunt for their ideal
              space."
            </p>
            <p>- John Barnes, Founder</p>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row mt-3 bottom_para">
          <div className="col-1"></div>
          <div className="col-10">
            <p>
              The negotiation and subsequent legals were protracted given the
              landlord was a foreign entity but the lease was completed within
              time and EB7 moved in on their target date.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Eb7;
