import React from 'react';
import './index.scss';

function CaseStudiesList ({handleSelect, activeIndex}){
    return(
        <div className="cardList_wrapper">
        <div className="row ">
          <div
            className={`col-md-4 text-center align-items-center cardList_content_wrapper bg_star ${activeIndex == 0 && "active"}`}
            onClick={() => handleSelect(0)}
          >
          
            <div className="header_title">Case Studies</div>
            <div className="bottom_content_star"></div>
          </div>
          <div
            className={`col-md-4 text-center align-items-center cardList_content_wrapper bg_compass ${activeIndex == 1 && "active"}`}
            onClick={() => handleSelect(1)}
          >
              
  
            <div className="header_title">Testimonials</div>
            <div className="bottom_content_compass"></div>
          </div>
          <div
            className={`col-md-4 align-items-center cardList_content_wrapper bg_office ${activeIndex == 2 && "active"}`}
            onClick={() => handleSelect(2)}
          >
           
            <div className="header_title">Clients</div>
            <div className="bottom_content_office office_bg"></div>
          </div>
          
        </div>
      </div>
    )
}
export default CaseStudiesList;