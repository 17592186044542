import seven from '../assets/CaseStudies/Testimonial/24seven.png';
import aud_sci from '../assets/CaseStudies/Testimonial/aud_sci.png';
import centrica from '../assets/CaseStudies/Testimonial/centrica.png';
import copofi from '../assets/CaseStudies/Testimonial/copofi.png';
import cytora from '../assets/CaseStudies/Testimonial/cytora.png';
import dga_logo from '../assets/CaseStudies/Testimonial/dga_logo.png';
import eb7 from '../assets/CaseStudies/Testimonial/eb7.png';
import green_giraffe from '../assets/CaseStudies/Testimonial/green_giraffe.png';
import imtech_logo from '../assets/CaseStudies/Testimonial/imtech_logo.png';
import lygon_group from '../assets/CaseStudies/Testimonial/lygon_group.png';
import mediaiq from '../assets/CaseStudies/Testimonial/mediaiq.png';
import meltwater from '../assets/CaseStudies/Testimonial/meltwater.png';
import mobile5 from '../assets/CaseStudies/Testimonial/mobile5.png';
import observe_point from '../assets/CaseStudies/Testimonial/observe_point.png';
import prosek_partners from '../assets/CaseStudies/Testimonial/prosek_partners.png';
import rmp_property_logo from '../assets/CaseStudies/Testimonial/rmp_property_logo.png';
import sola_group from '../assets/CaseStudies/Testimonial/sola_group.png';
import unity from '../assets/CaseStudies/Testimonial/unity.png';
import mundays from '../assets/CaseStudies/Testimonial/mundays.png';


export const testimonialData = [
    {
        img: green_giraffe,
        description1: '"David and George were absolutely incredible with our first lease acquisition. From start to finish we were supported the whole way through. David even went above and beyond his role to offer us invaluable advice on how to effectively work with our fit out company.',
        description2: 'This was the icing on the cake that made the whole project a success. We can’t thank REESA enough for their excellence. We are truly thankful."',
        name: "Nicole Elizabeth",
        location: ",Project Leader, Green Giraffe"
    },
    {
        img: prosek_partners,
        description1: '"As a US based company hoping to expand its presence in London, Prosek Partners had limited knowledge of the UK commercial real estate market and limited time to secure new space. Piers very quickly brought us up to speed on the UK market, identified a wide range of viable options and provided excellent counsel in securing the ideal space for our expanding company.',
        description2: 'We highly recommend the service provided by Piers and his team at REESA."',
        name: "Russell Nuce",
        location: ", COO, Prosek"
    },
    {
        img: copofi,
        description1: '"David provided a stunning service in finding us a commercial office space. Our company had just incepted and we were hiring staff, so we needed a larger office space. When we tried to find the office space ourselves we kept on getting it wrong and wasting significant amounts of time.',
        description2: 'David came in and organised our diaries and showed us the best properties the London market had to offer. We found a perfect location and I will use his services again as we expand in the future. REESA saved me time and money allowing me to focus on what I do best; growing my business."',
        name: "Manish Shah",
        location: " Founder, CopoFi"
    },
    {
        img: cytora,
        description1: '"The day after I reached out to David he had 5 bookings lined up and continually adapted the search based on my likes and dislikes of the spaces we had seen.',
        description2: 'It became very clear to me that David was not just trying to make a sale, but really cared about finding us the right space, where we would be at our most happy and productive. And that is how we have come to be in our wonderful office.',
        name: "Jessie Giladi",
        location: ", Head of Operations, Cytora"
    },
    {
        img: centrica,
        description1: '"REESA helped us secure two large spaces totalling 190 desks within Central London for a Tech Business, their hands on approach and market knowledge were pivotal in helping us meet the urgent deadline but also managed to find the workplace style the Business needed."',
        name: "Mark Lycett",
        location: ", Estates Manager, Centrica"
    },
    {
        img: observe_point,
        description1: '"My experience with REESA was excellent. David understood my budget, requirements and desires and found several properties that were suitable for us.',
        description2: 'He was patient as we worked through our decision and outside influences that shifted our timelines a bit, but was great about staying in touch. We found our questions answered quickly and clearly and we were very happy to find the space we did. We would definitely use REESA again!"',
        name: "Drew Sellers",
        location: ",  Territory Manager, Observe Point"
    },
    {
        img: mundays,
        description1: '"Piers and his team did an awesome job for us and basically slashed our London office cost and sourced excellent new space that we could call exclusively ours and expand into. The property overhead is always one that can be a bitter pill to swallow but the REESA guys certainly help sweeten it."',
        name: "Nish Kapoor",
        location: ", Partner, Mundays"
    },
    {
        img: eb7,
        description1: '"REESA have worked with us over an extended period of time in our office search, showing us a wide range of options in the market and providing great insight into everything considered. We have ended up with the perfect office for our growing company and would certainly recommend them to anyone on the hunt for their ideal space."',
        name: "John Barnes",
        location: ",  Founder, EB7"
    },
    {
        img: unity,
        description1: '"As a team we have a long standing relationship with REESA and trust them to locate great options for us. This they have done more than once and with great results.',
        description2: 'Piers manages the process with expertise, his knowledge is vast and always offers the best possible advice. It’s a pleasure to work with all of the team at REESA and wholeheartedly recommend them."',
        name: "Dawn Oldfield",
        location: ", Owner, Sean"
    },
    {
        img: mobile5,
        description1: '"We chose them as our advisor because they are an occupier focused practice that have very good landlord relationships without the associated conflict of acting on both sides of the fence....',
        description2: '... they acted professionally and diligently throughout the process and cannot be recommended highly enough."',
        name: "Guy Marks",
        location: ",Founder, Mobile5 (an Omnicom Group Company)"
    },
    {
        img: imtech_logo,
        description1: '"Imtech had the challenge of two office relocations taking place at the same time, Piers from REESA professionally sourced both properties and ensured we were fully informed about developments on both properties throughout the process.',
        description2: 'Thanks to his excellent advice and persistence relating to one of the leases that was particularly legally complex, we were able to reach an agreement that satisfied all parties. Imtech would not hesitate to recommend the service provided by REESA."',
        name: "Maritz Naudè",
        location: ", Finance Director, Imtech"
    },
    {
        img: dga_logo,
        description1: '"Just a short note to thank both you and George for guiding Amy and I through this process.',
        description2: 'All seems to be going more or less according to plan, so we are very pleased with the role you guys performed in starting the process."',
        name: "Amy Telling",
        location: ", Office Manager, DGA"
    },
    {
        img: mediaiq,
        description1: '"Piers led the search for a new office space for our advertising and technology company. He demonstrated a solid ability to interpret our brief to find a space suitable for our industry location, culture and, of course, budget.',
        description2: 'We altered our brief several times during the search and Piers was able to adapt and introduce relevant properties for our changing needs. I felt we were given a comprehensive view of available properties on the market, both in terms of location, price range and type from new build to sub-let. In addition to recommending properties he was able to provide expert knowledge on location, pricing, service charges etc. to help us make our decision.',
        description3: 'Once we identified the right property, Piers led the negotiation with agent, landlord and lawyers on price and condition improvements and acting as a driving force to close the deal quickly. He was great to work with, we felt the benefit of his expertise and drive."',
        name: "Steph Robin",
        location: ", Operations Director, Media"
    },
    {
        img: sola_group,
        description1: '"SOLA Engaged Piers to represent us in some difficult negotiations with our landlords. He was an absolute pleasure to deal with and due to his extensive market knowledge and experience was able to quickly recommend the best course of action to achieve a positive result for the company.',
        description2: 'From that point on, Piers took on all the negotiations with the agent and landlord and drove the project forwards to completion. This alleviated all of the internal pressure for SOLA and freed up the management team to focus on the day job. The results achieved were fantastic and I have no doubt far more favourable than the position we could have reached independently.',
        description3: 'Piers was also able to recommend a brilliant solicitor and the two of them worked as a fantastic team to represent us and both remained fully engaged with the project throughout. Worth every penny of the fees and SOLA couldn’t recommend the services of Piers and the REESA team highly enough. We will absolutely be engaging them for any property related negotiations in the future."',
        name: "Emily Thomas",
        location: ", Operations Director, Sola Group"
    },
    {
        img: rmp_property_logo,
        description1: '"We used REESA`s services to find and secure a beautiful office space in Clerkenwell. David and George guided us throughout the process in an extremely friendly and professional manner and recommended great third parties to work with."',
        name: "Christina Kauppinen",
        location: ",  Project Coordinator, RMP Property"
    },
    {
        img: meltwater,
        description1: '"Piers and his team have been a pleasure to work with. They not only helped us secure our fabulous new office space in Shoreditch, but have always gone out of their way to give us sound advice and guide us through what could otherwise be a demanding and complicated process.',
        description2: 'We could not have done it without their help, and I cannot recommend them enough."',
        name: "Natasha Nissar",
        location: ", Office of the CEO, Piers"
    },
    {
        img: seven,
        description1: '"We chose to partner with Piers and his company because we liked the idea that the occupier came first and it was clear Piers understood well the area we were buying and selling in.',
        description2: 'Piers got to understand our business drivers quickly and set about aligning our exit and new acquisition, so we could get on with the task of running our business. Piers felt like an extension of our team and the personal approach suited our business perfectly; and most importantly we achieved a sale at a great premium and a really good value purchase for what we considered a very reasonable cost."',
        name: "Jerry Harris",
        location: ", COO/CFO, 24 Seven"
    },
    {
        img: lygon_group,
        description1: '"Piers was pro-active and engaged at every stage of our search for new office accommodation .',
        description2: 'From taking the initial brief, through the subsequent viewings and the lease negotiation, he was always on hand to offer sound professional advice. He made our property search so much easier and was a pleasure to deal with."',
        name: "Fiona Kelly",
        location: ", Office Manager, Lygon Group"
    },
    {
        img: aud_sci,
        description1: '"Throughout our relocation, from the start to the finish, the team at REESA were my guides, my advisors, my help & support and my sanity checkers! Hugely professional at all times, the team have the experience and vast knowledge of the marketplace to help you through each step of the move process.',
        description2: 'They’re also a great group of people and I always look forward to catching up with them. It’s invaluable having a trusted team around you when you’re taking on a relocation project – quite simply, they nailed it!"',
        name: "Sharon Rimmell",
        location: ", Office Manager, Audience Science"
    },
]