import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../../assets/reesa_logo.svg";
import toggle_icon from "../../assets/menu_icon.svg";
import { ReactComponent as CompassIcon } from "../../assets/compass/compass-icon.svg";

function CompassNavbar(props) {
  return (
    <div>
      <div className="fixed_header bg-white">
        <Navbar collapseOnSelect expand="lg">
          <div className="d-flex flex-column">
            <img src={logo} alt="logo" />
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav float-right">
            <span className="navbar-toggle-icon text-white">
             <img src={toggle_icon} alt="toggle_icon" />
            </span>
          </Navbar.Toggle>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <div className="d-flex flex-column">
              <CompassIcon />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
export default CompassNavbar;
