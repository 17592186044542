export const businessExcellenceData1 = {
    content: [
      {
        title: "Home working essentials",
        description:
          "Your body and mind require the same working comfort and support at home, as when in the office. Adjustable seating, task lighting and acoustic products will immediately lift wellbeing and productivity levels. "
      },
      {
        title: "Home working apex",
        description:
          "For the initiated, sit stand desks have become bionic instruments and must be replicated at home. Organisations are beginning to rollout executive garden pods for what has become the proverbial company car."
      },
      {
        title: "Remote working access",
        description: `Whether you're working on the road or need to escape the home, our UK & Ireland wide network of coworking facilities provides you with both local and city centre access, as and when you need it.`
      },
      {
        title: "Experience first workplaces",
        description:
          "By placing human experience at the centre of your workplace, you can build a thought-led strategy which revolves around how you work... where you work..., to propel your people forward."
      },
    ],
    heading:
      "What if everything we needed could be found at home, on the go and at work?"
  };
  
  export const businessExcellenceData2 = {
    content: [
      {
        title: "Building choice ",
        description:
          "When selecting the right building, it's imperative to bear in mind the fundamental elements that your team will depend on to thrive; natural light, ventilation, acoustics and biophilia."
      },
      {
        title: "Physical features",
        description:
          "Reshape the landscape of your workplace through a variety of private and communal work areas. Our design team will develop the right overlay for your business needs and people to fall into."
      },
      {
        title: "Service features",
        description:
          "Comfort can be found in the little details and incidentals that underpin the satisfactory running of a workplace. Where splendid refreshments compliment online bookings for desks and meeting rooms."
      },
      {
        title: "Culture and community",
        description:
          "We create spaces which promote mobility, encourage chance encounters and drive closer collaboration. Innovation and creativity happen within the melting pot of your own ecosystem."
      },
    ],
    heading: "What if we built around nature? Instead of over it.",
  };
  
  export const businessExcellenceData3 = {
    content: [
      {
        title: "Value engineered real estate",
        description:
          "Move closer towards agile working and further away from permanent desks. Allowing you to reduce your footprint whilst simultaneously increasing the diversity and richness of your work areas."
      },
      {
        title: "Flexibility for every activity",
        description:
          "Providing people with the freedom to decide where and how they work across your workplace enables them to choose the ideal setting to complete a full array of daily tasks."
      },
      {
        title: "Elasticity generates electricity",
        description:
          "When workplace density comes down, we know that productivity levels go up."
      },
      {
        title: "The economics behind ergonomics",
        description:
          "Our furniture team can help you select the right products to accompany your workplace and specific individual needs. Helping you to increase comfort levels and reduce fatigue."
      },
    ],
    heading: `It pays to be ahead of the curve  `,
  };
  