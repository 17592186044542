export const projectManagementData1 = {
  content: [
    {
      title: "Tenacious Professional Experts",
      description:
        "Tenant Representation is at the core of REESA. We are retained by occupiers to solve problems, negotiate deals and oversee any aspect of Workplace Strategy. From finding a building, remote working, buying furniture through to end of Lease Dilapidations, and everything in between.",
    },
    {
      title: "Why be represented",
      description:
        "<p>Until you’ve mastered a topic, it’s impossible to know what you don’t know!</p> <p>Real Estate is an industry of compliance, contracts, commitments, money and big decisions. It’s not particularly transparent. Landlords have their handpicked experts. So being represented by REESA gives you an advantage and gets things done.</p>",
    },
    {
      title: "Getting proper advice",
      description:
        "Our advisors are qualified experts with real world experience of advising across the business spectrum. Our goal is always to stay involved with our clients and forge long term successful results together.",
    },
    {
      title: "Members of The Royal Institution of Chartered Surveyors",
      description:
        "REESA is a registered firm of Chartered Surveyors and are regulated by the RICS. We adhere to their code of conduct, guidance and regular updates on legislation and professional standards.",
    },
  ],
  heading: "What is Tenant Representation?",
};

export const projectManagementData2 = {
  content: [
    {
      title: "Working with REESA you’ll have:",

      description: {
        bulletType: "+",
        items1: [
          "A dedicated property consultant as an extension to your team",
          "Be treated like a VIP with our exclusive service ",
          "Research, insight and search tools at your disposal",
          "‘First to’ and ‘off market’ opportunities",
          "Strong project leadership, management and execution ",
        ],
      },
    },
    {
      title: "Preparation of a Budget",
      description:
        "Clients appreciate our candid approach when it comes to cost appraisals and budgets. Our experience and wide service offering, mean there is no commercial property cost that we can’t help you accurately prepare for.",
    },
    {
      title: "Searching the market for:",
      description: {
        bulletType: "+",
        items1: [
          "Leases, Sublets, Assignments",
          "Freeholds ",
          "Development Land",
          "Serviced Offices & Coworking",
          "M.O.R.E - Managed Office Real Estate",
          "Cat A+ (fitted out)",
        ],
      },
    },
    {
      title: "Property Tours",
      description:
        "Your REESA consultant will organise and accompany you on a series of property tours, and help create a short list.",
    },
  ],
  heading: "Property Search",
};

export const projectManagementData3 = {
  content: [
    {
      title: "Property Due Diligence",
      description: {
        bulletType: "+",
        items1: [
          "Site Surveys",
          "Measurement",
          "Layout plans & Test fits",
          "M&E appraisals",
          "Schedules of Condition",
        ],
      },
    },
    {
      title: "Negotiation",
      description:
      "Our pledge is simple; Every time we negotiate a deal for a client, we go to war for them, applying every ounce of our experience and market awareness. We then remain absolute to the principle of getting the best possible savings and result."
    },
    {
      title: "Project Management ",
      description:
        "Real Estate matters are rarely quick and simple given the number of stakeholders involved. Project Management in the context of Tenant Representation means being the overriding project leader and problem solver for workplace topics.",
    },
    {
      title: "Lease management – ongoing support ",
      description: {
        mainDes: "Tenant representation goes far beyond a one-off transaction. As Chartered Surveyors we’re qualified in:",
        bulletType: "+",
        items1: [
            "Rent Reviews & Lease Renewals",
            "Property Disposals & Lease Surrenders",
            "Service Charge Disputes",
            "Business Rates",
            "Fit out and Property Maintenance"
        ],
      },
    },
  ],
  heading: "Getting the job done!",
};
