import React from "react";
import Typography from "../Typography";
import { ReactComponent as Left_Arrow } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as Right_Arrow } from "../../assets/icons/right-arrow.svg";
import {
  FilterResultSubHeadingComponent,
  BackAndNext,
} from "./styledComponent";
import FilterStepper from "../FilterStepper";

function PropertyResultSubHeading({
  pageHeading,
  pageSubHeading,
  backButtom,
  nextButton,
  stepper,
  Cart,
  stepperIndex,
  stepperIcon,
  handleCheckout,
  handleBackToSearch,
  ...props
}) {
 
  
  return (
    <FilterResultSubHeadingComponent>
        <div className="row no-gutters align-items-center">
          <div className="col-12 col-xs-6 col-sm-6">
            <div className=" leftSectionDesc">
              <Typography
                className="property_result_sub_heading heading-title "
                variant="bold"
                size="large"
                mobileSize="medium"
              >
                {pageHeading}
              </Typography>
              <Typography
                className="property_result_sub_heading pt-2 heading-title "
                variant="regular"
                size="xxxSmall"
                mobileSize="xxxSmall"
              >
                {pageSubHeading}
              </Typography>
              {backButtom ? (
                <BackAndNext className="back_to_search d-flex pt-2 align-items-center" onClick={handleBackToSearch}>
                  <Left_Arrow className="leftArrow" />{" "}
                  <Typography
                    size="xxSmall"
                    variant="bold"
                    className="text-uppercase"
                  >
                    Back to search
                  </Typography>
                </BackAndNext>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-xs-6 col-sm-6 d-flex flex-column align-items-end">
            {stepper && (
                <FilterStepper index={stepperIndex} Icon={stepperIcon} />
            )}
            {nextButton ? (
              <BackAndNext className="d-flex align-items-center" onClick={handleCheckout}  >
                <Cart className='cartIcon'/>
                <Typography
                  size="xxSmall"
                  variant="bold"
                  className="text-uppercase"
                >
                  CHECKOUT{" "}
                </Typography>
                <Right_Arrow className="rightArrow" />
              </BackAndNext>
            ) : null}
          </div>
        </div>
    </FilterResultSubHeadingComponent>
  );
}
export default PropertyResultSubHeading;
