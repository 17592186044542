import { StepHeadingStyled } from "./styledComponent";

const StepHeading = (props) => {
  const { className, maxWidth } = props;
  return (
    <StepHeadingStyled
      className={`${className ? className : ""} stepHeading`}
      maxWidth={maxWidth ? maxWidth : "756px"}
    >
      {props.children}
    </StepHeadingStyled>
  );
};

export default StepHeading;
