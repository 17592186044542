import styled from "styled-components";
import * as variables from "../../Style/__variables";
export const PaginationWrapper = styled.div`
  .pagination__container {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    & > li:nth-last-child(2) {
      border-right: 0;
    }
    .pagination__item {
      border-right: 1px solid #242b30;
      font-size: 14px;
    }
    .pagination__nav {
      font-size: 14px;
      .right-arrow {
        fill: ${variables.darkPink};
      }
    }
    a {
      padding: 0px 15px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${variables.team_list_name};
      &:focus {
        outline: none;
      }
    }
    .arrow-right {
      fill: ${variables.darkPink};
    }
    .pagination__page--active {
      a {
        color: ${variables.darkPink};
        font-weight: bold;
      }
    }
    .pagination__nav--disabled {
      a {
        color: ${variables.darkGrey};
      }
    }
  }
`;
