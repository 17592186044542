import React, { useEffect, useState } from "react";
import Typography from "../Typography";
import {
  FilterStepperStep,
  FilterStepperInfo,
  FilterStepperIndicator,
  StepperLabel,
  Filter_Stepper,
} from "./styledComponent";
function FilterStepper({ handleSelect, index, Icon }) {
  useEffect(() => {
    setActiveIndex(index);
  }, [index]);
  const [activeIndex, setActiveIndex] = useState(0);
  // Step
  const Step = ({ activeIndex, handleSelect, indicator, title }) => (
    <FilterStepperStep activeIndex={activeIndex} indicator={indicator}>
      <div className="d-flex flex-column justify-content-between h-100">
        <FilterStepperIndicator
          style={activeIndex === indicator - 1 ? { top: 0 } : null}
          //   onClick={() => handleSelect(indicator - 1)}
        >
          <FilterStepperInfo
            style={
              activeIndex === indicator - 1
                ? {
                    backgroundColor: "#a42e5d",
                    width: "50px",
                    height: "50px",
                  }
                : null
            }
          >
            {indicator}
          </FilterStepperInfo>
          {activeIndex === indicator - 1 ? <Icon className="icon" /> : null}
        </FilterStepperIndicator>
        <StepperLabel
          className="text-center"
          activeIndex={activeIndex}
          indicator={indicator}
        >
          <Typography
            variant={activeIndex === indicator - 1 ? "extraBold" : "regular"}
          >
            {title}
          </Typography>
        </StepperLabel>
      </div>
      <div className="Stepper__panel"></div>
    </FilterStepperStep>
  );

  // Overlay
  const Stepper = (props) => (
    <Filter_Stepper
      className={`${props.isVertical ? "Stepper--vertical w-100" : ""} ${
        props.isInline ? "Stepper--inline w-100" : ""
      }`}
    >
      {props.children}
    </Filter_Stepper>
  );

  return (
    <div className="pt-4 w-100">
      <Stepper isInline>
        <Step
          indicator={1}
          title="Filter your search"
          activeIndex={activeIndex}
          style={{ letterSpacing: "0.84px", lineHeight: "normal" }}
          //   handleSelect={handleSelect}
        ></Step>
        <Step
          indicator={2}
          title="Shortlist"
          activeIndex={activeIndex}
          style={{ letterSpacing: "0.84px", lineHeight: "normal" }}
          //   handleSelect={handleSelect}
        ></Step>
        <Step
          indicator={3}
          title="Checkout"
          activeIndex={activeIndex}
          style={{ letterSpacing: "0.84px", lineHeight: "normal" }}
          //   handleSelect={handleSelect}
        ></Step>
      </Stepper>
    </div>
  );
}
export default FilterStepper;
