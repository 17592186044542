export const servicedTypeList = [
    { name: "Hot desk", value: "hotdesk" },
    { name: "Managed", value: "managed" },
    { name: "Private Office", value: "privateoffice" },
];
export const leaseholdTypeList = [
    { name: "Office", value: "office" },
    { name: "Industrial", value: "industrial" },
    { name: "Retail", value: "retail" },
    { name: "Healthcare", value: "healthcare" },
];
export const servicedSizeList = [
    { name: "1-5 people", value: "1 5" },
    { name: "6-10 people", value: "6 10" },
    { name: "11-20 people", value: "11 20" },
    { name: "21-35 people", value: "21 35" },
];
export  const leaseholdSizeList = [
    { name: "<1000 sq ft", value: "1000" },
    { name: "1000-2000", value: "1000 2000" },
    { name: "2000-3000", value: "2000 3000" },
    { name: "3000-5000", value: "3000 5000" },
    { name: "5000-10000", value: "5000 10000" },
    { name: "10000-20000", value: "10000 20000" },
    { name: "20000-50000", value: "20000 50000" },
    { name: "50000+", value: "50000+" },
];