import React from "react";
import LOGO_2020 from "../../assets/LOGO_2020.svg";
import { ReactComponent as Group } from "../../assets/group.svg";
import rics from "../../assets/rics.png";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";
import Typography from "../../Component/Typography";
import { Link, useHistory } from "react-router-dom";
import {
  AddressSection,
  HiringSection,
  Copyright,
  FooterSection,
} from "./styledComponent";

function Footer(props) {
  const {
    color,
    footerBtnHeading,
    footerBtnText,
    footerBtnLink,
    FooterIcon,
    fullScreen,
  } = props;
  const history = useHistory();
  const handlePrivacy = () =>{
    window.location.href = '/privacy';
    // history.push('/privacy')
  }
  const handleTermCondition = () =>{
    window.location.href = '/terms';
  }
  const handleAboutus = () =>{
    window.location.href = '/aboutus';
  }

  const handleCaseStudies = () =>{
    window.location.href = '/clients';
  }

  const handleTeam =()=>{
    if(footerBtnHeading==="GROWING?"){
    window.location.href = '/property-search';
    }
    else{
      window.location.href = '/team';
    }
  }

  return (
    <FooterSection
      className="px-5 py-4 d-flex flex-column justify-content-between"
      fullScreen={fullScreen ? fullScreen : false}
    >
      <div>
        <div className="footer_logo">
          <img src={LOGO_2020} alt="LOGO_2020" />
        </div>
        <div className="row pt-5">
          <AddressSection className="col-lg-8 col-sm-12">
            <div className="row">
              <div className="col-6 col-md-4 col-xl-4 col-sm-4 col-xs-4 mb-5 north_address">
                <Typography
                  variant="regular"
                  size="xSmall"
                  className="north_heading"
                >
                  North
                </Typography>
                <Typography
                  variant="regular"
                  size="xxxSmall"
                  className="north_para_one"
                >
                  Waverly House <br /> Ground floor <br /> 14 Woodhouse Square
                  <br /> Leeds
                  <br /> LS3 1AQ
                </Typography>
              </div>
              <div className="col-6 col-md-4 col-xl-4 col-sm-4 col-xs-4 mb-5 north_address">
                <Typography
                  variant="regular"
                  size="xSmall"
                  className="north_heading"
                >
                  South - HQ
                </Typography>
                <Typography
                  variant="regular"
                  size="xxxSmall"
                  className="north_para_one"
                >
                  Longbow House <br /> 1st floor <br />
                  20 Chiswell Street
                  <br /> London <br />
                  EC1Y 4TW
                </Typography>
              </div>
              <div className="col-6 col-md-4 col-xl-4 col-sm-4 col-xs-4 mb-5 north_address">
                <div className="d-flex">
                  <Typography
                    variant="regular"
                    className="vertical_line mr-0 mr-sm-5"
                  ></Typography>
                  <div className="d-flex justify-content-end">
                    <div>
                      <Typography
                        variant="regular"
                        size="xSmall"
                        className=" north_heading"
                      >
                        Quicklinks
                      </Typography>

                      <div className="mt-1 mb-3">
                        <Link className="north_para" onClick={handleAboutus}>
                          <Typography
                            variant="regular"
                            size="xxxSmall"
                            className="north_para  mb-3"
                          >
                            About us
                          </Typography>
                        </Link>
                        <Link className="north_para" onClick={handleCaseStudies}>
                          <Typography
                            variant="regular"
                            size="xxxSmall"
                            className="north_para mb-3"
                          >
                            Clients
                          </Typography>
                        </Link>
                        <Typography
                          variant="regular"
                          size="xxxSmall"
                          className=" north_para_one"
                        >
                          <span style={{color:"white"}}>0330 004 0404</span>                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AddressSection>
          <HiringSection className="col-lg-4 col-sm-12" color={color}>
            <div className="d-flex justify-content-end">
              <div className="hiring_box">
                <Typography
                  className="hiring_text mb-2"
                  size="xsLarge"
                  variant="regular"
                  mobileSize="xxxMedium"
                >
                  {footerBtnHeading ? footerBtnHeading : "Hiring Now?"}
                </Typography>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="col-sm-3 px-0 col-12">
                    {FooterIcon ? (
                      <FooterIcon
                        className="w-100 h-100"
                        width="50px"
                        height="40px"
                      />
                    ) : (
                      <Group className="w-100 h-100" />
                    )}
                  </div>
                  <div className="col-sm-9 col-12">
                    <Typography
                      variant="regular"
                      className="pt-2 build_team"
                      size="xxxMedium"
                      mobileSize="xSmall"
                    >
                   
                      <span className="underline" style={{color:"white"}} onClick={handleTeam}>
                          Click here
                      </span>{" "}
                 
                      {footerBtnText ? footerBtnText : "to build your T.E.A.M"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </HiringSection>
        </div>
      </div>
      <Copyright className="row mt-4 pt-4 copy_right">
        <div className="col-lg-6 ">
          <Typography
            size="xxxSmall"
            variant="regular"
            className=" copy_right_text"
          >
            © 2022 - All Rights Reserved
            <span className="px-3"> |</span>{" "}
            <Link className="north_para" onClick={handlePrivacy}>
              <span style={{color:"white"}} className="north_para_two"> Privacy Policy</span>
            </Link>{" "}
            <span className="px-3">| </span>{" "}
            <Link className="north_para" onClick={handleTermCondition}>
              <span style={{color:"white"}} className="north_para_two">Terms and Conditions</span>
            </Link>
          </Typography>
          <Typography
            size="xxxSmall"
            variant="regular"
            className="pt-2 copy_right_text"
          >
            REESA is a trading name of Row Group Limited 
          </Typography>
        </div>
        <div className="col-lg-6">
          <div className="d-flex justify-content-end social_icons">
            <div className="d-flex justify-content-between">
              <img
                className="px-3 cursor-pointer"
                src={instagram}
                alt="instagram"
                onClick={() =>
                  window.open("https://www.instagram.com/reesa.uk/")
                }
              />
              <img
                className="px-3 cursor-pointer"
                src={linkedin}
                alt="linkedin"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/reesa-property-and-people/"
                  )
                }
              />
              <img className="px-3" width="180px" src={rics} alt="rics" />
            </div>
          </div>
        </div>
      </Copyright>
    </FooterSection>
  );
}
export default Footer;
