
import {dimensionList} from 'src/constants/imageDimensionList';
let sizes = {
    space: dimensionList.imageSizes,
    content: dimensionList.contentImageSizes
  };
export function getDimensions(name, type = 'space') {
    return sizes[type].find(s => s.name === name)?.dims;
}

export const PlaceholderImage = () => (
    <div className="image-placeholder" style={{ margin : "auto" }}>
      <svg
        viewBox="0 0 32 32"
        width="32"
        height="32"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M20 24 L12 16 2 26 2 2 30 2 30 24 M16 20 L22 14 30 22 30 30 2 30 2 24" />
        <circle cx="10" cy="9" r="3" />
      </svg>
      <p className="image-placeholder__text">Images coming soon</p>
    </div>
  );