import React from 'react';
import './styledComponent.js';
import { PropertyFooter } from "./styledComponent";
import PropertyPagination from 'src/Component/Pagination';
import Typography from "../../Typography";
import Buttom from '../../Button';

function PropertyListFooter({resultsWidth,mapWidth, pageCount, totalCount, currentPage, ShortListReducer, propertyData, handlePageChange, handleClick }) {

    
    const min = (currentPage - 1) * 100 + 1;
    const max = propertyData && propertyData.length < 100 ? ((min - 1) + propertyData.length) : currentPage * 100;
    return (
        <PropertyFooter>
            <div className="propert_list_footer">
                <div className="d-flex propert_list_footer_parent  m-0">
                    <div style={{width : resultsWidth}}>
                        <div className="row justify-contnet-between m-0 py-3">
                            {propertyData?.length > 0 ?
                                <>
                                    <Typography
                                        className="property_pagination_text heading-title col-sm-4"
                                        variant="regular"
                                        size="xxxSmall"
                                        mobileSize='xxxSmall'
                                    >Showing <span>{min}-{max} </span> of <span>{totalCount} </span>results in this area
                            </Typography>
                                    <PropertyPagination
                                        pageCount={Math.ceil(totalCount / 100)}
                                        handlePageChange={handlePageChange}
                                    />
                                </>
                                : null}
                        </div>
                        <div className="paggination_border"></div>
                    </div>
                    <div style={{  width : mapWidth == 0 ? 830 :   mapWidth }}>
                        <div className="d-flex flex-wrap justify-content-around  book_tour mr-4 mt-3 align-items-center">
                            <div className="">
                                <Buttom handleClick={handleClick} buttonText="BOOK A TOUR" buttonClass="book_tour_bottom" />
                            </div>
                            <Typography
                                className="property_shortlist heading-title pr-3 "
                                variant="bold"
                                size="xxSmall"
                                mobileSize='xxSmall'
                            >{ShortListReducer?.items?.length} {ShortListReducer?.items.length > 1 ? 'properties shortlisted' : 'property shortlisted'} 
                            </Typography>

                        </div>
                    </div>
                </div>
            </div>
        </PropertyFooter>

    )
}
export default PropertyListFooter;