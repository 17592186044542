import React from "react";
import "./Prosek.scss";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import Sola_group from "../../assets/CasestudiesItemone/sola_post.jpg";


function Solagroup() {
  return (
    <div>
      <NavBar />
      <div className="container Main_container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <h1 className="prosek_header mt-5 mb-4">
              Sola – City of London, London SE1 & EC3
            </h1>
            <p>
              A project with different stages of development, including the exit
              from a Leasehold property, and consultancy and acquisition of more
              suitable flexible space.
            </p>
            <h3 className="mt-5 mb-3">BACKGROUND</h3>
            <p>
              Sola Group were looking to challenge the way that office space
              worked for the business and make sure that their physical
              environment supported the dynamic and growing needs of their team.
              They’d been in a Leasehold space for some years and although it
              had a beautiful location overlooking the River Thames, it was not
              effectively the wrong size and Reesa were appointed to put a plan
              together for a move.
            </p>
            <h5 className="mt-5 mb-3">PROCESS</h5>
            <p>
              Having been a tenant of a Leasehold property the internal team had
              endured their fair share of frustration dealing with a layer of
              Landlord management and related facilities management. They were
              open to exploring the new generation of flexible serviced space
              having been advised that a lot had changed since they’d been in a
              self contained environment. The idea of an onsite support team,
              managed internet and the ability to grow quickly was definitely of
              interest.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <img src={Sola_group} className="mt-5 mb-5 w-100" />
        <div className="row mb-4">
          <div className="col-3"></div>
          <div className="col-6">
            <p className="basedCompany">
              {" "}
              "We’ve worked with Piers at Reesa for a number of years on various
              property situations. He’s always looked after us extremely well,
              understanding our priorities and objectives and made what could
              have been a stressful project actually quite fun"
            </p>
            <p>- Emily Thomas, Operations Director</p>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row mt-3 bottom_para">
          <div className="col-1"></div>
          <div className="col-10">
            <p>
              But before the search for a new flexible office begun, Reesa had
              to sublet their current office. A marketing program was undertaken
              and a number of interested parties were identified. Once a
              preferred replacement tenant had been found, Reesa successfully
              negotiated Sola out of their Lease with a surrender back to the
              Landlord. This not only took the liability off of our client
              balance sheet it also meant that Sola could move to a new premises
              with the worry of having a sub tenancy.
            </p>
            <p>
              Once a surrender of the current Lease had been achieved,
              simultaneously a search in London Bridge and the City of London
              was undertaken to identify a suitable new office. The amount of
              choice presented by Reesa allowed Sola choice to ensure the new
              space reflected a modern firm consulting to the Technology
              industries including areas for collaboration, break out, client
              entertainment and staff wellbeing.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Solagroup;
