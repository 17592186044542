import React from 'react';
import Typography from "../../Typography";
import { EmptyShortListComponent } from "./styledComponent";
import { withRouter } from "react-router-dom";
import SubmitButton from '../../SubmitButton/SubmitButton';

function EmptyShortListItem(props) {
    const handleFormBackEnqueryClick = () => {
        props.history.goBack()
    }
    return (
        <div>
            <EmptyShortListComponent>
                <Typography
                    className="text-center heading-title pr-3 "
                    variant="regular"
                    size="xxLarge"
                    mobileSize='xxLarge'
                >Your shortlist is empty!
               </Typography>

                <Typography
                    className="text-center heading-title mt-5 pr-3 "
                    variant="bold"
                    size="xxLarge"
                    mobileSize='smxxLargeall'
                >Find Your New Office Space
               </Typography>
                <Typography
                    className="text-center heading-title mt-2 pr-3 "
                    variant="regular"
                    size="small"
                    mobileSize='small'
                >Serviced offices, hot desking, short term and long term flexible contracts.
               </Typography>
                <div className="d-flex justify-content-center mt-4">
                    <SubmitButton
                        text="SEARCH OFFICE"
                        advisorSubmit='search_office_button'
                        className='search_office_text'
                        handleFormEnqueryClick={handleFormBackEnqueryClick}
                        isLoading={false}
                        button={true}
                        checked={true}
                    />
                </div>
            </EmptyShortListComponent>
        </div>

    )
}

export default withRouter(EmptyShortListItem);