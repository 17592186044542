import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const FilterResultSubHeadingComponent = styled.div`
.filter_result_subheading{
    border-top: 2px solid #afafaf;
    box-shadow: 0 7px 7px -4px rgba(0, 0, 0, 0.21);
    .property_result_sub_heading{
       color: ${variables.team_list_name};
       padding: 0px 100px ;
    }
    .back_search{
        padding: 10px 100px ;
        .back_search_text{
            color: ${variables.darkPink};
            font-size: 16px;
            font-weight: bold;
        }
    }
}
@media screen and (max-width: 576px){
    .filter_result_subheading{
    .property_result_sub_heading{
        color: ${variables.team_list_name};
        padding: 0px 0px 0px 25px ;
     }
     .back_search{
        padding: 10px 0px 0px 25px ;
    }
  }
}
`;
