import React, { useEffect, useState } from "react";
import "./index.scss";
import Typography from "../../Typography";
import { Carousel } from "react-bootstrap";
import story1 from "src/assets/AboutUs/story1.png";
import story2 from "src/assets/AboutUs/story2.png";
import story3 from "src/assets/AboutUs/story3.png";
import story4 from "src/assets/AboutUs/story4.png";
import story5 from "src/assets/AboutUs/story5.png";
import story6 from "src/assets/AboutUs/story6.png";
import story7 from "src/assets/AboutUs/story6.jpeg";
import CarouselStepper from "../Stepper";

function Ourstory() {
  const [activeIndex, setIndex] = useState(0);
  const handleSelect = (index) => {
    setIndex(index);
  };

  return (
    <div className="our_story">
      <Typography
        className="our_story_heading heading-title"
        variant="extraBold"
        size="xxxxLarge"
        mobileSize="large"
      >
        Our story
      </Typography>
      <CarouselStepper handleSelect={handleSelect} activeIndex={activeIndex} />
      <div className="ourStory_carousel">
        <Carousel
          interval={3600000}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className="col-sm-6 mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  {" "}
                  Est. 2012
                </Typography>
                <Typography
                  className=" pt-3 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Our Story
                </Typography>
                <Typography
                  className=" pt-3 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  REESA was founded in the City of London with nine employees,
                  capital to invest in technology and a mission to transform the
                  performance of workspace. It quickly became a pioneering Real
                  Estate firm.
                </Typography>
                <Typography
                  className=" pt-3  sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="xxxxMedium"
                >
                  Why
                </Typography>
                <Typography
                  className=" pt-3 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  The founders, set the firm up because peoples’ priorities had
                  changed. Workspace had evolved and everything needed to be
                  connected with a modern approach to advising. The firm’s focus
                  in the early stages was finding and securing office space for
                  companies. As the firm grew, additional related services were
                  added.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img src={story1} alt="story1" className="w-100" />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className=" col-sm-6 text_size mb-5 mb-sm-0">
                <Typography
                  className="  mx-2 heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  A design revolution
                </Typography>
                <Typography
                  className=" pt-3  mx-2  sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Offices were becoming Aspirational
                </Typography>

                <div className="d-flex pt-4 mx-2 justify-content-start">
                  <div className="text-align-center"> + </div>
                  <Typography
                    className="px-2 text"
                    variant="regular"
                    size="xxxSmall"
                    mobileSize="xxxSmall"
                  >
                    {" "}
                    Companies needed help understanding not just where to locate
                    an office but what the true cost of their property  decision
                    would be.
                  </Typography>
                </div>
                <div className="d-flex pt-2 mx-2 justify-content-start">
                  <div className="text-align-center"> + </div>
                  <Typography
                    className="px-2 text"
                    variant="regular"
                    size="xxxSmall"
                    mobileSize="xxxSmall"
                  >
                    {" "}
                    Design and agile working were key.
                  </Typography>
                </div>
                <div className="d-flex pt-2 mx-2 justify-content-start">
                  <div className="text-align-center"> + </div>
                  <Typography
                    className="px-2  text"
                    variant="regular"
                    size="xxxSmall"
                    mobileSize="xxxSmall"
                  >
                    {" "}
                    REESA started a design and project management division.
                  </Typography>
                </div>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1 w-100"
                  src={story2}
                  alt="story2"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className=" col-sm-6 text_size mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="mdLarge"
                >
                  Time to grow
                </Typography>
                <Typography
                  className=" pt-4 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Growth Capital invested by Swiss Private Equity firm
                </Typography>
                <Typography
                  className=" pt-4 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  Clients realised just how important and interlinked their
                  success was to making the correct property decisions. Human
                  factors became equally important compared to just the
                  functional performance of a building.
                </Typography>
                <Typography
                  className=" pt-5 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  {" "}
                  We invested in connecting these dots and further developed
                  strategy and corporate services.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1 w-100"
                  src={story4}
                  alt="story4"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className="col-sm-6 text_size mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  Software, systems & strategy
                </Typography>
                <Typography
                  className=" pt-4 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Led a Joint Venture PropX Technologies
                </Typography>
                <Typography
                  className=" pt-4 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  Work begins on researching and developing tools that enhance
                  property performance, a healthy workplace and staff
                  productivity.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1 w-100"
                  src={story3}
                  alt="story3"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className="col-sm-6 text_size mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  The flexible age
                </Typography>
                <Typography
                  className="pt-4 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Acquisition of flexible office space brokerage oco
                </Typography>
                <Typography
                  className="pt-3 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  A revolution was happening as the flexible office space market
                  innovated and expanded. OCO (formerly Ofpro) was a leading broker of serviced
                  office deals and was a critical addition - granting access to
                  their platform and relationships with the complete flex
                  market.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1"
                  src={story5}
                  alt="story5"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className=" col-sm-6  text_size mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  Welcome White Bear
                </Typography>
                <Typography
                  className="pt-4 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  Interior Design & Online Furniture Store
                </Typography>
                <Typography
                  className="pt-3 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  Online furniture retailer White Bear became part of REESA in
                  the summer of 2020. As well as being an independent furniture
                  consultancy, its ecommerce store and interior design
                  capabilities are a perfect fit to our Real Estate services.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1 w-100"
                  src={story6}
                  alt="story4"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex align-items-start carousel-Wrapper">
              <div className=" col-sm-6  text_size mb-5 mb-sm-0">
                <Typography
                  className="heading-title"
                  variant="bold"
                  size="large"
                  mobileSize="smLarge"
                >
                  A new era, a new way
                </Typography>
                <Typography
                  className="pt-4 sub-heading-title"
                  variant="regular"
                  size="xxxxMedium"
                  mobileSize="small"
                >
                  The UK and Ireland’s first one stop shop for commercial
                  property
                </Typography>
                <Typography
                  className="pt-3 text"
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  Our full 360 suite of services and products are now all
                  conveniently found and accessed in one place. Providing you
                  with a trusted outsourced partner for your Real Estate,
                  Workplace Design and Wellness programmes. Our collective of
                  industry experts will take on the burden and legwork of these
                  areas for you, allowing you to know you are in safe hands, be
                  more available to focus on your everyday work.
                </Typography>
              </div>
              <div className="col-sm-6">
                <img
                  className="d-block carousel_Img1 w-100"
                  src={story7}
                  alt="story6"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
export default Ourstory;
