import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slick';
import _ from 'lodash';
import cx from 'classnames';
import SliderButton from './SliderButton';
import './Slider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DEFAULT_SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  lazyLoad: true
};
/**
 * @class Slider
 *
 * Handles rendering of the carousel
 *
 * @param {object} settings - optional additional slider settings
 * @param {array} children - array of elements which make up the carousel
 * @param {bool} internalArrows - left/right arrows should appear within the slider
 */
export default class Slider extends Component {
  static propTypes = {
    settings: PropTypes.object,
    children: PropTypes.array,
    internalArrows: PropTypes.bool
  }
  state = {
    isMounted: false,
    settings: {
      ...DEFAULT_SLIDER_SETTINGS,
      prevArrow: <SliderButton direction="left" />,
      nextArrow: <SliderButton direction="right" />,
      ...this.props.settings
    }
  }
  componentDidMount() {
    this.setState({
      isMounted: true
    });
  }
  componentDidUpdate(prevProps) {
    const { slidesToScroll, slidesToShow } = this.props.settings;
    const changed =
      (slidesToScroll !== prevProps.settings.slidesToScroll) ||
      (slidesToShow !== prevProps.settings.slidesToShow);
    if (changed) {
      this.setState({
        settings: {
          ...this.state.settings, slidesToShow, slidesToScroll
        }
      });
    }
  }
  renderSlider(/* slides, settings */) {
    const { children: slides, settings, internalArrows } = this.props;
    const sliderSettings = {
      ...DEFAULT_SLIDER_SETTINGS,
      prevArrow: <SliderButton direction="left" />,
      nextArrow: <SliderButton direction="right" />,
      ...settings
    };
    if (!slides.length) return null;
    const { isMounted } = this.state;
    const slidesToRender = isMounted ? slides : _.take(slides, sliderSettings.slidesToShow);
    return <ReactSlider 
    className={ cx({ 'internal-arrows': internalArrows }) }{...sliderSettings}
    >
      {slidesToRender}
    </ReactSlider>;
  }
  render() {
    return <div className="slider-container">{this.renderSlider()}</div>;
  }
}

















