import React from "react";
import "./Prosek.scss";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import Hive_post from "../../assets/CasestudiesItemone/hive_post.jpg";

function Hive() {
  return (
    <div>
      <NavBar />
      <div className="container Main_container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <h1 className="prosek_header mt-5 mb-4">
              HIVE – 1 Bloomsbury Way, Covent Garden, London
            </h1>
            <p>
              We helped HIVE acquire large serviced office space to accommodate
              their growing team in the beautiful 1 Bloomsbury Way, WC1.
            </p>
            <h3 className="mt-5 mb-3">BACKGROUND</h3>
            <p>
              HIVE is part of the Connected Home division of Centrica, the FTSE
              100 listed energy company. They had been occupying serviced office
              space in Soho during the development of their range of products
              and needed to move office to accommodate a fast growing team. It
              was important to find a building which would help attract the most
              talented graduates and developers, and one which would offer
              scalable growth opportunities.
            </p>
            <h5 className="mt-5 mb-3">PROCESS</h5>
            <p>
              Reesa were instructed by the estates team of Centrica to undertake
              research into available options in the West End of London. It was
              an unusually large acquisition as we needed to source buildings
              able to accommodate up to 200 people. Due to the size,
              consideration was given to whether a leased space would work,
              although during consultation the upfront costs and lack of
              flexibility concluded that serviced space would work best.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <img src={Hive_post} className="mt-5 mb-5 w-100" />
        <div className="row mb-4">
          <div className="col-3"></div>
          <div className="col-6">
            <p className="basedCompany">
              {" "}
              "We were delighted to find a building that not only offered the
              flexibility required for our fast growing tech team but a modern
              working environment that reflected our culture"
            </p>
            <p>- M Lycett, Centrica</p>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row mt-3 bottom_para">
          <div className="col-1"></div>
          <div className="col-10">
            <p>
              A tour of serviced office providers able and willing to offer
              large space identified the preferred option as The Office Group’s
              building 1 Bloomsbury Way. It’s mix of large bright spaces and
              convenient location to other Centrica locations as well as its
              very generous provision of break out space and coworking space
              made this the perfect choice.
            </p>
            <p>
              This project is an example of large business demanding a more
              flexible approach to occupying and using office space to empower
              workers and culture. Five years ago, this requirement would have
              focused on leasehold options but the progression of serviced
              offices and the flexible workspace model enabled Centrica to
              choose the right space.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Hive;
