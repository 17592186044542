import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import Timeline from "src/Component/timeline";
import {
  proposalDisposalsData1,
  proposalDisposalsData2,
  proposalDisposalsData3,
} from "src/constants/propertyDisposals";
import D1 from "src/assets/PropertySearch/D1.jpg";
import D2 from "src/assets/PropertySearch/D2.jpg";
import D4 from "src/assets/PropertySearch/D4.jpg";
import D5 from "src/assets/PropertySearch/D5.jpg";
import D6 from "src/assets/PropertySearch/D6.jpg";
import D7 from "src/assets/PropertySearch/D7.jpg";
import D8 from "src/assets/PropertySearch/D8.png";
import FooterBg from 'src/assets/PropertySearch/ps-footer.jpg';
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const PropertyDisposal = (props) => {
  const SubHeading = () => {
    return (
      <div>
        Let or Sell your property <span className="darkPink">Quickly</span> and
        at the <span className="darkPink">Best</span> possible price
      </div>
    );
  };
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Property Disposals" image={D1} />
      <SubHeader
        showBar={true}
        themeColor={"darkPink"}
        subHeading={SubHeading}
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={proposalDisposalsData1}
        timelineData2={proposalDisposalsData2}
        timelineData3={proposalDisposalsData3}
        image1={D2}
        image2={D8}
        image3={D4}
        image4={D5}
        image5={D6}
        image6={D7}
        formBg={FooterBg}
        themeColor={"darkPink"}
        timelineDivider1Height="128px"
        borderContainerWidth="70%"
        divider1Height="289"
      />
      <Footer />
    </Fragment>
  );
};

export default PropertyDisposal;
