import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const AdvisorSection = styled.div`
background-size: cover;
background-position: center;
padding:30px;
@media(max-width:576px){
  padding:30px 20px;
}
height:${(props) => (props.fullScreen ? "100vh" : "auto")};
position: relative;
.advisors_form {
  width: 40vw;
  ${
    "" /* width: 640px;
  height: 676px; */
  }
  @media(max-width:992px){
      width:90vw;
     
  }
  background-color: #242b30;
  .advisor_form_heading {
    color: #ffffff;
    margin-bottom: 22px;
    letter-spacing: 1.5px;
    line-height: 1.52;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
  }
  .lable_name {
    color: #ffffff;
    line-height: 2.43;
    letter-spacing: 2px;
  }
  .inputbox {
    padding: 9px;
    border: 1px solid #afafaf;
  }
  .textArea {
    border: 1px solid #afafaf;
    padding: 9px;
  }
  .dropdownBox {
    width: 100%;
    ${'' /* padding: 11px 9px; */}
    outline: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #fff;
    .optionList {
      font-family: 'Proxima Nova W01 Regular';
      letter-spacing: 1.14px;
      color: #7e7e7e;
      font-size:16px;
      line-height: 2.13;
      margin: 10.7px 21px 0 0;
     ${'' /* padding:50px */}
    }
  }
  .advisorSubmit {

    border: none;
    padding: 11px 80px;
    font-family: 'Proxima Nova W01 Regular';
    background-color:${(props) =>
      props.themeColor ? variables[props.themeColor] : variables.greeny_blue};
    color: #ffffff;
    letter-spacing: 2px;
    @media(max-width:576px) {
      width:100%;
    }
  }

  
}
.advisorFormBg {
  position: absolute;
  z-index: -1;
  top: 0;
}
}
@media screen and (max-width: 770px) {
.advisors_section {
  padding-left: 0px;
  .advisors_form {
    width: 88vw;
    .inputbox {
      width: 104%;
    }
    .textArea {
      width: 104%;
    }
  }
}
}

@media screen and (max-width: 426px) {
.advisors_section {
  padding-left: 0px;
  .advisors_form {
    width: 88vw;
    .inputbox {
      width: 108%;
    }
    .textArea {
      width: 108%;
    }
  }
}

`;
