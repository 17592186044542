import React from "react";
import carouselImg from "../../../assets/carousel_img.png";
import carouselImg2 from "../../../assets/carousel_img2.jpg";
import carouselImg3 from "../../../assets/carousel_img3.jpg";
import carouselImg4 from "../../../assets/carousel_img4.png";
import "./homeCarousel.scss";
import { Carousel } from "react-bootstrap";
import Typography from "../../Typography";

function HomeCarousel() {
  return (
    <div className="home_carouseel">
      <Carousel  controls={false} interval={3000}>
        <Carousel.Item className="one_stop_shop" >
          <img
            class="d-block w-100 carousel_img"
            src={carouselImg}
            alt="First slide"
          />
          <Carousel.Caption>
            <div class="d-md-block d-flex flex-column  justify-content-between">
              <Typography
                className="caption_text text-left heading-title "
                variant="extraBold"
                size="xxxxxxLarge"
                mobileSize="xSmall"
              >
                 Welcome to our one stop <br /> shop for commercial <br/> property,
                
              </Typography>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="beautiful_building">
          <img
            class="d-block w-100 carousel_img"
            src={carouselImg2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <div class="d-md-block">
              <Typography
                className="caption_text text-left heading-title "
                variant="extraBold"
                size="xxxxxxLarge"
                mobileSize="xSmall"
              >
                Embrace new <br /> ways of working
              </Typography>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="work_balance">
          <img
            class="d-block w-100 carousel_img"
            src={carouselImg3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <div class=" d-md-block">
              <Typography
                className="caption_text text-left heading-title "
                variant="extraBold"
                size="xxxxxxLarge"
                mobileSize="xSmall"
              >
                Beautiful buildings, <br/> breathtaking interiors
              </Typography>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="informed_decisions">
          <img
            class="d-block w-100 carousel_img"
            src={carouselImg4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <div class="  d-md-block">
              <Typography
                className="caption_text text-left heading-title "
                variant="extraBold"
                size="xxxxxxLarge"
                mobileSize="xSmall"
              >
                Informed decisions, <br/> better results.
              </Typography>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
export default HomeCarousel;
