import styled from "styled-components";
import * as variables from "../../Style/__variables";
export const MainHeader = styled.div`
  max-width: 1196px;
  padding: 0 20px; 
  margin:auto;
  text-align: center;
  .main-heading {
    color: ${variables.darkpurple};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 4.89px;
  }
  .main-sub-heading {
    text-transform: uppercase;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 2.4px;
    color: ${variables.team_list_name};
    .firstChar {
      color: ${variables.darkpurple};
      font-weight: bold;
    }
  }
`;

export const QuoteSection = styled.div`
  max-width: 1196px;
  padding: 48px 20px 96px 20px;
  margin: auto;
  .quoteTextSubheading {
    line-height: 1.5;
    letter-spacing: 1px;
    max-width: 498px;
    margin: auto;
    margin-bottom:40px;
  }
  .quoteTextHeading {
    line-height: 1.38;
    letter-spacing: 2.29px;
    max-width: 498px;
    margin: auto;
  }
  .writerName{
    line-height: 1.89;
    letter-spacing: 1.29px;
    font-weight:600;
    margin: auto;
    max-width: 498px;
    text-align:right;
    margin-top:15px;
  }
  @media(max-width:576px){
    padding: 48px 20px
  }
`;

export const BenifitsSection=styled.div`
  color:${variables.white};
  background-color:${variables.darkpurple};
  padding:62px 32px;
  .benifitsHeading{
    font-weight:bold;
    margin-bottom:80px;
  }
  .benifitsDesc{
    letter-spacing: 3px;
    line-height: 1.42;
    margin-bottom:40px;
  }
  .benifit-section-image{
    margin-bottom:28px;
    height:84px;
    object-fit:contain;
    width:100%;

  }
  .quoteTextWrapper{
    max-width:693px;
    margin:80px auto 0 auto;
    text-align:center;
    .writerName{
      margin-top:20px;
    }
    @media(max-width:576px){
      margin:20px auto 0 auto;
    }
  }
  
`
