import * as actions from "../action";
import fireAjax from "../../services/fireAjax";
import { call, put } from "redux-saga/effects";

export function* notifyForm(action) {
    try {
      const response = yield call(
        fireAjax,
        "POST",
        `${process.env.REACT_APP_BASE_URL}/api/enquire/submit?type=empty-search`,
        {},
        action.payload
      );
      if (response.statusText == "Accepted") {
        yield put(actions.notifyFormSuccess(response.data));
      }
    } catch (e) {

      yield put(actions.notifyFormError(e.response));
    }
}