import React from "react";
import {
  StarIcon,
  CompassIcon,
  OfficeBuildingIcon,
  FloorPlan,
} from "src/Component/Icons";

import "./index.scss";

const Card = ({ handleSelect, activeIndex }) => {
  return (
    <div className="card_wrapper">
      <div className="row">
        <div
          className={`col-md-3 text-center align-items-center card_content_wrapper bg_star ${activeIndex == 0 && "active"}`}
          onClick={() => handleSelect(0)
          }
        >
          <div>
            <StarIcon />
          </div>

          <div className="header_title">Your wishlist</div>
          <div className="content_title">Capturing the brief</div>
          <div className="bottom_content_star"></div>
        </div>
        <div
          className={`col-md-3 text-center align-items-center card_content_wrapper bg_compass ${activeIndex == 1 && "active"}`}
          onClick={() => handleSelect(1)}
        >
          <div>
            <CompassIcon className="svg_hover" />
          </div>

          <div className="header_title">Workplace strategy</div>
          <div className="content_title">Designing the future</div>
          <div className="bottom_content_compass"></div>
        </div>
        <div
          className={`col-md-3 text-center align-items-center card_content_wrapper bg_office ${activeIndex == 2 && "active"}`}
          onClick={() => handleSelect(2)}
        >
          <div>
            <OfficeBuildingIcon className="svg_hover" />
          </div>

          <div className="header_title">Site visit</div>
          <div className="content_title">Measurements & initial surveys</div>
          <div className="bottom_content_office office_bg"></div>
        </div>
        <div
          className={`col-md-3 text-center align-items-center card_content_wrapper bg_floorplan ${activeIndex == 3 && "active"}`}
          onClick={() => handleSelect(3)}
        >
          <div>
            <FloorPlan className="svg_hover" />
          </div>

          <div className="header_title">Floor plans</div>
          <div className="content_title">Space planning & test fit</div>
          <div className="bottom_content_floorplan floorplan_bg "></div>
        </div>
      </div>
    </div>
  );
};

export default Card;
