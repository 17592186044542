import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/AudioVisual/AVBgImage.jpg";
import Timeline from "src/Component/timeline";
import {
  audioVisualData1,
  audioVisualData2,
  audioVisualData3,
} from "src/constants/audioVisual";
import AV2Image from "src/assets/AudioVisual/AV2.jpg";
import AVPersonImage from "src/assets/AudioVisual/AVPersonImage.jpg";
import AV3Image from "src/assets/AudioVisual/AV3.jpeg";
import AV4Image from "src/assets/AudioVisual/AV4.jpg";
import AV5Image from "src/assets/AudioVisual/AV5.jpg";
import AV6Image from "src/assets/AudioVisual/AV6.jpg";
import AV7Image from "src/assets/AudioVisual/AV7.jpg";
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const AudioVisualService = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history}/>
      <Header title="Audio-Visual Services" image={BgImage} />
      <SubHeader
        showBar={true}
        subHeading={
          "Connecting people and spaces through sound and vision technology"
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={audioVisualData1}
        timelineData2={audioVisualData2}
        timelineData3={audioVisualData3}
        image1={AV2Image}
        image2={AVPersonImage}
        image3={AV3Image}
        image4={AV4Image}
        image5={AV5Image}
        image6={AV6Image}
        formBg={AV7Image}
        themeColor='greeny_blue'
        step1HeadingMaxWidth="504px"
      />
      <Footer />
    </Fragment>
  );
};

export default AudioVisualService;
