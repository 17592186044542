

import * as actions from "../action";
import fireAjax from "../../services/fireAjax";
import { call, put } from "redux-saga/effects";

export function* getPropertyData(action) {

  try {
    const response = yield call(
      fireAjax,
      "GET",
      `${process.env.REACT_APP_BASE_URL}/api/spaces?per_page=100&page=${action.payload.page}&summary=true&published=true&${action?.payload?.propertyType}=true&${(action?.payload?.propertyType === "leasehold") ? "sqftMin" : "desk_min"}=${action?.payload?.desk_min}&${(action?.payload?.propertyType === "leasehold") ? "sqftMax" : "desk_max"}=${action?.payload?.desk_max}&${action.payload.type}=true&bounds=${action?.payload?.location}&sort_by=createdAt&direction=desc`,
      // `http://test1.reesa.com/api/spaces?per_page=100&page=${action.payload.page}&summary=true&published=true&${action?.payload?.propertyType}=true&${(action?.payload?.propertyType === "leasehold") ? "sqftMin" : "desk_min"}=${action?.payload?.desk_min}&${(action?.payload?.propertyType === "leasehold") ? "sqftMax" : "desk_max"}=${action?.payload?.desk_max}&${action.payload.type}=true&bounds=${action?.payload?.location}&sort_by=createdAt&direction=desc`,
      {},
      action.payload
    );
   let allResponse = {
     propertyItemsData : {},
     current_page : "",
     page_count : "",
     total_count : ""
   }
    if (response.statusText == "OK") {
      allResponse.propertyItemsData = response.data;
      allResponse.current_page = response.headers['current-page'];
      allResponse.page_count = response.headers['page-count'];
      allResponse.total_count = response.headers['total-count'];

      yield put(actions.getPropertyDataSuccess(allResponse));
    }
  } catch (e) {

    yield put(actions.getPropertyDataError(e.response));
  }
}