import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const Circle = styled.div`
  width: 104px;
  height: 104px;
  font-family: "Proxima Nova W01 Regular";
  font-size: 61.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3.42px;
  text-align: center;
  color: ${(props) =>
    props.theme.color ? variables[props.theme.color] : variables.greeny_blue};
  border-radius: 50%;
  border: 4px solid
    ${(props) =>
      props.theme.color ? variables[props.theme.color] : variables.greeny_blue};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${variables.white};
  transform: ${(props) =>
    props.stepSide
      ? props.stepSide === "right"
        ? `translate(50%,0)`
        : "translate(-50%,0)"
      : ""};
    flex-shrink:0;
  @media (max-width: 576px) {
    transform: translate(0, 0);
    width: 80px;
    height: 80px;
    font-size: 45px;
  }

  &::before {
    content: "";
    position: absolute;
    width: ${(props) => props.dividerWidth};
    background-color: ${(props) =>
      props.theme.color ? variables[props.theme.color] : variables.greeny_blue};
    height: ${(props) => props.dividerHeight};
    top: ${(props)=>props.dividerPosition?props.dividerPosition:'100%'};
    left: 50%;
    transform:translate(-50%,0);
    @media (max-width: 576px) {
      display: none;
    }
  }
  
`;
