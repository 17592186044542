

import * as actions from "../action";
import fireAjax from "../../services/fireAjax";
import { call, put } from "redux-saga/effects";

export function* getPropertyDetail(action) {

    try {
      const response = yield call(
        fireAjax,
        "GET",
        `${process.env.REACT_APP_BASE_URL}/api/spaces/${action.payload.id}?images=${action.payload.images}&places=${action.payload.places}`,
        {},
        action.payload
      );
      if (response.statusText == "OK") {
        yield put(actions.getPropertyDetailaSuccess(response.data));
      }
    } catch (e) {

      yield put(actions.getPropertyDetailError(e.response));
    }
}