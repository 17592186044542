import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/AudioVisual/AVBgImage.jpg";
import Timeline from "src/Component/timeline";
import {
  businessRatesData1,
  businessRatesData2,
  businessRatesData3,
} from "src/constants/businessRates";
import BR1 from "src/assets/BusinessRates/BR1.jpg";
import BR2 from "src/assets/BusinessRates/BR2.jpg";
import BR3 from "src/assets/BusinessRates/BR3.png";
import BR4 from "src/assets/BusinessRates/BR4.jpg";
import BR5 from "src/assets/BusinessRates/BR5.jpg";
import BR6 from "src/assets/BusinessRates/BR6.jpg";
import BR7 from "src/assets/BusinessRates/BR7.jpg";
import BR8 from "src/assets/BusinessRates/BR8.jpg";
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const BusinessRates = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history}/>
      <Header title="Business Rates" image={BR1} />
      <SubHeader
        showBar={true}
        themeColor={"darkPink"}
        subHeading={
          `Qualified Chartered Surveyors helping property owners 
          and occupiers save money on Business Rates`
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={businessRatesData1}
        timelineData2={businessRatesData2}
        timelineData3={businessRatesData3}
        image1={BR2}
        image2={BR3}
        image3={BR4}
        image4={BR5}
        image5={BR6}
        image6={BR7}
        formBg={BR8}
        themeColor={"darkPink"}
        timelineDivider1Height='128px'
      />
      <Footer />
    </Fragment>
  );
};

export default BusinessRates;
