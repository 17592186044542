export const projectManagementData1 = {
  content: [
    {
      title: "Team Selection & Tender Process ",
      description:
      "Choosing the correct professional team is crucial. REESA will prepare your brief, tender documentation and appraise the responses. We run an objective process for the appointment of external consultants, architects, designers, contractors and technical teams."
    },
    {
      title: "Managing the Design and Construction Process",
      description:
        "With many years' experience overseeing the design and construction stages of a project our approach is structured, diligent and transparent. Our role is to mitigate the risk inherent in any project and trouble shoot when required.",
    },
    {
      title: "Cost Consultancy",
      description:
        "Protecting our client's budget and making sure any costs involved are fair, clearly understood and value for money.",
    },
    {
      title: "Members of The Royal Institution of Chartered Surveyors",
      description:
        "REESA is a registered firm of Chartered Surveyors and are regulated by the RICS. We adhere to their code of conduct, guidance and regular updates on legislation and professional standards.",
    },
  ],
  heading: "Fit Out Project Management",
};

export const projectManagementData2 = {
  content: [
    {
      title: "Schedule of Condition",

      description: {
        maindes: "Often a legal requirement and always recommended:",
        bulletType:'+',
        items1: [
          "Accurate recording of building condition",
          "Written, photographic and 360 video options",
          "Helps identify areas requiring repair",
          "Protection against future repairing liabilities",
          "Before or after building work",
        ],
      },
    },
    {
      title: "Pre-Acquisition Survey",
      description:
        "Identify any repairs needed, highlight maintenance considerations during occupation and what obligations both the landlord or the tenant have.",
    },
    {
      title: "Acoustics",
      description:
        "An acoustic survey will utilise the latest testing tools to assess acoustic performance and recommend improvements.",
    },
    {
      title: "Measured Surveys",
      description: {
        bulletType:'+',
        items1: [
          "RICS Property Measurement",
          "IPMS (International Property Measurement Standard)",
          "Create a DWG file for AutoCAD",
          "Lease Plans",
          "Boundary Disputes",
        ],
      },
    },
  ],
  heading: "Building Surveys",
};

export const projectManagementData3 = {
  content: [
    {
      title: "End of Lease Compliance",
      description:
        "REESA have a wealth of experience providing advice on Lease reinstatement requirements and Dilapidations at the expiry of a Lease term.",
    },
    {
      title: "Break Notice Obligations",
      description:
        "Professional Dilapidations expertise is particularly important when Lease break notices are being served.",
    },
    {
      title: "Repairs",
      description:
        "Dilapidations are not exclusively a Lease exit topic. There is important work to be done during occupation to mitigate future risk and to maintain building performance.",
    },
    {
      title: "Staying up to Date",
      description:
      "We firmly believe that the products we recommend and sell are pivotal to the successful running of our client's activities and experience. We keep you regularly updated on the latest opportunities and trends."
    },
  ],
  heading: "Dilapidations",
};
