import React, { useEffect } from "react";
import {
  HomeNavbar,
  Benfit,
  PropertyType,
  BusinessSpectrum,
  Help,
  AdvisorsForm,
  HomeCarousel,
  Footer,
} from "../Component/HomePage";
import MapSection from "../Component/HomePage/MapSection";
import advisorImg from "../assets/advisor.jpg";
import $ from "jquery";
import "../jquery.pagepiling.css";
import "../jquery.pagepiling";

function Home(props) {

  return (
    <div>
      <HomeNavbar history={props.history} />
      <HomeCarousel />
      <Benfit />
      <PropertyType />
      <BusinessSpectrum />
      <MapSection />
      <Help />
      <AdvisorsForm bgImage={advisorImg}/>
      <Footer/>
    </div>
  );
}

export default Home;
