export const teamsData1 = {
  content: [
    {
      description: `It's an area that can often be overlooked and undervalued. For the companies that invest the time, effort and research into their people management, talent attraction strategy and company culture; it can be far and away the biggest competitive advantage.`
    },
  ],
  heading: "Creating a competitive advantage",
};

export const teamsData2 = {
  content: [
    {
      description: "Today, tomorrow and future milestones.",
    },
  ],
  heading: "Planning headcount",
};

export const teamsData3 = {
  content: [
    {
      description: {
        items1: [
          "What lessons have you learned from the past?",
          "What does success look like 12 months from now?",
          "Why is this role integral to your business?",
          "What does a contingency plan look like?",
        ],
        bulletType: "+",
      },
    },
  ],
  heading: "Asking the right questions",
};
export const teamsData4 = {
  content: [
    {
      description:
        "We provide up to date market intelligence and salary guides to help you position roles to secure talent.",
    },
  ],
  heading: "Understanding your market",
};
export const teamsData5 = {
  content: [
    {
      description:
        "There are a huge range of factors that impact on a company's ability to assemble and bring through talent. With our roots in workplace design, experience and facilities, we are uniquely placed to help.",
    },
  ],
  heading: "Building a world class team ",
};
export const teamsData6 = {
  content: [
    {
      description: {
        items1: [
          "Job specifications & advertising",
          "Social media & online oversight",
          "White label options & managed services (MSP)",
          "Events",
        ],
        bulletType: "+",
      },
    },
  ],
  heading: "Campaign building",
};
export const teamsData7 = {
  content: [
    {
      description: {
        items1: [
          "Unique to your business",
          "Executive search",
          "Non-executives & board members",
          "Temps",
          "Graduates & internships",
          "Consultancy services",
        ],
        bulletType: "+",
      },
    },
  ],
  heading: "Bespoke solutions",
};
export const teamsData8 = {
  content: [
    {
      description: {
        items1: [
          "Training",
          "HR support",
          "Payroll services",
          "Employment contracts & handbooks",
          "Screening, onboarding, reference checks",
        ],
        bulletType: "+",
      },
    },
  ],
  heading: "People management ",
};
