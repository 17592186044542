import styled from "styled-components";
import * as variables from "../../Style/__variables";
export const ContentWrapper = styled.div`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "782px")};
  padding: 0 20px;
  margin: ${(props) => (props.margin ? props.margin : "60px auto 0 auto")};
  .heading-title {
    font-weight: normal;
    margin-bottom: 35px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 3px;
  }

  .heading-content {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 3px;
    text-align: center;
    &.bar {
      position: relative;
      &::after {
        content: "";
        width: 18vw;
        height: 3px;
        background-color: ${(props) =>
          props.themeColor
            ? variables[props.themeColor]
            : variables.greeny_blue};
        position: absolute;
        bottom: -33px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .darkPink {
      color: ${variables.darkPink};
      font-weight: bold;
    }
  }
`;
