//colour
export const team_list_name = "#242b30";
export const greeny_blue = "#44b7b4";
export const white = "#fff";
export const darkPink='#a42e5d'
export const darkpurple='#8d62a0'
export const waterBlue='#1292c4';
export const darkYellow='#e2b500';
export const lightGrey='#f8f8f8'
export const purple='#5b00a2';
export const darkGrey='#979797';
export const grey='#c8c8c8';
export const brown_grey='#afafaf';
export const Shark='#242B30';

//font-size
export const xxxxxSmall = 9;
export const xxxxxxSmall = 10;
export const xxxxSmall = 12;
export const xxxSmall = 14;
export const xxSmall = 16;
export const xSmall = 18;
export const small = 20;
export const xxxxMedium = 21;
export const xxxMedium = 22;
export const xxMedium = 24;
export const xMedium = 25;
export const medium = 26;
export const xsLarge = 28;
export const smLarge = 30;
export const mdLarge = 32;
export const large = 36;
export const xLarge = 38;
export const xxLarge = 40;
export const xxxLarge = 45;
export const xxxxLarge = 50;
export const ExtraLarge=88
export const xxxxxLarge = 60;
export const xxxxxxLarge = 80;
export const xxxxxxxLarge=90;

//font-family
export const extraBold = "Proxima Nova W01 Extrabold";
export const bold = "Proxima Nova W01 Bold";
export const regular = "Proxima Nova W01 Regular";
export const semibold = "Proxima Nova S W05 Semibold";
