import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const CustomerReviewComponent = styled.div`
  .custome_review_heading {
    color: ${variables.white};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 3px;
    font-size: 50px;
    padding: 80px 0px 0px 73px;
    font-family: 'Proxima Nova W01 Extrabold';
  }
  .review_section {
    padding: 20px 73px;
    .customer_review_para {
      text-align: center;
      color:  ${variables.white};
      opacity: 0.81;
      line-height: 1.36;
      letter-spacing: 1.57px;
      padding: 44px 60px;
      min-height: 250px;
      font-family: 'Proxima Nova W01 Regular';
      font-size: 22px;
      line-height: 1.36;
      letter-spacing: 1.57px;
    }
    .customer_reviewer{
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      padding-right: 65px;
      ${'' /* text-align: center!important; */}
      color:  ${variables.white};
      opacity: 0.81;
      line-height: 1.89;
      letter-spacing: 1.29px;
      padding-bottom:70px;
      ${'' /* padding: 30px 0px; */}
  
      font-family: 'Proxima Nova W01 Regular';

    }
  
}

@media screen and (max-width: 576px) {
  .custome_review {
    background-color: ${variables.purple};
    .custome_review_heading {
      padding: 20px 0px 0px 30px;
    }
    .review_section {
      padding: 0px 0px;
      .customer_review_para {
        
        padding: 30px 35px;
        min-height: 200px;
      }
      .customer_reviewer{
         
        padding: 30px 30px;
      }
    }
  }
}
`;
