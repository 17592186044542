import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const PropertyFooter = styled.div`
.footer_position{
    position : fixed;
    bottom : 0;
}
.propert_list_footer_parent{
    display : flex;
}
.propert_list_footer{
    background-color: ${variables.white};
    min-height: 70px;
    padding: 20px;
    box-shadow: -4px 7px 7px 4px rgba(0, 0, 0, 0.21);
    .book_tour{
        float : center;
        .book_tour_bottom{
            border : none ; 
            background-color: ${variables.darkPink} ;
            color : ${variables.white};
            padding : 10px 25px;
            font-size : 16px;
            font-weight : bold ;
        }
    }
  .paggination_border{
    border-left: solid 3px #c8c8c8;
    width: -7px;
    height: 95px;
    bottom: -20px;
    position: absolute;
    right: 0;
  }
  .property_shortlist{             
      color : ${variables.team_list_name};
      line-height: 1.5;
      letter-spacing: 1px;
  }
  .property_pagination_text{   
      font-size: 14px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 1px;
     span{
         color : ${variables.darkPink};
         font-weight : bold;
     }
  }
}

@media screen and (max-width: 576px){
    .propert_list_footer_parent{
        flex-wrap : wrap;
    }
    .propert_list_footer{
        height: auto;
        .book_tour{
            float : right;
            padding-left : 20px;
            padding-bottom : 10px;
        } 
    } 
}
`;
