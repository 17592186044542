import React from 'react';
import { SearchEmptyStateComponent } from './styledComponent';
import Typography from "../../Typography";
import { textList } from '../../../constants/SearchEmptyPage';

function SearchEmptyPage({mapHeight,isLoading,handleCheckoutFormSubmit,handleCheckoutFormInput,handleCheckoutFormcheckbox}) {
    return (
        <div >
            <SearchEmptyStateComponent mapHeight={mapHeight}>
                <Typography
                    className=" heading-title "
                    variant="regular"
                    size="xxLarge"
                    mobileSize='xxLarge'
                >Finding Your New Office Now...
                </Typography>
                {textList.map(val => (
                    <Typography
                        className="no_result_text mt-3 heading-title "
                        variant="regular"
                        size="small"
                        mobileSize='small'
                    >{val.text}
                    </Typography>
                ))}
            </SearchEmptyStateComponent>
        </div>
    )
}
export default SearchEmptyPage;