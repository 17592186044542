import React, { isValidElement } from "react";
import "./industries.scss";
import embassy from "../../../assets/icons/strategy/embassy.svg";
import paint_palette from "../../../assets/icons/strategy/paint-palette.svg";
import cpu from "../../../assets/icons/strategy/cpu.svg";
import heart1 from "../../../assets/icons/strategy/heart1.svg";
import truck from "../../../assets/icons/strategy/truck.svg";
import fast_food from "../../../assets/icons/strategy/fast-food.svg";
import manufacture from "../../../assets/icons/strategy/manufacture.svg";
import shopping_cart from "../../../assets/icons/strategy/shopping-cart.svg";
import money from "../../../assets/icons/strategy/money.svg";
import plane from "../../../assets/icons/strategy/plane.svg";
import tower from "../../../assets/icons/strategy/tower.svg";
import medicine from "../../../assets/icons/strategy/medicine.svg";
import mortarboard from "../../../assets/icons/strategy/mortarboard.svg";
import heartbeat from "../../../assets/icons/strategy/heartbeat.svg";
import cocktail from "../../../assets/icons/strategy/cocktail.svg";
import football from "../../../assets/icons/strategy/football.svg";
import brickwall from "../../../assets/icons/strategy/brickwall.svg";
import officeChair from "../../../assets/icons/strategy/office-chair.svg";

import Typography from "../../Typography";


function Industries() {
  let firstIndustries = [
    { img: embassy, text: "Central Government & local authorities" },
    { img: money, text: "Financial services, banking & insurance" },
    { img: paint_palette, text: "Arts, design & creative industries" },
    { img: cpu, text: "Technology & telecommunications" },
    { img: heart1, text: "Charities, not-for profit & religious groups" },
    { img: truck, text: "Transport, logistics & shipping" },
    { img: fast_food, text: "Food & beverages" },
    { img: manufacture, text: "Manufacturing & industrial " },
    { img: shopping_cart, text: "Retail, e-commerce & data centres" },
  ];
  let secondIndustries = [
    { img: plane, text: "Central Government & local authorities" },
    { img: tower, text: "Financial services, banking & insurance" },
    { img: medicine, text: "Arts, design & creative industries" },
    { img: mortarboard, text: "Technology & telecommunications" },
    { img: heartbeat, text: "Charities, not-for profit & religious groups" },
    { img: cocktail, text: "Transport, logistics & shipping" },
    { img: football, text: "Food & beverages" },
    { img: brickwall, text: "Manufacturing & industrial " },
    { img: officeChair, text: "Retail, e-commerce & data centres" },
  ];

  return (
    <div className="industries">
      <Typography
        className="industries_heading"
        variant="extraBold"
        size="xxxxLarge"
        mobileSize='mdLarge'
      >
        Industries
      </Typography>
      <div className="industries_list">
        <div className="industries_container">
          <Typography
            className="text-white industries_subheading"
            variant="regular"
            size="xxxxMedium"
          >
            With REESA you can create an industry leading workplace strategy 
          </Typography>
          <div className="row">
            <div className="col-lg-6">
              <div>
                {firstIndustries.map((val) => (
                  <div className="d-flex test-list-wrapper">
                    <img src={val.img} />
                    <Typography
                      className="text-white text-list  pl-4 heading-title"
                      variant="regular"
                      size="xxSmall"
                      mobileSize="xxSmall"
                    >
                      {val.text}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 d-sm-flex justify-content-end">
              <div>
                {secondIndustries.map((val) => (
                  <div className="d-flex test-list-wrapper">
                    <img src={val.img} />
                    <Typography
                      className="text-white text-list  pl-4 heading-title"
                      variant="regular"
                      size="xxSmall"
                      mobileSize="xxSmall"
                    >
                      {val.text}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Industries;
