/* eslint-disable jsx-a11y/alt-text */
import AdvisorsForm from "src/Component/AdvisorsForm/advisorsForm";
import { ReactComponent as PhoneIcon } from "../../assets/leasehold/phone.svg";
import { useSelector, useDispatch } from "react-redux";
import Footer from "src/Component/Footer/Footer";
import Navbar from "../../Component/Navbar/Navbar";
import BgImage from "../../assets/leasehold/pr-s-footer.jpg";
import Georgie from "../../assets/leasehold/georgie.png";
import HeroImage from "../../assets/leasehold/heroImage.jpg";
import { ReactComponent as Train } from "../../assets/leasehold/train.svg";
import { ReactComponent as Bus } from "../../assets/leasehold/bus.svg";
import { ReactComponent as Tube } from "../../assets/leasehold/tube.svg";
import { ReactComponent as Cart } from "../../assets/leasehold/cart.svg";
import { ReactComponent as Body } from "../../assets/leasehold/body.svg";
import PropertiesSearchSubHeading from "src/Component/PropertySubHeading";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg";
import { getDimensions, PlaceholderImage } from "src/Component/ImageDimensions";
import {getContainerSize} from '../../constants/getDimension';
import Slider from "../../Component/common/Slider";
import Typography from "src/Component/Typography";
import * as _ from "lodash";
import {
  Badge,
  LeaseholdMainSection,
  RentSection,
  DescriptionSection,
  ShortListSection,
  CallSection,
  LocationSection,
  PriceListSection,
  AmenitiesSection,
  SlideerSection
} from "./styledComponent";
import { getSpaceWalkingTime } from "src/Utils/display";
import StaticMap from "src/Component/LeaseholdMap";
import { ReactComponent as Cancel } from "../../assets/PropertySearch/cancel1.svg";
import { ReactComponent as Heart } from "../../assets/PropertySearch/heart.svg";
import {
  getPropertyDetailRequest,
  shortlistAdd,
  shortlistedRemove
} from "src/redux/action";
import { useEffect, useState } from "react";
import { shorlisted } from "src/Utils/localStorageUtil";
import { useHistory } from "react-router-dom";

const Leasehold = props => {
  const [propertyDetails, setPropertyDetails] = useState({});
  const [shortListProperty, setshortListProperty] = useState(false);
  const [serviceTypeValue, setServiceTypeValue] = useState([]);
  const [leaseTypeValue, setLeaseTypeValue] = useState([]);
  const [imageDimension, setImageDimesion] = useState([]);
  const [discriptionList, setDiscriptionList] = useState([]);
  const [mapCoordinate, setMapCoordinate] = useState({
    northeastlat: 0,
    northeastlng: 0,
    southwestlat: 0,
    southwestlng: 0
  });
  const [amenities, setAmenities] = useState({
    services: [],
    facilities: [],
    community: []
  });
  const [next, setNext] = useState(1);
  const [prev, setPrev] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const getPropertyDetailStatus = useSelector(state => state.getPropertyDetail);
  const ShortListReducer = useSelector(state => state.ShortListReducer);

  var forArray = getPropertyDetailStatus?.data?.images;
  var finalArry = forArray?.length;
  let leaseholdTypeValue = ["office", "retail", "industrial", "healthcare"];
  let servicedTypeValue = ["hotDesk", "managed", "privateOffice"];

  useEffect(() => {
    let serviceTypeList = [];
    let leaseholdTypeList = [];
    if (propertyDetails?.id) {
      let amenitieservices = propertyDetails?.amenities?.filter(
        val => val.category === "services"
      );
      let amenitiefacilities = propertyDetails?.amenities?.filter(
        val => val.category === "facilities"
      );
      let amenitiecommunity = propertyDetails?.amenities?.filter(
        val => val.category === "community"
      );
      setAmenities({
        ...amenities,
        services: amenitieservices,
        facilities: amenitiefacilities,
        community: amenitiecommunity
      });
      Object.keys(propertyDetails).map(val =>
        servicedTypeValue.filter(el => {
          if (val == el && propertyDetails[val]) {
            serviceTypeList.push(el);
          }
        })
      );

      setServiceTypeValue(serviceTypeList);

      Object.keys(propertyDetails).map(val =>
        leaseholdTypeValue.filter(el => {
          if (val === el && propertyDetails[val]) {
            leaseholdTypeList.push(el);
          }
        })
      );
      setLeaseTypeValue(leaseholdTypeList);
    }

    if (propertyDetails?.places?.length > 0) {
      setMapCoordinate({
        ...mapCoordinate,
        northeastlat: propertyDetails?.places[0].location.coordinates[1],
        northeastlng: propertyDetails?.places[0].location.coordinates[0],
        southwestlat: propertyDetails?.places[1].location.coordinates[1],
        southwestlng: propertyDetails?.places[1].location.coordinates[0]
      });
    }
    if (propertyDetails?.description) {
      var formattedString = propertyDetails?.description.split(".");
      setDiscriptionList(formattedString);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (ShortListReducer?.items?.length > 0) {
      shorlisted(ShortListReducer);

      if (ShortListReducer?.items.length > 0) {
        ShortListReducer?.items.map(val => {
          if (val.id == props.match.params.id) {
            setshortListProperty(true);
          } else {
            setshortListProperty(false);
          }
        });
      }
    }
  }, [ShortListReducer]);

  const TRANSPORT_ICONS = {
    bus_station: <Bus width="20" height="20" />,
    train_station: <Train width="20" height="20" />,
    subway_station: <Tube width="20" height="20" />
  };
  useEffect(() => {
    dispatch(
      getPropertyDetailRequest({
        id: props.match.params.id,
        images: true,
        places: true
      })
    );
    const dims = getDimensions("listing-xl", "space");
    if (dims?.length > 0) {
      setImageDimesion(dims);
    }
  }, []);

  useEffect(() => {
    if (getPropertyDetailStatus?.data?.id) {
      setPropertyDetails(getPropertyDetailStatus?.data);
    }
  }, [getPropertyDetailStatus?.data]);

  //shortlist property
  const handleAddShortlistProperty = propertyDetails => {
    setshortListProperty(true);
    dispatch(shortlistAdd(propertyDetails));
  };

  const handleRemoveShortlistProperty = propertyDetails => {
    dispatch(shortlistedRemove(propertyDetails));
    setshortListProperty(false);
  };
  const handleCheckout = () => {
    const {
      type,
      propertyType,
      disk_min,
      disk_max,
      location,
      northeastlat,
      northeastlng,
      southwestlat,
      southwestlng
    } = props.location.state;
    history.push({
      pathname: `/property-checkout/${type}/${propertyType}/${disk_min}/${disk_max}/${location}`,
      state: {
        northeastlat: northeastlat,
        northeastlng: northeastlng,
        southwestlat: southwestlat,
        southwestlng: southwestlng
      }
    });
  };

  const handleBackToSearch = () => {
    // history.push('/property-search')
    history.goBack();
  };
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const getSliderContent = images => {
    const result = getContainerSize()
    if(result.screenWidth){

    }
    const slidesToShow = result.screenWidth > 1200 ? 3 : result.screenWidth < 1199 && result.screenWidth > 721 ? 2 : 1 ;

    const sliderSettings = {
      slidesToShow,
      slidesToScroll: 1
    };
    let sliderImages = images;
    if (!images.length) {
      sliderImages = _.times(slidesToShow, i => ({
        id: `${i}`,
        placeholder: true
      }));
    }
    const sliderContent = sliderImages.map(image => (
      <div key={image.id} styleName="listing__slider-image" className="pl-0 px-0">
        <img
          src={
            propertyDetails?.images?.length > 0 ? (
              `${process.env.REACT_APP_IMG_BASE_URL}/images/${image.spaceId}/${image.id}_${imageDimension[0]}_${imageDimension[1]}`
            ) : (
              <PlaceholderImage />
            )
          }
          className="image_Slider"
          // height="356px"
          // width="100%"
        />{" "}
      </div>
    ));
    return (
      <Slider key={`slider__${images.length}`} settings={sliderSettings}>
        {sliderContent}
      </Slider>
    );
  };
  return (
    <div>
      <Navbar history={props.history} />
      <div >
      <SlideerSection>
      <div styleName="listing__slider">
        {getSliderContent(propertyDetails?.images || [])}
      </div>
      </SlideerSection>

      <PropertiesSearchSubHeading
        backButtom={true}
        nextButton={true}
        Cart={Cart}
        handleCheckout={handleCheckout}
        handleBackToSearch={handleBackToSearch}
      />
      <LeaseholdMainSection>
        <div className="row m-0 mb-5">
          <div className="col-sm-12 d-flex align-items-center flex-wrap">
            <Typography variant="regular" size="xxxSmall" className="tabText">
              Home
            </Typography>
            <RightArrow className="rightArrow" />
            <Typography variant="regular" size="xxxSmall" className="tabText">
              {propertyDetails?.address4}
            </Typography>
            <RightArrow className="rightArrow" />
            {propertyDetails.address3 ? (
              <>
                <Typography
                  variant="regular"
                  size="xxxSmall"
                  className="tabText"
                >
                  {propertyDetails.address3}
                </Typography>
                <RightArrow className="rightArrow" />
              </>
            ) : null}
            <Typography variant="bold" size="xxxSmall">
              {" "}
              {propertyDetails.address2
                ? propertyDetails.address2
                : propertyDetails.address1}
            </Typography>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-sm-12 col-lg-9">
            <RentSection className="row m-0">
              <div className="col-sm-12 col-md-7 pl-0">
                <Typography variant="bold" size="large" className="heading">
                  {propertyDetails.address2
                    ? propertyDetails.address2
                    : propertyDetails.address1}
                </Typography>
                <Typography
                  variant="regular"
                  size="xxxxMedium"
                  className="mb-4 sub-heading"
                >
                  {propertyDetails.address4}{" "}
                  {propertyDetails?.contactPostcode?.split(" ")[0]}
                </Typography>
                <div className="d-flex mb-4 flex-wrap">
                  <Badge className="mr-2 mb-2">
                    <Typography variant="regular" size="xSmall">
                      {propertyDetails.leasehold == false
                        ? `${propertyDetails.deskMin} - ${propertyDetails.deskMax} people`
                        : !propertyDetails.squareFootage == null
                        ? propertyDetails.squareFootage
                        : `${propertyDetails.squareFootageMin} - ${propertyDetails.squareFootageMax} sqft`}
                    </Typography>
                  </Badge>
                  {propertyDetails.leasehold ? (
                    <>
                      {props.match.params.type == "admin" ? (
                        <>
                          {leaseTypeValue.map(val => (
                            <Badge className="mr-2 mb-2">
                              <Typography variant="regular" size="xSmall">
                                {val}
                              </Typography>
                            </Badge>
                          ))}
                        </>
                      ) : (
                        <Badge className="mr-2 mb-2">
                          <Typography variant="regular" size="xSmall">
                            {capitalize(props.match.params.type)}
                          </Typography>
                        </Badge>
                      )}
                    </>
                  ) : (
                    <>
                      {serviceTypeValue.map(val => (
                        <Badge className="mr-2 mb-2">
                          <Typography variant="regular" size="xSmall">
                            {val == "hotDesk"
                              ? "Hot Desk"
                              : val == "privateOffice"
                              ? "Private Office"
                              : val === "managed"
                              ? "Managed"
                              : ""}
                          </Typography>
                        </Badge>
                      ))}
                    </>
                  )}

                  <Badge className="mb-2">
                    <Typography variant="regular" size="xSmall">
                      {propertyDetails.leasehold ? "Leasehold" : "Serviced"}
                    </Typography>
                  </Badge>
                </div>
              </div>
              <div className="col-sm-12 col-md-5">
                <ShortListSection>
                  <div
                    className={
                      shortListProperty
                        ? "active_shortList_section  d-flex justify-content-between"
                        : "shortlist_section"
                    }
                    onClick={() => {
                      shortListProperty
                        ? handleRemoveShortlistProperty(propertyDetails)
                        : handleAddShortlistProperty(propertyDetails);
                    }}
                  >
                    <Typography
                      className="text-uppercase text-center"
                      variant="bold"
                      size="xxxSmall"
                    >
                      {shortListProperty ? (
                        <>
                          <Heart fill="#fff" height="20px" width="20px" />
                          <span>shortlisted</span>
                        </>
                      ) : (
                        "add to shortlist"
                      )}
                    </Typography>
                    <Cancel />
                  </div>
                </ShortListSection>
              </div>
            </RentSection>
            <PriceListSection>
              <div className="price_badge mr-5">
                <Typography
                  variant="regualr"
                  size="xxxxMedium"
                  className="text-uppercase title"
                >
                  {propertyDetails.leasehold
                    ? "Rent Per Square Foot per annum"
                    : "Price per person per month"}
                </Typography>
                {propertyDetails.leasehold ? (
                  <div className="row">
                    <div className="col-lg-6 price">
                      <div className="row no-gutters">
                        <div className="col-lg-6">
                          <Typography
                            variant="bold"
                            size="xxSmall"
                            className="text-uppercase   mt-2 mr-2  rent"
                          >
                            {" "}
                            GUIDE PRICE
                          </Typography>
                        </div>
                        <div className="col-lg-6">
                          <Typography
                            variant="regular"
                            size="xxxLarge"
                            className="text-right mr-3"
                          >
                            {" "}
                            {propertyDetails?.guidePrice
                              ? parseInt(propertyDetails?.guidePrice)
                              : "£POA"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row no-gutters">
                    <div className="col-lg-4 price  ">
                      <div className="row no-gutters">
                        <div className="col-lg-6">
                          <Typography
                            variant="bold"
                            size="xxSmall"
                            className="text-uppercase ml-2 mt-2 mr-2  rent"
                          >
                            {!propertyDetails.leasehold ? "Hot Desk" : "OFFICE"}
                          </Typography>
                        </div>
                        <div className="col-lg-6">
                          <Typography
                            variant="regular"
                            size="xxxLarge"
                            className="text-right mr-3"
                          >
                            {" "}
                            {!propertyDetails.leasehold ? (
                              propertyDetails.priceHotDesk ? (
                                `£${parseInt(propertyDetails.priceHotDesk)}`
                              ) : (
                                <span>-</span>
                              )
                            ) : propertyDetails.priceOffice ? (
                              `£${parseInt(propertyDetails.priceOffice)}`
                            ) : (
                              <span>-</span>
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 price ">
                      <div className="row no-gutters">
                        <div className="col-lg-6">
                          <Typography
                            variant="bold"
                            size="xxSmall"
                            className="text-uppercase ml-2  mt-2 rent"
                          >
                            {!propertyDetails.leasehold
                              ? "MANAGED"
                              : "Industrial"}
                          </Typography>
                        </div>
                        <div className="col-lg-6">
                          <Typography
                            variant="regular"
                            size="xxxLarge"
                            className="mr-3 text-right"
                          >
                            {" "}
                            {!propertyDetails.leasehold ? (
                              propertyDetails.priceLeasedManaged ? (
                                `£${parseInt(
                                  propertyDetails.priceLeasedManaged
                                )}`
                              ) : (
                                <span>-</span>
                              )
                            ) : propertyDetails.priceIndustrial ? (
                              `£${parseInt(propertyDetails.priceIndustrial)}`
                            ) : (
                              <span>-</span>
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 price ">
                      <div className="row no-gutters">
                        <div className="col-lg-6">
                          <Typography
                            variant="bold"
                            size="xxSmall"
                            className="text-uppercase mt-2 ml-2 rent"
                          >
                            {!propertyDetails.leasehold
                              ? "Private Office"
                              : "Retail"}
                          </Typography>
                        </div>
                        <div className="col-lg-6">
                          <Typography
                            variant="regular"
                            size="xxxLarge"
                            className="mr-3 text-right"
                          >
                            {" "}
                            {!propertyDetails.leasehold ? (
                              propertyDetails.pricePrivateOffice ? (
                                `£${parseInt(
                                  propertyDetails.pricePrivateOffice
                                )}`
                              ) : (
                                <span>-</span>
                              )
                            ) : propertyDetails.priceRetail ? (
                              `£${parseInt(propertyDetails.priceRetail)}`
                            ) : (
                              <span>-</span>
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {propertyDetails.leasehold ? (
                      <div className="col-lg-4 mt-3 price ">
                        <div className="row no gutters">
                          <div col-lg-6>
                            <Typography
                              variant="bold"
                              size="xxSmall"
                              className="text-uppercase ml-4 mt-3  rent"
                            >
                              Healthcare
                            </Typography>
                          </div>
                          <div col-lg-6>
                            <Typography
                              variant="regular"
                              size="xxxLarge"
                              className="text-right ml-5 mt-2 "
                            >
                              {" "}
                              {propertyDetails.priceHealthCare ? (
                                `£${parseInt(propertyDetails.priceHealthCare)}`
                              ) : (
                                <span>-</span>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </PriceListSection>
            <DescriptionSection>
              <Typography
                variant="regular"
                size="xxxxMedium"
                className="text-uppercase title"
              >
                Description
              </Typography>
              <Typography variant="regular" size="xxxSmall" className="desc">
                {propertyDetails?.description?.split('\n')?.map(val =>(
                  <>
                  <div className="py-1">{val}</div>
                  </>
                ))}              
              </Typography>
            </DescriptionSection>
            <LocationSection>
              <Typography
                variant="regular"
                className="text-uppercase title"
                size="xxxMedium"
              >
                Location
              </Typography>
              <div className="row">
                <div className="col-sm-7 mb-sm-0 mb-4">
                  {propertyDetails?.location?.coordinates.length ? (
                    <StaticMap
                      lng={
                        propertyDetails?.location?.coordinates &&
                        propertyDetails?.location?.coordinates[0]
                      }
                      lat={
                        propertyDetails?.location?.coordinates &&
                        propertyDetails?.location?.coordinates[1]
                      }
                      zoom={14}
                    />
                  ) : null}
                </div>
                <div className="col-sm-5">
                  <Typography
                    variant="regular"
                    size="xxxSmall"
                    className="text-uppercase transport-links"
                  >
                    Close transport links
                  </Typography>
                  {propertyDetails &&
                    propertyDetails?.places?.map(val => (
                      <div className="d-flex justify-content-between">
                        <Typography
                          variant="regular"
                          size="xxxSmall"
                          className="distanceSection"
                        >
                          {TRANSPORT_ICONS[val.type]}
                          <span className="ml-3">{val.name} </span>
                        </Typography>
                        <Typography
                          variant="regular"
                          size="xxxSmall"
                          className="distanceSection"
                        >
                          <Body className="mr-3" />{" "}
                          {getSpaceWalkingTime(val.distance)}{" "}
                        </Typography>
                      </div>
                    ))}
                </div>
              </div>
            </LocationSection>
            <AmenitiesSection>
              <Typography
                size="xxxMedium"
                variant="regular"
                className="text-uppercase title"
              >
                Amenities
              </Typography>
              {!propertyDetails.leasehold ? (
                <div>
                  <div className="">
                    <Typography
                      variant="regular"
                      size="xxxSmall"
                      className="listItem"
                    >
                      Services
                    </Typography>
                    <div className="row">
                      {amenities &&
                        amenities?.services?.map(val => (
                          <div className="col-sm-4">
                            <div className="row no-gutters">
                              <div className="col-1">
                                <CheckedIcon className="checkedIcon" />
                              </div>
                              <div className="col-11">
                                <Typography
                                  variant="regular"
                                  size="xxxSmall"
                                  className="listItem pl-2 text-wrap"
                                >
                                  {val.name}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mt-3">
                    <Typography
                      variant="regular"
                      size="xxxSmall"
                      className="listItem"
                    >
                      Facilities
                    </Typography>
                    <div className="row">
                      {amenities &&
                        amenities?.facilities?.map(val => (
                          <div className="col-sm-4">
                            <div className="row no-gutters">
                              <div className="col-1">
                                <CheckedIcon className="checkedIcon" />
                              </div>
                              <div className="col-11">
                                <Typography
                                  variant="regular"
                                  size="xxxSmall"
                                  className="listItem pl-2 text-wrap"
                                >
                                  {val.name}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="">
                    <Typography
                      variant="regular"
                      size="xxxSmall"
                      className="listItem"
                    >
                      Community
                    </Typography>
                    <div className="row">
                      {amenities &&
                        amenities?.community?.map(val => (
                          <div className="col-sm-4">
                            <div className="row no-gutters">
                              <div className="col-1">
                                <CheckedIcon className="checkedIcon" />
                              </div>
                              <div className="col-11">
                                <Typography
                                  variant="regular"
                                  size="xxxSmall"
                                  className="listItem pl-2 text-wrap"
                                >
                                  {val.name}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {propertyDetails.leasehold ? (
                <div className="">
                  <div className="row">
                    {propertyDetails &&
                      propertyDetails.amenities?.map(val => (
                        <div className="col-sm-4">
                          <div className="row no-gutters">
                            <div className="col-1">
                              <CheckedIcon className="checkedIcon" />
                            </div>
                            <div className="col-11">
                              <Typography
                                variant="regular"
                                size="xxxSmall"
                                className="listItem pl-2 text-wrap"
                              >
                                {val.name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
            </AmenitiesSection>
          </div>
          <CallSection className="col-sm-12 col-lg-3 text-center">
            <img src={Georgie} />
            <Typography variant="bold" size="xxMedium" className="mb-2">
              Call us now to discuss this property
            </Typography>
            <Typography variant="bold" size="xxMedium" className="phoneNum">
              <PhoneIcon /> 0330 004 0404{" "}
            </Typography>{" "}
          </CallSection>
        </div>
      </LeaseholdMainSection>
      <AdvisorsForm bgImage={BgImage} />
      <Footer />
      </div>
    </div>
  );
};

export default Leasehold;
