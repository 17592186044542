export const CovidResponseData1 = {
  content: [
    {
      title: "Signage",
      description:
        "Our wide range can help you implement your hygiene, social distancing, and flow management procedures:",
    },
    {
      // title: "Signage",
      description : "Our wide range can help you implement your hygiene, social distancing, and flow management procedures:",
      description: {
        items1: [
          " Wall stickers",
          ` Desk graphics`,
          ` Free standing signs`,
          ` Machine stickers `
        ], 
        items2: [
          `Rigid board signs`,
          `Floor graphics`,
          `Posters`,
          `Branding options`
        ],  
      },
    },
   
    {
      title: "Desk dividers",
      description:
        "We provide off the shelf desk dividers which can be shipped quickly and easily installed. With bespoke dimensions and custom finishes available to fit your existing desk system.",
    },
    {
      title: "Cleaning & disinfection",
      description:
        "For an enhanced daily clean, our fogging service uses an ultra-low volume machine to coat surfaces throughout your workplace with a protective antibacterial layer.",
    },
    {
      title: "Air quality",
      description:
        "Our selection of air purifiers and HVAC systems circulate cleaner and safer air. Supplemented by our biophilia led design and specialist plants which help to further reduce air pollutants.",
    },
  ],
  heading:'Keeping you safe'
};
  
  export const CovidResponseData2 = {
    content: [
      {
        title: "Save where you can",
        description:
          "Our team of Chartered Surveyors are leading efforts for cost reduction and operational flexibility across all areas of our client's Real Estate.",
      },
      {
        title: "Invest where you need to",
        description:
          "Continuity challenges and resiliency pressures have required our clients to support their employees who are working remotely with tailored furniture packages and access to local coworking hubs.",
      },
      {
        title: "Always be prepared",
        description:
          "We are working closely with our clients to recalibrate disaster recovery plans for their Real Estate, helping them to be better prepared to mitigate the impact of future risks.",
      },
      {
        title: "A new way of working",
        description:
          "Embrace an uncertain future by exploring new and more agile ways of working. Our strategy team will advise you on all future considerations for your organisation’s post-pandemic workplace needs.",
      },
    ],
    heading:'Contingency planning'
  };

  export const CovidResponseData3 = {
    content: [
      {
        title: "Space evaluation ",
        description:
          "Our designers will re-evaluate your layout and recommend changes to support distancing guidelines, flow management and your organisational needs.",
      },
      {
        title: "Workplace modifications",
        description:
          "Our Build  team can carry out all of the necessary works that you require to be ready for re-opening:",
      },
      {
      description: {
        items1: [
          "Contactless entry systems",
          `Partitioning & screen systems`,
          `Phone booths`,
          `Furniture re-positioning`
        ], 
        items2: [
          `Automatic doors`,
          `Audio visual systems`,
          `Booth seating & pods`,
          `Furniture storage`
        ],      
      }},
      {
        title: "Practical measures",
        description:
          "Minimise the anxiety and stress some employees will experience when transitioning back to the workplace:",
      },
      {
      description: {
        items1: [
          "Staggering occupancy",
          `Limiting access to communal areas`,
          `Reducing meeting room capacity`,
        ], 
        items2: [
          `Restricting visitor access`,
          `Clear desk policy`,
          `Converting small meeting rooms into private offices`,
        ],   
      }},
      {
        title: "Risk assessment",
        description:
          "Book a risk assessment and we can create a bespoke ‘Getting back to work’ plan for your organisation.",
      },
    ],
    heading:'Return to work'
  };