import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const TrustedComponent= styled.div`
    border-top : 2px solid ${variables.brown_grey};
    padding : 100px 88px ;
    .organise_text{
        color : ${variables.team_list_name}
    }
    @media screen and (max-width: 576px){
        padding : 50px 40px ;

    }
`;
