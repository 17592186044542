import React from "react";
import { CheckoutComponent } from "./styledComponent";
import Typography from "../../Typography";
import Inputbox from "../../Inputbox/input";
import Buttom from "../../Button";
import {useDispatch} from 'react-redux';
import { resetenqueryFormSuccess } from 'src/redux/action';

function CheckoutForm({
  backgroundColorNane,
  isLoading,
  handleCheckoutFormSubmit,
  buttonText,
  shortlistCopy,
  checkoutHeading,
  handleCheckoutFormInput,
  handleCheckoutFormcheckbox,
  enqueryFormReducer,
  email,
  
}) {
  const dispatch =useDispatch();
  
  const InboxList = [
    { name: "name", lable: "Name", type: "text", requiredField: true },
    { name: "email", lable: "Email", type: "email", requiredField: true },
    {
      name: "phoneNumber",
      lable: "Phone number",
      type: "tel",
      requiredField: true,
    },
  ];

  const handleSendAgain = () =>{
    dispatch(resetenqueryFormSuccess())
  }
  return (
    <CheckoutComponent>
    {enqueryFormReducer===true?(<div className="checkout_msg pt-4 h-100">
        <div className="row no-gutters pt-5">
        
          <div className="col-2"></div>
          <div className="col-8">
          <div className="">
           <div className="pt-5 pb-5 confirmation_screen">
           <span style={{fontFamily:"Proxima Nova W01 Bold"}} className='d-flex justify-content-center'>Thank you!</span>
              <div className="pt-5 text-center">Your shortlist has been emailed to <span className="" style={{color:"#44b7b4"}}>{email}</span></div>
             <div className="pt-3 text-center">A member our Workplace Strategy team will be contacting shortly to discuss your property search in more detail.</div>
              <div className="mt-5 pt-5 pb-5 text-center">Haven’t your received your shortlist yet? <sapn className="" style={{color:"#44b7b4"}} onClick={handleSendAgain}>Send again</sapn></div>
           </div>
  
          </div>
          <div className="col-2"></div>
          </div>
          </div>
      </div>):(
      <>
        {checkoutHeading ? (
          <Typography
            className="text-center mt-4  heading-title "
            variant="bold"
            size="xxxxLarge"
            mobileSize="xxxxLarge"
          >
            Check out now
          </Typography>
        ) : null}
        
        <div className="checkout_form">
          <div className="row no-gutters">
            <div className="col-3"></div>
            <div className="col-8">
              <form onSubmit={(e) => handleCheckoutFormSubmit(e)}>
                {InboxList.map((val) => (
                  <div className="mt-3 ml-2">
                    <Typography
                      className="checkout_inbox_lable  heading-title "
                      variant="regular"
                      size="xxxSmall"
                      mobileSize="xxxSmall"
                    >
                      {val.lable}
                    </Typography>
                    <Inputbox
                      type={val.type}
                      name={val.name}
                      inputbox="checkout_inbox"
                      handleChangeInputbox={(e) => handleCheckoutFormInput(e)}
                    />
                  </div>
                ))}
                <div className="ml-2 mb-3">
                  {shortlistCopy ? (
                    <>
                      <input
                        name="copyRequested"
                        onChange={handleCheckoutFormcheckbox}
                        type="checkbox"
                        className="checkout_checkbox mt-3"
                      />{" "}
                      <span>Send me a copy of my shortlist</span>
                    </>
                  ) : null}
                  <Buttom
                    buttonText={isLoading ? "Seanding..." : buttonText}
                    buttonClass="checkout_submit_button mt-4 py-3"
                  />
                </div>
              </form>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </>
      )}
    </CheckoutComponent>
  );
}
export default CheckoutForm;
