import React, { Component } from "react";
// import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate";

import { ReactComponent as ArrowRight } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/left-arrow.svg";
import { PaginationWrapper } from "./styledComponent";
const Pagination = (props) => {

  const {
    pageCount,
    currentPage,
    pageRange,
    marginPages,
    paginationText,
    paginationPosition,
    width,
  } = props;
  const selectedPage = currentPage - 1;

  const renderPaginate = ({
    pageCount,
    pageRange,
    marginPages,
    selectedPage,
  }) => {
    if (pageCount < 2) {
      return null;
    }
    return (
      <ReactPaginate
        pageCount={pageCount}
        pageNum={6}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        initialPage={0}
        previousLabel={getLabel("prev")}
        nextLabel={getLabel("next")}
        disableInitialCallback={true}
        onPageChange={(e) => props.handlePageChange(e)}
        containerClassName={"pagination__container"}
        previousClassName={"pagination__nav pagination__nav--prev"}
        previousLinkClassName={"pagination__nav-link"}
        nextClassName={"pagination__nav pagination__nav--next"}
        nextLinkClassName={"pagination__nav-link "}
        pageClassName={"pagination__item pagination__page"}
        pageLinkClassName={"pagination__page-link"}
        activeClassName={"pagination__page--active"}
        breakClassName={"pagination__item pagination__break"}
        disabledClassName={"pagination__nav--disabled"}
      />
    );
  };
 

  const getLabel = (direction) => {
    const { compact } = props;
    const className = `pagination__nav-text pagination__nav-text--${direction}`;
    const iconSize = compact ? "10" : "14";
    if (direction === "prev") {
      return (
        <>
          <ArrowLeft width={iconSize} height={iconSize} className="mr-2" />
          <span className={className}>Previous</span>
        </>
      );
    }
    return (
      <>
        <span className={className}>Next</span>
        <ArrowRight
          width={iconSize}
          height={iconSize}
          className="arrow-right ml-2"
        />
      </>
    );
  };
  return (
    <PaginationWrapper className='col-sm-8'>
      {renderPaginate({
        pageCount,
        pageRange,
        marginPages,
        selectedPage,
      })}
    </PaginationWrapper>
  );
};
export default Pagination;
