import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import pr_s_footer from "src/assets/PropertySearch/pr-s-footer.png";
import FilterResultSubHeading from "../../Component/PropertySearchResult/FilterResultSubHeading";
import ResultSection from "../../Component/PropertySearchResult/ResultSection";
import PropertyListFooter from "../../Component/PropertySearchResult/PropertyListFooter";
import PropertyResultSubHeading from "../../Component/PropertySubHeading";
import { ReactComponent as Heart } from "../../assets/PropertySearch/heartIcon.svg";
import * as Actions from '../../redux/action';
import { useHistory } from 'react-router-dom';
import { getBoundsForPoints} from '../../constants/geocoder'
import { getPropertyDataClear, getPropertyDataRequest,notifyFormRequest } from 'src/redux/action';
import {getContainerSize} from '../../constants/getDimension';
function PropertiesSearchResult(props) {
  const [propertyData, setPropertyData] = useState({
    "propertyDataLists" : [],
    "page_count" : '',
    "total_count" : '',
    "current_page" : ''
  })
  const [checkoutForm, setCheckoutForm] = useState({
    "name": "",
    "email": "",
    "phoneNumber": 0,
   })
   const [boundsPoint, setBoundsPoint] =useState({
     latittude : 0,
     longitude : 0,
     zoom : 0
   }) 
   const  [resultDimension , setResultDimension] = useState({
    mapHeight : 0,
    mapWidth : 0,
    resultsWidth : 0,
  })
  const [markerListItem ,SetMarkerListItem] = useState([])
  const [pageCount ,setPageCount] = useState(1)
  const dispatch = useDispatch();
  const history = useHistory();
  const getPropertyData = useSelector((state) => state.getPropertyData);
  const ShortListReducer = useSelector((state) => state.ShortListReducer)
  const NotifyFormReducer = useSelector((state) => state.notifyFormReducer)
  let urlLocation = props.match.params;
  let location = []
  useEffect(() => {
    // if(getPropertyData?.data?.propertyItemsData?.length > 0){
      setPropertyData({
        ...propertyData, 
        "propertyDataLists" : getPropertyData?.data?.propertyItemsData,
        "page_count" : getPropertyData?.data?.page_count,
        "current_page" : getPropertyData?.data?.current_page,
        "total_count" : getPropertyData?.data?.total_count,
      });
    // }
  }, [getPropertyData])

  useEffect(() =>{
    if(propertyData?.propertyDataLists?.length > 0){
      propertyData.propertyDataLists.map(val => (location.push(val?.location?.coordinates)))
      SetMarkerListItem(location)
    }
  },[propertyData])

  useEffect(() => {
    if(getPropertyData?.data?.length <=  0){
      dispatch(getPropertyDataRequest({
        page: 1,
        per_page: 100,
        location: urlLocation.location == "NoLocation" ? "" : urlLocation.location ,
        type: urlLocation.type,
        propertyType: urlLocation.propertyType,
        desk_min: urlLocation.desk_min,
        desk_max: urlLocation.desk_max,
      }))
    }
    var bounds = getBoundsForPoints(urlLocation.northeastlat, urlLocation.northeastlng, urlLocation.southwestlat, urlLocation.southwestlng)
    setBoundsPoint({...boundsPoint,latittude : bounds.latitude, latitude : bounds.longitude, zoom : bounds.zoom})
  
    const result = getContainerSize()
    if(result){
     const {mapHeight,mapWidth,resultsWidth} = result;
      setResultDimension({...resultDimension, 
        mapHeight : mapHeight,
        mapWidth : mapWidth,
        resultsWidth : resultsWidth
       })
    }
  
  }, [])


  useEffect(() =>{
   
    if(NotifyFormReducer.data === "Accepted"){
      setCheckoutForm({...checkoutForm,name : "",email : "",phoneNumber : 0 })
    }
    
  },[NotifyFormReducer])

  const handlePageChange = (data) => {
    setPageCount(data.selected + 1)
    dispatch(getPropertyDataRequest({
      page: data.selected + 1,
      per_page: 100,
      location: "",
      type: props.match.params.type,
      propertyType: props.match.params.propertyType,
      desk_min: props.match.params.desk_min,
      desk_max: props.match.params.desk_max,
    }))
  }

  const handleClick = () =>{
    props.history.push({pathname :`/property-checkout/${urlLocation.type}/${urlLocation.propertyType}/${urlLocation.desk_min}/${urlLocation.desk_max}/${urlLocation.location}`, state : {northeastlat : urlLocation.northeastlat, northeastlng : urlLocation.northeastlng, southwestlat : urlLocation.southwestlat, southwestlng : urlLocation.southwestlng }})
  }

  const handleShowCardDetails = (id) =>{
    props.history.push({pathname :`/listing/${urlLocation.type}/${id}`, state : {type : urlLocation.type, propertyType : urlLocation.propertyType,disk_min : urlLocation.desk_min, disk_max : urlLocation.desk_max, location : urlLocation.location, northeastlat : urlLocation.northeastlat, northeastlng : urlLocation.northeastlng, southwestlat : urlLocation.southwestlat, southwestlng : urlLocation.southwestlng }})
  }

  const handleBackToSearch = () =>{
    dispatch(getPropertyDataClear());
    history.push('/property-search')
  }

  const handleCheckoutFormSubmit =(e) =>{
    e.preventDefault();

    dispatch(notifyFormRequest({
      email : checkoutForm.email,
      name : checkoutForm.name,
      phone : checkoutForm.phone,
      mapUrl : `https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/${boundsPoint.longitude},${boundsPoint.latittude},${boundsPoint.zoom}/300x300@2x?access_token=pk.eyJ1Ijoib2Zwcm9tYXBzZXJ2aWNlIiwiYSI6ImNqYXV2ODMybzAzNDMzMmxibTliM3MzZmcifQ.qOZRczQ7XcFtXBE_gujSQg`
    }))
  }
  const handleCheckoutFormInput = (e) =>{
    setCheckoutForm({ ...checkoutForm, [e.target.name]: e.target.value })
  }



  return (
    <Fragment>
      <NavBar history={props.history} />
      <PropertyResultSubHeading
        pageHeading="Building selection"
        pageSubHeading="Browse what’s available, shortlist your favourites and proceed to checkout"
        backButtom={true}
        stepper={true}
        stepperIndex={1}
        stepperIcon={Heart}
        handleBackToSearch={handleBackToSearch}
      />
      <ResultSection 
        markerList={markerListItem}
        handleShowCardDetails={(propertyId) =>handleShowCardDetails(propertyId)}
        propertyData={propertyData.propertyDataLists}
        handleCheckoutFormSubmit={(e) =>handleCheckoutFormSubmit(e)}
        handleCheckoutFormInput={(e) =>handleCheckoutFormInput(e)}
        isLoading={NotifyFormReducer.isLoading}
        urlLocation={urlLocation}
        pageCount={pageCount}
        resultsWidth={resultDimension.resultsWidth}
        mapWidth={resultDimension.mapWidth}
        mapHeight={resultDimension.mapHeight}
      />
      <PropertyListFooter
        propertyData={propertyData.propertyDataLists}
        handlePageChange={handlePageChange}
        handleClick={handleClick}
        ShortListReducer={ShortListReducer}
        pageCount={propertyData.page_count}
        totalCount={propertyData.total_count}
        currentPage={propertyData.current_page}
        resultsWidth={resultDimension.resultsWidth}
        mapWidth={resultDimension.mapWidth}

      />
      <AdvisorsForm bgImage={pr_s_footer} />
      <Footer />
    </Fragment>
  );
}
export default PropertiesSearchResult;
