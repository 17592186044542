export const dimensionList = {
    "imageSizes": [
        {
          "name": "loading-image",
          "dims": [35, 22]
        },
        {
          "name": "result-sm",
          "dims": [348, 215]
        },
        {
          "name": "result-xl",
          "dims": [696, 430]
        },
        {
          "name": "listing-sm",
          "dims": [770, 414]
        },
        {
          "name": "listing-xl",
          "dims": [1540, 828]
        }
      ],
    "contentImageSizes": [
        {
          "name": "loading-image",
          "dims": [64, 17]
        },
        {
          "name": "result-sm",
          "dims": [1280, 343]
        },
        {
          "name": "result-xl",
          "dims": [1280, 343]
        },
        {
          "name": "landing-sm",
          "dims": [1280, 343]
        },
        {
          "name": "landing-xl",
          "dims": [1280, 343]
        }
      ],
}