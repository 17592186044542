import { handleActions } from "redux-actions";
import * as constants from "../constant";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: [],
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    case constants.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data : action.payload
      };
    case constants.REGISTER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        data : action.payload,
      };
      case constants.RESET_REGISTRATION_REQUEST:
        return{
            ...state,
            isSuccess:false,
        }
    default:
      return {
        ...state,
      };
  }
};
export default registrationReducer;