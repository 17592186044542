import React from "react";
import "./styledComponent.js";
import Typography from "../../Typography";
import {CommercialBg} from './styledComponent';

const Commercial = ({ image, title,margin}) => {
    return (
        <>
            <CommercialBg
                className='commercial_bg'
                style={{ backgroundImage: `url(${image})` }}
                margin={margin}
            >
                <Typography
                    className="commercial_title  text-start heading-title "
                    variant="bold"
                    size="xxxxxxLarge"
                    mobileSize='xSmall'
                >{title}
                </Typography>
            </CommercialBg>
            
        </>
    );
};

export default Commercial;
