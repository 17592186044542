import React, { useEffect, useState } from "react";
import Typography from "../../Typography";
import { ReactComponent as DownArrow } from "../../../assets/icons/down-arrow.svg";
import { FilterSearchComponent } from "./styledComponent";
import ps_banner_1 from "../../../assets/PropertySearch/ps_banner_1.png";
import Geocoder from "../../SearchLocationBox";

function FilterSearch({
  onSuggest,
  location,
  handelSelectValue,
  handleSearchForm,
  handleSelectType,
  typeList,
  sizeList,
  propertyType,
  isLoading
}) {
  return (
    <FilterSearchComponent>
      <div
        className="filter_search"
        style={{ backgroundImage: `url(${ps_banner_1})` }}
      >
        <div className="row no-gutters filter_search_row">
          <div className="col-lg-6 col-md-12">
            <Typography
              className="filter_search_heading  heading-title "
              variant="bold"
              size="xxxxxxLarge"
              mobileSize="smLarge"
            >
              Filter your search
            </Typography>
            <Typography
              className="filter_search_subheading heading-title "
              variant="regular"
              size="xxxxMedium"
              mobileSize="xSmall"
            >
              Tell us what you need, and we will tell you what is available.
            </Typography>
          </div>
          <div className=" col-lg-6  col-md-12">
            <div className="search_form">
              <form onSubmit={(e) => handleSearchForm(e)}>
                <Typography
                  className="location_text heading-title "
                  variant="regular"
                  size="xxxSmall"
                  mobileSize="xxxSmall"
                >
                  LOCATION
                </Typography>
                <Geocoder
                  location={location}
                  onSuggest={(place) => onSuggest(place)}
                />
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-xs-6 col-lg-6 col-6">
                    <div className="mt-4">
                      <input
                        name="select"
                        type="radio"
                        checked="checked"
                        className="service_type_checkbox"
                        value="serviced"
                        checked={propertyType == "serviced" ? "checked" : null}
                        onChange={handleSelectType}
                      />
                      <span class="checkmark"></span>
                      <label
                        for="serviced"
                        className="service_type_checkbox_lable pl-2"
                      >
                        Serviced
                      </label>
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-xs-6 col-lg-6 col-6 leashold_checkout">
                    <div className="mt-4">
                      <input
                        name="select"
                        type="radio"
                        checked={!propertyType == "leasehold" ? "checked" : null}
                        className="service_type_checkbox"
                        value="leasehold"
                        onChange={handleSelectType}
                      />
                      <label
                        for="serviced"
                        className="service_type_checkbox_lable pl-2"
                      >
                        Leasehold
                      </label>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="select_type col-sm-6  col-12  pr-sm-1 ">
                    <label className="select_type_lable float-left">TYPE</label>
                    <div className="dropdown-wrapper">
                      <DownArrow className="dropdownArrow1" />
                      <span className="servicelist_dropdown_main">
                        <select
                          name="type"
                          className="servicelist_dropdown"
                          onChange={(e) => handelSelectValue(e)}
                        >
                          {typeList.map((val, key) => (
                            <option
                              className="option_list"
                              name={val.name}
                              value={val.value}
                            >
                              {val.name}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>

                  </div>
                  <div className="select_type col-sm-6 col-12 pl-sm-1">
                    <label className="select_type_lable float-left">
                      SIZE (Square footage)
                    </label>
                    
                    <div className="dropdown-wrapper">
                      <DownArrow className="dropdownArrow1" />
                      <span className="servicelist_dropdown_main">
                        <select
                          name="size"
                          className="servicelist_dropdown"
                          onChange={(e) => handelSelectValue(e)}
                        >
                          {sizeList.map((val, key) => (
                            <option
                              className="option_list"
                              name="size"
                              value={val.value}
                            >
                              {val.name}
                            </option>
                          ))}
                        </select>
                      </span>
                     
                    </div>
                  </div>
                </div>
                <button className="search_button">
                  <Typography
                    className=" heading-title "
                    variant="regular"
                    size="xxxSmall"
                    mobileSize="xxxSmall"
                  > {isLoading ? <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>: "CLICK HERE TO SEE YOUR BUILDINGS"}
                  </Typography>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </FilterSearchComponent>
  );
}
export default FilterSearch;
