import React from 'react';
import desktopIcon from '../assets/desktop.svg';
import heart from '../assets/heart.svg';
import feedback from '../assets/feedback.svg';
import benchmarking from '../assets/benchmarking.svg';
import overview from '../assets/overview.svg';
import innovation from '../assets/innovation.svg';
import excellence from '../assets/excellence.svg';
import humanExperience from '../assets/humanExperience.svg';
import futureProofing from '../assets/futureProofing.svg';

import dropicons from "../assets/dropicons.svg";
import { HomeNavbar, Approach, Commodity, Philosophy, Deepdive, Industries } from '../Component/strategyPage';
import { Footer, AdvisorsForm } from "../Component/HomePage";
import ChallengerConsultancy from "../Component/strategyPage/challengerConsultancy/challengerConsultancy"
import Review from "../Component/strategyPage/review/review"
import stratgy_form from '../assets/images/stratgy_form.png'
import Header from "src/Component/common/header";
import approach_img from 'src/assets/approach_img.png';

function StrategyPage(props) {
    return (
        <div className="">
            <HomeNavbar history={props.history}/>
            <Header title="Approach" image={approach_img} />
            <Commodity />
            <ChallengerConsultancy />
            <Philosophy />
            <div className="element" name="test3">
                <Deepdive
                    heading="Deep dive"
                    icon1={desktopIcon}
                    icon2={feedback}
                    icon3={heart}
                    reviewHeading1="Workplace Review "
                    reviewHeading2="Employee Feedback"
                    reviewHeading3="Culture & Design Integration"
                    firstReviewMesage={["- Observational study and space audit to assess existing workplace performance", "- Analysis of existing costs and liabilities ", "- Evaluating business plan alignment and readiness for change"]}
                    secondReviewMesage={["- Engaging employees in the change process, understanding departmental differences", "- Tailored online survey based on your objectives", "- Conclusive change report including feedback on the viability of home working"]}
                    thirdReviewMesage={["- Brand identity and application", "- Confirmation of core goals, values & culture ", "- Impact study of existing physical and service features "]}
                    className1="deep_dive"
                />

                <Deepdive
                    heading="Discover what’s possible"
                    icon1={benchmarking}
                    icon2={overview}
                    icon3={innovation}
                    reviewHeading1="Benchmarking"
                    reviewHeading2="360° Market Overview"
                    reviewHeading3="Innovation & Foresight"
                    firstReviewMesage={["+ Get inspired by some of the leading workplace designs and trends in your sector ", "+ Industry insights from all of the data we have collected over the last 10 years", "+ Field trips to some of the best performing workplaces in the UK & Ireland"]}
                    secondReviewMesage={["+ Access to every available commercial property in the UK & Ireland", " + Access to all furniture manufacturers, showrooms and sample products for you to test within your workplace", " + Access to specialist advice and support from our team of Chartered Surveyors, furniture consultants and interior designers"]}
                    thirdReviewMesage={["+ Consideration of new to market products and smart technology", "+ Definition of KPIs for employee experience and workplace performance", "+ Creation of key milestones and implementation timeline "]}
                    className1="discover"
                />
                <Deepdive
                    heading="Make change happen"
                    icon1={excellence}
                    icon2={humanExperience}
                    icon3={futureProofing}
                    reviewHeading1="Business Excellence"
                    reviewHeading2="Human Experience"
                    reviewHeading3="Future Proofing"
                    firstReviewMesage={["+ Cost certainty and capacity to forecast", "+ Digitisation & automation within the workplace ", "+ Value engineered real estate with increased productivity ", " + Investment into working from home infrastructure"]}
                    secondReviewMesage={["+ Satisfaction with work environment and diversity of work spaces", "+ Levels of indoor comfort and wellbeing amenities", "+ Empowerment through workplace technology ", "+ Virtual collaboration & culture "]}
                    thirdReviewMesage={["+ Real estate agility & flexibility ", "+ Smart FM & data-based decision making", "+ Early diagnostics of underperformance", "+ Affinity to flexible working"]}
                    className1="change_happen"
                    firstBenfit={["Traditionally inspired metrics focussing on cost", " space efficiency, capital expenditure and", "financial performance."]}
                    secondBenfit={["People oriented metrics, focussing on the impact", "of a range of workplace factors."]}
                    thirdBenfit={["Forward looking & transformative metrics", "gauging the workplace’s ability to meet the", "organisations need for innovative change."]}
                    dropicons={dropicons}
                />
                
            </div>
            <Industries />
            <Review />
            <AdvisorsForm
                bgImage={stratgy_form}
                themeColor={'waterBlue'}
            />
            <Footer />
        </div>
    )
}
export default StrategyPage;