import { combineReducers } from 'redux'
import getPropertyReducer from './property/reducer';
import getPropertyDetailReducer from './propertyDetails/reducer';
import ShortListReducer from './shortList/reducer';
import registrationReducer from './registration/reducer';
import enqueryFormReducer from './enqueryForm/reducer';
import notifyFormReducer from './notifyForm/reducer';

const rootReducer = combineReducers({
    getPropertyData : getPropertyReducer,
    getPropertyDetail : getPropertyDetailReducer,
    ShortListReducer : ShortListReducer,
    registrationReducer : registrationReducer,
    enqueryFormReducer : enqueryFormReducer,
    ShortListReducer : ShortListReducer,
    notifyFormReducer : notifyFormReducer
})


export default rootReducer