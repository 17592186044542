import { handleActions } from "redux-actions";
import * as constants from "../constant";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
};

const notifyFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.NOTIFY_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
            };
        case constants.NOTIFY_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                data: action.payload
            };
        case constants.NOTIFY_FORM_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                data: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};
export default notifyFormReducer;
