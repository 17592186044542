import React from "react";
import "./index.scss";
import Typography from "../../Typography";
import { caseStudiesList } from "src/constants/case_studies";

function Case_Studies() {
  const handleCaseStudiesPages = (e, val) => {
    if (val.name === "First") {
      window.location.href = "/clients/prosek";
    } else if (val.name === "Second") {
      window.location.href = "/clients/lovecraft";
    } else if (val.name === "Third") {
      window.location.href = "/clients/eb7";
    }
    else if (val.name==="Fourth"){
      window.location.href = "/clients/solagroup";
    }
    else if(val.name==="Fifth"){
      window.location.href = "/clients/hive";
    }
  };
  
  return (
    <div className="mt-5">
      <div className="case_studies">
        <div className="">
          {caseStudiesList.map((val) => (
            <div
              className="col-6 mx-auto case_studies_card_wrapper"
              onClick={(e) => handleCaseStudiesPages(e, val)}
            >
              <div className="case_studies_card">
                <div className="case_studies_card_bg">
                  <img src={val.bckg} className="w-100" />
                  <div className="logo_wrapper">
                    <img src={val.img} alt="logo1" className="w-100 img_logo" />
                  </div>
                </div>
                <Typography
                  className="location heading-title"
                  variant="regular"
                  size="xxMedium"
                  mobileSize="smLarge"
                >
                  {val.location}
                </Typography>
                <Typography
                  className="description heading-title"
                  variant="regular"
                  size="xxMedium"
                  mobileSize="smLarge"
                >
                  {" "}
                  {val.address}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Case_Studies;
