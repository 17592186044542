import React from 'react';
import rightArrow from '../../assets/icons/next.svg';

function Buttom({ buttonText, buttonClass, handleClick ,isImage}) {
    return (
        <div>
            <button className={buttonClass} onClick={handleClick}>{buttonText}</button>
           {isImage ? <img src={rightArrow} alt="rightArrow"/> : null}
        </div>
    )
}
export default Buttom;