import * as variables from "../../../Style/__variables";
import styled from "styled-components";

export const CommercialBg = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  height: 51vw;
  width: 100%;
  background-position: top;
  position: relative;
  margin: ${(props) => props.margin && props.margin};
  .commercial_title {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 14%;
    max-width: 900px;
    line-height: 1.1;
    color: ${variables.white};
    @media(max-width:992px){
      font-size:50px;
    }
    @media (max-width: 700px) {
      font-size: 28px;
    }
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
`;
