export const futureProofingData1 = {
  content: [
    {
      title: "Room for improvement",
      description:
        "Most people do not have a perfect five-star experience working at home or in the office.",
    },
    {
      title: "The office is turning inside out ",
      description:
        "COVID-19 has placed greater scrutiny on the performance and relevance of open plan office layouts.",
    },
    {
      title: "Changing attitudes and routines",
      description:
        "We used to fit around our jobs, now our jobs must fit around us. Less time is spent in the office than ever before, but we are working longer hours.",
    },
    {
      title: "Shift from ownership to access",
      description:
        "Increasingly landlords are outlaying the CAPEX required to design, build and furnish workplaces. Granting tenants unprecedented access to <a href='/more'>plug and play leases</a> for the first time.",
    },
  ],
  heading: "Macro trends",
};

export const futureProofingData2 = {
  content: [
    {
      title: "New performance drivers ",
      description:
        "No more do we solely use occupancy costs and ROI to measure workplace performance. Technology is helping us maximise the value of workplaces by bridging its gap with human resources departments.",
    },
    {
      title: "Visitor experience",
      description: {
        mainDesc:
          "We all know the importance of making a good first impression:",
        items1: [
          "Contactless entry systems",
          "Parking space booking",
          "Multilingual",
        ],
        items2:[
            "Contactless sign in",
            "Wayfinding",
            "Wifi passwords"
        ],
        bulletType:'-'
      },
    },
    {
        title: "Services & wellness",
        description: {
          mainDesc:
            "Improve your workplace experience through tech-enabled solutions:",
          items1: [
            "Community dashboard",
            "Mindfulness",
            "Gamification",
            "Bicycle sharing"
          ],
          items2:[
            "Maintenance requests",
            "Heat map for communal areas",
            "Parcel lockers",
            "On demand catering"
          ],
          bulletType:'-'
        },
      },
      {
        title: "Room & desk booking",
        description: {
          mainDesc:
            "Simplicity is the ultimate sophistication:",
          items1: [
            "Live availability",
            "Desk booking"
          ],
          items2:[
            "Live occupancy",
            "Room booking"
          ],
          bulletType:'-'
        },
      },
    
    
  ],
  heading:
    "Technology is generating best in class, finely tuned workplace experiences",
};

export const futureProofingData3 = {
  content: [
    {
      title: "The problem",
      description:
        "The majority of carbon emissions stem from the materials we consume. Standard offices have a short 5–10-year life cycle, with most features and furnishings becoming waste during this time frame."
    },
    {
      title: "The millennial wave",
      description:
        "Our perspective on work and the role it plays in our lives is changing. 86% of millennials say making a difference in the world is more important than personal or professional success."
    },
    {
      title: "The material difference",
      description:
        "With REESA you can source recycled and recyclable materials to enrich your workplace with unique features that tell your brand’s story. From flooring to acoustics, we have got your spaces covered."
    },
    {
      title: "Circular design",
      description:
        "Be inspired by our collection of sustainable furniture and accessories. Designed by some of the UK & Ireland’s leading manufacturers, where their ingenuity has used the waste around us as a resource to create beautiful long-lasting products."
    },
  ],
  heading: `What if sustainability didn’t only just protect the planet, but it also delivered an elevated working environment?`,
};
