import Typography from "src/Component/Typography";
import * as variables from "src/Style/__variables";
import { Button } from "./styledComponent";

const GenericButton = (props) => {
  const { className, size, color, onClick } = props;
  return (
    <Button className={`${className} btn`} onClick={onClick} color={color}>
      {props.children}
    </Button>
  );
};
export default GenericButton;
