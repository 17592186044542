import React from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import "./index.scss";
function index(props) {
  return (
    <div>
      <NavBar history={props.history} />
      <div className="container mb-5">
        <h1 className="Terms">Terms and Conditions of use</h1>
        <div className="policy mb-5">
          <p>REESA is a trading name of Row Group Limited.</p>
          <p>
            Your use of this website is subject to the terms and conditions as
            set out here and as may be amended from time to time by Row Group
            Limited.
          </p>
          <p>
            This website is the property of Row Group Limited, registered
            address Longbow house, 1st floor, 20 Chiswell Street, London EC1Y
            4TW. Row Group Limited is registered in England, company number
            09659178.
          </p>
          <p>
            If you do not agree to these Terms and Conditions of Use, you must
            not use the REESA website.
          </p>
          <h2>Other Applicable Terms</h2>
          <p>
            These terms of use refer to the following additional terms, which
            also apply to your use of the REESA website:
          </p>
          <p>
            Our privacy policy which sets out the terms on which we process any
            personal data we collect from you, or that you provide to us. By
            using the REESA website, you consent to such processing and you
            warrant that all data provided by you is accurate.
          </p>
          <p>
            REESA may amend these terms from time to time. Every time you wish
            to use the REESA website, please check these terms to ensure you
            understand the terms that apply at that time.
          </p>
          <h2>
            {" "}
            Copyright, Trade Mark and other Intellectual Property Rights{" "}
          </h2>
          <p>
            The copyright and all other intellectual property rights in the
            REESA website including all text, graphics, the selection and
            arrangement thereof, photos, code, files and links belong to REESA
            and the REESA website may not be reproduced, transmitted or stored
            in whole or in part without REESA's prior written consent. However,
            you may print out, save or download individual selections for your
            own personal, private and non commercial use.
          </p>
          <p>
            REESA is a registered trade mark. You may not use or reproduce this
            trademark in any way or form.
          </p>
          <h2>Liability</h2>
          <p>
            REESA does not guarantee that the REESA website, or any content on
            it, will always be available or be uninterrupted or free from
            errors, defects or viruses. REESA may suspend or withdraw or
            restrict the availability of all or any part of the REESA website
            for business and/or operational reasons.
          </p>

          <p>
            The content on the REESA website is provided for information only.
            It is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content on the REESA
            website.
          </p>
          <p>
            Although REESA make reasonable efforts to update the information on
            the REESA website, REESA makes no representations, warranties or
            guarantees, whether express or implied, that the content on the
            REESA website is accurate, complete or up to date.
          </p>

          <h2>Online Store</h2>
          <p>
            {" "}
            You may not use our products for any illegal or unauthorized purpose
            nor may you, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </p>
          <p>
            You must not transmit any worms or viruses or any code of a
            destructive nature.
          </p>
          <p>
            {" "}
            A breach or violation of any of the Terms will result in an
            immediate termination of your Services.
          </p>

          <u>Modifications to the Service and Prices:</u>
          <p>Prices for our products are subject to change without notice.</p>
          <p>
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.{" "}
          </p>
          <p>
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>

          <u>Products or Services:</u>
          <p>
            Certain products or services may be available exclusively online
            through the website. These products or services may have limited
            quantities and are subject to return or exchange only according to
            our Return Policy.
          </p>
          <p>
            The products shown on the site are believed to be available
            including the materials for making the products. However, in the
            event that a product is purchased and no longer available we will
            inform you as soon as possible and organise for a refund.
          </p>
          <p>
            The delivery times indicated on the site are estimated and based on
            the expected lead time for such product. If ordering multiple
            products, there could be a situation when different delivery
            timescales apply. In the event of a major delay in the delivery of
            your product you will be notified as soon as possible. A member of
            our team will also get in contact in the event that multiple
            products need to be delivered so that a co-ordinated delivery
            schedule can be arranged.{" "}
          </p>
          <p>
            We have made every effort to display as accurately as possible the
            colours and images of our products that appear at the store. We
            cannot guarantee that your computer monitor's display of any colour
            will be accurate.
          </p>
          <p>
            Every effort has been made to be as accurate as possible when
            providing measurements, weights, dimensions and capacities shown on
            our site. They are however to be assumed as approximate only.
          </p>
          <p>
            We reserve the right, but are not obligated, to limit the sales of
            our products or Services to any person, geographic region or
            jurisdiction. We may exercise this right on a case-by-case basis. We
            reserve the right to limit the quantities of any products or
            services that we offer. All descriptions of products or product
            pricing are subject to change at anytime without notice, at the sole
            discretion of us. We reserve the right to discontinue any product at
            any time. Any offer for any product or service made on this site is
            void where prohibited.
          </p>
          <p>
            We do not warrant that the quality of any products, services,
            information, or other material purchased or obtained by you will
            meet your expectations, or that any errors in the Service will be
            corrected.
          </p>

          <u>Accuracy of Billing and Account Information:</u>
          <p>
            We reserve the right to refuse any order you place with us. We may,
            in our sole discretion, limit or cancel quantities purchased per
            person, per household or per order. These restrictions may include
            orders placed by or under the same customer account, the same credit
            card, and/or orders that use the same billing and/or shipping
            address. In the event that we make a change to or cancel an order,
            we may attempt to notify you by contacting the e-mail and/or billing
            address/phone number provided at the time the order was made. We
            reserve the right to limit or prohibit orders that, in our sole
            judgment, appear to be placed by dealers, resellers or distributors.
          </p>
          <p>
            You agree to provide current, complete and accurate purchase and
            account information for all purchases made at our store. You agree
            to promptly update your account and other information, including
            your email address and credit card numbers and expiration dates, so
            that we can complete your transactions and contact you as needed.
          </p>
          <u>Terms of Sale:</u>
          <p>
            Our site allows you to place an order to buy and pay for a product.
            You will go through a process which once completed puts you under an
            obligation to pay for the products at the checkout stage. As part of
            this process you will be shown a summary of your order so that you
            can check the details and also the delivery details.
          </p>
          <p>
            Any order is subject to acceptance by us, which will be acknowledged
            when you receive an order acknowledgement email. At all stages it is
            your responsibility to check the details of your order and those
            which you provide us to be accurate and correct.{" "}
          </p>
          <p>
            Unfortunately, we are unable to cancel an order once it has been
            placed. Please refer to our returns policy and if you have any
            questions, please get in contact with us by email to discuss any
            specific query.
          </p>
          <p>
            It is your responsibility to check your ability to receive the
            products. You need to be sure that the products can freely be
            delivered and access to your site is unencumbered and available at
            the point of delivery. It is also your responsibility to establish
            before purchasing that the products fit their intended location in
            the property. Please contact us by email if you’d like to discuss
            delivery.{" "}
          </p>
          <p>
            You must enter to the best of your knowledge accurate and truthful
            information at the point of entering any information on the site
            about delivery to your chosen address.
          </p>
          <p>
            If there are any changes to your contact information once you’ve
            made an order you must notify us immediately via email.
          </p>

          <u>Returns Policy:</u>
          <p>
            To be eligible for a return, your item must be unused and in the
            same condition that you received it. It must also be in the original
            packaging.
          </p>
          <p>
            Several types of goods namely those which are made to order are
            exempt from being returned.
          </p>
          <p>
            To complete your return, we require a receipt or proof of purchase.
          </p>
          <p>
            We only accept returns for items if they are defective or damaged.
            If you need to return it for the same item, send us an email at{" "}
            <span className="text-primary">sales@reesa.com </span> and a member
            of team will reply with confirmation of where you need to send your
            item to.
          </p>
          <p>
            You will be responsible for paying for your own shipping costs for
            returning your item. Shipping costs are non-refundable. If you
            receive a refund, the cost of return shipping will be deducted from
            your refund.
          </p>

          <u>Refunds (if applicable):</u>
          <p>
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item. We
            will also notify you of the approval or rejection of your refund.
          </p>
          <p>
            If you are approved, then your refund will be processed, and a
            credit will automatically be applied to your credit card or original
            method of payment, within a certain amount of days.
          </p>
          <p>
            If you haven’t received a refund yet, first check your bank account
            again.
          </p>
          <p>
            Then contact your credit card company, it may take some time before
            your refund is officially posted.
          </p>
          <p>
            Next contact your bank. There is often some processing time before a
            refund is posted.
          </p>
          <p>
            If you’ve done all of this and you still have not received your
            refund yet, please contact us at{" "}
            <span className="text-primary"> sales@reesa.com </span>
          </p>
          <p>
            {" "}
            Only regular priced items may be refunded, unfortunately sale items
            cannot be refunded.
          </p>
          <p>
            You will be responsible for paying for your own shipping costs for
            returning your item. Shipping costs are non-refundable. If you
            receive a refund, the cost of return shipping will be deducted from
            your refund.
          </p>

          <u>Exchanges policy:</u>
          <p>
            We only replace items if they are defective or damaged. If you need
            to exchange it for the same item, send us an email at
            <span className="text-primary"> sales@reesa.com </span> and a member
            of team will reply with confirmation of where you need to send your
            item to.
          </p>

          <h2>Links to this website and links to third party websites</h2>
          <p>
            The REESA website may contain links to websites and resources
            operated and provided by third parties, any such links are provided
            for your convenience and information only. REESA has no control over
            their individual content and REESA makes no representations,
            warranties or guarantees, whether express or implied as to the
            accuracy or completeness of any of the information appearing in
            relation to any linked websites nor as to the suitability or quality
            of any of products or services offered.
          </p>
          <p>
            Links to the REESA website may not be included in any other website
            without the prior written consent of REESA limited.
          </p>

          <h2 className="mb-3">Privacy Policy</h2>
          <p>
            We process information about you in accordance with our Privacy
            Policy. By using our site, you consent to such processing and you
            warrant that all data provided by you is accurate.
          </p>
          <h2>Information</h2>
          <p>
            REESA gives notice that the information relating to any properties
            on the REESA website are provided by the Property
            Providers/operators and are displayed on their behalf, the details
            are intended for guidance only and do not form any offer or
            contract. REESA is not therefore responsible for the validity of the
            detail relating to the offices shown. Tenants and/or licensees
            should not rely on them as statements or representations of facts
            and prior to entering into any agreement, recommend that all parties
            satisfy themselves by site visits or other means. REESA and its
            employees do not have any authority to make or give representations
            or warranties in relation to these properties. All prices quoted are
            net of VAT and/or local taxes. Price and availability information is
            subject to change without notice.
          </p>
          <h2>Disclaimer</h2>
          <p>
            The Site and all the content on the website is provided for use "as
            is" and "as available" and any reliance upon the same is at your
            sole risk. Whilst REESA take all reasonable care to ensure that the
            content on this website is accurate, REESA cannot guarantee its
            accuracy and REESA reserve the right to change the content on this
            website at any time. REESA make no representations or warranties of
            any kind with respect to this website or the content of the website,
            including any text, graphics, advertisements, links or other items.
            Further more, neither REESA nor any other contributor to this
            website make any representation or gives any warranty, condition,
            undertaking or term either expressed or implied as to the condition,
            quality, performance, accuracy, fitness for purpose, completeness or
            freedom from viruses of the content contained on the website or that
            such content will be accurate, up to date, uninterrupted or error
            free.
          </p>
          <h2>Limitation of Liability</h2>
          <p>
            You acknowledge that you are solely responsible for the use to which
            you put this website and all the results and information you obtain
            from it and that all warranties, conditions, undertakings,
            representations and terms whether expressed or implied, statutory or
            otherwise are hereby excluded to the fullest extent permitted by
            law.
          </p>
          <p>
            Save in respect of liability for death or personal injury arising
            out of negligence or for fraudulent misrepresentation, REESA and all
            contributors to this website hereby disclaim to the fullest extent
            permitted by law all liability for any loss or damage including any
            consequential or indirect loss or damage incurred by you, whether
            arising in tort, contract or otherwise, and arising out of or in
            relation to or in connection with your access to or use of or
            inability to use this Site.
          </p>
          <h2>No Contract</h2>
          <p>
            Please note that no part of the content of the REESA website apart
            from these Terms and Conditions, the Disclaimer, the Copyright
            Notice and Privacy Policy, constitutes any part of an invitation to
            treat, offer or contract.
          </p>
          <h2>Claims</h2>
          <p>
            Any contract you may enter into for the rental of any property is
            between you and the other party to the contract, and not REESA. Any
            dispute you may have with the provider of a rental property is not
            the concern of REESA and you acknowledge that any claim in relation
            to such a dispute should be brought against the provider of the
            property in question directly and not against REESA. You agree to
            indemnify us for any loss we may suffer as a result of your
            attempting to take action against us on the basis of such a
            contract.
          </p>
          <h2>Jurisdiction and applicable law</h2>
          <p>
            The English Courts will have non-exclusive jurisdiction over any
            claim arising from or related to a visit to the Site. English Law
            will apply to these Terms and Conditions.
          </p>

          <h2>Contact Details</h2>
          <p>REESA (Row Group Limited):</p>
          <p>Longbow house, 1st floor, 20 Chiswell Street, London EC1Y 4TW</p>
          <p>
            You can also email{" "}
            <span className="text-primary">info@reesa.com</span> or telephone on{" "}
            <span style={{ color: "#e22a58" }}>0330 004 0404</span>
          </p>

          <h2>Acceptable Use Policy</h2>
          <p>
            This acceptable use policy sets out the terms between you and REESA
            Limited under which you may access the REESA Limited (and all pages
            linked within its sitemap){" "}
            <span style={{ color: "#e22a58" }}>(our site)</span>. This
            acceptable use policy applies to all users of, and visitors to, our
            site as well as any social medial platforms that REESA create and/or
            are active on.
          </p>
          <p>
            Your use of our site means that you accept, and agree to abide by,
            all of the policies in this acceptable use policy, which supplement
            our terms and conditions of use.
          </p>
          <p>
            <span className="text-primary"> https://www.reesa.com</span> (and
            all pages linked within its sitemap) is a site operated by Row Group
            Limited who is registered in England and Wales under company number
            09659178 and has its registered office at Longbow house, 1st floor,
            20 Chiswell Street, London EC1Y 4TW
          </p>
          <h2>Prohibited Uses</h2>
          <p>
            You may use our website only for lawful purposes. You may not use
            our website:
          </p>
          <ul>
            <li>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </li>
            <li>
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </li>
            <li>
              For the purpose of harming or attempting to harm minors in any
              way.
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards.
            </li>
            <li>
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
            <li>
              To knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>
          <p>You also agree:</p>
          <ul>
            <li>
              Not to reproduce, duplicate, copy or re-sell any part of our
              website in contravention of the provisions of our terms and
              conditions of use.
            </li>
            <li>
              Not to access without authority, interfere with, damage or
              disrupt:
            </li>
            <ul>
              <li>any part of our website;</li>
              <li>any equipment or network on which our website is stored;</li>
              <li>any software used in the provision of our website; or</li>
              <li>
                any equipment or network or software owned or used by any third
                party.
              </li>
            </ul>
          </ul>
          <h2>Content Standards</h2>
          <p>
            These content standards apply to any and all material which you
            contribute to our site (contributions), and to any interactive
            services associated with it.
          </p>
          <p>
            You must comply with the spirit and the letter of the following
            standards. The standards apply to each part of any contribution as
            well as to its whole.
          </p>
          <u>Contributions must:</u>
          <ul className="mt-2">
            <li>Be accurate (where they state facts).</li>
            <li>Be genuinely held (where they state opinions).</li>
            <li>
              Comply with applicable law in the UK and in any country from which
              they are posted.
            </li>
          </ul>
          <u>Contributions must not:</u>
          <ul className="mt-2">
            <li>Contain any material which is defamatory of any person.</li>
            <li>
              Contain any material which is obscene, offensive, hateful or
              inflammatory.
            </li>
            <li>Promote sexually explicit material.</li>
            <li>Promote violence.</li>
            <li>
              Promote discrimination based on (but not limited to) race, sex,
              religion, nationality, disability, sexual orientation or age.
            </li>
            <li>
              Infringe any copyright, database right or trade mark of any other
              person.
            </li>
            <li>Be likely to deceive any person.</li>
            <li>
              Be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence.
            </li>
            <li>Promote any illegal activity.</li>
            <li>
              Be threatening, abusive or invade another's privacy, or cause
              annoyance, inconvenience or needless anxiety.
            </li>
            <li>
              Be likely to harass, upset, embarrass, alarm or annoy any other
              person.
            </li>
            <li>
              Be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person.
            </li>
            <li>
              Give the impression that they emanate from REESA or any franchisee
              of REESA under the REESA brand, if this is not the case.
            </li>
            <li>
              Advocate, promote or assist any unlawful act such as (by way of
              example only) copyright infringement or computer misuse.
            </li>
          </ul>
          <h2>Suspension and Termination</h2>
          <p>
            REESA will determine, in its discretion, whether there has been a
            breach of this acceptable use policy through your use of our
            website. When a breach of this policy has occurred, REESA may take
            such action as REESA deems appropriate.
          </p>
          <p>
            Failure to comply with this acceptable use policy constitutes a
            material breach of the terms and conditions of use upon which you
            are permitted to use our website, and may result in our taking all
            or any of the following actions:
          </p>
          <ul>
            <li>
              Immediate, temporary or permanent withdrawal of your right to use
              our website.
            </li>
            <li>
              Immediate, temporary or permanent removal of any posting or
              material uploaded by you to our website.
            </li>
            <li>Issue of a warning to you.</li>
            <li>
              {" "}
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach.
            </li>
            <li>Further legal action against you.</li>
            <li>
              Disclosure of such information to law enforcement authorities as
              REESA reasonable feel is necessary.
            </li>
          </ul>
          <p>
            REESA exclude liability for actions taken in response to breaches of
            this acceptable use policy. The responses described in this policy
            are not limited, and REESA may take any other action REESA
            reasonably deems appropriate.
          </p>
          <h2>Changes to the Acceptable Use Policy</h2>
          <p className="mb-5">
            REESA may revise this acceptable use policy at any time by amending
            this page. You are expected to check this page from time to time to
            take notice of any changes REESA make, as they are legally binding
            on you. Some of the provisions contained in this acceptable use
            policy may also be superseded by provisions or notices published
            elsewhere on our website.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default index;
