

import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const FilterSubHeadingComponent = styled.div`
.filter_subheading {
  border-top: 2px solid ${variables.brown_grey};
  .property_sub_heading {
    color: ${variables.team_list_name};
    padding: 20px 100px;
    line-height: 1.42;
    letter-spacing: 2.29px;
  }
}

@media screen and (max-width: 576px) {
  .filter_subheading {
    .property_sub_heading {
      padding: 20px 10px !important;
    }
  }
}
`;
