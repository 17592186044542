import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const EmptyShortListComponent = styled.div`
    box-shadow: 0px 7px 7px -4px rgb(0 0 0 / 21%);
    /* height: 80px; */

    min-height: 440px;
   .search_office_button{
    background-color: ${variables.darkPink};
    border: none;
    color: ${variables.white};
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    padding: 15px 40px;
    cursor: pointer;
    .search_office_text{
        font-size: 16px;
        font-weight: bold;
    }
   }
`;