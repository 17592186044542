//Get Data
export const getData = (data) => {
    if (localStorage.getItem(`${data}`)) {
      return JSON.parse(localStorage.getItem(`${data}`));
    }
  };
  
  /////////////////////////////*****SET DATA*****///////////////////////////////////
  
//   export const setAccessToken = (response) => {
//     localStorage.setItem("accessToken", JSON.stringify(response.data.token));
//   };
  
//   export const setUserId = (response) => {
//     localStorage.setItem("userid", JSON.stringify(response.data.id));
//   };
  
  export const shorlisted = async (data) => {
    await localStorage.setItem("shorlisted", JSON.stringify(data));
  };
  