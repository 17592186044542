export const buildServicesData1 = {
  content: [
    {
      title: "Your Professional Team",
      description:
        "Our Build team work in conjunction with the Interior Design department and depending on the scope of works operate under the guidance of a Chartered Surveyor from Project Management. Exceptionally hard working, you’ll appreciate our pragmatic approachable style.",
    },
    {
      title: "Our role",
      description:
        "It’s all about building things. Luckily, we’re blessed to work with such talented designers, and it falls to the Build team to turn what’s on a floor plan into reality. ‘Making Dreams Come True’ as we say on site!",
    },
    {
      title: "Bespoke",
      description:
        "Focused on the practical aspect of a project we are often called upon to advise on the construction of custom furniture and joinery, or one off ‘show stoppers’.",
    },
    {
      title: "Budget, Timeline and Project Overview",
      description:
        "When it comes to construction, no one likes surprises, problems, budget creep or delays. That’s why we bring all our experience and expertise to the planning, budgeting and time map stages.",
    },
  ],
  heading: "Scope of works",
};

export const buildServicesData2 = {
  content: [
    {
      title: "Interiors",
      description:
        "We work either as part of a ‘Traditional’ approach to interior fit out or on a Design and Build basis. All kinds of projects big and small.",
    },
    {
      title: "Dilapidations",
      description: "Undertaking the works at lease exit.",
    },
    {
      title: "Investors / Landlords",
      description: {
        items1: [`Receptions`, `Refurbishments`, `M & E`],
        items2: [` Common parts `, ` Audio Visual `, ` Art `],
      },
    },

    {
      title: "Managed Offices (M.O.R.E) – Plug & Play",
      description: `An all-inclusive Bespoke Fitout created between a landlord and tenant. Or with a specialist managed office partner. Click here for <a href="/more" target='blank'>M.O.R.E</a>`,
    },
    {
      title: "Landlord - Category A+",
      description:
      "One key advantage we have is how close we are to the letting market. We see what deals get done, and hear directly from the Tenant Representation team what to build for landlords needing new tenants in the fastest possible time. Reducing letting voids and enhancing value."
    },
  ],
  heading: "For every type of project",
};

export const buildServicesData3 = {
  content: [
    {
      title: "CRAFTSMANSHIP",
      description:
        "Tradespeople who take pride in their work in addition to proper experience that compliments the overall team.",
    },
    {
      title: "Materials",
      description:
        "The environmental impact of the construction industry, especially in the interiors world can be shocking. The average lifespan of an office fitout is 5 years – so we’re always pushing for the procurement of materials that are ethically sourced.",
    },
    {
      title: "Compliance",
      description:
      "Building regulations; health and safety, accountability and ethical standards."
    },
    {
      title: "On Going Success",
      description:
        "We’ll be handing over a high-quality product which we want to be used and enjoyed for as long as possible. That’s why we offer regular maintenance and aftercare support. Experience tells us that things change and we need to be ready to adapt.",
    },
  ],
  heading: "Delivered to a high standard",
};
