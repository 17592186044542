import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/AudioVisual/AVBgImage.jpg";
import Timeline from "src/Component/timeline";
import {
  humanExperienceData1,
  humanExperienceData2,
  humanExperienceData3,
} from "src/constants/HumanExperience";
import HE1 from "src/assets/HumanExperience/he-banner.jpg";
import HE2 from "src/assets/HumanExperience/he-img-1.jpg";
import HE3 from "src/assets/HumanExperience/he-img-2.jpg";
import HE4 from "src/assets/HumanExperience/he-img-3.jpg";
import HE5 from "src/assets/HumanExperience/he-img-4.jpg";
import HE6 from "src/assets/HumanExperience/he-img-5.jpg";
import HE7 from "src/assets/HumanExperience/he-img-6.jpg";
import HE8 from "src/assets/HumanExperience/he-footer.jpg";
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const HumanExperience = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Human Experience " image={HE1} />
      <SubHeader
        showBar={true}
        themeColor={"waterBlue"}
        subHeading={`Make work more intrinsically motivating, cultivate success, and balance workplace stressors`}
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={humanExperienceData1}
        timelineData2={humanExperienceData2}
        timelineData3={humanExperienceData3}
        image1={HE2}
        image2={HE3}
        image3={HE4}
        image4={HE5}
        image5={HE6}
        image6={HE7}
        formBg={HE8}
        themeColor={"waterBlue"}
        timelineDivider1Height="128px"
      />
      <Footer />
    </Fragment>
  );
};

export default HumanExperience;
