import styled, { css } from "styled-components";
import * as variables from "../../../Style/__variables";

const bullet = css`
  position: absolute;
  left: 0px;
`;
export const TextCardWrapper = styled.div`
  margin-bottom: 40px;
  max-width: 530px;
  .title_font {
    font-family: "Proxima Nova W01 Regular";
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 2px;
    color: #242b30;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .title_font_content {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #242b30;
    a {
      color: ${(props) =>
        props.themeColor ? variables[props.themeColor] : variables.greeny_blue};
      font-weight: bold;
      text-decoration: none;
    }
    &.listItem {
      position: relative;
      margin-bottom: 10px;
      padding: 0 16px;
    }
    &.minus {
      &::before {
        content: "-";
        ${bullet}
      }
    }
    &.plus {
      &::before {
        content: "+";
        ${bullet}
      }
    }
  }
`;
