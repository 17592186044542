import React from 'react';
import "./index.scss";
import Typography from "../../Typography";
import {companyList,aboutCompany } from '../../../constants/aboutUs';

function CompanyInformation() {
    
    return (
        <div className="company  my-5">
            <Typography
            className="company_list_heading py-5 text-start heading-title"
                variant="extraBold"
                size="xxxxLarge"
                mobileSize='large'
            >Company information
            </Typography>
            <div className="company_list row">
                {companyList.map(val => (
                    <div className=" company_list_item col-lg-4 col-sm-12 col-md-12">
                        <Typography
                            className=" py-3 company_name text-center heading-title text-uppercase"
                            variant="regular"
                            size="xxMedium"
                            mobileSize='medium'
                        >{val.name}
                        </Typography>
                        {val.img ?
                            <div className="d-flex justify-content-center">
                                <img className=" company_img py-3" src={val.img} />
                            </div> : null}
                        <Typography
                            className={`about_company pt-3 text-center heading-title ${val.horizental && 'horizontal'}`}
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxxSmall'
                        > {val.about}
                        </Typography>
                    </div>
                ))}
            </div>
            <div className="d-flex flex-wrap partership justify-content-around mx-5">

                {aboutCompany.map(el => (
                    <div >
                        <Typography
                            className="my-4 text-center heading-title text-uppercase partnership-text"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxxSmall'
                        > {el.name}
                        </Typography>
                        <img style={{minHeight : "20px"}} src={el.img} alt={el.img} />
                    </div>
                ))}
            </div>
            
        </div>
    )
}
export default CompanyInformation;