import React,{useState} from "react";
import { Carousel } from "react-bootstrap";
import "./carousel.scss";

function HomeCarousel(props) {
  const [activeIndex, setIndex] = useState(0);
  const handleSelect = (index) => {
    setIndex(index);
  };
  const { data } = props;
  return (
    <div className="home_carouseel">
      <Carousel
        indicators={true}
        activeIndex={activeIndex}
        onSelect={handleSelect}
        className="fixedicon"
      >
        {data.map((slide, i) => {
          return (
            <Carousel.Item interval={3000} className="one_stop_shop" alt="interval">
              {slide}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
export default HomeCarousel;
