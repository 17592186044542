import { Circle } from "./styledComponent";

const TimelineCircle = ({
  step,
  width,
  height,
  stepSide,
  themeColor,
  className,
  dividerPosition
}) => {
  return (
    <Circle
      className={className}
      dividerWidth={width}
      dividerHeight={height}
      stepSide={stepSide}
      themeColor={themeColor}
      dividerPosition={dividerPosition}
    >
      <span>{step}</span>
    </Circle>
  );
};

export default TimelineCircle;
