import React from "react";
import Typography from "../../Typography";
import star from "src/assets/PropertySearch/star.svg";
import { CustomerReviewComponent } from "./styledComponent";
import QuoteBackgroundImage from "src/assets/PropertySearch/quoteBackground.jpg";
function CustomerReview() {
  return (
    <CustomerReviewComponent
      className="custome_review"
      style={{ backgroundImage: `url(${QuoteBackgroundImage})` }}
    >
      <Typography
        className="custome_review_heading  heading-title "
        variant="extraBold"
        size="xxxxLarge"
        mobileSize="xxxMedium"
      >
        Customer reviews
      </Typography>
      <div className="row no-gutters review_section">
        <div className="col-lg-6 col-md-12">
          <div className="d-flex justify-content-center mt-4">
            {[1, 2, 3, 4, 5].map((val) => (
              <img src={star} alt="star" className="px-1" />
            ))}
          </div>
          <Typography
            className="customer_review_para  heading-title "
            variant="regular"
            size="xxxMedium"
            mobileSize="xxxMedium"
          >
            “REESA offers a friendly, fast and consultative search service
            throughout the UK & Ireland. They recently helped us acquire a fully
            serviced office for up to 45 people in Leeds, with their furniture
            team assisting us to create a dynamic environment for our people to
            thrive in.”
          </Typography>
          <Typography
            className="customer_reviewer text-right heading-title "
            variant="regular"
            size="xSmall"
            mobileSize="xxxMedium"
          >
            A Firth, Digital Wave Solutions
          </Typography>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="d-flex justify-content-center mt-4">
            {[1, 2, 3, 4, 5].map((val) => (
              <img src={star} alt="star" className="px-1" />
            ))}
          </div>
          <Typography
            className="customer_review_para heading-title "
            variant="regular"
            size="xxxMedium"
            mobileSize="xxxMedium"
          >
            "REESA helped us secure two large spaces totalling 190 desks within Central London. Their hands on approach and market knowledge were pivotal in helping us meet the urgent deadline but also managed to find the workplace style the business needed."
          </Typography>
          <Typography
            className="customer_reviewer text-right heading-title "
            variant="regular"
            size="xSmall"
            mobileSize="xxxMedium"
          >
             M Lycett, HIVE
          </Typography>
        </div>
      </div>
    </CustomerReviewComponent>
  );
}
export default CustomerReview;
