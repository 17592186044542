import React from "react";
import Typography from "../../Typography";
import { ReactComponent as Left_Arrow } from "../../../assets/PropertySearch/left-arrow.svg";
import FilterStepper from "../../FilterStepper";
import { ReactComponent as Heart } from "../../../assets/PropertySearch/heart1.svg";
import { FilterResultSubHeadingComponent } from "./styledComponent";

function FilterResultSubHeading() {
  return (
    <FilterResultSubHeadingComponent>
      <div className="filter_result_subheading">
        <div className="row no-gutters">
          <div className="col-sm-6 col-12">
            <div className="my-3">
              <Typography
                className="property_result_sub_heading heading-title "
                variant="bold"
                size="large"
                mobileSize="large"
              >
                Building selection
              </Typography>
              <Typography
                className="property_result_sub_heading heading-title "
                variant="regular"
                size="xxxSmall"
                mobileSize="xxxSmall"
              >
                Browse what’s available, shortlist your favourites and proceed
                to checkout
              </Typography>
              <div className="back_search">
                <Left_Arrow />{" "}
                <span className="back_search_text">Back to search</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 ">
              <FilterStepper index={1} Icon={Heart} />
          </div>
        </div>
      </div>
    </FilterResultSubHeadingComponent>
  );
}
export default FilterResultSubHeading;
