import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const OrgnaiseViewComponent = styled.div`
   border-bottom : 2px solid ${variables.brown_grey};
    .organise_view{
        .organise_view_profile{
            height: auto;
           }
        .organise_text{
            color : ${variables.team_list_name}
        }
    }

    @media screen and (max-width: 576px){
        .organise_view{
           margin : 0px 30px;
           .organise_view_profile{
            height: 80px;
           }
        } 
    }
`;
