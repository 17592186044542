import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/AudioVisual/AVBgImage.jpg";
import Timeline from "src/Component/timeline";
import {
  businessExcellenceData1,
  businessExcellenceData2,
  businessExcellenceData3,
} from "src/constants/BusinessExcellence";
import BE1 from "src/assets/BusinessExcellence/be-banner.jpg";
import BE2 from "src/assets/BusinessExcellence/be-img-1.jpg";
import BE3 from "src/assets/BusinessExcellence/be-img-2.jpg";
import BE4 from "src/assets/BusinessExcellence/be-img-3.jpg";
import BE5 from "src/assets/BusinessExcellence/be-img-4.jpg";
import BE6 from "src/assets/BusinessExcellence/be-img-5.jpg";
import BE7 from "src/assets/BusinessExcellence/be-img-6.jpg";
import BE8 from "src/assets/BusinessExcellence/be-footer.jpg";
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const BusinessExcellence = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Business Excellence  " image={BE1} />
      <SubHeader
        showBar={true}
        themeColor={"waterBlue"}
        subHeading={
          "The transformative twenties have begun, immediately delivering us vast new possibilities"
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={businessExcellenceData1}
        timelineData2={businessExcellenceData2}
        timelineData3={businessExcellenceData3}
        image1={BE2}
        image2={BE3}
        image3={BE4}
        image4={BE5}
        image5={BE6}
        image6={BE7}
        formBg={BE8}
        themeColor={"waterBlue"}
        timelineDivider1Height="128px"
        step2HeadingMaxWidth={'62%'}

      />
      <Footer />
    </Fragment>
  );
};

export default BusinessExcellence;
