export const PATH={
    HOME:'/',
    APPROACH:'/approach',
    INTERIOR_DESIGN:'/interior-design',
    AUDIO_VISUAL_SERVICE:'/audio-visual-services',
    BUILD_SERVICE:'/build-services',
    ABOUT_US : '/aboutus',
    BUSINESS_RATES : '/business-rates',
    PROJECT_MANAGMENT:'/building-consultancy',
    TENANT_REPRESENTATION:'/tenant-representation',
    PROPERTY_DISPOSAL:'/property-disposals',
    FURNITURE_SERVICES:'/furniture-services',
    TEAM:'/team',
    CASE_STUDIES : '/clients',
    COVID_RESPONSE : '/covid-response',
    HUMAN_EXPERIENCE:'/human-experience',
    BUSINESS_EXCELLENCE:'/business-excellence',
    FUTURE_PROOFING:'/future-proofing',
    MORE:'/more',
    COMPASS:'/compass48' ,
    PROPERTYSEARCH : '/property-search',
    // PROPERTYSEARCHRESULT : '/property-search-result/:type',
    PROPERTYSEARCHRESULT : '/property-search/:type?/:propertyType?/:desk_min?/:desk_max?/:location/:northeastlat/:northeastlng/:southwestlat/:southwestlng',
    LEASEHOLD:'/listing/:type/:id',
    PROPERTYCHECKOUT : '/property-checkout/:type?/:propertyType?/:desk_min?/:desk_max/:location',
    CONFIRMATIONSCREEN:'/confirmation',
    PRIVACY:'/privacy',
    POLICY:'/terms',
    CASE_STUDIES_FIRST:'/clients/prosek',
    CASE_STUDIES_SECOND:'/clients/lovecraft',
    CASE_STUDIES_THIRD:'/clients/eb7',
    CASE_STUDIES_FOURTH:'/clients/solagroup',
    CASE_STUDIES_FIFTH:'/clients/hive'
}