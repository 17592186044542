import React from "react";
import "./help.scss";
import help_card from "../../../assets/help_card.png";
import star from "../../../assets/star.svg";
import Typography from "../../Typography";

function Help() {
  return (
    <div className="help_section element bg-white" name="test4 ">
      <div className="row align-items-center">
        <div className="col-lg-7  help_message">
          <Typography
            className="help_header"
            variant="extraBold"
            size="xxxxLarge"
            mobileSize="smLarge"
          >
            We are here to help
          </Typography>
          <img
            className="mx-auto d-block help_image mt-4"
            src={help_card}
            alt="help_card"
          />
          <div className="d-flex justify-content-center star-section">
            <img
              height="20px"
              width="20px"
              className="mx-1"
              src={star}
              alt="star"
            />
            <img
              height="20px"
              width="20px"
              className="mx-1"
              src={star}
              alt="star"
            />
            <img
              height="20px"
              width="20px"
              className="mx-1"
              src={star}
              alt="star"
            />
            <img
              height="20px"
              width="20px"
              className="mx-1"
              src={star}
              alt="star"
            />
            <img
              height="20px"
              width="20px"
              className="mx-1"
              src={star}
              alt="star"
            />
          </div>
          <div className="quoteSection">
            <Typography
              className="text-center message_text  heading-title "
              variant="regular"
              size="xxxMedium"
              mobileSize="xSmall"
            >
              “Essential reading for both business owners and operations
              professionals.”
            </Typography>
            <Typography
              className="pt-4  text-right heading-title "
              variant="bold"
              size="xSmall"
              mobileSize="xxSmall"
            >
              - M Lycett, Centrica
            </Typography>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="d-flex flex-column help_form_section">
            <Typography
              className="help_form_heading"
              variant="bold"
              size="small"
              mobileSize='xSmall'
            >
              The future of work is evolving rapidly
            </Typography>
            <Typography
              variant="regular"
              size="xxSmall"
              className="help_form_para"
              mobileSize='xxxSmall'

            >
              In these 3 E-Books we identify and analyse the key factors
              influencing UK occupiers during these unprecedented times.
            </Typography>
            <div className="help_list">
              <div className="d-flex mt-3">
                <p className="workplace_strategy dot1 mt-2 "></p>
                <Typography
                  className="workplace_strategy_text heading-title "
                  variant="bold"
                  size="xxSmall"
                  mobileSize="xxxSmall"
                >
                  The Future of Workplace Strategy
                </Typography>
              </div>
              <div className="d-flex ">
                <div className="workplace_strategy dot2 mt-2"></div>
                <Typography
                  className="workplace_strategy_text heading-title "
                  variant="bold"
                  size="xxSmall"
                  mobileSize="xxxSmall"
                >
                  The Future of Real Estate
                </Typography>
              </div>
              <div className="d-flex mt-2">
                <div className="workplace_strategy dot3 mt-2"></div>
                <Typography
                  className="workplace_strategy_text heading-title "
                  variant="bold"
                  size="xxSmall"
                  mobileSize="xxxSmall"
                >
                  The Future of Furniture & Interiors
                </Typography>
              </div>
            </div>
            <Typography
              className="sign_message heading-title "
              variant="bold"
              size="xxMedium"
              mobileSize="xSmall"
            >
              Sign up for Volume 1 today & also receive 10% off all work from
              home furniture
            </Typography>
            <div className="d-flex pt-4 flex-wrap">
              <div className="col-sm-8 pl-0 col-12 email_sendbox">
                <input
                  placeholder="Type your email here" 
                  className="email_input w-100"
                />
              </div>
              <div className="col-sm-4 px-0 col-12">
                <button className="email_button w-100 h-100">SEND</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Help;
