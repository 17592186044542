import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent  as ArrowLeft } from '../../assets/icons/left-arrow.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/right-arrow.svg';

/**
 * @class Slider Button
 *
 * Handles rendering of the carousel buttons
 *
 * @param {string} direction - orientation of the button (left or right)
 * @param {function} onClick - called when the button is clicked
 */
export default class SliderButton extends Component {
  static propTypes = {
    direction: PropTypes.string,
    onClick: PropTypes.func
  }
  render() {
    const { direction, onClick, className } = this.props;
    if (direction === 'left') {
      return (
        <ArrowLeft
          className={ className }
          onClick={ e => e.stopPropagation() || (onClick && onClick(e)) }
        />
      );
    }
    return (
      <ArrowRight
        className={ className }
        onClick={ e => e.stopPropagation() || (onClick && onClick(e)) }
      />
    );
  }
}