import React from "react";

const StarIcon = () => {
  return (
    <svg width="71px" height="71px" viewBox="0 0 65 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Star</title>
    <g id="3.0-Furniture-&amp;-Interiors" stroke="#44b7b4" stroke-width="3" fill="none" fill-rule="evenodd">
        <g id="3.2_Reesa_Interior-design" transform="translate(-201.000000, -775.000000)" fill="#FFFFFF">
            <g id="step-1" transform="translate(0.000000, 719.000000)">
                <g id="Group" transform="translate(76.000000, 0.000000)">
                    <polygon id="Star" points="157.5 106.25 137.809194 116.602069 141.569803 94.6760347 125.639607 79.1479307 147.654597 75.9489653 157.5 56 167.345403 75.9489653 189.360393 79.1479307 173.430197 94.6760347 177.190806 116.602069"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
  );
};

export default StarIcon;
