import TextCard from "../InteriorDesign/text-card";
import StepHeading from "../stepHeading";
import TimelineCircle from "../timelineCircle";
import AdvisorsForm from "../AdvisorsForm/advisorsForm";
import {
  BoxContentContainer,
  MainWrapper,
  TimelineThreeBorderContainer,
  Seperator,
  BoxContent,
} from "./styledComponent";
import { ThemeProvider } from "styled-components";

const { default: Typography } = require("../Typography");

const Timeline3 = (props) => {
  const {
    heading,
    timelineData1,
    timelineData2,
    themeColor,
    Image1,
    Image2,
    timelineData3,
    timelineData4,
    timelineData5,
    timelineData6,
    timelineData7,
    timelineData8,
    IconComponent,
    Image3,
    Image4,
    Image5,
    formBg,
    pageType,
    RentSection,
  } = props;
  const theme = {
    color: themeColor,
  };
  return (
    <ThemeProvider theme={theme}>
      <MainWrapper className="section1">
        <Typography
          size="xxxxLarge"
          mobileSize="xxLarge"
          variant="extraBold"
          className="timeline-main-heading"
        >
          {heading}
        </Typography>
        <TimelineThreeBorderContainer
          className="d-flex flex-wrap col-sm-8 px-0"
          border={["left", "bottom"]}
          step={1}
        >
          <TimelineCircle
            step={1}
            stepSide="left"
            className="timeline-circle"
          />
          <BoxContentContainer>
            {timelineData1.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={1}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3">
                        {timelineData1.heading}
                      </StepHeading>
                    </div>
                    <TextCard description={val.description} />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
        </TimelineThreeBorderContainer>

        <TimelineThreeBorderContainer
          className="d-flex flex-wrap justify-content-sm-end col-sm-8 px-0"
          border={["right"]}
          step={2}
        >
          <BoxContentContainer>
            {timelineData2.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={2}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3">
                        {timelineData2.heading}
                      </StepHeading>
                    </div>{" "}
                    <TextCard
                      description={val.description}
                      themeColor={themeColor}
                    />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
          <TimelineCircle
            step={2}
            stepSide="right"
            className="timeline-circle"
          />
        </TimelineThreeBorderContainer>
      </MainWrapper>
      {pageType === "More" ? (
        <img src={Image1} className="w-100" />
      ) : (
        <MainWrapper>
          <img src={Image1} className="w-100" />
        </MainWrapper>
      )}
      <MainWrapper>
        <TimelineThreeBorderContainer
          className="d-flex flex-wrap justify-content-sm-end col-sm-8 px-0"
          border={["right", "bottom"]}
          step={2}
        >
          <BoxContentContainer></BoxContentContainer>
        </TimelineThreeBorderContainer>

        <TimelineThreeBorderContainer
          className="d-flex flex-wrap col-sm-8 px-0"
          border={pageType === "More" ? ["left"] : ["left", "bottom"]}
          step={3}
        >
          <TimelineCircle
            step={3}
            stepSide="left"
            className="timeline-circle"
          />
          <BoxContentContainer>
            {timelineData3.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={3}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3">
                        {timelineData3.heading}
                      </StepHeading>
                    </div>{" "}
                    <TextCard
                      description={val.description}
                      themeColor={themeColor}
                    />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
        </TimelineThreeBorderContainer>
        {pageType === "More" && (
          <>
            {" "}
            <BoxContent
              className="row justify-content-center"
              border={["top", "bottom", "right", "left"]}
            >
              <div className="col-sm-5 mb-sm-0 mb-4">
                <div className="container_box h-100">
                  <div className="d-flex justify-content-center">
                    <img src={Image4} alt="Image4" className="image5" />
                  </div>
                  <Typography
                    size="xxMedium"
                    mobileSize="xxMedium"
                    variant="regular  "
                    className="text-center description"
                  >
                    Blank canvas ready for your design?
                  </Typography>
                </div>
              </div>
              <div className="or d-flex justify-content-center align-items-center">
                <Typography variant="bold" size="large">
                  or
                </Typography>
              </div>
              <div className="col-sm-5 mt-sm-0 mt-4">
                <div className="container_box h-100">
                  <div className="d-flex justify-content-center">
                    <img src={Image5} alt="Image5" className="image5" />
                  </div>
                  <Typography
                    size="xxMedium"
                    mobileSize="xxMedium"
                    variant="regular"
                    className="text-center description"
                  >
                    Customise a pre-built Managed Space?
                  </Typography>
                </div>
              </div>
            </BoxContent>
            <BoxContent
              className="row justify-content-center"
              border={["bottom", "right", "left"]}
            >
              <div className="col-sm-5 container_box container_box_bottom d-sm-block d-none"></div>
            </BoxContent>
          </>
        )}
        <TimelineThreeBorderContainer
          className="d-flex  justify-content-sm-end col-sm-8 px-0"
          pageType={pageType}
          border={["right", "bottom"]}
          step={4}
        >
          <BoxContentContainer>
            {timelineData4.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={4}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3">
                        {timelineData4.heading}
                      </StepHeading>
                    </div>{" "}
                    <TextCard
                      description={val.description}
                      themeColor={themeColor}
                    />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
          <TimelineCircle
            step={4}
            stepSide="right"
            className="timeline-circle"
          />
        </TimelineThreeBorderContainer>

        <TimelineThreeBorderContainer
          className="d-flex flex-wrap col-sm-8 px-0"
          border={["left"]}
          step={5}
        >
          <TimelineCircle
            step={5}
            stepSide="left"
            className="timeline-circle"
          />
          <BoxContentContainer>
            {timelineData5.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={5}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3">
                        {timelineData5.heading}
                      </StepHeading>
                    </div>{" "}
                    <TextCard
                      description={val.description}
                      themeColor={themeColor}
                    />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
        </TimelineThreeBorderContainer>
      </MainWrapper>
      <IconComponent className="section2" />
      <MainWrapper className="section3">
        <div className="row">
          <div className="col-sm-12  col-md-12  col-lg-5 col-12 mb-5 section-3-part-1 ">
            <img src={Image2} className="w-100" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7 col-12 mb-sm-5">
            <div className="row">
              <div className="col-sm-3 d-flex align-items-center d-sm-block">
                <TimelineCircle
                  step={6}
                  stepSide="left"
                  className="seperator-circle"
                  dividerPosition="-96px"
                  width="4px"
                  height="96px"
                />
                <StepHeading className="px-sm-0 px-3 timeline-three-step-heading-mobile">
                  {timelineData6.heading}
                </StepHeading>

                <Seperator />
              </div>
              <div className="col-sm-9">
                {timelineData6.content.map((val, i) => {
                  return (
                    <div className="contentWrapper py-3">
                      <StepHeading
                        className="px-sm-0 px-3 timeline-three-step-heading"
                        maxWidth="18vw"
                      >
                        {timelineData6.heading}
                      </StepHeading>
                      <TextCard
                        description={val.description}
                        themeColor={themeColor}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 d-flex align-items-center d-sm-block">
                <TimelineCircle
                  step={7}
                  stepSide="left"
                  className="seperator-circle"
                />
                <StepHeading className="px-sm-0 px-3 timeline-three-step-heading-mobile">
                  {timelineData7.heading}
                </StepHeading>
                <Seperator />
              </div>
              <div className="col-sm-9">
                {timelineData7.content.map((val, i) => {
                  return (
                    <div className="contentWrapper py-3">
                      <StepHeading
                        className="px-sm-0 px-3 timeline-three-step-heading"
                        maxWidth="18vw"
                      >
                        {timelineData7.heading}
                      </StepHeading>
                      <TextCard
                        description={val.description}
                        themeColor={themeColor}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <TimelineThreeBorderContainer
          className="d-flex flex-wrap bg-white col-sm-8 px-0 border-container-step-eight"
          border={["left", "right", "top"]}
          step={8}
        >
          <TimelineCircle
            step={8}
            stepSide="left"
            className="timeline-circle"
          />
          <BoxContentContainer>
            {timelineData8.content.map((val) => {
              return (
                <>
                  <div className="contentWrapper pt-4 pb-5">
                    <div className="d-flex align-items-center">
                      <TimelineCircle
                        step={8}
                        stepSide="left"
                        className="timeline-circle-mobile"
                      />
                      <StepHeading className="px-sm-0 px-3" maxWidth="81%">
                        {timelineData8.heading}
                      </StepHeading>
                    </div>
                    <TextCard
                      description={val.description}
                      themeColor={themeColor}
                    />
                  </div>
                </>
              );
            })}
          </BoxContentContainer>
          <div className="image-3">
            <img src={Image3} className="h-100" />
          </div>
        </TimelineThreeBorderContainer>
      </MainWrapper>
      {RentSection && (
        <>
          {" "}
          <RentSection />
          <MainWrapper>
            <TimelineThreeBorderContainer
              border={["left"]}
              className="box-9 col-sm-8 px-0"
            ></TimelineThreeBorderContainer>
          </MainWrapper>
        </>
      )}
      <AdvisorsForm bgImage={formBg} themeColor={themeColor} />
    </ThemeProvider>
  );
};

export default Timeline3;
