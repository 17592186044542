import React, { Fragment, useState } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import M1 from "../../assets/More/M1.jpg";
import M2 from "../../assets/More/M2.jpg";
import M3 from "../../assets/More/M3.jpg";
import M4 from "../../assets/More/M4.jpg";
import M5 from "../../assets/More/M5.jpg";
import M6 from "../../assets/More/M6.jpg";
import M7 from "../../assets/More/M7.jpg";
import M8 from "../../assets/More/icons/Rectangle.svg";
import M9 from "../../assets/More/icons/plant.svg";

import Footer from "../../Component/Footer/Footer";
import {
  MoreData1,
  MoreData2,
  MoreData3,
  MoreData4,
  MoreData5,
  MoreData6,
  MoreData7,
  MoreData8,
} from "src/constants/More";
import Timeline3 from "../../Component/timeline3";
import SubHeader from "../../Component/SubHeader";
import {
  MainHeader,
  ManagedSection,
  BenifitsSection,
  IconsSection1,
  RentSectionWrapper,
  RatingSection,
} from "./styledComponent";
import Typography from "src/Component/Typography";
import MoreIcon1 from "../../assets/More/icons/cheaper.svg";
import MoreIcon2 from "../../assets/More/icons/Fixed.svg";
import MoreIcon3 from "../../assets/More/icons/furniture.svg";
import MoreIcon4 from "../../assets/More/icons/pin.svg";
import MoreIcon5 from "../../assets/More/icons/chair.svg";
import MoreIcon6 from "../../assets/More/icons/interior-design.svg";
import MoreIcon7 from "../../assets/More/icons/wash.svg";
import MoreIcon8 from "../../assets/More/icons/buildings.svg";
import MoreIcon9 from "../../assets/More/icons/Fixed-1.svg";
import MoreIcon10 from "../../assets/More/icons/sketch.svg";

import MoreIcon11 from "../../assets/More/icons/desk-2.svg";
import MoreIcon12 from "../../assets/More/icons/calendar.svg";
import MoreIcon13 from "../../assets/More/icons/cash.svg";
import MoreIcon14 from "../../assets/More/icons/office-chair.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/Star.svg";
import fabricImage from 'src/assets/More/fabric.jpg';

const Icons2 = [
  {
    text: `25% cheaper than 
    a serviced office`,
    image: MoreIcon1,
  },
  {
    text: `Fixed 
    cost`,
    image: MoreIcon2,
  },
  {
    text: `Choose your style`,
    image: MoreIcon3,
  },
  {
    text: `Pick 
    a location`,
    image: MoreIcon4,
  },
  {
    text: `Modern ergonomic furniture`,
    image: MoreIcon5,
  },
  {
    text: "Fitout done by experts",
    image: MoreIcon6,
  },
  {
    text: "Professional cleaning",
    image: MoreIcon7,
  },
  {
    text: "Facilities management",
    image: MoreIcon8,
  },
  {
    text: "Avoid high upfront cost",
    image: MoreIcon9,
  },
  {
    text: "Customise design",
    image: MoreIcon10,
  },
];

const Icons1 = [
  {
    text: `From 
    25+ desks`,
    image: MoreIcon11,
  },
  {
    text: "Flexible lease terms",
    image: MoreIcon12,
  },
  {
    text: "All inclusive quarterly costs",
    image: MoreIcon13,
  },
  {
    text: "Bespoke branded fit-out",
    image: MoreIcon14,
  },
];

const IconComponent = () => {
  return (
    <div>
      <img src={M4} className="w-100" />
    </div>
  );
};

const RentSection = () => {
  return (
    <RentSectionWrapper >
      <div className="row">
        <div className="col-sm-7">
          <Typography
            size="xxxxLarge"
            variant="extraBold"
            className="rentTitle"
          >
            No stress bespoke property solutions
          </Typography>
          <Typography size="xSmall" variant="regular" className="rentDesc mb-5">
            Retain focus on your goals by choosing a Managed Space so you
            outsource the hassle and stress of property management and
            procurement in a space that you've helped design and configure.
          </Typography>
          <Typography size="xSmall" variant="regular" className="listDesc mb-3">
            Managed deals help companies:
          </Typography>
          <div className="row">
            <div className="col-sm-6">
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                <CheckedIcon className="mr-4" /> Reduce letting voids
              </Typography>
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                {" "}
                <CheckedIcon className="mr-4" />
                Get the best of both worlds (serviced & leased)
              </Typography>
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                {" "}
                <CheckedIcon className="mr-4" />
                Have fun designing and configuring your own space
              </Typography>
            </div>
            <div className="col-sm-6">
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                {" "}
                <CheckedIcon className="mr-4" />
                Choose your location
              </Typography>
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                {" "}
                <CheckedIcon className="mr-4" />
                Create an inspiring workplace
              </Typography>
              <Typography
                size="xxSmall"
                variant="regular"
                className="listItem d-flex align-items-center mb-3"
              >
                {" "}
                <CheckedIcon className="mr-4" /> Improve overall maintenance of
                One all-in monthly bill
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <RatingSection style={{ backgroundImage: `url(${fabricImage})` }}>
            <div className="d-flex justify-content-center">
              {[1, 2, 3, 4, 5].map(() => {
                return <StarIcon className="starIcon" />;
              })}
            </div>
            <Typography
              variant="regular"
              size="xxxMedium"
              className="quoteSection"
            >
              “We needed a flexible contract, furniture, fit out, creative ideas
              and a new space really fast and totally benefitted from REESA's
              expertise and drive to deliver the total package.”
            </Typography>
            <Typography variant="bold" size="xSmall" className="text-center">
              - S Robin, MIQ
            </Typography>
          </RatingSection>
        </div>
      </div>
    </RentSectionWrapper>
  );
};

const Teams = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="" image={M1} />
      <SubHeader
        subHeading={`Make a strategic Real Estate decision.
        Combine the benefits and advantages of both Serviced & Leased Space with`}
      />
      <MainHeader>
        <Typography
          className="main-heading"
          size="ExtraLarge"
          variant="bold"
          mobileSize="xxxxxLarge"
        >
          M.O.R.E
        </Typography>
        <Typography
          className="main-sub-heading"
          size="mdLarge"
          mobileSize="small"
          variant="regular"
        >
          <span className="firstChar">M</span>anaged{" "}
          <span className="firstChar">O</span>ffice{" "}
          <span className="firstChar">R</span>eal{" "}
          <span className="firstChar">E</span>state
        </Typography>
      </MainHeader>
      <ManagedSection>
        <div className="row align-items-center">
          <div className="col-sm-5 mb-4 mb-sm-0">
            <img src={M2} className="w-100" />
          </div>
          <div className="col-sm-7">
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="managedHeading"
            >
              What are Managed Offices?
            </Typography>
            <Typography
              size="xxxSmall"
              variant="regular"
              className="managedDesc"
            >
              A managed office is your own space, created to fit your brand and
              requirements with the landlord/provider covering the upfront
              costs. It's developed to your bespoke needs then fully fitted and
              furnished with high speed internet. As an occupier you can expect
              the space to be maintained and looked after by a specialist
              service provider, taking away the headaches and hassle of keeping
              a larger space clean, modern and legal.
            </Typography>
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="managedHeading"
            >
              Is Managed Space for me?
            </Typography>
            <Typography
              size="xxxSmall"
              variant="regular"
              className="managedDesc"
            >
              Typically managed office space suits companies of 25+ employees
              and those able to commit for a period of 18+ months.
            </Typography>
          </div>
        </div>
      </ManagedSection>
      <IconsSection1>
        <div className="row">
          {Icons1.map((item, i) => {
            return (
              <div className="col-md-3 col-12 col-sm-6 text-center mb-sm-0 mb-5">
                <div className="iconsSection1Image">
                  <img src={item.image} />
                </div>
                <Typography
                  size="xxMedium"
                  variant="regular"
                  className="iconsSection1Desc"
                >
                  {item.text}
                </Typography>
              </div>
            );
          })}
        </div>
      </IconsSection1>
      <BenifitsSection>
        <Typography
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="xxLarge"
          className="benifitsHeading"
        >
          Key Benefits
        </Typography>
        <div className="row  justify-content-around">
          {Icons2.map((val, i) => {
            return (
              <div className="text-center icons2 px-4">
                <img src={val.image} className="benifit-section-image w-100" />
                <Typography
                  size="xxMedium"
                  variant="regular"
                  className="benifitsDesc"
                >
                  {val.text}
                </Typography>
              </div>
            );
          })}
        </div>
      </BenifitsSection>
      <Timeline3
        themeColor="darkPink"
        heading={"How to secure a managed office"}
        timelineData1={MoreData1}
        timelineData2={MoreData2}
        timelineData3={MoreData3}
        timelineData4={MoreData4}
        timelineData5={MoreData5}
        timelineData6={MoreData6}
        timelineData7={MoreData7}
        timelineData8={MoreData8}
        Image1={M3}
        Image2={M5}
        Image3={M7}
        Image4={M8}
        Image5={M9}
        formBg={M6}
        IconComponent={IconComponent}
        RentSection={RentSection}
        pageType="More"
      />
      <Footer />
    </Fragment>
  );
};

export default Teams;
