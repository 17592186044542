import styled from "styled-components";
import * as variables from '../../Style/__variables';
export const PropertyCardList = styled.div`
  .list{
    overflow: auto;
    max-height:${(props) => props.mapHeight}px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
    width: 10px;
    }
  
    .inActiveCard{
      opacity : .25;
    }
    .cardListActive{
      opacity : 1; 
    }

    &:-webkit-scrollbar-track {
      background: ${variables.white};
    }

    &::-webkit-scrollbar-thumb {
      background: ${variables.darkGrey};
      border-radius:7px;
    }

  }

  .checkoutpropertyList{
    overflow : scroll;
    height : 550px;
  }
  @media screen and (max-width: 576px){
    .list{
      max-height : 440px;
    }
  }
`;
