import React from "react";
import "./index.scss";

function CarouselStepper({ activeIndex, handleSelect }) {
  // Step
  const Step = ({ activeIndex, handleSelect, indicator, title }) => (
    <div className="Stepper__step">
      <div className="d-flex flex-column">
        <div
          className="Stepper__indicator"
          onClick={() => handleSelect(indicator - 1)}
        >
          <span
            className="Stepper__info"
            style={
              activeIndex === indicator - 1
                ? {
                    backgroundColor: "#44b7b4",
                    width: "34px",
                    height: "34px",
                    top: "-8px",
                  }
                : null
            }
          >
            {indicator}
          </span>
        </div>
        <div
          className={`${
            activeIndex === indicator - 1
              ? "Stepper__label__active"
              : "Stepper__label"
          } text-center pt-3`}
        >
          {title}
        </div>
      </div>
      <div className="Stepper__panel"></div>
    </div>
  );

  // Overlay
  const Stepper = (props) => (
    <div
      className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
        props.isInline ? "Stepper--inline" : ""
      }`}
    >
      {props.children}
    </div>
  );

  return (
    <div>
      <Stepper isInline>
        <Step
          indicator="1"
          title="2012"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="2"
          title="2015"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="3"
          title="2016"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="4"
          title="2017"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="5"
          title="2018"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="6"
          title="2020"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
        <Step
          indicator="7"
          title="2021"
          activeIndex={activeIndex}
          handleSelect={handleSelect}
        >
          <div className="Content">
            <button>Prev</button>
            <button>Next</button>
          </div>
        </Step>
      </Stepper>
    </div>
  );
}
export default CarouselStepper;
