export const proposalDisposalsData1 = {
  content: [
    {
      title: "Understanding the Motives",
      description:
        "Commercial Real Estate deals sign when two motivated parties are introduced and given the support and guidance to make it happen. REESA are deal makers; central to the transaction process working tirelessly to achieve success.",
    },
    {
      title: "How much is your property worth?",
      description:
        "On any given day, a property can change in value. One thing that doesn’t change is REESA's expertise in advising clearly and honestly on market conditions and what it will take to achieve the best result.",
    },
    {
      title: "One size, does not fit all",
      description:
        "Disposal strategies to find the right occupier or buyer differ depending on the type of deal. The advice from our advisors will be research based, and always customised to a particular building or situation. ",
    },
    {
      title: "Types of Deals",
      description: {
        mainDes:
          "When it comes to Disposals, no one likes surprises, problems, budget creep or delays. That's why we bring our extensive experience and expertise to the planning, budgeting and timing phases.",
        items1: [
          "Leases",
          "Vacant freeholds",
          "Investment property",
          "Serviced offices & coworking",
          "M.O.R.E - Managed Office Real Estate",
          "Cat A + (fitted out)",
          "Sublets & lease assignments",
        ],
      },
    },
    
  ],
  heading:"Define the strategy to dispose of your Real Estate assets or liabilities anywhere in the UK & Ireland"
};

export const proposalDisposalsData2 = {
  content: [
    {
      title: "Understanding the Fundamentals ",
      description:
        "The basics of good marketing, apply no less to Estate Agency than they do to selling a chair. The Disposal strategy focuses on understanding the product and market. Next comes analytical profiling of who may want it. ",
    },
    {
      title: "The Marketing Plan",
      description:
        "With the product and likely buyer/occupier appraised - there is then the need for a detailed plan of what is required to showcase the product, ensuring maximum market reach. Most important is making sure decision makers become aware of the opportunity.",
    },
    {
      title: "Using Technology to drive Enquiries",
      description:
        "Showcasing available buildings starts with a listing on REESA's proprietary property search engine. Active multi media advertising and targeted marketing drives traffic to our website and sales team. ",
    },
    {
      title: "Investment into Marketing",
      description:
      "The Disposal team draw from many years of experience across the spectrum of property marketing. Understanding this topic means we’ve mastered our approach to deploying unique Disposal and marketing strategies."
    },
    
  ],

  heading:
    "Proactive marketing campaigns that get buildings in front of relevant audiences",
};

export const proposalDisposalsData3 = {
  content: [
    {
      title: "Team Selection",
      description:
        "The process our Disposals team undertake to create a Disposal and marketing strategy is objective, and will highlight the individuals or specialist partners required to maximise a successful outcome. ",
    },
    {
      title:"AVAILABLE BUILDINGS NEED AVAILABLE AGENTS",
      description:
      "We pride ourselves on our ability to respond and qualify enquiries quickly and in a professional manner. Our team is ready by phone, text, email, online or in person."
    },
    {
      title: "Viewings",
      description:
      "Making sure you arrive early, turn on the lights, know the name of the person inspecting, may sound simple, but we operate in an industry where these things are often overlooked. Not at REESA. You can rely on us to represent any building we deal with as if it is our own."
    },
    {
      title: "Reporting, Follow Ups and Next Steps",
      description:
        "There is always a Director responsible for managing the disposal process. Reporting is fast, constant, transparent and always proactive.",
    },
    {
      title:'SUCCESS',
      description:'We are in no doubt, that it’s been a privilege to have played our part in the deals we’ve been involved with over the years. Something that we are supremely proud of. But in truth, what drives us is always that next deal… We are ready to get to work!'
    }
  ],
  heading: "Getting the job done!",
};
