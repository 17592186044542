import styled, { css } from "styled-components";
import * as variables from "../../Style/__variables";

const image_common = css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`;

const selector_common = css`
  content: "";
  position: absolute;
  width: 4px;
  background-color: ${(props) => variables[props.theme.color]};
  @media (max-width: 576px) {
    content: none;
  }
`;

const spacing_left = css`
  padding-right: 4vw;
`;
const spacing_right = css`
  padding-left: 4vw;
`;

export const TimelineWrapper = styled.div`
  position: relative;
`;

export const Image1 = styled.div`
  position: relative;
  max-height: 731px;
  &::after {
    ${selector_common}
    left: 50%;
    bottom: ${(props) =>
      props.divider1Height ? `-${props.divider1Height}px` : "-265px"};
    height: ${(props) =>
      props.divider1Height ? `${props.divider1Height}px` : "265px"};
  }

  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
`;

const WrapperContainer = css`
  max-width: 1240px;
  margin: auto;
  padding-right: 22px;
  padding-left: 22px;
`;

export const Section1 = styled.div`
  ${WrapperContainer}
  .timeline_circle_wrapper {
    padding: 95px 0;
    @media (max-width: 576px) {
      padding: 54px 0;
    }
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  .section2 {
    padding-top: 81px;

    @media (max-width: 576px) {
      padding: 0 0 80px 0;
    }
  }
`;

export const Image2 = styled.div`
  max-width:265px;
  width:100%;
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 30px;
    max-width:100%;
  }
  ${image_common}
  position: relative;
  margin-bottom: 77px;

  .image2-timeline-circle {
    position: absolute;
    transform: translate(-50%, -40%);
    top: 50%;
    left: -189px;
    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      width: 70px;
      height: 200px;
      top: 50%;
      right: -74px;
      background-color: ${variables.white};
      border-right: 4px solid ${(props) => variables[props.theme.color]};
      border-top: 4px solid ${(props) => variables[props.theme.color]};
    }
  }
  &::after {
    z-index: -2;
    content: "";
    position: absolute;
    background-color: ${variables.white};
    left: -189px;
    top: 16%;
    width: 189px;
    height: 50px;
    border-top: 4px solid ${(props) => variables[props.theme.color]};
    border-left: 4px solid ${(props) => variables[props.theme.color]};
    @media(max-width:576px){
      content:none;
    }
  }
  &::before {
    ${selector_common}
    background-color: ${(props) => variables[props.theme.color]};
    left: 50%;
    top: -45px;
    width: 4px;
    height: 45px;
  }
`;
export const Image7 = styled.div`
  width: 25vw;
  @media (max-width: 576px) {
    width: 100%;
  }
  &::after {
    ${selector_common}
    height: 62px;
    background-color: ${(props) => variables[props.theme.color]};
    bottom: -62px;
    left: 33%;
  }
`;

export const Image3 = styled.div`
  @media (max-width: 576px) {
    width: 100%;
  }
  ${image_common}
  position: relative;
`;

export const Section3 = styled.div`
  padding-top: 143px;
  position: relative;
  .image_4 {
    width: 100%;
    height: 30vw;
    ${image_common}
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

export const Section4 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding-top: 45px;
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  .col-left {
    ${spacing_left}
    margin-bottom:124px;
    @media(max-width:576px){
      margin-bottom:40px;
    }
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
  .image_5 {
    max-height:730px;
    max-width:530px;
  }
  position: relative;
  &.bottomSpacingPersonTwoSection {
    padding-top: 213px;
    padding-bottom: 62px;
  }
  .step2Heading{
    margin: 9vw 0 8vw 0;
  }
  @media (max-width: 576px) {
    padding-bottom: 0;
  }
  
`;

export const Section5 = styled.div`
  ${WrapperContainer}
  padding-top:89px;
  padding-bottom: 62px;
  position: relative;
  &.bottomSpacingPersonTwoSection {
    padding-top: 213px;
    padding-bottom: 62px;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  @media (max-width: 576px) {
    padding-top:0;
  }
  .image_6 {
    width: 100%;
    max-height:730px;
    max-width:530px;
    margin-bottom: 30px;
    ${image_common}
  }
`;
export const Section6 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding: 213px 0 150px 0;
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
  .image_5 {
    width: 100%;
    height: 50vw;
    ${image_common}
  }
`;

export const Section7 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding-top: 213px;
    padding-bottom: 62px;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
  .image_5 {
    width: 100%;
    height: 50vw;
    ${image_common}
  }
`;

const BorderContainer = css`
  height: 100%;
  position: absolute;
  border-bottom: 4px solid ${(props) => variables[props.theme.color]};
  z-index: -1;
  width: ${(props) =>
    props.theme?.borderContainerWidth
      ? props.theme?.borderContainerWidth
      : "73%"};
  left: 6%;
  @media (max-width: 576px) {
    border: none;
    width: 100%;
    left: 0;
    padding: 0 22px;
  }
`;


export const BorderContainerTwo = styled.div`
${BorderContainer}
@media(max-width:576px){
  position:relative;
}
  &.personTwoImageSection {
    height: 88%;
  }
  top: -13%;
  border-left: 4px solid ${(props) => {
    return variables[props.theme.color];
  }};
  border-top: 4px solid ${(props) => {
    return variables[props.theme.color];
  }};
  .step-three {
    position: relative;
    left: 0;
    background-color: white;
    display: flex;
    align-items: center;
    height: 213px;
    justify-content: flex-start;
  }
  @media (min-width: 1800px) {
    top: -7%;
  }
}
`;

export const BorderContainerThree = styled.div`
  ${BorderContainer}
  border-right: 4px solid ${(props) => variables[props.theme.color]};
  top: 0;
  .step-four {
    position: relative;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 213px;
    @media (max-width: 576px) {
      flex-direction: row-reverse;
    }
  }
`;

export const BorderContainerFour = styled.div`
  ${BorderContainer}
  border-left: 4px solid ${(props) => variables[props.theme.color]};
  top: 0;
  &.personTwoImageSection {
    height: 88%;
  }
  .step-5 {
    position: relative;
    left: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 213px;
  }
`;



export const BuilderBox = styled.div`
  padding: 42px 35px;
  max-width: 419px;
  margin: auto;
  background-color: ${variables.greeny_blue};
  color: ${variables.white};
  .project-builder-title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 2px;
    margin-bottom: 19px;
  }
  .project-builder-content {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  &::after {
    ${selector_common};
    height: 262px;
    bottom: -143px;
    left: 50%;
    z-index: -1;
  }
`;
