export function getSpaceWalkingTime(distance = 0) {
    // 3km = 3600s | 1km = 1200s | 100m = 120s | 10m = 12s.
    // timeSeconds = distanceMeters * 1.2
    const seconds = (distance || 0) * 1.2;
    // const minutes = ~~(seconds / 60);
    const minutes = Math.floor(seconds / 60);
    if (minutes > 60) return '> 1 hr';
    if (minutes < 1) {
        return '< 1 min';
    }
    return `${minutes} min`;
}