import React from "react";
import Typography from "src/Component/Typography";
import "./index.scss";

const Header = ({ image, title }) => {
  return (
    <div
      className='interior_design'
      style={{ backgroundImage: `url(${image})` }}
    >
      <Typography className='interior_title' variant='bold' size='xxxLarge'>{title}</Typography>
    </div>
  );
};

export default Header;
