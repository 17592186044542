import { handleActions } from "redux-actions";
import * as constants from "../constant";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: [],
};

const getPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PROPERTY_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    case constants.GET_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data : action.payload
      };
    case constants.GET_PROPERTY_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        data : action.payload,
      };
      case constants.GET_PROPERTY_DATA_CLEAR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default getPropertyReducer;
