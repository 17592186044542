import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDataRequest, getPropertyDataClear } from 'src/redux/action';
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import pr_s_footer from 'src/assets/PropertySearch/pr_s_footer.png';
import CustomerReview from '../../Component/PropertySearch/CustomerReview';
import FilterSunHeading from '../../Component/PropertySearch/FilterSubHeading';
import FilterSearch from '../../Component/PropertySearch/FilterSearch';
import { servicedTypeList, leaseholdTypeList, servicedSizeList, leaseholdSizeList } from 'src/constants/propertyForm';
import '../../../src/index.scss';

function PropertiesSearch(props) {
    const [typeList, setTypeList] = useState(servicedTypeList)
    const [sizeList, setSizeList] = useState(servicedSizeList)
    const [location, setLocation] = useState("");
    const [center, setCenter] = useState({
        northeastlat: 61.5471111,
        northeastlng: 9.5844157,
        southwestlat: 47.5554486,
        southwestlng: -18.5319589
    })
    const [propertyType, setPropertyType] = useState("serviced");
    const [state, setState] = useState({
        type: (propertyType === "leasehold") ? "office" : 'hotdesk',
        size: (propertyType === "leasehold") ? "1000" : "1 5 people",

    })
   
    const dispatch = useDispatch();
    const getPropertyData = useSelector((state) => state.getPropertyData);

    useEffect(() => {
        if (propertyType === "serviced") {
            setTypeList(servicedTypeList)
            setSizeList(servicedSizeList)
            setState({ ...state, type: "hotdesk", size: "1 5 people" })
        } else {
            setTypeList(leaseholdTypeList)
            setSizeList(leaseholdSizeList)
            setState({ ...state, type: "office", size: "1000" })
        }
    }, [propertyType])

    useEffect(() => { 
        if (getPropertyData.isSuccess) {
            props.history.push(`/property-search/${state.type}/${propertyType}/${state.size.split(" ")[0]}/${state.size.split(" ")[1]}/${location ? location : "NoLocation"}/${center.northeastlat ? center.northeastlat : "northeastlat"}/${center.northeastlng ? center.northeastlng : "northeastlng"}/${center.southwestlat ? center.southwestlat : "southwestlat"}/${center.southwestlng ? center.southwestlng : "southwestlng"}`)
        }
    }, [getPropertyData])

    useEffect(() => {
        return () => { dispatch(getPropertyDataClear()); }

    }, [])


    const onSuggest = (place) => {
        if (place) {
            const bb = place[0]?.bbox;
            const ce = place[0]?.center;

            const bounds = bb?.length && `${bb[2]},${bb[1]}|${bb[0]},${bb[1]}|${bb[0]},${bb[3]}|${bb[2]},${bb[3]}`;
            setLocation(bounds)
            if(bb?.length > 0){
                setCenter({
                    ...center,
                    northeastlat: bb[1],
                    northeastlng: bb[0],
                    southwestlat: bb[3],
                    southwestlng: bb[2]
                })
            }
        }
    }

    const handelSelectValue = (e) => {

        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSelectType = (e) => {
        setPropertyType(e.target.value)
    }


    const handleSearchForm = (e) => {
        e.preventDefault();
        dispatch(getPropertyDataRequest({
            page: 1,
            per_page: 100,
            location: location,
            type: state.type,
            propertyType: propertyType,
            desk_min: state.size == "1000" ? state.size : state.size.split(" ")[0],
            desk_max: state.size.split(" ")[1],
        }))
    }
    return (
        <Fragment>
            <NavBar history={props.history} />
            <FilterSunHeading />
            <FilterSearch
                handelSelectValue={handelSelectValue}
                handleSearchForm={handleSearchForm}
                handleSelectType={handleSelectType}
                onSuggest={(place) => onSuggest(place)}
                typeList={typeList}
                sizeList={sizeList}
                propertyType={propertyType}
                isLoading={getPropertyData.isLoading}
            />
            <CustomerReview />
            <AdvisorsForm
                bgImage={pr_s_footer}
                handelSelectValue={handelSelectValue}
            />
            <Footer />
        </Fragment>
    )
}
export default PropertiesSearch;