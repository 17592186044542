import React from "react";
import "./philosophy.scss";
import philosophyImg from "../../../assets/philosophy.png";
import Typography from "../../Typography";

function Philosophy() {
  return (
    <div>
      <div className="philosophy row m-0 ">
        <div className="col-lg-5 mb-sm-0 mb-4">
          <div className="px-5"></div>
          <Typography
            className="philosophy_heading heading-title ml-4"
            variant="extraBold"
            size="xxxxLarge"
            mobileSize="mdLarge"
          >
            Our Philosophy
          </Typography>
          <Typography
            className="philosophy_para text-break text-start heading-title ml-4"
            variant="regular"
            size="xxxxMedium"
            mobileSize="xxSmall"
          >
            We have a simple three-pronged approach to set you up for sustained
            success: delving into the detail; showing you what is in the art of
            the possible; and implementing organisational change.
          </Typography>
        </div>
        <div className="col-lg-7">
          <img
            className="philosophy_img w-100"
            src={philosophyImg}
            alt="philosophyImg"
          />
        </div>
      </div>
    </div>
  );
}
export default Philosophy;
