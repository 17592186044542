export const audioVisualData1 = {
  content: [
    {
      title: "Defining what you need",
      description:
        "Our Audio-Visual team provide a consultative approach to understanding your requirements when it comes to video conferencing, presentation systems and a host of connected solutions for modern environments. ",
    },
    {
      title: "Which system is right for you?",
      description:
        "Understanding which system your organisation requires, is about integrating existing equipment or platforms (Zoom, Teams, Google etc), reviewing behaviour patterns and planning for the future.",
    },
    {
      title: "Room / Acoustic survey",
      description:
        "Book a survey and/or acoustic study with one of our experts to understand the likely performance of an audio-visual system or connected workplace solution.",
    },
    {
      title: "Preparation of a Budget",
      description:
        "Clients always appreciate our candid approach when it comes to cost appraisals and budgets. Our strong belief is that given our experience and wide service offering we can advise you quickly on expected costs.",
    },
  ],
  heading: "Choosing your platform and technology",
};

export const audioVisualData2 = {
  content: [
    {
      title: "Installation",
      description: {
        items1: [
          "Video conferencing",
          `Audio systems, speakers,
             amplifiers, microphones, 
            AV receivers etc`,
          `Video walls & flat panel 
            displays etc`,
        ],
        items2: [
          `Remote working packages`,
          `Signage, access controls and
            interactive products like
            whiteboards`,
          `Desk and meeting room 
            booking systems`,
        ],
      },
    },
    {
      title: "Furniture",
      description: {
        items1: [
          `Pods, booths and hoods`,
          `AV trolleys`,
          `Sound absorbing panels, 
                curtains & furniture`,
        ],
        items2: [
          `Monitor arms`,
          `Meeting room tables `,
          `Bespoke display cabinets /structures `,
        ],
      },
    },
    {
      title: "Acoustics",
      description:
        "Improving sound quality is often about enhancing the acoustic performance of a room. We work alongside our furniture team who bring an in-depth knowledge of acoustic furniture, panels and fittings.",
    },
    {
      title: "White Noise / Background Sound Products",
      description:
        "Background noise is proven when used correctly to enhance privacy and concentration or simply control the mood of an environment such as music in an elevator.",
    },
    {
      title:'SOUND ABSORBING MATERIALS',
      description:"Following a sound survey REESA's team will put together a schedule of specialist sound absorbing products combining the latest technologies, sustainability considerations and a huge choice of colour and fabrics."
    }
  ],
  heading: "Implementation and installation ",
};

export const audioVisualData3 = {
  content: [
    {
      title: "Excellent Customer Service",
      description:
        "We appreciate that the systems we install are often quite technical behind the easy-to-use interfaces. Our friendly customer support team and your dedicated account manager are there to help.",
    },
    {
      title: "Training",
      description:
        "With every new system we ensure that adequate training and supporting documents are provided.",
    },
    {
      title: "Maintenance",
      description:
        "Systems 100% benefit from regular cleaning, updates and maintenance checks so we always recommend an appropriate care package.",
    },
    {
      title: "Staying up to Date",
      description:
        "We firmly believe that the products we recommend and sell are pivotable to the successful running of our client activities and experience. We keep you regularly updated on the latest opportunities and trends.",
    },
  ],
  heading: "Aftercare, support and training",
};
