import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import Timeline from "src/Component/timeline";
import {
  projectManagementData1,
  projectManagementData2,
  projectManagementData3,
} from "src/constants/tenantRepresentation";
import TR1 from 'src/assets/tenantRepresentation/TR1.jpg';
import TR2 from 'src/assets/tenantRepresentation/TR2.jpg';
import TR3 from 'src/assets/tenantRepresentation/TR3.jpg';
import TR4 from 'src/assets/tenantRepresentation/TR4.jpeg';
import TR5 from 'src/assets/tenantRepresentation/TR5.jpg';
import TR6 from 'src/assets/tenantRepresentation/TR6.jpg';
import TR7 from 'src/assets/tenantRepresentation/TR7.jpg';
import TR8 from 'src/assets/tenantRepresentation/TR8.jpg';


import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const TenantRepresentation = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Tenant Representation" image={TR1} />
      <SubHeader
        showBar={true}
        themeColor="darkPink"
        subHeading={
          "Success for occupiers in Property Search, Negotiation and Project Management"
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={projectManagementData1}
        timelineData2={projectManagementData2}
        timelineData3={projectManagementData3}
        image1={TR2}
        image2={TR4}
        image3={TR3}
        image4={TR5}
        image5={TR6}
        image6={TR7}
        formBg={TR8}
        themeColor="darkPink"
      />
      <Footer />
    </Fragment>
  );
};

export default TenantRepresentation;
