import React from "react";
import { Typo } from "src/Component/Typography/styledComponent";
import Typography from "../../Typography";
import "./deepDive.scss";

function Deepdive({
  dropicons,
  className1,
  heading,
  icon1,
  icon2,
  icon3,
  reviewHeading1,
  reviewHeading2,
  reviewHeading3,
  firstReviewMesage,
  secondReviewMesage,
  thirdReviewMesage,
  firstBenfit,
  secondBenfit,
  thirdBenfit,
}) {
  return (
    <div className={className1}>
      <div className="deepdrive_container">
        <Typography
          className="deep_dive_head text-start heading-title"
          variant="bold"
          size="large"
          mobileSize="medium"
        >
          {heading}
        </Typography>
        <Typography
          className="deep_dive_head"
          variant="regular"
          size="large"
        ></Typography>
        <div className="row  deep_dive_row">
          <div className="col-lg-4 workplace">
            <div className="text-center">
              <img className="" src={icon1} alt="desktopIcon" />
            </div>
            <Typography
              className="workplace_review text-center  heading-title"
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              {reviewHeading1}
            </Typography>
            {firstBenfit &&
              firstBenfit.map((el) => (
                <div>
                  <Typography
                    className="text-center benfit"
                    variant="regular"
                    size="xxxSmall"
                  >
                    {el}
                  </Typography>
                </div>
              ))}

            <div className="text-center">
              {dropicons && <img src={dropicons} alt="icon2" />}
            </div>
            {firstReviewMesage.map((val) => (
              <Typography
                className="text-center review_message"
                variant="regular"
                size="xxxSmall"
              >
                {val}
              </Typography>
            ))}
          </div>
          <div className="col-lg-4 workplace">
            <div className="text-center">
              <img className="img-fluid" src={icon2} alt="desktopIcon" />
            </div>
            <Typography
              className="workplace_review text-center  heading-title"
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              {reviewHeading2}
            </Typography>
            {secondBenfit &&
              secondBenfit.map((el) => (
                <div>
                  <Typography
                    className="text-center benfit"
                    variant="regular"
                    size="xxxSmall"
                  >
                    {el}
                  </Typography>
                </div>
              ))}
            <div className="text-center">
              {dropicons && <img src={dropicons} alt="icon2" />}
            </div>
            {secondReviewMesage.map((val) => (
              <Typography
                className="text-center review_message"
                variant="regular"
                size="xxxSmall"
              >
                {val}
              </Typography>
            ))}
          </div>
          <div className="col-lg-4 workplace">
            <div className="text-center">
              <img src={icon3} alt="desktopIcon" />
            </div>
            <Typography
              className="workplace_review text-center  heading-title"
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              {reviewHeading3}
            </Typography>
            {thirdBenfit &&
              thirdBenfit.map((el) => (
                <div>
                  <Typography
                    className="text-center benfit"
                    variant="regular"
                    size="xxxSmall"
                  >
                    {el}
                  </Typography>
                </div>
              ))}
            <div className="text-center">
              {dropicons && <img src={dropicons} alt="icon2" />}
            </div>
            {thirdReviewMesage.map((val) => (
              <Typography
                className="text-center review_message"
                variant="regular"
                size="xxxSmall"
              >
                {val}
              </Typography>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Deepdive;
