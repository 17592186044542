import React from "react";
import "./index.scss";
import { Carousel } from "react-bootstrap";
import WishlistImage from "src/assets/images/wishlist.svg";
import WorkplaceImage from "src/assets/images/workplace.svg";
import SiteVisitImage from "src/assets/images/site-visit.svg";
import FloorPlan from "src/assets/images/floor-plan.jpg";
import Typography from "src/Component/Typography";

const InteriorCarousel = (props) => {
  const { activeIndex, handleSelect } = props;
  return (
    <div className="carouselDiv">
      <Carousel
        interval={3600000}
        indicators={false}
        activeIndex={activeIndex}
        onSelect={handleSelect}
      >
      <Carousel.Item>
        <div className="d-flex align-items-center carousel-Wrapper">
          <div className="Text_div">
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="carousel-subheading"
            >
              Getting to know you
              </Typography>
            <Typography
              className="text_size"
              variant="regular"
              size="xxxSmall"
            >
              <p>
                The most important thing for us at the start of a project is
                to listen. There’s always a reason and thought process behind
                what a client asks us for. It’s our role to properly interpret
                what is said and capture the essence of the brief and make
                sure we fully understand the priorities and the overall
                objectives.
                </p>
              <p>
                Experience has taught us the questions that need to be asked,
                and our process is engaging and insightful.
                </p>
            </Typography>
          </div>
          <img
            className="d-block carousel_Img"
            src={WishlistImage}
            alt="First slide"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex align-items-center carousel-Wrapper">
          <div className="Text_div text_size ">
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="carousel-subheading"
            >
              Your vision?
              </Typography>
            <Typography
              className="text_size"
              variant="regular"
              size="xxxSmall"
            >
              <p>
                We strongly believe that successful commercial interior design
                  has a deep connection with effective workplace strategy.{" "}
              </p>
              <p>
                How to design in conjunction with a business plan and expected
                change?
                </p>
              <p>
                Let’s talk flexibility, culture, technology and what people
                need to feel valued and fulfil their potential.
                </p>
            </Typography>
          </div>
          <img
            className="d-block carousel_Img"
            src={WorkplaceImage}
            alt="Second slide"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex align-items-center carousel-Wrapper">
          <div className="Text_div text_size ">
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="carousel-subheading"
            >
              Let's take a look
              </Typography>
            <Typography
              className="text_size"
              variant="regular"
              size="xxxSmall"
            >
              <p>
                When it comes to property there’s no substitute to actually
                making a physical inspection together. Our design team need to
                get a sense of the space. Understand the light, appreciate the
                acoustics and the access the overall style of the building.
                </p>
              <p>
                Alongside our Building Consultancy colleagues we are able to
                conduct the necessary measurements and surveys where needed.
                </p>
            </Typography>
          </div>
          <img
            className="d-block carousel_Img"
            src={SiteVisitImage}
            alt="Third slide"
          />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex align-items-center carousel-Wrapper">
          <div className="Text_div ">
            <Typography
              size="xxxxMedium"
              variant="regular"
              className="carousel-subheading"
            >
              Test fit, zoning - space plans
              </Typography>
            <Typography
              className="text_size"
              variant="regular"
              size="xxxSmall"
            >
              <p>
                So you’ve found a property, and promptly fallen in love with
                it, or maybe just not sure yet. It’s our job to quickly
                provide a test fit appraisal and zoning exercise to make sure
                  it meets the requirements set out in the brief.{" "}
              </p>
              <p>
                Let us run several test fits, on competing buildings to aid
                the decision-making process. It’s amazing how two buildings
                the same size can be totally different when it comes to
                efficiency. Factors like air conditioning, and pillars can
                play havoc.
                </p>
            </Typography>
          </div>
          <img
            className="d-block  carousel_Img"
            src={FloorPlan}
            alt="Fourth slide"
          />
        </div>
      </Carousel.Item>
      </Carousel>
    </div >
  );
};

export default InteriorCarousel;
