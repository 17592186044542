import styled, { css } from "styled-components";
import * as variables from "../../Style/__variables";

const commonPadding = css`
  @media (max-width: 576px) {
    padding: 77px 20px;
  }
`;

export const CompassDesc = styled.div`
  margin: auto;
  padding: 77px;
  ${commonPadding}
  .col-left {
    padding-right: 42px;
  }
  .col-right {
    padding-left: 42px;
  }
  .desc {
    letter-spacing: 1.43px;
    line-height: 1.4;
    padding-bottom:50px;
  }
  .title {
    letter-spacing: 3px;
    line-height: 1.24;
    margin-bottom: 15px;
    padding-top:50px;
  }
  @media(max-width:576px){
    .col-left{
      padding:0
    }
    .col-right{
      padding:0
    }
  }
`;

export const CompassBenifitSection = styled.div`
  padding: 44px;
  ${commonPadding}

  background-color: ${variables.lightGrey};
  .row {
    & > div {
      padding: 32px;
      img {
        margin-bottom: 41px;
      }
      .benifitDesc {
        letter-spacing: 3px;
        line-height: 1.42;
      }
    }
  }
  .title {
    letter-spacing: 3px;
    line-height: 1.24;
  }
`;

export const PercentageSection = styled.div`
  padding: 44px;
  ${commonPadding}

  background-color: ${variables.waterBlue};
  color: ${variables.white};
  & > div {
    padding: 32px;
  }
  .percentageText {
    letter-spacing: 5.4px;
  }
  .percentageDesc {
    letter-spacing: 2px;
    line-height: 1.33;
  }
`;

export const CarouselSection = styled.div`
  color: ${variables.white};
  .compassHeroImage{
    max-height:850px;
  }
  .tag {
    left: 7%;
    max-width:507px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background-color: ${variables.waterBlue};
    padding: 42px;
    padding-left: 50px;
    .reesacompass{
      line-height:1.18;
      letter-spacing: 4.8px;
    }
    @media(max-width:576px){
      padding:20px;
      left:2%;
    }
    
  }
  .caption {
    left: 7%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`;
export const ServicesSection = styled.div`
  margin: auto;
  padding: 80px 77px;
  .row {
    margin: 0 -50px;
    & > div {
      padding: 0 50px;
    }
  }
  .title {
    letter-spacing: 3px;
    margin-bottom: 40px;
    margin-top: 100px;
  }
  .desc {
    margin-bottom: 100px;
  }

  @media(max-width:576px){
    padding:80px 20px 0 20px;
    .title{
      margin-top:0;
    }
    img{
      margin:40px 0;
    }
    .desc{
      margin-bottom:0;

    }
    .row{
      margin:0 -20px;
      & >div{
        padding:0 20px;
      }
    }
  }
`;
export const LookingForSection = styled.div`
  margin: auto;
  padding: 100px 77px;
  ${commonPadding}
  .gettingStartedSection{
    min-height:415px;
    background-color: rgb(255, 255, 255,0.9);
    padding:6%;
  }
  .title {
    letter-spacing: 1.43px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  .desc {
    max-width: 980px;
    margin: auto;
    line-height: 1.24;
    letter-spacing: 3px;
  }

`;

export const PromiseSection = styled.div`
  margin: auto;
  padding: 80px 77px;
  ${commonPadding}

  background-color: ${variables.waterBlue};
  color: ${variables.white};
  .desc {
    letter-spacing: 2.1px;
    line-height: 1.36;
  }
  .title {
    letter-spacing: 3px;
    line-height: 1.24;
    margin-bottom: 50px;
  }
  @media(max-width:576px){
    .compassBg{
      height:400px;
    }
  }
`;
