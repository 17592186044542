import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const FilterResultSubHeadingComponent = styled.div`
  padding: 25px 77px;
    box-shadow: 0 7px 7px -4px rgba(0, 0, 0, 0.21);
    .property_result_sub_heading {
      color: ${variables.team_list_name};
      line-height: 1.22;
      letter-spacing: 2.16px; 
    }
    .leftSectionDesc{
      max-width:460px;
      margin : 20px 0px;
    }
  
  @media screen and (max-width: 576px) {
    .property_result_sub_heading {
      display : none;
    }
    .leftSectionDesc{
      margin : 5px 0px;
    }
  }
  @media screen and (max-width: 576px){
    padding: 20px 20px;
  }
`;

export const BackAndNext = styled.div`
   cursor: pointer;
  color: ${variables.darkPink};
  letter-spacing: 1.5px;
  .leftArrow {
    width: 13px;
    height: 20px;
    fill: ${variables.darkPink};
    margin-right: 18px;
  }
  .rightArrow {
    width: 7px;
    height: 12px;
    fill: ${variables.darkPink};
    margin-left: 18px;
  }
  .cartIcon{
    margin-right:18px;
  }
`;
