import React from 'react';
import "./index.scss";
import Typography from "../../Typography";
import {leaderteam} from '../../../constants/aboutUs';
function LeadershipTeam() {
  

    return (
        <div className="leadership_team my-5">
            <Typography
                className="leadership_team_heading text-start heading-title"
                variant="extraBold"
                size="xxxxLarge"
                mobileSize='large'
            >Leadership   team
            </Typography>
            <div className="leader_team_list row ">
                {leaderteam.map(val => (
                    <div className=" leadership_people d-flex flex-column col-sm-4">
                        <div className="d-flex justify-content-center">
                            <img className="leader_img" src={val.img} />
                        </div>
                        <Typography
                            className=" pt-3  text-center leaderdership-title"
                            variant="regular"
                            size="xxMedium"
                            mobileSize='xxxMedium'
                        >{val.name}
                        </Typography>
                        <Typography
                            className="pt-3 text-center leaderdership-text"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxxSmall'
                        > {val.about}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default LeadershipTeam;