import styled from "styled-components";
import * as variables from "../../Style/__variables";
export const MainWrapper = styled.div`
  max-width: 1212px;
  margin: auto;
  padding: 0 20px;
  position: relative;
  .timeline-main-heading {
    margin: 100px 0;
  }
  &.section3 {
    padding: 92px 20px 0 20px;
    .seperator-circle {
      transform: translate(0, 0);
      margin: auto;
    }
    .section-3-part-1{
      position:relative;
      z-index:1;
    }
  }
  .timeline-three-step-heading-mobile {
    display: none;
  }

  @media (max-width: 576px) {
    .timeline-main-heading {
      margin: 48px 0;
    }
    .timeline-three-step-heading {
      display: none;
    }
    .timeline-three-step-heading-mobile {
      display: block;
    }
    &.section3 {
      .seperator-circle {
        margin: 0;
      }
      
    }
  }
`;
export const TimelineThreeBorderContainer = styled.div`
  position: relative;
  top: 0;
  height: 100%;
  margin: auto;
  border-left: ${(props) =>
    props.border.includes("left")
      ? `4px solid ${variables[props.theme.color]}`
      : ""};
  border-right: ${(props) =>
    props.border.includes("right")
      ? `4px solid ${variables[props.theme.color]}`
      : ""};
  border-bottom: ${(props) =>
    props.border.includes("bottom")
      ? `4px solid ${variables[props.theme.color]}`
      : ""};
  border-top: ${(props) =>
    props.border.includes("top")
      ? `4px solid ${variables[props.theme.color]}`
      : ""};
  .timeline-circle {
    top: ${(props) => (props.step !== 1 ? "50px" : "-10px")};
  }

  .timeline-circle-mobile {
    display: none;
  }

  .image-3 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    height: 80%;
  }
  &.box-9{
    height:123px;
  }

  &:before {
    content: ${(props) =>
      props.pageType === "More" && props.step === 4 ? "close-quote" : "none"};
    position: absolute;
    background: ${(props) => variables[props.theme.color]};
    width: 50%;
    height: 4px;
    top: 0;
  }

  @media(max-width:769px){
    .image-3{
      position:unset;
      transform:none;
      margin:auto;
      margin-bottom:46px;

    }
  }
  @media (max-width: 1200px) {
    width: 70vw;
  }
  @media (max-width: 576px) {
    &:before{
      content:none;
    }
    width: 100%;
    border: none;
    .image-3 {
      position: relative;
      transform: translate(0, 0);
    }
    .timeline-circle {
      display: none;
    }
    .timeline-circle-mobile {
      display: block;
    }
  }
`;

export const BoxContentContainer = styled.div`
  position: relative;
  .contentWrapper {
    max-width: 480px;
    margin: auto;
  }
  padding: 50px 0;
  img {
    margin: 40px 0;
  }
  @media (max-width: 576px) {
    padding: 24px 0 0 0;
  }
`;

export const Seperator = styled.div`
  height: calc(200%);
  width: 4px;
  background-color: ${(props) => variables[props.theme.color]};
  margin: auto;
  position: relative;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const BoxContent = styled.div`
  position: relative;
  .container_box {
    border-left: ${(props) =>
      props.border.includes("left")
        ? `4px solid ${variables[props.theme.color]}`
        : ""};
    border-right: ${(props) =>
      props.border.includes("right")
        ? `4px solid ${variables[props.theme.color]}`
        : ""};
    border-bottom: ${(props) =>
      props.border.includes("bottom")
        ? `4px solid ${variables[props.theme.color]}`
        : ""};
    border-top: ${(props) =>
      props.border.includes("top")
        ? `4px solid ${variables[props.theme.color]}`
        : ""};
    .image5 {
      margin-top: 39px !important;
    }
    .description {
      margin: 30px 50px;
      color: #242b30;
      letter-spacing: 3px;
      line-height: 1.42;
    }
  }
  .container_box_bottom {
    padding: 40px;
    position: relative;
    margin-bottom: 80px;
    &:after {
      content: "";
      background-color: ${(props) => variables[props.theme.color]};
      width: 4px;
      height: 88px;
      bottom: -88px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .or {
    letter-spacing: 0.92px;
    width: 87px;
    height: 87px;
    background-color: #a42e5d;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index:1;
    transform: translate(-50%, -50%);
    .or_text {
      padding-top: 30%;
      font-size: 24px;
    }
  }
`;
