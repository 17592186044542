import React from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import lovecraft_post from "../../assets/CasestudiesItemone/lovecraft_post.png";

import "./Lovecraft.scss";

function Lovecraft() {
  return (
    <div>
      <NavBar />
      <div className="container Main_container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <h1 className="lovecraft_header mt-5 mb-4">
              Lovecrafts – Midtown, London
            </h1>
            <p>
              A multi phase project involving the acquisition of a leasehold
              headquarters, subsequent disposal and move to serviced office
              accommodation over 3 years
            </p>
            <h3 className="mt-5 mb-3">BACKGROUND</h3>
            <p>
              Lovecrafts an ecommerce platform and community for crafters that
              made the Financial Times list of fastest growing companies
              instructed us back in 2016 to provide acquisition advice for their
              new London headquarters, success on that acquisition and
              subsequent success of the Lovecrafts business led to a disposal
              and acquisition of new premises in 2018.
            </p>
            <h5 className="mt-5 mb-3">PROCESS</h5>
            <p>
              Lovecrafts success meant that they were fast outgrowing their
              space despite driving efficiencies. It was time to find new
              offices, they instructed Reesa and after a thorough search and
              subsequent negotiation process they took possession of 7,500sqft
              in the newly refurbished 10 Bloomsbury Way.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <img src={lovecraft_post} className="mt-5 mb-5 w-100 h-75" />
        <div className="row mb-4">
          <div className="col-3"></div>
          <div className="col-6">
            <p className="Thankyou_text">
              "Thank you for getting this over the line to an ever shorter
              deadline... Thanks again for all your help on the office front, we
              are settling in nicely at WeWork..."
            </p>
            <p>- Russell Nuce, COO</p>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row mt-3 bottom_para">
          <div className="col-1"></div>
          <div className="col-10">
            <p>
              Continued growth and a change in culture over the next 3 years
              forced their hand yet again and they instructed Reesa to dispose
              of their space and find new offices that could meet and adapt to
              their aggressive growth strategy. We explored both new leasehold
              space and serviced offices and after much technical comparison
              completed a short term licence on wework’s new centre in Holborn.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Lovecraft;
