import styled from "styled-components";
import * as variables from "../../../Style/__variables";
export const CheckoutComponent = styled.div`

   height:100%;
  .checkout_msg {
    background-color: #242b30;
    color:white;
    font-size:18px;
  
    .confirmation_screen {
      min-height: "400px";
      font-family: "Proxima Nova W01 Regular";
    }
  }
  .backgroundColorNane {
    
  }
  .checkout_form {
    .checkout_inbox_lable {
      line-height: 2.43;
      letter-spacing: 2px;
      color: ${variables.team_list_name};
      text-transform: uppercase;
    }
    .checkout_inbox {
      border: solid 1px ${variables.brown_grey};
      width: 80%;
      padding: 10px;
      outline: none;
    }
    .checkout_checkbox {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.43;
      letter-spacing: 2px;
      color: ${variables.team_list_name};
    }
    .checkout_submit_button {
      background-color: ${variables.darkPink};
      border: none;
      width: 80%;
      color: ${variables.white};
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
    }
  }
  @media screen and (max-width: 576px) {
    .checkout_form {
      .checkout_submit_button {
        margin-bottom: 50px;
      }
    }
  }
  .checkout_form {
    .checkout_inbox_lable {
      line-height: 2.43;
      letter-spacing: 2px;
      color: ${variables.team_list_name};
      text-transform: uppercase;
    }
    .checkout_inbox {
      border: solid 1px ${variables.brown_grey};
      width: 80%;
      padding: 10px;
      outline: none;
    }
    .checkout_checkbox {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.43;
      letter-spacing: 2px;
      color: ${variables.team_list_name};
    }
    .checkout_submit_button {
      background-color: ${variables.darkPink};
      border: none;
      width: 80%;
      color: ${variables.white};
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
    }
  }
  @media screen and (max-width: 576px) {
    .checkout_form {
      .checkout_submit_button {
        margin-bottom: 50px;
      }
    }
  }
`;
