import React from "react";
import "./review.scss";
import PersonOne from "../../../assets/icons/strategy/Person1.jpg";
import PersonTwo from "../../../assets/icons/strategy/Person2.jpg";
import Star from "../../../assets/icons/strategy/star.png";
import Bgimage from "../../../assets/icons/strategy/ap-quote-bg@2x.jpg";
import drop_down from "../../../assets/down-arrow.svg";
import Typography from "../../Typography";

function review() {
  const starIcon = [Star, Star, Star, Star, Star];
  return (
    <div className="main">
      <Typography
        className="text heading-title"
        variant="extraBold"
        size="xxxxLarge"
        mobileSize="mdLarge"
      >
        Don’t take our word for it
      </Typography>
      <div
        className="rectangle container-fluid"
        style={{ backgroundImage: `url(${Bgimage})` }}
      >
        <div className="row">
          <div className="col-sm-6 mb-sm-0 mb-4">
            <div className=" firstRectangle h-100 ">
              {starIcon.map((val) => (
                <img src={val} className="star" />
              ))}
              <Typography
                className="mt-5 reviewMessage  heading-title"
                variant="regular"
                size="xxxMedium"
                mobileSize="xxSmall"
                style={{ fontColor: "white" }}
              >
                “Over the last ten years REESA have become an extension of our
                team. Identifying the right buildings for us to invest into, at
                the right time of our growth, on tenant friendly terms. Their
                flair for interior design and passion for furniture has helped
                us unlock the full potential that our spaces have to offer our
                community.”
              </Typography>
              <Typography
                className="mt-4 text-right reviewMessageWriter  heading-title"
                variant="regular"
                size="xSmall"
                mobileSize="xxxSmall"
              >
                - D Oldfield, Unity Working
              </Typography>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="secondRectangle h-100">
              {starIcon.map((val) => (
                <img src={val} className="star" />
              ))}

              <Typography
                className="mt-5 reviewMessage  heading-title"
                variant="regular"
                size="xxxMedium"
                mobileSize="xxSmall"
              >
                “REESA worked with us closely to formulate a better performing
                real estate strategy for our UK operation. Designed from the
                employee outwards, making their experience better, whether that
                be from home, at our new Birmingham headquarters or on the road
                with our customers.”
              </Typography>
              <Typography
                className="mt-4 text-right reviewMessageWriter heading-title"
                variant="regular"
                size="xSmall"
                mobileSize="xxxSmall"
              >
                - P King, The Energy Comparison Service
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center GetTouchWrapper">
        <div className="row d-flex forLines">
          <div className="col-sm-6 justify-content-center text-center  MaleOne mb-sm-0 mb-4">
            <img src={PersonOne} className="PersonOne" />
          </div>
          <div className="col-sm-6 justify-content-center text-center FemaleTwo">
            <img src={PersonTwo} className="PersonTwo" />
          </div>
          <div className="boxContainer"></div>
        </div>
        <Typography
          className="GetTouch heading-title d-inline"
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="medium"
        >
          {" "}
          Get in touch today
        </Typography>
      </div>
    </div>
  );
}

export default review;
