export const areaOfInterestList = [
    { name: "Workplace Strategy", value: "workplaceStrategy" },
    { name: "Real Estate", value: "realEstate" },
    { name: "Furniture & Interiors", value: "furnitureInterious" },
    { name: "T.E.A.M", value: "team" },
  ];

  export const enquiryForList = [
    { name: "Approach", value: "approach" },
    { name: "Business Excellence", value: "businessExcellence" },
    { name: "Human Experience", value: "humanExperience" },
    { name: "Future Proofing", value: "futureProofing" },
    { name: "COVID-19 Response", value: "covid-19" },
  ];

  export const enquiryForListRs =[
    { name: "Property Search", value: "propertySearch" },
    { name: "M.O.R.E" ,value: "more" },
    { name: "Business Rates" ,value: "businessRates" },
    { name: "Property Disposals", value: "propertyDisposals" },
    { name: "Tenant Representation", value: "tenantRepresentation" },
    { name: "Building Consultancy", value: "buildingConsultancy"}
  ]

  export const enquiryForListFi = [
    { name:"Furniture Services", value:"furnitureServices"},
    { name:"Interior Design", value:"interiorDesign"},
    { name:"Audio Visual", value:"audioVisual"},
    { name:"Build", value:"build"},
    { name:"Store", value:"store"},
  ]
   
  export const enquiryForListteam = [
   { name:" People Services" , value:" peopleServices"},
   { name:" People Management" , value:" peopleManagement"},
   { name:" Candidate Registration" , value:" candidateRegistrations"},
   { name:" Work at REESA" , value:" WorkatReesa"},
  
  ]