import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import Timeline from "src/Component/timeline";
import {
  futureProofingData1,
  futureProofingData2,
  futureProofingData3,
} from "src/constants/FutureProofing";
import FP1 from "src/assets/FutureProofing/fp-banner.jpg";
import FP2 from "src/assets/FutureProofing/fp-img-1.jpg";
import FP3 from "src/assets/FutureProofing/fp-img-2.jpg";
import FP4 from "src/assets/FutureProofing/fp-img-3.jpg";
import FP5 from "src/assets/FutureProofing/fp-img-4.jpg";
import FP6 from "src/assets/FutureProofing/fp-img-5.jpg";
import FP7 from "src/assets/FutureProofing/fp-img-6.jpg";
import FP8 from "src/assets/FutureProofing/fp-footer.jpg";
import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const FutureProofing = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Future Proofing" image={FP1} />
      <SubHeader
        showBar={true}
        themeColor={"waterBlue"}
        subHeading={
          "Replace outdated Real Estate paradigms with cutting edge future fit practices"
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={"border-bottom-0"}
        timelineData1={futureProofingData1}
        timelineData2={futureProofingData2}
        timelineData3={futureProofingData3}
        image1={FP2}
        image2={FP3}
        image3={FP4}
        image4={FP5}
        image5={FP6}
        image6={FP7}
        formBg={FP8}
        themeColor={"waterBlue"}
        timelineDivider1Height="128px"
      />
      <Footer />
    </Fragment>
  );
};

export default FutureProofing;
