import React from 'react';
import { OrgnaiseViewComponent } from "./styledComponent";
import Typography from "../../Typography";
import person from '../../../assets/PropertySearch/person.png';

function OrganiseViewing() {
    return (
        <OrgnaiseViewComponent>
            <div className="row no-gutters">
                <div className="col-lg-4"></div>
                <div className="col-lg-8">
                    <div className="d-flex organise_view  py-4">
                        <img src={person} alt="person" className="organise_view_profile"/>
                        <div className='pt-4 pl-5'>
                            <Typography
                                className="text-center organise_text  heading-title "
                                variant="regular"
                                size="xxMedium"
                                mobileSize="xSmall"
                            >Checkout to discuss your shortlist with us
                           </Typography>
                            <Typography
                                className=" text-center organise_text heading-title "
                                variant="regular"
                                size="xxMedium"
                                mobileSize="xSmall"
                            >and to organise viewings
                        </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </OrgnaiseViewComponent>
    )
}
export default OrganiseViewing;