import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const MainHeader = styled.div`
  max-width: 1196px;
  padding: 0 20px;
  margin: auto;
  text-align: center;
  .main-heading {
    color: ${variables.darkPink};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 4.89px;
  }
  .main-sub-heading {
    text-transform: uppercase;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 2.4px;
    color: ${variables.team_list_name};
    .firstChar {
      color: ${variables.darkPink};
      font-weight: bold;
    }
  }
`;

export const ManagedSection = styled.div`
  max-width: 1196px;
  padding: 48px 20px 60px 20px;
  margin: auto;
  .managedHeading {
    line-height: 1.43;
    letter-spacing: 2px;
    margin: auto;
    margin-bottom: 20px;
    text-transform: uppercase;
    max-width: 413px;
  }
  .managedDesc {
    line-height: 1.5;
    letter-spacing: 1px;
    max-width: 413px;
    margin: auto;
    margin-bottom: 40px;
  }
  @media (max-width: 576px) {
    padding: 48px 20px;
  }
`;
export const IconsSection1 = styled.div`
  max-width: 1196px;
  padding: 60px 20px 96px 20px;
  margin: auto;
  .iconsSection1Desc {
    max-width: 240px;
    margin: auto;
    letter-spacing: 3px;
    line-height: 1.42;
  }
  .iconsSection1Image {
    min-height: 86px;
    margin-bottom: 41px;
  }
  @media (max-width: 576px) {
    padding: 0 20px 68px 20px;
    .iconsSection1Image {
      margin-bottom: 10px;
    }
  }
`;

export const BenifitsSection = styled.div`
  color: ${variables.white};
  background-color: ${variables.darkPink};
  padding: 62px 32px;
  .benifitsHeading {
    font-weight: bold;
    margin-bottom: 80px;
  }
  .benifitsDesc {
    letter-spacing: 3px;
    line-height: 1.42;
    margin-bottom: 40px;
  }
  .benifit-section-image {
    margin-bottom: 28px;
    height: 84px;
    object-fit: contain;
    width: 100%;
  }
  .icons2 {
    width: 20%;
  }
  @media (max-width: 768px) {
    .icons2 {
      width: 50%;
    }
  }
  @media (max-width: 576px) {
    .icons2 {
      width: 100%;
    }
  }
`;

export const RentSectionWrapper = styled.div`
  background-color: ${variables.darkPink};
  color: ${variables.white};
  & > .row {
    max-width: 1196px;
    padding: 70px 20px;
    margin: auto;
    .rentDesc {
      line-height: 1.5;
      letter-spacing: 1.71px;
    }
    .rentTitle {
      letter-spacing: 3px;
      line-height: 1.24;
      margin-bottom: 30px;
    }
    .listDesc {
      letter-spacing: 1.71px;
    }
    .listItem {
      letter-spacing: 1px;
    }
    svg {
      fill: ${variables.white};
    }
  }
`;

export const RatingSection = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  color: ${variables.team_list_name};
  padding: 46px;
  .starIcon {
    width: 26px;
    margin: 0 10px;
    g {
      fill: ${variables.darkYellow};
    }
  }
  .quoteSection {
    letter-spacing: 1.57px;
    line-height: 1.36;
    text-align: center;
    margin: 60px 0 50px 0;
  }
`;
