import React from 'react';
import { TrustedComponent } from "./styledComponent";
import Typography from "../../Typography";
import moo_com from '../../../assets/CaseStudies/moo_com.png';
import king from '../../../assets/CaseStudies/king.png';
import Visa from '../../../assets/CaseStudies/visa.png';
import forbes from '../../../assets/CaseStudies/forbes.png';
import centrica from '../../../assets/CaseStudies/Testimonial/centrica.png';

function TrustedBy() {
    return (
        <TrustedComponent>
            <Typography
                className="organise_text  heading-title "
                variant="bold"
                size="xxxxLarge"
                mobileSize="xxxxLarge"
            >Trusted by
               </Typography>
            <div className="d-flex justify-content-between flex-wrap  align-items-center">
            <div>  
            <img  src={centrica} alt={centrica} style={{minWidth:"180px"}}/>
            </div>
            <div>  
            <img  src={moo_com} alt={moo_com} style={{minWidth:"180px"}}/>
            </div>
            <div>  
            <img  src={king} alt={king} style={{minWidth:"180px"}}/>
            </div>
            <div>  
            <img  src={Visa} alt={Visa} style={{minWidth:"160px"}}/>
            </div>
            <div>  
            <img  src={forbes} alt={forbes} style={{minWidth:"180px"}}/>
            </div>
            </div>
        </TrustedComponent>

    )

}
export default TrustedBy;