import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const FilterSearchComponent = styled.div`
  .filter_search {
    height: 650px;
    .filter_search_row {
      padding: 80px 85px;
      .filter_search_heading {
        color: ${variables.white};
        line-height: 1.18;
        letter-spacing: 4.8px;
      }
      .filter_search_subheading {
        color: ${variables.white};
        padding: 30px 210px 0px 0px;
        line-height: 1.33;
        letter-spacing: 2px;
      }
      .search_form {
        background-color: ${variables.team_list_name};
        padding: 62px 62px;
        margin: auto;
        .location_text {
          color: ${variables.white};
          margin: 0 133px 4px 0;
          font-family: "Proxima Nova W01 Regular";
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.43;
          letter-spacing: 2px;
        }
        .location_box {
          width: 100%;
          border: 1px solid ${variables.team_list_name};
          outline: none;
          padding: 5px;
        }
        .leashold_checkout{
          padding: 0;
        }
        .service_type_checkbox {
          color: ${variables.white};
          .checkmark:after {
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
          }
          .container input:checked ~ .checkmark {
            background-color: black;
          }
        }
        

        .service_type_checkbox_lable {
          color: ${variables.white};
          font-family: "Proxima Nova W01 Regular";
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.14px;
        }

        .select_type {
          .select_type_lable {
            color: ${variables.white};
            font-family: "Proxima Nova W01 Regular";
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.43;
            letter-spacing: 2px;
          }
          .dropdown-wrapper {
            .dropdownArrow {
              position: absolute;
              width: 10px;
              right: 12px;
              top: 60px;
            }
            .lable_name {
              color: white;
            }

            .dropdownArrow1 {
              position: absolute;
              width: 10px;
              right: 35px;
              top: 55px;
            }
            &:after {
              content: " ";
              pointer-events: none;
            }
            .servicelist_dropdown_main {
            }

            .servicelist_dropdown {
              width: 100%;
              padding: 7px;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              text-indent: 1px;
              text-overflow: "";
            }
          }
        }

        .search_button {
          border: none;
          background-color: ${variables.darkPink};
          color: ${variables.white};
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          padding: 10px 15px;
          width: 100%;
          margin-top: 20px;
          margin: 35px 3px 0 1px;
          padding: 13px 21px 14px 24px;
          font-family: "Proxima Nova W01 Regular";
          letter-spacing: 2px;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .filter_search {
      .filter_search_row {
        padding: 25px 10px;
        .filter_search_heading {
          color: ${variables.white};
          line-height: 1.18;
          text-align: center;
        }
        .filter_search_subheading {
          color: ${variables.white};
          text-align: center;
          padding: 15px 0px;
        }
        .search_form {
          background-color: ${variables.team_list_name};
          padding: 15px 30px;
          width: 80%;
          margin: auto;
          
        }
      }
    }
  }
`;
