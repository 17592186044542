import React from "react";
import TextCard from "src/Component/InteriorDesign/text-card";
// import "./index.scss";
import TimelineCircle from "../timelineCircle";
import StepHeading from "../stepHeading";
import AdvisorsForm from "../AdvisorsForm/advisorsForm";
import { useContext } from "react";
import { ThemeProvider } from "styled-components";
import {
  BorderContainerOne,
  BorderContainerTwo,
  BorderContainerThree,
  BorderContainerFour,
  ImageContainerTwo,
  Section1,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Image1,
  Image2,
  Image3,
  Image7,
  BorderContainerFive,
} from "./styledComponent";
const Timeline = (props) => {
  const {
    formBg,
    totalSteps,
    person2ImageStep,
    borderContainerOneClassName,
    borderContainerTwoClassName,
    borderContainerFourClassName,
    section5ClassName,
    timelineData1,
    timelineData2,
    timelineData3,
    timelineData4,
    timelineData5,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    timelineDivider1Height,
    borderContainerWidth,
    divider1Height,
    step1HeadingMaxWidth,
    step2HeadingMaxWidth,
  } = props;
  const theme = {
    color: props.themeColor,
    borderContainerWidth: borderContainerWidth,
  };
  const renderStep1 = () => {
    if (totalSteps >= 1) {
      return (
        <>
          <Section1>
            <div className="timeline_circle_wrapper d-flex align-items-center">
              <TimelineCircle
                step={1}
                width={"4px"}
                height={
                  timelineDivider1Height ? timelineDivider1Height : "104px"
                }
              />
              <StepHeading className="px-4" maxWidth={step1HeadingMaxWidth}>
                {timelineData1.heading}
              </StepHeading>
            </div>
            <div className="row timeline_wrapper">
              <Image1
                className="col-sm-6 col-12 col-left d-flex justify-content-center h-100"
                divider1Height={divider1Height}
              >
                <img src={image1} className="w-100" />
              </Image1>
              <div className="col-sm-6 col-12 col-right">
                {timelineData1.content?.map(({ title, description }) => (
                  <TextCard
                    title={title}
                    description={description}
                    themeColor={props.themeColor}
                  />
                ))}
              </div>
            </div>
            <div className="section2 row">
              <div className="col-sm-6 col-12 col-left d-flex justify-content-center align-items-center ">
                <Image2>
                  <img src={image2} className="w-100" />
                </Image2>
              </div>
              <div className="col-sm-6 col-12  col-right d-flex justify-content-center">
                <Image3>
                  <img src={image3} className="w-100" />
                </Image3>
              </div>
            </div>
          </Section1>
          <Section3>
            <div
            
            >
              <img src={image4} className="w-100" />
            </div>
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: "0",
                maxWidth: "1240px",
                margin: "auto",
                padding: "0 20px",
                maxWidth: "1240px",
                left: "50%",
                transform: "translate(-50%, 0)",
                zIndex: "-1",
              }}
            >
              {" "}
              <BorderContainerFive></BorderContainerFive>
            </div>
          </Section3>
        </>
      );
    }
  };

  const renderStep2 = () => {
    if (totalSteps >= 2) {
      return (
        <Section4>
          {window.screen.availWidth > 576 && (
            <StepHeading
              maxWidth={step2HeadingMaxWidth ? step2HeadingMaxWidth : "65%"}
              className="step2Hedaing"
            >
              {timelineData2.heading}{" "}
            </StepHeading>
          )}
          <div className="row">
            <div className="col-sm-6 col-left col-12 ">
              {timelineData2.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
            </div>
            <div className="col-sm-6 col-right col-12 d-flex justify-content-center">
              <img src={image5} className="w-100 img-section-2" />
            </div>
          </div>
          <BorderContainerOne>
            <div className="step-two">
              {window.screen.availWidth <= 576 && (
                <StepHeading className="px-sm-0 px-4">
                  {timelineData2.heading}{" "}
                </StepHeading>
              )}
              <TimelineCircle
                step={2}
                width={"0"}
                height={"0px"}
                stepSide={"right"}
              />
            </div>
          </BorderContainerOne>
        </Section4>
      );
    } else {
      return null;
    }
  };

  const renderStep3 = () => {
    if (totalSteps >= 3) {
      return (
        <Section5 className={`${section5ClassName ? section5ClassName : ""}`}>
          <div className="row">
            <div className="col-sm-6 col-left col-12">
              <div
              className="image_6"
              >
                <img src={image6} className="w-100 h-100" />
              </div>
            </div>
            <div className="col-sm-6 col-right col-12 ">
              {timelineData3.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
              {person2ImageStep === 3 && (
                <Image7>
                  <img src={image9} className="w-100 " />
                </Image7>
              )}
            </div>
          </div>
          <BorderContainerTwo
            className={`${
              borderContainerTwoClassName ? borderContainerTwoClassName : ""
            }`}
          >
            <div className="step-three">
              <TimelineCircle
                step={3}
                width={"0"}
                height={"0px"}
                stepSide={"left"}
              />
              <StepHeading className="px-sm-0 px-4">
                {timelineData3.heading}{" "}
              </StepHeading>
            </div>
          </BorderContainerTwo>
        </Section5>
      );
    }
  };

  const renderStep4 = () => {
    if (totalSteps >= 4) {
      return (
        <Section6 className="section6 wrapper_container">
          {window.screen.availWidth > 576 && (
            <StepHeading maxWidth={"65%"} className="step4Hedaing">
              {timelineData4.heading}{" "}
            </StepHeading>
          )}
          <div className="row">
            <div className="col-sm-6 col-left col-12 ">
              {timelineData4?.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
            </div>
            <div className="col-sm-6 col-right col-12 d-flex justify-content-center">
              <div className="image_5">
                <img src={image7} className="w-100 h-100" />
              </div>
            </div>
          </div>
          <BorderContainerThree>
            <div className="step-four">
              {window.screen.availWidth <= 576 && (
                <StepHeading className="px-sm-0 px-4">
                  {timelineData4.heading}{" "}
                </StepHeading>
              )}

              <TimelineCircle
                step={4}
                width={"0px"}
                height={"0px"}
                stepSide={"right"}
              />
            </div>
          </BorderContainerThree>
        </Section6>
      );
    }
  };

  const renderStep5 = () => {
    if (totalSteps >= 5) {
      return (
        <Section7>
          <div className="row">
            <div className="col-sm-6 col-12 col-left d-flex justify-content-center">
              <div
                className="image_5"
              >
                <img src={image8} className='w-100 h-100'/>
              </div>
            </div>
            <div className="col-sm-6 col-right col-12">
              {timelineData5.content?.map(({ title, description }) => (
                <TextCard
                  title={title}
                  description={description}
                  themeColor={props.themeColor}
                />
              ))}
              {person2ImageStep === 5 && (
                <Image7>
                  <img src={image9} className="w-100" />
                </Image7>
              )}
            </div>
          </div>

          <BorderContainerFour
            className={`${
              borderContainerFourClassName ? borderContainerFourClassName : ""
            }`}
          >
            <div className="step-5">
              <TimelineCircle
                step={5}
                width={"0"}
                height={"0px"}
                stepSide={"left"}
              />
              <StepHeading className="px-sm-0 px-4">
                {timelineData5.heading}
              </StepHeading>
            </div>
          </BorderContainerFour>
        </Section7>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="main_wrapper">
        {renderStep1()}
        {renderStep2()}
        {renderStep3()}
        {renderStep4()}
        {renderStep5()}
        <AdvisorsForm bgImage={formBg} themeColor={props.themeColor} />
      </div>
    </ThemeProvider>
  );
};

export default Timeline;
