import * as actions from "../action";
import fireAjax from "../../services/fireAjax";
import { call, put } from "redux-saga/effects";

export function* enqueryForm(action) {
    try {
      const response = yield call(
        fireAjax,
        "POST",
        `${process.env.REACT_APP_BASE_URL}/api/enquire/submit?type=shortlist`,
        {},
        action.payload
      );
      if (response.statusText == "Accepted") {

        yield put(actions.enqueryFormSuccess(response.data));
      }
    } catch (e) {

      yield put(actions.enqueryFormError(e.response));
    }
}