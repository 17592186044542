import React, { Fragment, useState } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import team_banner from "src/assets/CaseStudies/team_banner.png";
import cas_footer from "src/assets/CaseStudies/cas_footer.png";
import Header from "src/Component/common/header";
import CaseStudiesSection from "src/Component/CaseStudies/CaseStudiesSection";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import SubHeader from "../../Component/SubHeader";

function CaseStudies(props) {
  const [activeIndex, setIndex] = useState(2);
  const handleSelect = (index) => {
    setIndex(index);
  };
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Clients" image={team_banner} />
      <SubHeader
        showBar={true}
        subHeading={`REESA work with a wide range of clients across the UK & Ireland providing a one stop shop for commercial property.
          `}
      />{" "}
      <CaseStudiesSection
        handleSelect={handleSelect}
        activeIndex={activeIndex}
      />
      <AdvisorsForm bgImage={cas_footer} />
      <Footer />
    </Fragment>
  );
}
export default CaseStudies;
