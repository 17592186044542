import React, { useState, useEffect } from "react";
import TextArea from "../TextArea/Textarea";
import Inputbox from "../Inputbox/input";
import DropDownList from "../dropDown/Dropdown";
import Typography from "src/Component/Typography";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AdvisorSection } from "./styledComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  areaOfInterestList,
  enquiryForList,
  enquiryForListRs,
  enquiryForListFi,
  enquiryForListteam,
} from "../../constants/footerform";
import {
  registerRequest,
  resetregistrationFormSuccess,
} from "../../redux/action";

function AdvisorsForm(props) {
  const { themeColor, fullScreen } = props;
  const [info, setInfo] = useState({
    name: "",
    email: "",
  });
  const [message, setMessage] = useState("");
  const [optValue, setOptValue] = useState({
    name: "Workplace Strategy",
    value: "workplaceStrategy",
  });
  const [enquiry, setEnquiry] = useState(enquiryForList);
  const [enquiryValue, setEnquiryValue] = useState({
    name: enquiry[0].name,
    value: enquiry[0].value,
  });
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setEnquiryValue({
      ...enquiryValue,
      name: enquiry[0].name,
      value: enquiry[0].value,
    });
  }, [enquiry]);
  const [button, setButton] = useState(false);

  const { bgImage } = props;
  const dispatch = useDispatch();
  const registrationReducer = useSelector((state) => state.registrationReducer);
  const handleFormEnqueryClick = (e) => {
    e.preventDefault();
    dispatch(
      registerRequest({
        email: info.email,
        name: info.name,
        message: message,
        areaofinterest: optValue.value,
        enquiry: enquiryValue.value,
      })
    );
  };
  useEffect(() => {
    if (info.name.length > 0 && info.email.length > 0) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [info]);
  const handleChangeInputbox = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };
  const handleMessage = (e) => {
    setMessage(e);
  };
  const handleSendagain = (e) => {
    dispatch(resetregistrationFormSuccess());
    setInfo({ name: "", email: "" });
  };
  const handelSelectArea = (val, type) => {
    setOptValue({ ...optValue, name: val.name, value: val.value });
  };
  const handelSelectEnquiry = (val, type) => {
    setEnquiryValue({ ...enquiryValue, name: val.name, value: val.value });
  };

  useEffect(() => {
    if (optValue.value === "workplaceStrategy") {
      setEnquiry(enquiryForList);
    } else if (optValue.value === "realEstate") {
      setEnquiry(enquiryForListRs);
    } else if (optValue.value === "furnitureInterious") {
      setEnquiry(enquiryForListFi);
    } else if (optValue.value === "team") {
      setEnquiry(enquiryForListteam);
    }
  }, [optValue]);

  const history = useHistory();
  const handlePrivacy = () => {
    window.location.href = "/privacy";
    // history.push('/privacy')
  };
  const handleTermCondition = () => {
    window.location.href = "/terms";
  };

  const handleCheckbox = (e) => {
    setChecked(!checked);
  };

  return (
    <AdvisorSection
      themeColor={themeColor}
      className="d-flex justify-content-center"
      fullScreen={fullScreen}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="advisors_form px-4 py-5">
        {registrationReducer.isSuccess == true ? (
          <div className="">
            <div className="confirmation">
              <span className="thankyou d-flex justify-content-center">
                Thank you!
              </span>
              <div className="sent_email">
                <span>We have sent a confirmation email to {info.email}</span>
              </div>
              <div className="received mt-4">
                <span>
                  Haven’t received it?
                  <span className="send ml-2" onClick={handleSendagain}>
                    Send again
                  </span>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Typography
              className="advisor_form_heading  text-center mb-5"
              variant="bold"
              size="xxxxMedium"
            >
              Our advisors can answer your questions and help you find what you
              are looking for…
            </Typography>
            <Form onSubmit={handleFormEnqueryClick}>
              <div className="row px-3">
                <div className="col-lg-6 mb- 3">
                  <Typography
                    className="lable_name"
                    variant="regular"
                    size="xxxSmall"
                  >
                    NAME
                  </Typography>
                  <Inputbox
                    type="text"
                    inputbox={`w-100 inputbox`}
                    handleChangeInputbox={handleChangeInputbox}
                    name="name"
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <Typography
                    className="lable_name"
                    variant="regular"
                    size="xxxSmall"
                  >
                    EMAIL
                  </Typography>
                  <Inputbox
                    type="text"
                    inputbox={`w-100 inputbox`}
                    handleChangeInputbox={handleChangeInputbox}
                    name="email"
                    required
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <Typography
                    className="lable_name"
                    variant="regular"
                    size="xxxSmall"
                    required
                  >
                    AREA OF INTEREST
                  </Typography>
                  <DropDownList
                    areaOfInterestList={areaOfInterestList}
                    classnames="dropdownBox text-muted"
                    optionClassname="optionList"
                    handelSelectValue={handelSelectArea}
                    type="areaofinterest"
                    optValue={optValue.name}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <Typography
                    className="lable_name"
                    variant="regular"
                    size="xxxSmall"
                  >
                    ENQUIRY FOR
                  </Typography>
                  <DropDownList
                    areaOfInterestList={enquiry}
                    classnames="dropdownBox text-muted"
                    optionClassname="optionList"
                    handelSelectValue={handelSelectEnquiry}
                    type="enquiryfor"
                    optValue={enquiryValue.name}
                  />
                </div>
                <div className="col-lg-12">
                  <Typography
                    className="lable_name"
                    variant="regular"
                    size="xxxSmall"
                  >
                    MESSAGE
                  </Typography>
                </div>
                <div className="col-lg-12 mb-2">
                  <TextArea
                    textArea="textArea w-100"
                    handleMessage={handleMessage}
                    required
                  />
                </div>
                <div className="col-lg-12 mb-2 text-white d-flex align-items-baseline justify-content-center mb-3">
                  <div className="justify-content-center row">
                    {" "}
                    <div className="col-11" style={{ letterSpacing: "3px" }}>
                      <Typography
                        className="lable_name"
                        variant="regular"
                        size="xxxSmall"
                      >
                        I confirm that I have read and agree to REESA
                        <Link onClick={handleTermCondition}>
                          <u className="ml-1" style={{ color: "white" }}>
                            Terms and Conditions
                          </u>
                        </Link>,
                        and
                        <Link onClick={handlePrivacy}>
                          {" "}
                          <u style={{ color: "white" }}>Privacy Policy</u>
                        </Link>
                        .
                      </Typography>
                    </div>
                    <div className="col-1">
                      <Form.Check
                        type="checkbox"
                        className="pt-1"
                        onClick={handleCheckbox}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-4 mb-5">                 
                  <Button
                    type="submit"
                    variant="info"
                    className="advisorSubmit rounded-0"
                    text="SEND"

                  >
                    <span style={{fontFamily:""}}>SEND</span>
                  </Button>
                </div>
              </div>
            </Form>
          </>
        )}
      </div>
    </AdvisorSection>
  );
}

export default withRouter(AdvisorsForm);
