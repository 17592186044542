import React from "react";

const CompassIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="94"
      viewBox="0 0 94 94"
    >
      <g className={className} fill="#a42e5d" fill-rule="evenodd">
        <g>
          <path
            d="M1332.016 61l11.079 9.24c1.581 5.006 4.59 9.473 8.64 12.834 3.421 2.841 7.586 4.893 12.265 5.812l-1.918 9.639-2.085 10.475-3.891-12.217c-14.854-5.582-24.557-19.997-24.09-35.783zm63.555 5.667l9.666 1.905 10.511 2.074-12.255 3.872c-5.603 14.776-20.061 24.432-35.897 23.967l9.266-11.024c5.022-1.573 9.503-4.567 12.875-8.596 2.85-3.403 4.909-7.546 5.834-12.198zM1371.889 44L1375 63.326l-1.877 1.947-1.877 1.95L1366.64 72l-2.557-6.16.235.57-1.22-2.944-.057-.137-1.04-2.51L1371.89 44zM1378 15l3.894 12.217c14.854 5.582 24.557 19.994 24.09 35.783l-11.079-9.24c-1.584-5.006-4.59-9.473-8.64-12.837-3.421-2.838-7.586-4.89-12.265-5.812l1.918-9.636L1378 15zm-43.743 33.733c5.6-14.777 20.061-24.43 35.897-23.965l-9.269 11.022c-5.022 1.575-9.503 4.566-12.875 8.598-2.85 3.4-4.908 7.54-5.833 12.199l-9.667-1.906-10.511-2.074z"
            transform="translate(-1322 -15)"
          />
        </g>
      </g>
    </svg>
  );
};

export default CompassIcon;
