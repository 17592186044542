import React from 'react';
import Typography from "../../Typography";
import './index.scss';

function CovidSubHeader (){
    return(
        <div className="covid_subHeader">
        <div className="py-3">
            <Typography
                className="covid_subHeader_text  text-center heading-title "
                variant="regular"
                size="xxMedium"
                mobileSize='xxxSmall'
            >Navigate a smooth transition 
            
            </Typography>
            <Typography
                className="covid_subHeader_text text-center heading-title "
                variant="regular"
                size="xxMedium"
                mobileSize='xxxSmall'
            >back to office-based work 
            </Typography>  
        <div className="subheader_row"></div>
        </div>
    </div>
    )
}
export default CovidSubHeader;