import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import './static-map.scss';
import { getStaticMapUrl } from 'src/constants/StaticMapboxDetails';

/**
 * @class Static Map
 *
 * Handles rendering of a space static map
 *
 * @param {number} lng - longitude co-ordinate of the space
 * @param {number} lat - latitude co-ordinate of the space
 * @param {number} width - width of the map
 * @param {number} height - height of the map
 * @param {number} zoom - zoom of the map
 */
export default class StaticMapWrapper extends Component {
  static propTypes = {
    lng: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    zoom: PropTypes.number
  }
  constructor(props) {
    super();
    this.state = {
      url: this.getUrl(props)
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    // only update if the map url has changed
    const boundsChanged = (nextProps.lng !== this.props.lng) ||
      (nextProps.lat !== this.props.lat);
    const sizeChanged = (nextProps.width !== this.props.width) ||
      (nextProps.height !== this.props.height);
    const zoomChanged = nextProps.zoom !== this.props.zoom;
    const { url } = this.state;
    const { url: nextUrl } = nextState;
    const urlChanged = url !== nextUrl;
    return boundsChanged || sizeChanged || zoomChanged || urlChanged;
  }
  componentWillReceiveProps() {
    this.setState({
      url: this.getUrl()
    });
  }
  componentDidMount() {
    this.setState({
      url: this.getUrl()
    });
  }
  getUrl({ lng, lat, zoom, width, height } = this.props) {
    return getStaticMapUrl({
      height,
      width,
      zoom,
      lat: parseNumber(lat),
      lng: parseNumber(lng),
      retina: true
    });
  }
  render() {
    const { lng, lat } = this.props;
    const { url } = this.state;
    if (typeof lng === 'undefined' || typeof lat === 'undefined') {
      throw new Error('Both lat and lng are required');
    }
    const style = {
      backgroundImage: `url("${url}")`,
      backgroundPosition: 'center',
      color: 'red',
      height: "250px",
      width: "400px",
      backgroundSize: "cover",
    };
    return <div style={style} />;
  }
}

function parseNumber(val) {
  const coord = parseFloat(val);
  if (isNaN(coord)) return 0;
  return coord;
}
