import styled from "styled-components";
import * as variables from '../../Style/__variables'
export const Filter_Stepper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  &.Stepper--inline {
    .Stepper__label {
      text-align: left;
      background: #fff;
      font-size: 15px;
      @media (576px) {
        font-size: 12px;
      }
    }
  }
`;

export const FilterStepperStep = styled.div`
  position: relative;
  text-align: center;
  // padding: 0.5rem;
  flex:1;
  .icon {
    fill:#fff;
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  &:after {
    content: " ";
    position: absolute;
    left: 50%;
    top:20px;
    transform: translate(0, 50%);
    width: 100%;
    height: 0.125rem;
    background-color: #fff;
    border: ${(props) =>
      props.activeIndex === 2 || props.activeIndex > props.indicator - 1
        ? "1.6px solid #a42e5d"
        : "1.6px dashed #a42e5d"};
    z-index: 1;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const FilterStepperIndicator = styled.div`
  position: relative;
  display: block;
  z-index: 2;
  top: 15px;
`;

export const StepperLabel = styled.div`
  position: relative;
  display: block;
  margin: 0.5rem 0;
  z-index: 2;
  font-size:15.2px;
  letter-spacing: 0.84px;

`;

export const FilterStepperInfo = styled.span`
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: #a42e5d;
  border-radius: 50%;
  background-color: #a42e5d;
  font-size: 0;
  line-height: 2.5rem;
  text-align: center;
  color: #a42e5d;
  z-index: 2;
`;
