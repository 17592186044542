import React, { Fragment, useState } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/Team/team-banner.jpg";
import TeamFooter from "src/assets/Team/team-footer.jpg";
import T1 from "src/assets/Team/team-img-1.jpg";
import T2 from "src/assets/Team/team-img-2.jpg";
import T3 from "src/assets/Team/team-img-3.jpg";
import T4 from "src/assets/Team/team-img-4.jpg";

import Footer from "../../Component/Footer/Footer";
import {
  teamsData1,
  teamsData2,
  teamsData3,
  teamsData4,
  teamsData5,
  teamsData6,
  teamsData7,
  teamsData8,
} from "src/constants/team";
import Timeline3 from "../../Component/timeline3";
import SubHeader from "../../Component/SubHeader";
import { MainHeader, QuoteSection, BenifitsSection } from "./styledComponent";
import Typography from "src/Component/Typography";
import TeamIcon1 from "../../assets/Team/team-icon-1.svg";
import TeamIcon2 from "../../assets/Team/team-icon-2.svg";
import TeamIcon3 from "../../assets/Team/team-icon-3.svg";
import TeamIcon4 from "../../assets/Team/team-icon-4.svg";
import TeamIcon5 from "../../assets/Team/team-icon-5.svg";

import TeamIcon6 from "../../assets/Team/team-icon-6.svg";
import TeamIcon7 from "../../assets/Team/team-icon-7.svg";
import TeamIcon8 from "../../assets/Team/team-icon-8.svg";
import TeamIcon9 from "../../assets/Team/team-icon-9.svg";
import TeamIcon10 from "../../assets/Team/team-icon-10.svg";
import {ReactComponent as BuildingIcon} from '../../assets/More/icons/buildings.svg';

const Icons1 = [
  {
    text: "People first approach",
    image: TeamIcon1,
  },
  {
    text: "Access to UK & Ireland talent pool",
    image: TeamIcon2,
  },
  {
    text: "Comprehensive vetting process",
    image: TeamIcon3,
  },
  {
    text: "Unique insight to what talent wants",
    image: TeamIcon4,
  },
  {
    text: "Trusted by SME’s, scale-ups & multinationals",
    image: TeamIcon5,
  },
];

const Icons2 = [
  {
    text: "Culture",
    image: TeamIcon6,
  },
  {
    text: "Wellness & ergonomics",
    image: TeamIcon7,
  },
  {
    text: "Training & development",
    image: TeamIcon8,
  },
  {
    text: "Compensation & benefits",
    image: TeamIcon9,
  },
  {
    text: "Candidate feedback ",
    image: TeamIcon10,
  },
];

const IconComponent = () => {
  return (
    <BenifitsSection>
      <div className="row  justify-content-around">
        {Icons2.map((val, i) => {
          return (
            <div className="text-center col-sm-4 col-lg-2 col-md-4 col-12">
              <img src={val.image} className="benifit-section-image" />
              <Typography
                size="xxMedium"
                variant="regular"
                className="benifitsDesc"
              >
                {val.text}
              </Typography>
            </div>
          );
        })}
      </div>
    </BenifitsSection>
  );
};

const Teams = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="" image={BgImage} />
      <SubHeader
        subHeading={`Driving company growth through talent attraction, people management and culture creation`}
      />
      <MainHeader>
        <Typography
          className="main-heading"
          size="ExtraLarge"
          variant="bold"
          mobileSize="xxxxxLarge"
        >
          T.E.A.M
        </Typography>
        <Typography
          className="main-sub-heading"
          size="mdLarge"
          mobileSize="small"
          variant="regular"
        >
          <span className="firstChar">T</span>alent{" "}
          <span className="firstChar">E</span>mployers{" "}
          <span className="firstChar">A</span>ttraction{" "}
          <span className="firstChar">M</span>anagement
        </Typography>
      </MainHeader>
      <QuoteSection>
        <div className="row align-items-center">
          <div className="col-sm-5 mb-4 mb-sm-0">
            <img src={T1} className="w-100" />
          </div>
          <div className="col-sm-7">
            <Typography
              size="xxxSmall"
              variant="regular"
              className="quoteTextSubheading"
            >
              REESA T.E.A.M works with clients to attract the best possible
              talent and build high performing, world class teams. 
            </Typography>
            <Typography
              size="mdLarge"
              variant="regular"
              mobileSize="small"
              className="quoteTextHeading"
            >
              “The secret to my success is that we’ve gone to exceptional
              lengths to hire the best people in the world.”
            </Typography>
            <Typography size="xSmall" variant="regular" className="writerName">
              — Steve Jobs
            </Typography>
          </div>
        </div>
      </QuoteSection>
      <BenifitsSection>
        <Typography
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="xxLarge"
          className="benifitsHeading"
        >
          Key Benefits
        </Typography>
        <div className="row  justify-content-around">
          {Icons1.map((val, i) => {
            return (
              <div className="text-center col-md-4 col-lg-2 col-12">
                <img src={val.image} className="benifit-section-image" />
                <Typography
                  size="xxMedium"
                  variant="regular"
                  className="benifitsDesc"
                >
                  {val.text}
                </Typography>
              </div>
            );
          })}
        </div>
        <div className="quoteTextWrapper">
          <Typography
            size="mdLarge"
            variant="regular"
            className="quoteText"
            mobileSize="xxxMedium"
          >
            “I’d rather interview 50 people and not hire anyone than hire the
            wrong person.”
          </Typography>
          <Typography
            size="small"
            variant="regular"
            className="writerName"
            mobileSize="xxSmall"
          >
            — Jeff Bezos
          </Typography>
        </div>
      </BenifitsSection>
      <Timeline3
        themeColor="darkPurple"
        heading={"Discover what’s possible "}
        timelineData1={teamsData1}
        timelineData2={teamsData2}
        timelineData3={teamsData3}
        timelineData4={teamsData4}
        timelineData5={teamsData5}
        timelineData6={teamsData6}
        timelineData7={teamsData7}
        timelineData8={teamsData8}
        themeColor="darkpurple"
        Image1={T2}
        Image2={T3}
        Image3={T4}
        IconComponent={IconComponent}
        formBg={TeamFooter}
      />
      <Footer
        color="darkPink"
        footerBtnHeading="GROWING?"
        footerBtnText="to find your new office"
        footerBtnLink="https://www.reesa.com/search"
        FooterIcon={BuildingIcon}
      />
    </Fragment>
  );
};

export default Teams;
