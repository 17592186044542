import styled from "styled-components";
import * as variables from "../../../Style/__variables";

export const SearchEmptyStateComponent = styled.div`
background: white;
display: flex;
flex-direction: column;
margin: 30px 30px 0;
overflow: auto;
height: calc(${(props) => props.mapHeight}px - 32px);
.rTapNumber303303{
    color : ${variables.darkPink}
}
  &::-webkit-scrollbar {
    width: 10px;
  }

  &:-webkit-scrollbar-track {
    background: ${variables.white};
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.darkGrey};
    border-radius:7px;
  }
.no_result_text{
    line-height: 29px;
    letter-spacing: 0;
}
`;