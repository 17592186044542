import React from "react";
import "./Prosek.scss";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import Post from "../../assets/CasestudiesItemone/post.jpeg";

function Prosek() {
  return (
    <div className="">
      <NavBar />
      <div className="container Main_container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <h1 className="prosek_header mt-5 mb-4">
              Prosek Partners – City, London
            </h1>
            <p>
              Acting for Prosek Partners who acquired the 4th and 5th floors of
              17 St Swithin’s Lane, London, EC4
            </p>
            <h3 className="mt-5 mb-3">BACKGROUND</h3>
            <p>
              Prosek Partners a highly successful US based financial
              communications consultancy. Headquartered in New York the company
              saw the potential for growth in the UK and opened their operations
              from a serviced office centre in Midtown. Success within the UK
              market gave them confidence to look for a more permanent property
              solution. They instructed Reesa to undertake a full market survey
              into leasehold options.
            </p>
            <h5 className="mt-5 mb-3">PROCESS</h5>
            <p>
              Prosek where keen to transition to new offices that better matched
              their success and culture. As a US company they wanted to test the
              UK market before committing to a long term lease so occupied
              serviced space for 5 years which was fit for purpose. They
              instructed us to explore longer term options that fitted their
              growth plans but also gave them the opportunity to further cement
              their culture and enhance their employees environment.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <img src={Post} className="mt-5 mb-5 w-100" />
        <div className="row mb-4">
          <div className="col-3"></div>
          <div className="col-6">
            <p className="basedCompany">
              {" "}
              "As a US based company hoping to expand its presence in London,
              Prosek Partners had limited knowledge of the UK commercial real
              estate market and limited time to secure new space. Piers very
              quickly brought us up to speed on the UK market, identified a wide
              range of viable options and provided excellent counsel in securing
              the ideal space for our expanding company. We highly recommend the
              service provided by Piers and his team at Reesa."
            </p>
            <p>- Russell Nuce, COO</p>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row mt-3 bottom_para">
          <div className="col-1"></div>
          <div className="col-10">
            <p>
              We took a brief and fine tuned their criteria before conducting a
              City wide search. We viewed 12 properties with the team and
              shortlisted this to 2 options for means of better comparison.
              Reesa conducted full negotiations and recommended a lawyer to work
              alongside us to lease completion. Our sister company White Bear
              then designed and project managed the fit out and furniture
              package of their new offices.
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Prosek;
