import React, { Fragment, useState } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import cas_footer from 'src/assets/CaseStudies/cas_footer.png';
import cr_banner from 'src/assets/CovidResponse/cr_banner.png';
import Header from "src/Component/common/header";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import CovidSubHeader from "../../Component/CovidResponse/CovidSubHeader";
import Timeline from '../../Component/timeline';
import {
    CovidResponseData1,
    CovidResponseData2,
    CovidResponseData3,
  } from "src/constants/CovidResponseData";
  import Room from "src/assets/CovidResponse/cr-img-1.png";
  import PersonImage from "src/assets/CovidResponse/cr-img-2.png"; 
  import RoundTable from "src/assets/CovidResponse/office.png";
  import SoofaImage from "src/assets/CovidResponse/cr-img-4.png";
  import ChairImage from "src/assets/CovidResponse/cr-img-5.png";
  import OfficeChairImage from "src/assets/CovidResponse/cr-img-6.png";
  import formBg from "../../assets/CovidResponse/formBg.png";
  import SubHeader from "../../Component/SubHeader";

function CovidResponse(props) {
   
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Covid Response"  image={cr_banner} />
      
       <SubHeader
        showBar={true}
        themeColor={"waterBlue"}
        subHeading={`Navigate a smooth transition back to office-based work `}
        maxWidth='490px'
      />
      <Timeline
        totalSteps={3}
        timelineData1={CovidResponseData1}
        timelineData2={CovidResponseData2}
        timelineData3={CovidResponseData3}
        image1={Room}
        image2={PersonImage}
        image3={RoundTable}
        image4={SoofaImage}
        image5={ChairImage}
        image6={OfficeChairImage}
        formBg={formBg}
        borderContainerTwoClassName={"border-bottom-0"}
        borderContainerFourClassName={"personTwoImageSection"}
        themeColor='waterBlue'
      />
      <Footer />
    </Fragment>
  )
}
export default CovidResponse; 