import * as variables from "../../Style/__variables";
import styled from 'styled-components';

export const StepHeadingStyled = styled.div`
  font-size: 36px;
  color: ${variables.team_list_name};
  font-weight: bold;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "756px")};
  line-height: 1.22;
    letter-spacing: 2.16px;
    font-stretch: normal;
  @media (max-width:576px) {
    font-size: 20px;
  }
`;
