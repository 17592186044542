import logo_rics_black from '../assets/AboutUs/RICS.jpg';
import Insurers from '../assets/AboutUs/au-img-6.png';
import Regulated from '../assets/AboutUs/au-img-7.png';
import Auditors from '../assets/AboutUs/au-img-8.png';
import Advisers from '../assets/AboutUs/au-img-9.png';
import Sponsorship from '../assets/AboutUs/au-img-10.png';
import Partner from '../assets/AboutUs/loughborough-uni.png';
import Charitable_Partner from '../assets/AboutUs/au-img-11.png';
import david from '../assets/AboutUs/Davidnew1.jpg';
import piers from '../assets/AboutUs/Piersnew1.jpg';
import seans from '../assets/AboutUs/Seannew1.jpg';

export const companyList = [
    {
        horizental: false,
        name: "Sustainability Policy",
        about: "REESA in addition to their own CSR and sustainability policy work with partners and suppliers who demonstrate a commitment to sustainability and the circular economy. This is particularly relevant in the manufacturing of furniture and workplace products."
    },
    {
        horizental: true,
        img: logo_rics_black,
        name: "Regulated by RICS",
        about: "REESA is a firm of certified and regulated Chartered Surveyors governed by the rules of conduct of the RICS."
    },
    {
        horizental: false,
        name: "Anti-Bribery & Corruption",
        about: "All employees receive training and sign our code of conduct as part of their employment contract. Corruption or bribery is unacceptable."
    }
]
export const aboutCompany = [
    { name: "Insurers", img: Insurers },
    { name: "Regulated by", img: Regulated },
    { name: "Auditors", img: Auditors },
    { name: "Legal Advisers", img: Advisers },
    { name: "Sponsorship", img: Sponsorship },
    { name: "Education Partner", img: Partner },
    { name: "Charitable Partner", img: Charitable_Partner },
]

export const leaderteam = [
    {
        img: seans,
        name: "SEAN DOOLEY",
        about: "Sean oversees our Workplace Strategy operation. He is a management consultant and trusted trouble-shooter. Graduating with a LLB (Hons) law degree in 2008."
    },
    {
        img: piers,
        name: "PIERS BARTTELOT",
        about: "Piers runs REESA’s Real Estate division. He has extensive experience across the EMEA region and qualified as a RICS Chartered Surveyor in 2001."
    },
    {
        img: david,
        name: "DAVID PERRINS",
        about: "David leads the firm’s Furniture & Interiors division. He is a specialist in ergonomics  and workplace products. Qualifying as a Chartered Surveyor in 2002."
    }
]