import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/AudioVisual/AVBgImage.jpg";
import Timeline from "src/Component/timeline";
import {
  buildServicesData1,
  buildServicesData2,
  buildServicesData3,
} from "src/constants/buildServices";
import BuildImage1 from "../../assets/Build/B1.jpg";
import BuildImage2 from "../../assets/Build/B2.jpg";
import BuildImage3 from "../../assets/Build/B3.jpg";
import BuildImage4 from "../../assets/Build/B4.jpg";
import BuildImage5 from "../../assets/Build/B5.jpg";
import BuildImage6 from "../../assets/Build/B6.jpg";
import BuildImage7 from "../../assets/Build/B7.jpg";
import PersonImage1 from "../../assets/Build/Person1.jpg";
import PersonImage2 from "../../assets/Build/Person2.jpg";

import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const BuildServices = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Build Services" image={BuildImage1} />
      <SubHeader
        showBar={true}
        subHeading={
          "A multi discipline commercial Build team, bringing astounding design to life; on time and budget"
        }
      />
      <Timeline
        borderContainerTwoClassName={"personTwoImageSection"}
        section5ClassName={'bottomSpacingPersonTwoSection'}
        totalSteps={3}
        timelineData1={buildServicesData1}
        timelineData2={buildServicesData2}
    
        timelineData3={buildServicesData3}
        image1={BuildImage2}
        image2={PersonImage1}
        image3={BuildImage3}
        image4={BuildImage4}
        image5={BuildImage5}
        image6={BuildImage6}
        formBg={BuildImage7}
        image9={PersonImage2}
        person2ImageStep={3}
        themeColor='greeny_blue'
      />
      <Footer />
    </Fragment>
  );
};

export default BuildServices;
