import { Viewport } from 'src/Component/PropertyMap';
import { mapDetails } from 'src/constants/MapboxDetails'
const mapboxUrl = 'https://api.mapbox.com';


export function getStaticMapUrl({
  bounds,
  height = 300,
  width = 300,
  zoom = 10,
  lat = 0,
  lng = 0,
  retina = false,
  showPin = true
}) {
  const viewport = new Viewport({
    longitude: lng,
    latitude: lat,
    zoom,
    width,
    height
  });

  let { longitude, latitude, zoom: viewportZoom } = viewport.values();

  if (bounds) {
    const fitViewport = viewport.fitBounds(bounds);
    const fitViewportValues = fitViewport.values();
    longitude = fitViewportValues.longitude;
    latitude = fitViewportValues.latitude;
    viewportZoom = fitViewportValues.zoom;
  }
  let url = `styles/v1/mapbox/streets-v10/static/`;
  if (showPin) url += `url-${encodeURIComponent("https://s3.eu-west-2.amazonaws.com/ofpro-website-eu-west-2-emails/location-icon.png")}(${longitude},${latitude})/`;
  url += [
    [longitude, latitude, viewportZoom].join(),
    `${1280}x${350}`
  ].join('/');
  return `${mapboxUrl}/${url}${retina ? '@2x' : ''}?access_token=${mapDetails.access_token}`;
}
