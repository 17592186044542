import React from 'react';
import './businessSpectrum.scss';
import early from "../../../assets/early.png";
import icon1 from '../../../assets/early-stage.svg';
import icon2 from '../../../assets/established.svg';
import icon3 from '../../../assets/growth.svg';
import icon4 from '../../../assets/enterprise.svg';
import Typography from "../../Typography";


function BusinessSpectrum() {
    return (
        <div className="business_spectrum scale-down element">
            <div className="business_spectrum_container px-5 w-100">
                <Typography
                    className="business_spectrum_heading pt-4 "
                    variant="extraBold"
                    size="xxxxLarge"
                    mobileSize='smLarge'
                >Across the business spectrum
                </Typography>
                <div className="business_spectrum_list row pt-5 pb-4" >
                    <div className="early_stage  col-lg-3 col-md-6">
                        <img src={icon1} alt="early" className="mx-auto d-block" />
                        <Typography
                            className="early_stage_heading text-center heading-title"
                            variant="regular"
                            size="xxMedium"
                            mobileSize='xxMedium'
                        >Early Stage
                        </Typography>
                        <Typography
                            className="early_stage_contain text-center py-3"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxSmall'
                        >Start-ups, trading less than 3 years, often with seed or Series A funding
                        </Typography>
                    </div>
                    <div className="early_stage col-lg-3 col-md-6">
                        <img src={icon2} alt="early" className="mx-auto d-block" />
                        <Typography
                            className="early_stage_heading text-center heading-title"
                            variant="regular"
                            size="xxMedium"
                            mobileSize='xxMedium'
                        >Established
                        </Typography>
                        <Typography
                            className="early_stage_contain text-center py-3"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxSmall'
                        >Trading for 3 years +, settled headcount
                        </Typography>
                    </div>
                    <div className="early_stage col-lg-3 col-md-6">
                        <img src={icon3} alt="early" className="mx-auto d-block" />
                        <Typography
                            className="early_stage_heading text-center heading-title"
                            variant="regular"
                            size="xxMedium"
                            mobileSize='xxMedium'
                        >Growth Stage
                        </Typography>
                        <Typography
                            className="early_stage_contain text-center py-3"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxSmall'
                        >Scale-ups, often funded by Series B or later stage investment
                        </Typography>
                    </div>
                    <div className="early_stage col-lg-3 col-md-6">
                        <img src={icon4} alt="early" className="mx-auto d-block" />
                        <Typography
                            className="early_stage_heading text-center heading-title "
                            variant="regular"
                            size="xxMedium"
                            mobileSize='xxMedium'
                        >Enterprise
                        </Typography>
                        <Typography
                            className="early_stage_contain text-center py-3"
                            variant="regular"
                            size="xxxSmall"
                            mobileSize='xxxSmall'
                        >Public sector, national & multinational firms
                        </Typography>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default BusinessSpectrum;