import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const PropertyCardComponent = styled.div`
.property_card {
 width : 350px;
  margin-right:18px;
  max-height: 500px;
  border: solid 0.8px #f5f5f5;
  background-color: #f8f8f8;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.21);
  .property_details{
    cursor: pointer;
    .property_bg{
       height: 182px; 
       width : 100%; 
       position: "relative"
    }
    .no_image{
      min-height : 182px;
      display: flex;
      justify-content: center;
    }
  }
  .card_img {
      position: absolute;
      right: 10px;
      bottom: 0px;
    .healthcare_button {
      background-color: ${variables.darkPink};
      color: ${variables.white};
      border: none;
      border-radius: 6px;
      padding: 5px 18px
    }
  }
  .card_details{
    .property_location{
      min-height: 100px;
      .property_address{
        line-height: 1.86;
        letter-spacing: 1.2px;
      }
      .property_sublocation{
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .price_list{
      min-height : 70px;
      
      .price_heading{
        line-height: normal;
        letter-spacing: normal;
        padding-right: 10px;
        border-right: 1px solid ${variables.brown_grey};
      }
      .price{
        color: ${variables.darkPink};
        text-align : center;
      }
      .null_price{
        color: ${variables.darkPink};
      }
    }
  }
  .shortlist{
    background-color: ${variables.darkPink};
    width: 100%;
    cursor: pointer;

    .shortlist_button{
      padding: 10px 0;
      .shortlist_button_text{
        font-size: 12px;
        font-size: 11.8px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.27px;
        text-align: center;
        color:  ${variables.white};
      }
    }
  }
  .shortlist_cancle{
    background-color: ${variables.white}; 
    width: 100%;
    cursor: pointer;
    .shortlist_button{
      padding: 10px 0;
      .shortlist_button_text{
        font-size: 12px;
        font-size: 11.8px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.27px;
        text-align: center;
        color: ${variables.darkPink};
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .property_card {
    max-width: 100%;
    width : 290px;
    margin: 0 24px;
    .property_details{
    cursor: pointer;
    .property_bg{
       height: 182px; 
       width : 100%; 
    }
  }
  }
}
`;