import React, { useState } from "react";
function Inputbox({name, type, placeholder, inputbox,handleChangeInputbox}) {
  const [requiredValue , setRequiredValue] = useState(parseInt(''))
  return (
    <input type={type}
      placeholder={placeholder}
      className={inputbox}
      name={name}
      onChange={handleChangeInputbox}
      required
    />
  )
}
export default Inputbox;