import React from "react";
import "./styledComponent.js";
import PropertyCard from "../PropertyCard";
import SearchEmptyState from "../PropertySearchResult/SearchEmptyState";
import { PropertyCardList } from "./styledComponent";
import { withRouter } from "react-router-dom";
import EmptyShortListItem from "../PropertyCheckout/EmptyShortlistItem";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

function PropertyResultList({
  mapHeight,
  isLoading,
  propertyData,
  handleShowCardDetails,
  handleCheckoutFormSubmit,
  handleCheckoutFormInput,
  activeMarker,
  inactiveInactiveCard,
  handleActiveMarkerCard,
  ...props
}) { 
  return (
    <div >
      {propertyData?.length > 0 ? (
          <PropertyCardList mapHeight={mapHeight}>
            <Element
              className={`element d-flex justify-content-start flex-wrap  mx-2 no-gutters  ${props.location.pathname.includes("property-checkout") ? "checkoutpropertyList" : "list"}`}
              name="test"
              id="containerElement"
            >
              {propertyData &&
                propertyData?.map((val) => (
                  <Element
                    className={`element mx-1  my-3 ${(activeMarker == val.id ) ? "active" : "inActiveCard"} ${inactiveInactiveCard == false ? "cardListActive" : ""}`}
                    name={val.id}
                  >
                    <PropertyCard
                      address1={val?.address1}
                      address2={val?.address2}
                      address3={val?.address3}
                      address4={val?.address4}
                      squareFootageMax={val?.squareFootageMax}
                      squareFootageMax={val?.squareFootageMax}
                      squareFootage={val?.squareFootage}
                      images={val.images}
                      priceHotDesk={val?.priceHotDesk}
                      priceLeasedManaged={val?.priceLeasedManaged}
                      pricePrivateOffice={val?.pricePrivateOffice}
                      propertyId={val?.id}
                      propertyallData={val}
                      handleShowCardDetails={(propertyId) =>
                        handleShowCardDetails(propertyId)
                      }
                      handleActiveMarkerCard={handleActiveMarkerCard}
                    />
                  </Element>
                ))}
            </Element>
          </PropertyCardList>
      ) : (
        <>
          {props.history.location.pathname.includes("property-checkout") ? (
            <EmptyShortListItem />
          ) : (
            <SearchEmptyState
              handleCheckoutFormSubmit={handleCheckoutFormSubmit}
              handleCheckoutFormInput={handleCheckoutFormInput}
              isLoading={isLoading}
              mapHeight={mapHeight}
            />
          )}
        </>
      )}
    </div>
  );
}
export default withRouter(PropertyResultList);
