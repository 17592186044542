import React from "react";
import Typography from "../../Component/Typography";


function SubmitButton({ text, advisorSubmit ,className,handleFormEnqueryClick, isLoading,button,checked}) {

  return (
    <button className={advisorSubmit} onClick={handleFormEnqueryClick}>
      <Typography variant="regular" size="xxxSmall" className={className} >
      {!isLoading ? text : "Loading......"}
      </Typography>
    </button>
  );
}
export default SubmitButton;
