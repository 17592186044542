import React from "react";
import './challengerConsultancy.scss'
import Typography from "../../Typography";
import group from "../../../assets/challenger/fixedSafari.png"


function ChallengerConsultancy() {
    return (
        <div className="consultancy-wrapper" name="test1">
            <Typography
                className="challenger heading-title ml-4"
                variant="extraBold"
                size="xxxxLarge"
                mobileSize='mdLarge'
            >
                The challenger consultancy
            </Typography>
            <div className="consultancy container-fluid">
                <img  src={group} className="center"/>  
            </div>
        </div>
    )
}

export default ChallengerConsultancy;