import styled from "styled-components";
import * as variables from "../../Style/__variables";

export const AddressSection = styled.div`
  .north_address {
    padding-left: -12px !important;
    .north_heading {
      color: ${variables.white};
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.71px;
      font-family: "Proxima Nova W01 Regular";
      margin-bottom: 26px;
    }
    .north_para_one {
      color: ${variables.white};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1px;
    }
    .north {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
    .north_para {
      color: ${variables.white};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1px;
      &:hover {
        text-decoration: underline;
      }
    }

    .vertical_line {
      width: 1px;
      background-color: ${variables.white};
      @media (max-width: 576px) {
        display: none;
      }
    }
  }
`;

export const HiringSection = styled.div`
  .hiring_box {
    background-color: ${(props) =>
      props.color ? variables[props.color] : variables.darkpurple};
    border-radius: 4px;
    padding: 14px 23px;
    .hiring_text {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2.67px;
      color: ${variables.white};
      text-transform: uppercase;
    }
    .build_team {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: 2.44px;
      color: ${variables.white};
      .underline {
        text-decoration: underline;
        a {
          color: ${variables.white};
          text-decoration: underline;
        }
        &:hover {
          ${"" /* border-bottom: 1px solid white; */}
          ${'' /* text-decoration: underline; */}
          cursor: pointer;
        }
      }
    }
  }
`;

export const Copyright = styled.div`
  .copy_right_text {
    color: ${variables.white};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    .north_para {
      &:hover {
        text-decoration: none;
      }
      .north_para_two {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

export const FooterSection = styled.div`
  height: ${(props) => (props.fullScreen ? "100vh" : "auto")};
  background-color: #242b30;
  @media screen and (max-width: 426px) {
    .social_icons {
      justify-content: flex-start !important;
      margin: 10px 30px 0px 0px !important;
    }
  }
`;
