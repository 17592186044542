import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import about_us_advisorform from "src/assets/AboutUs/about_us_advisorform.png";
import about_us_header from "src/assets/AboutUs/about_us_header.png";
import au_hero from "src/assets/AboutUs/au-hero-2.jpg";
import commercial_property_bg from "src/assets/AboutUs/au-hero-1.jpg";
import Header from "src/Component/common/header";
import Commercial from "src/Component/AboutUs/Commercial";
import Ourpeople from "src/Component/AboutUs/Ourpeople";
import LeadershipTeam from "src/Component/AboutUs/LeaderShipTeam";
import CompanyInformation from "src/Component/AboutUs/CompanyInformation";
import Ourstory from "src/Component/AboutUs/Ourstory";
import SubHeader from "../../Component/SubHeader";

function AboutUs(props) {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="About Us" image={about_us_header} />
      <SubHeader
        margin={" 60px auto"}
        subHeading={`REESA provides its clients and customers with commercial 
        property services and workplace products. Across the UK and Ireland we
         serve the full spectrum of organisations from big to small.`}
      />{" "}
      <Commercial
        title="We are the one stop shop for commercial property."
        image={commercial_property_bg}
        margin={'0 0 60px 0'}
      />
      <Commercial
        title="Trusted to be creative, professional and hard working."
        image={au_hero}
      />
      <SubHeader
        margin={" 90px auto"}
        subHeading={`We are proud of our history and progress, 
        and invite you to explore our time line to discover the
         evolution of our company. We hope you enjoy our website.`}
      />{" "}
      <Ourstory />
      <Ourpeople />
      <LeadershipTeam />
      <CompanyInformation />
      <AdvisorsForm bgImage={about_us_advisorform} />
      <Footer />
    </Fragment>
  );
}
export default AboutUs;
