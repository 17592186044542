import React from "react";
import CaseStudiesList from '../CaseStudiesList';
import ClientList from '../ClientList';
import Testimonial from '../Testimonials';
import Case_Studies from '../Case_Studies';

import './index.scss';
function CaseStudiesSection ({handleSelect ,activeIndex}){
    return(
     <div className="case_studies_section">
         
      <CaseStudiesList handleSelect={handleSelect} activeIndex={activeIndex}/>
     { activeIndex == 0 ? <Case_Studies /> : (activeIndex == 1 ) ?
      <Testimonial /> :  <ClientList /> }
     </div>
    )
}
export default CaseStudiesSection ;