export const humanExperienceData1 = {
  content: [
    {
      title: "Balance",
      description:
        "Your approach to how work is performed needs to become more flexible and adaptive to help your people manage the push and pull effect between personal, and professional life.",
    },
    {
      title: "Offering",
      description:
        "The work environments that you provide to your team should embody the work that they do, enabling them to focus, collaborate, learn and socialise, in order to prosper.",
    },
    {
      title: "Engagement",
      description: `When the offering to your team improves, so does their contribution to your organisation's overall success. And engaged employees are exactly the kind of people who care more about their working spaces.`,
    },
    {
      title: "The butterfly effect",
      description:
        "Our strategists join your organisation throughout its journey, giving you all of the foresight and resources that you need to make better decisions every little step of the way.",
    },
  ],
  heading:
    "Create a Workplace Strategy that reflects the vision of your brand and how it cares for its people",
};

export const humanExperienceData2 = {
  content: [
    {
      title: "Individual performance",
      description:
        "Wellness is a state in which people can reach their full potential.",
    },
    {
      title: "Workplace performance",
      description:
        "Organisations which invest in a wellbeing-led design are more likely to have higher levels of employee satisfaction and retention.",
    },
    {
      title: "Turn stress into success",
      description:
        "Negate the threats of presenteeism and absenteeism by creating a dynamic workplace which improves the health and wellbeing of your people.",
    },
    {
      title: "Success breeds success",
      description:
        "A healthier and happier workforce will help to reinforce the brand values and behaviours that your organisation wants to encourage.",
    },
  ],
  heading: "Is wellbeing worth the investment?",
};

export const humanExperienceData3 = {
  content: [
    {
      title: "Health risks",
      description:
        "Research has shown that sitting for long periods of time can cause everyday aches, pains and permanent damage to our bodies.",
    },
    {
      title: "Active sitting",
      description:
        "Keep your body moving whilst seated. Active chairs are designed to promote unconscious movement. Taking the pressure off your whole lumbar region through a move open angle between your legs and torso.",
    },
    {
      title: "Periodic standing",
      description:
        "Try alternating between sitting and standing every thirty minutes. Sit-stand desks are great, but even simply providing a diverse range of table heights throughout your communal spaces will help.",
    },
    {
      title: "Commercial realities",
      description:
        "Good furniture design does not have to cost the earth. Our furniture selection process identifies the products that offer you the best value for your budget and purchasing objectives.",
    },
  ],
  heading: `Understanding the importance of ergonomics `,
};
