import styled, { css } from "styled-components";
import * as variables from "../../Style/__variables";

export const TitleStyle = css`
  letter-spacing: 2px;
  line-height: 1.43;
`;
export const Badge = styled.div`
  background-color: ${variables.darkPink};
  letter-spacing: 0.32px;
  padding: 6px 22px;
  color: ${variables.white};
  border-radius: 4px;
`;

export const SlideerSection = styled.div`
  // max-width : 1300px;
  // margin : auto;
  .listing__slider{

  }
  .image_Slider{
    height : 356px;
    width : 100%;
  }
  img{
    display: inline-block; 
  }
  @media (max-width: 576px) {
    .image_Slider{
      width : 100vw;
      height : 245px;
      object-fit : contain;
    }
  }
`;

export const LeaseholdMainSection = styled.div`
  padding: 53px 62px;
  .heading {
    letter-spacing: 2.16px;
    line-height: 1.22;
  }
  .sub-heading {
    letter-spacing: 2px;
  }
  .title {
    ${TitleStyle};
    margin-bottom: 13px;
  }
  .rent {
    letter-spacing: 0.27px;
    line-height: 1.25;
  }
  .price {
    color: ${variables.darkPink};
    letter-spacing: -0.88px;
    line-height: 0.8;
    border-right: 1px solid ${variables.darkGrey};
  }
  .rightArrow{
      fill:${variables.brown_grey};
      width:15px;
      height:10px;
      margin:0 10px;
  }
  .tabText{
      color:${variables.brown_grey};
  }
  @media (max-width: 576px) {
    padding: 53px 20px;
  }
`;

export const RentSection = styled.div`
  padding-bottom: 40px;
  @media (max-width: 576px) {
    & > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
export const PriceListSection = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid ${variables.grey};
  @media (max-width: 576px) {
    & > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
export const DescriptionSection = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${variables.grey};
  .desc {
    letter-spacing: 1px;
    line-height: 1.5;
    .description_point{
      font-size: xx-large;
    }
  }
`;

export const ShortListSection = styled.div`
.shortlist_section{
  color: ${variables.darkPink};
  letter-spacing: 1.5px;
  padding: 15px 23px 0 0;
  border: 1px solid ${variables.darkPink};
  max-width: 239px;
  line-height : normal;
  margin: auto;
  cursor: pointer;
  @media (max-width: 767px) {
    margin: unset;
  }
}
.active_shortList_section {
    color: ${variables.white};
    background-color : ${variables.darkPink};
    letter-spacing: 1.5px;
    padding: 18px 23px;
    max-width: 239px;
    margin: auto;
    cursor: pointer;
  }
`;

export const CallSection = styled.div`
  letter-spacing: 1.44px;
  line-height: 1.42;
  img {
    margin-bottom: 38px;
  }
  .phoneNum {
    color: ${variables.darkPink};
  }
`;

export const LocationSection = styled.div`
  padding: 30px 0;
  .transport-links {
    letter-spacing: 2px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  .distanceSection {
    margin-bottom: 15px;
    letter-spacing: 1px;
    line-height: 1.5;
  }
  border-bottom: 1px solid ${variables.grey};

`;

export const AmenitiesSection = styled.div`
  padding: 30px 0;
  .listItem {
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  .checkedIcon {
    margin-top: -2px;
    margin-right: 12px;
    fill: ${variables.team_list_name};
  }
`;
