import React from 'react';
import './index.scss';
import Typography from "../../Typography";
import { testimonialData } from "src/constants/testimonial";

function Testimonial() {

    return (
        <div className="mt-5">
            {testimonialData.map(val => (
                <div className="testimonial">
                    <div className="row testimonial_row">
                        <div className="col-md-4 col-sm-12">
                            <img src={val.img} alt="green_giraffe" height="auto" width="70%" />
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <Typography
                                className=" heading-title "
                                variant="regular"
                                size="xxxMedium"
                                mobileSize='smLarge'
                            >{val.description1}
                            </Typography>
                            <Typography
                                className="mt-4  heading-title "
                                variant="regular"
                                size="xxxMedium"
                                mobileSize='smLarge'
                            >{val.description2}
                            </Typography>
                            <Typography
                                className="mt-4  heading-title "
                                variant="regular"
                                size="xxxMedium"
                                mobileSize='smLarge'
                            >{val.description3}
                            </Typography>
                            <p className="testimonial_details pt-3"><span>{val.name}</span>{val.location}</p>                            
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}
export default Testimonial;