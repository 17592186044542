
import * as constant from "../constant";
let localstorageData = JSON.parse(localStorage.getItem("shorlisted"));
const defaultState = {
    lastModified: localstorageData?.lastModified ? localstorageData?.lastModified : 0,
    items: localstorageData?.items?.length > 0 ? localstorageData?.items : []
};

const ShortListReducer = (state = defaultState, action) => {
    switch (action.type) {
        case constant.SHORTLIST_ADD: {
            return {
                ...state,
                lastModified: Date.now(),
                items: [...state.items, action.payload]
            };
        }
        case constant.SHORTLIST_REMOVE: {
            if (state?.items.length == 1) {
                localStorage.removeItem("shorlisted") 
            }
            return {
                ...state,
                lastModified: Date.now(),
                items: state?.items.filter(space => space?.id !== action?.payload?.id)
            };
        }
        default:
            return state;
    }
};
export default ShortListReducer;