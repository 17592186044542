import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import Inputbox from '../Inputbox/input';
import './index.scss';
import { lookupForward } from '../../constants/geocoder';

export default class Geocoder extends Component {
    static defaultProps = {
        endpoint: 'https://api.tiles.mapbox.com',
        inputClass: '',
        resultClass: '',
        resultsClass: '',
        resultFocusClass: 'strong',
        inputPosition: 'top',
        inputPlaceholder: 'Search',
        showLoader: false,
        source: 'mapbox.places',
        proximity: [],
        bbox: '',
        types: [],
        country: ['gb'],
        onSuggest() { },
        focusOnMount: true,
        intitialValue: '',
        disabled: false
    }
    state = {
        results: [],
        focus: null,
        loading: false,
        searchTime: new Date(),
        value: ''
    }
    static propTypes = {
        endpoint: PropTypes.string,
        source: PropTypes.string,
        inputClass: PropTypes.string,
        resultClass: PropTypes.string,
        resultsClass: PropTypes.string,
        inputPosition: PropTypes.string,
        inputPlaceholder: PropTypes.string,
        resultFocusClass: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        onSuggest: PropTypes.func,
        accessToken: PropTypes.string,
        proximity: PropTypes.array,
        bbox: PropTypes.string,
        showLoader: PropTypes.bool,
        focusOnMount: PropTypes.bool,
        types: PropTypes.array,
        country: PropTypes.array,
        intitialValue: PropTypes.string
    }
    componentDidMount() {
        const { intitialValue, focusOnMount } = this.props;
        if (focusOnMount) ReactDOM.findDOMNode(this.refs.input).focus();
        if (intitialValue) {
            this.setState({
                value: intitialValue
            });
        }
        if (!(intitialValue == "undefined")) {
            this.setState({
                value: intitialValue
            });
        }
    }

    onInput(e) {
        const value = e.target.value;

        this.setState({
            loading: true,
            value
        });
        if (value.length > 1) {
            if (value === '') {
                this.setState({
                    results: [],
                    focus: null,
                    loading: false
                });
            } else {
                lookupForward(value)
                    .then(entity => {
                        let resultBody = entity;
                        if (entity) {

                            resultBody = {
                                ...entity,
                                features: sortByRelevance(value, postResultsFilter(value, entity.features))
                            };
                        }
                        this.onResult(resultBody, Date.now());
                    });
            }
        }
    }
    moveFocus(dir) {
        if (this.state.loading) return;
        this.setState({
            focus: this.state.focus === null ?
                0 : Math.max(0,
                    Math.min(
                        this.state.results.length - 1,
                        this.state.focus + dir))
        });
    }
    acceptFocus() {
        if (this.state.focus !== null) {
            this.props.onSelect(this.state.results[this.state.focus]);
        }
    }
    onKeyDown(e) {
        const { focus, results } = this.state;

        switch (e.which) {
            // up
            case 38:
                e.preventDefault();
                this.moveFocus(-1);
                break;
            // down
            case 40:
                this.moveFocus(1);
                break;
            // accept
            case 13:
                if (results.length > 0 && focus == null) {
                    this.clickOption(results[0], 0);
                }
                this.clickOption(results[focus], focus);
                this.acceptFocus();
                break;
            default:
                break;
        }
    }
    onResult(body, searchTime) {

        if (body && body.features && this.state.searchTime <= searchTime) {

            this.setState({
                searchTime,
                loading: false,
                results: body.features,
                focus: null
            });
        }
    }
    clickOption(place, listLocation) {
        if (!place) return false;
        this.props.onSuggest(this.state.results);
        this.setState({
            value: getPlaceLabel(place),
            focus: listLocation
        });

        this.state.results = [];
        return false;
    }




    render() {

        const { focus } = this.state;
        const input = <input
            ref='input'
            className={cx('geocoder__input', {
                'geocoder__input--disabled': this.props.disabled
            })}
            onInput={(e) => this.onInput(e)}
            onKeyDown={(e) => this.onKeyDown(e)}
            placeholder={this.props.inputPlaceholder}
            type='text'
            value={this.state.value}
            disabled={this.props.disabled}
        />;

        return (
            <div className="geocoder">
                {input}
                {this.state.results.length > 0 && (
                    <ul className={`geocoder__results ${this.state.loading ? 'geocoder__results--loader' : ''}`} >
                        {
                            this.state.results.map((result, i) => {
                                const classes = cx(this.props.resultClass, {
                                    'geocoder__result--selected': i === focus
                                });
                                return (
                                    <li key={result.id} className="geocoder__list">
                                        <span
                                            className="geocoder__result"
                                            className="geocoder__result"
                                            onClick={this.clickOption.bind(this, result, i)}
                                            styleName={classes}
                                            key={result.id}>
                                            {
                                                getPlaceLabel(result)
                                            }
                                        </span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                )}

            </div>
        );
    }
}


function sortByRelevance(query, results = []) {
    const match = new RegExp(query, 'ig');
    const [matched, unMatched] = _.partition(results, r => match.test(r.text));
    return [
        ...matched,
        ...unMatched
    ];
}

// remove results that do not fully match the query
function postResultsFilter(query, results = []) {
    return results.filter(r => r.relevance > 0.98);
}

function getPlaceLabel({ text, context, place_name: placeName }) {

    if (placeName) {
        return placeName;
    }
    const place = context.find(c => c.id.startsWith('place'));
    if (place) {
        return `${text}, ${place.text}`;
    }
    return text;
}
