import React from 'react';
import './index.scss';
import blackrock from 'src/assets/CaseStudies/blackrock.png';
import deloitte from 'src/assets/CaseStudies/deloitte.png';
import ey from 'src/assets/CaseStudies/ey.png';
import forbes from 'src/assets/CaseStudies/forbes.png';
import iwg from 'src/assets/CaseStudies/iwg.png';
import lloyds from 'src/assets/CaseStudies/lloyds.png';
import tog from 'src/assets/CaseStudies/tog.png';
import wework from 'src/assets/CaseStudies/wework.png';
import seven from 'src/assets/CaseStudies/24_seven.png';
import adecco from 'src/assets/CaseStudies/adecco.png';
import adnologies from 'src/assets/CaseStudies/adnologies.png';
import affice_angels from 'src/assets/CaseStudies/affice_angels.png';
import aljazeera from 'src/assets/CaseStudies/aljazeera.png';
import barclays from 'src/assets/CaseStudies/barclays.png';
import britannia from 'src/assets/CaseStudies/britannia.png';
import connectv from 'src/assets/CaseStudies/connectv.png';
import credit_swiss from 'src/assets/CaseStudies/credit_swiss.png';
import criteol from 'src/assets/CaseStudies/criteol.png';
import daily_motion from 'src/assets/CaseStudies/daily_motion.png';
import digital from 'src/assets/CaseStudies/digital.png';
import g_sachs from 'src/assets/CaseStudies/g_sachs.png';
import hbvp from 'src/assets/CaseStudies/hbvp.png';
import jp_morgan_c from 'src/assets/CaseStudies/jp_morgan_c.png';
import kano from 'src/assets/CaseStudies/kano.png';
import king from 'src/assets/CaseStudies/king.png';
import liveops from 'src/assets/CaseStudies/liveops.png';
import media from 'src/assets/CaseStudies/media.png';
import mixcloud from 'src/assets/CaseStudies/adnologies.png';
import mobile_5 from 'src/assets/CaseStudies/mobile_5.png';
import moo_com from 'src/assets/CaseStudies/moo_com.png';
import navigate_d from 'src/assets/CaseStudies/navigate_d.png';
import osper from 'src/assets/CaseStudies/osper.png';
import pictet from 'src/assets/CaseStudies/pictet.png';
import reed_s from 'src/assets/CaseStudies/reed_s.png';
import rl_games from 'src/assets/CaseStudies/rl_games.png';
import rsa from 'src/assets/CaseStudies/rsa.png';
import satmetrix from 'src/assets/CaseStudies/satmetrix.png';
import serco from 'src/assets/CaseStudies/serco.png';
import technip from 'src/assets/CaseStudies/technip.png';
import whiptail_tech from 'src/assets/CaseStudies/whiptail_tech.png';
import totum from 'src/assets/CaseStudies/totum.png';
import control_circle from 'src/assets/CaseStudies/control_circle.png';

const clientLogoList = [digital,seven,adecco,adnologies,aljazeera,connectv,control_circle,credit_swiss,criteol,daily_motion,g_sachs,hbvp,jp_morgan_c,kano,king,liveops,media,mixcloud,mobile_5,moo_com,navigate_d,affice_angels,osper,pictet,rl_games,reed_s,rsa,satmetrix,serco,technip,totum,whiptail_tech,blackrock,deloitte,ey,forbes,iwg,lloyds,tog,wework,barclays,britannia]




function ClientList() {
    return (
        <div className="client_list  ">
            <div className="d-flex flex-wrap ">
                {clientLogoList.map(val => (
                    <div className="company_logo my-2 d-flex justify-content-center">
                        <img src={val} alt="val" className="logo_img"  />
                    </div>
                ))}
            </div>

        </div>
    )
}
export default ClientList;