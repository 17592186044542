import React from "react";
import Typography from "../../Typography";
import FilterStepper from "../../FilterStepper";
import { ReactComponent as Search } from "../../../assets/PropertySearch/search.svg";
import { FilterSubHeadingComponent } from "./styledComponent";

function FilterSunHeading() {
  return (
    <FilterSubHeadingComponent>
      <div className="filter_subheading">
        <div className="row no-gutters">
          <div className="col-sm-6 col-12">
            <Typography
              className="property_sub_heading heading-title "
              variant="bold"
              size="xxMedium"
              mobileSize="xSmall"
            >
              The UK & Ireland's fastest growing commercial property search
              company.
          </Typography>
          </div>
          <div className="col-sm-6 col-12">
              <FilterStepper index={0} Icon={Search} />
          </div>
        </div>
      </div>
    </FilterSubHeadingComponent>
  );
}
export default FilterSunHeading;
