import React from 'react';
import './commodity.scss';
import Typography from "../../Typography";

function Commodity() {
    return (
        <div>
            <div className="commodity">
                <div className="">
                    <div className="commodity_message">
                        <Typography
                            className=" commodity_heading heading-title"
                            variant="extraBold"
                            size="xxxxLarge"
                            mobileSize='xxxMedium'
                        >Strategy is a commodity,<br /> execution is an art.
                    </Typography>
                        <Typography
                            className=" commodity_para heading-title"
                            variant="regular"
                            size="xxxxMedium"
                            mobileSize='xxxxSmall'
                        >We help executives understand this, and then work with <br /> them to ensure their business model is aligned to their <br /> Real Estate Strategy so that they can respond effectively <br /> to new opportunities or disruptive market forces.
                   </Typography>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default Commodity;