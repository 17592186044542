import React, { Fragment } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import Timeline from "src/Component/timeline2";
import {
  furnitureServicesData1,
  furnitureServicesData2,
  furnitureServicesData3,
} from "src/constants/furnitureServices";
import FS1 from "src/assets/furnitureServices/FS1.jpg";
import FS2 from "src/assets/furnitureServices/FS2.jpg";
import FS3 from "src/assets/furnitureServices/FS3.jpg";
import FS4 from "src/assets/furnitureServices/FS4.jpg";
import FS5 from "src/assets/furnitureServices/FS5.jpg";
import FS6 from "src/assets/furnitureServices/FS6.jpg";
import FS7 from "src/assets/furnitureServices/FS7.jpg";
import Lady from "src/assets/furnitureServices/Lady.jpg";
import Person1 from "src/assets/furnitureServices/Person1.jpg";


import SubHeader from "../../Component/SubHeader";
import Footer from "../../Component/Footer/Footer";

const FurnitureServices = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title="Furniture Services" image={FS1} />
      <SubHeader
        showBar={true}
        themeColor="greeny_blue"
        subHeading={
          "Expert advice and access to the best workplace products so that you can create productive, creative and healthy work environments"
        }
      />
      <Timeline
        totalSteps={3}
        timelineData1={furnitureServicesData1}
        timelineData2={furnitureServicesData2}
        timelineData3={furnitureServicesData3}
        image1={FS2}
        image2={Person1}
        image3={FS3}
        image4={FS4}
        image5={FS5}
        image6={FS6}
        image9={Lady}
        formBg={FS7}
        person2ImageStep={3}
        themeColor="greeny_blue"
      />
      <Footer />
    </Fragment>
  );
};

export default FurnitureServices;
