import { Typo } from "./styledComponent";
import React from 'react'
const Typography = (props) => {
  const { variant, size, className, mobileSize} = props;
  return (
    <Typo
      variant={variant}
      size={size}
      mobileSize={mobileSize}
      className={className ? className : ""}
    >
      {props.children}
    </Typo>
  );
};

export default Typography;
