import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Cart } from "../../assets/leasehold/cart.svg";
import { getPropertyDataClear, getPropertyDataRequest,enqueryFormRequest } from 'src/redux/action';
import { useSelector, useDispatch } from 'react-redux'
import NavBar from "src/Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import AdvisorsForm from "../../Component/AdvisorsForm/advisorsForm";
import pr_s_footer from "src/assets/PropertySearch/property_checkout_footer.png";
import PropertyResultSubHeading from "src/Component/PropertySubHeading";
import OrganiseViewing from "src/Component/PropertyCheckout/OrganiseViewing";
import CheckoutSection from "src/Component/PropertyCheckout/CheckoutSection";
import TrustedBy from "src/Component/PropertyCheckout/TrustedBy";
import { checkout } from "superagent";

function PropertyCheckout(props) {
  const [propertyData, setPropertyData] = useState([])
  const [checkoutForm, setCheckoutForm] = useState({
    "name": "",
    "email": "",
    "phoneNumber": 0,
    "copyRequested" : false,
    "shortlistedId" : []
   })
  const dispatch = useDispatch();
  const getPropertyData = useSelector((state) => state.getPropertyData);
  const ShortListReducer = useSelector((state) => state.ShortListReducer);
  const enqueryFormReducer = useSelector((state) => state.enqueryFormReducer.isSuccess);
  let urlLocation = props.location;
  let urlMatch = props.match.params;

  useEffect(() => {
    setPropertyData(getPropertyData?.data)
  }, [getPropertyData])

  useEffect(() => {
   
    return () => {
      dispatch(getPropertyDataClear());
    }
  }, [])

  useEffect(() =>{
    let Id =[]
    if(ShortListReducer?.items.length > 0){
      ShortListReducer?.items.map(val =>{
        Id.push(val.id)
      })
    }
    setCheckoutForm({...checkoutForm, shortlistedId : Id  })
  },[ShortListReducer])
 
  const handleBack = () => {
    //  props.history.replace(`property-search/${urlMatch.type}/${urlMatch.propertyType}/${urlMatch.desk_min}/${urlMatch.desk_max}/${urlMatch.location}/${urlLocation.state.northeastlat}/${urlLocation.state.northeastlng}/${urlLocation.state.southwestlat}/${urlLocation.state.southwestlng}`)
    props.history.goBack()
  }

  const handleCheckoutFormInput = (e) => {
    setCheckoutForm({ ...checkoutForm, [e.target.name]: e.target.value })
  }

  const handleCheckoutFormcheckbox = () =>{
    setCheckoutForm({ ...checkoutForm, copyRequested : !checkoutForm.copyRequested})
  }

  const handleCheckoutFormSubmit = (e) =>{
    e.preventDefault();
    dispatch(enqueryFormRequest({
      copyRequested : checkoutForm.copyRequested,
      email : checkoutForm.email,
      message : "",
      name : checkoutForm.name,
      phone : checkoutForm.phoneNumber,
      spaces : checkoutForm.shortlistedId
    }))
  }

  const handleShowCardDetails = (propertyId) =>{
    props.history.push(`/listing/${propertyId}`)
  }
  


  return (
    <Fragment>
      <NavBar history={props.history} />
      <PropertyResultSubHeading
        pageHeading="Your shortlist"
        pageSubHeading="Speak to an advisor, arrange property tours and receive a copy of your shortlist"
        backButtom={false}
        stepper={true}
        stepperIndex={2}
        stepperIcon={Cart}
      />

      <OrganiseViewing />
      <CheckoutSection
        handleCheckoutFormInput={(e) => handleCheckoutFormInput(e)}
        handleCheckoutFormcheckbox={handleCheckoutFormcheckbox}
        handleCheckoutFormSubmit={(e) => handleCheckoutFormSubmit(e)}
        ShortListReducer={ShortListReducer}
        handleBack={handleBack}
        handleShowCardDetails={(propertyId) =>handleShowCardDetails(propertyId)} 
        propertyData={ShortListReducer?.items} 
        enqueryFormReducer={enqueryFormReducer}
        email={checkoutForm.email}
        />
      <TrustedBy />
      <AdvisorsForm bgImage={pr_s_footer} />
      <Footer />
    </Fragment>
  );
}
export default PropertyCheckout;
